import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
	Autocomplete,
	AutocompleteChangeDetails,
	AutocompleteChangeReason,
	AutocompleteProps,
	TextFieldProps
} from '@mui/material';
import CustomTextField from '../CustomTextField';
import { ReactComponent as ArrowDownIcon } from '../../../assets/arrow-triangle-down.svg';
import colors from 'assets/theme/base/colors';
import LoaderComponent from 'components/Loader';
import { debounce } from '@mui/material';

import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import { setResourceMappingDataCache } from 'Context/contexts/cacheData/resourceMapping';

interface Option {
	value: string;
	label: string;
}

interface CustomAutocompleteProps extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> {
	textFieldLabel?: string | JSX.Element;
	customTextFieldProps?: TextFieldProps;
	onChange?:
		| ((
				event: React.SyntheticEvent,
				value: any,
				// reason: AutocompleteChangeReason,
				// details?: AutocompleteChangeDetails<any> | undefined
		  ) => void)
		| undefined;
	renderInput?: (params: any) => React.ReactNode;
	options: Option[];
	fieldValueOptionsCallback?: (input: string) => Promise<Option[]>;
	mappingType: string;
	columnName: string;
}

const useDebouncedHandleBlur = (
	callback: (
		defOpt?: Option[],
		callbackFn?: React.Dispatch<React.SetStateAction<Option[]>>,
		loadingCallback?: React.Dispatch<React.SetStateAction<boolean>>
	) => void,
	delay: number
) => {
	const timeoutRef = useRef<number | null>(null);

	const debouncedHandleBlur = (
		defOpt?: Option[],
		callbackFn?: React.Dispatch<React.SetStateAction<Option[]>>,
		loadingCallback?: React.Dispatch<React.SetStateAction<boolean>>
	) => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = window.setTimeout(() => {
			callback(defOpt, callbackFn, loadingCallback);
		}, delay);
	};

	return debouncedHandleBlur;
};

const AsyncAutoComplete: React.FC<CustomAutocompleteProps> = ({
	textFieldLabel,
	customTextFieldProps,
	renderInput,
	onChange,
	options = [],
	fieldValueOptionsCallback,
	columnName,
	mappingType,
  	inputValue,
	...rest
}) => {
	const [autoInputValue, setInputValue] = useState(inputValue ?? '');
	const [searchTerm, setSearchTerm] = useState('');
	const [defaultOptions, setDefaultOptions] = useState<Option[]>(options);
	const [filteredItems, setFilteredItems] = useState<Option[]>(options);
	const [loading, setLoading] = useState(false);

	const [controller, dispatch] = useCacheDataController();
	const {resourceMapping} = controller;
	
	const isFetchOptionEnabled = !!fieldValueOptionsCallback && searchTerm.length > 2;

	const toggleDropdown = useDebouncedHandleBlur(
		(
			defOpt?: Option[],
			callbackFn?: React.Dispatch<React.SetStateAction<Option[]>>,
			loadingCallback?: React.Dispatch<React.SetStateAction<boolean>>
		) => {
			if (defOpt?.length === 0) {
				loadingCallback?.(true);
				fetchOptions('')
					.then((opts) => callbackFn?.(opts))
					.finally(() => loadingCallback?.(false));
			}
		},
		10
	);

	const fetchOptions = async (searchInput: string): Promise<Option[]> => {
		try {
			if (fieldValueOptionsCallback) {
				
				const optionArray = await fieldValueOptionsCallback(searchInput);
				let searchInputValue = searchInput.toLowerCase();
				const searchOptionsKey = `${columnName}-${searchInputValue}`;// combination of search field and search input value
				// Store the fetched options in the resourceMapping context
				dispatch({
					type: 'CACHE_SEARCH_INPUT_OPTIONS_DATA',
					resourceMappingTableType: mappingType, // resource mapping table type
					searchFieldSearchInput: searchOptionsKey,
					value: optionArray // The fetched options
				});
				return optionArray;
			}
			return [];
		} catch (error) {
			console.error('Error fetching options:', error);
			return [];
		}
	};

	useEffect(() => {
		if (!isFetchOptionEnabled && defaultOptions.length > 0) {
			setFilteredItems(defaultOptions);
		}
	}, [defaultOptions]);

	const debouncedFetchOption = useCallback(
		debounce((newValue) => {

			let searchInput = newValue.toLowerCase();
			// Check if the mappingType-newValue exists in the resourceMapping context
			const searchOptionsKey = `${columnName}-${searchInput}`; // combination of search field and search input value
			const existingSearchFilterOptions = resourceMapping?.[mappingType]?.[searchOptionsKey];

			// If options exist in the resourceMapping, use them from context directly
			if (existingSearchFilterOptions) {
				setFilteredItems(existingSearchFilterOptions);
			} else if (!!fieldValueOptionsCallback && newValue.length > 2 && !existingSearchFilterOptions) {
				setLoading(true);
				fetchOptions(newValue)
					.then((option) => {
						setFilteredItems(option);
					})
					.finally(() => setLoading(false));
			} else {
				setFilteredItems(defaultOptions.filter((item) => item.value.toLowerCase().includes(newValue.toLowerCase())));
			}
		}, 300),
		[fieldValueOptionsCallback, defaultOptions, resourceMapping, dispatch]
	);

	const handleInputChange = (e: any, newInputValue: string) => {
		setSearchTerm(newInputValue);
		debouncedFetchOption(newInputValue);
		setInputValue(newInputValue);
		onChange?.(e, newInputValue);
	};

	return (
		<Autocomplete
			options={ filteredItems.length > 0 ? filteredItems : defaultOptions }
			onFocus={() => toggleDropdown(defaultOptions, setDefaultOptions, setLoading)}
			getOptionLabel={(option) => (typeof option === 'object' ? option.label : option)}
			inputValue={autoInputValue}
			onInputChange={(e, newInput, reason) => handleInputChange(e, newInput)}
			{...rest}
			sx={{
				'& .MuiAutocomplete-popupIndicator': {
					color: 'white.main'
				},
				'& .MuiAutocomplete-clearIndicator': {
					color: 'white.main'
				},
				'& .MuiInputBase-input.Mui-disabled': {
					WebkitTextFillColor: 'light.diff'
				},
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: `${colors.dark.dark} !important`
				},
				'& .MuiInputBase-root': {
					border: 'none',
					fontSize: '14px',
					fontWeight: '500',
					minWidth: '15rem'
				},

				...rest.sx
			}}
			popupIcon={<ArrowDownIcon />}
			renderInput={
				renderInput ||
				((params) => (
					<CustomTextField
						{...params}
						label={textFieldLabel}
						{...customTextFieldProps}
						InputProps={{ ...params.InputProps, ...customTextFieldProps?.InputProps }}
					/>
				))
			}
			noOptionsText={
				loading ? <LoaderComponent size={20} /> : 'No options'
			  }
		/>
	);
};

export default AsyncAutoComplete;

import { Stack } from '@mui/material';

type TableCheckboxProp = {
	checked: boolean;
	onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

const TableCheckbox = ({ checked, onChange }: TableCheckboxProp) => {
	return (
		<Stack
			component="input"
			type="checkbox"
			sx={{
				WebkitAppearance: 'none',
				MozAppearance: 'none',
				appearance: 'none',
				borderColor: 'light.diff',
				borderWidth: '1px',
				borderStyle: 'solid',
				borderRadius: '3px',
				width: '20px',
				height: '20px',
				backgroundColor: 'transparent',
				cursor: 'pointer',
				'&:checked': {
					backgroundColor: 'info.main',
					borderColor: '#00B9FF',
					position: 'relative',
					'&::before': {
						content: '"✓"',
						position: 'absolute',
						left: '4px',
						top: '1px',
						fontSize: '14px',
						lineHeight: '16px',
						color: '#fff'
					}
				}
			}}
			className="row-checkbox"
			checked={checked}
			onChange={onChange}
		/>
	);
};

export default TableCheckbox;

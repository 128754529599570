import { BoxProps } from '@mui/material';
import AutoCompleteIndex from './AutoCompleteIndex';
import { Padding } from '@mui/icons-material';

type ProgramFilterProps = {
    programName: string;
    setProgramName: React.Dispatch<React.SetStateAction<string>>;
    programOptionsList: string[];
    handleChange?: (event: any, value: string) => void;
    boxProps?: BoxProps;
	searchInputLoading? : boolean;
};

const programFilter = {
    backgroundColor: '#003771',
    position: 'fixed',
    right: '215px',
    minWidth: '210px !important',
    '& fieldset': {
        display: 'none',
    },
};

const dropdownPaperStyles = {
    fontSize: '12px',
    height: '160px',
};

const inputSx = {
	'& .MuiInputBase-root': {
		padding: 0, 
		'& .MuiAutocomplete-input': {
			fontSize: '15px',
			padding: '10px 4px 10px 8px',
		},
	},
};

const ProgramFilter = ({ 
    programName,
    setProgramName,
    programOptionsList,
    handleChange,
	searchInputLoading  
}: ProgramFilterProps) => {
    
    const handleProgramChange = (event: any, val: any) => {
        handleChange?.(event, val);
    };

    const handleInputChange = (event: any) => {
		const { target: { value } } = event;
        const valueTrim = value.trim();
		setProgramName(valueTrim);
    };

    const options = [
        { label: 'All Programs', value: 'all', displayLabel: 'All Programs' },
        ...programOptionsList.map(option => ({
            label: option,
            value: option,
			displayLabel: option.length > 25 ? option.substring(0, 25) + '...' : option
        })),
    ];

    return (
        <AutoCompleteIndex
            value={programName} 
            handleChange={handleProgramChange}
            handleInputChange={handleInputChange}
            options={options}
            boxProps={{
                sx: programFilter,
            }}
            componentsProps={{
                paper: {
                    sx: {
                    	fontSize: '12px',
                        // height: '160px',
                    },
                },
            }}
			inputSx={inputSx}
			searchInputLoading = {searchInputLoading}
			noOptionsText={'No program found'}
        />
    );
};

export default ProgramFilter;

import { Box, BoxProps, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';

type SearchFilterProps = {
    handleSearchData?: (value: string) => void;
};

const SearchField: React.FC<TextFieldProps> = ({ ...props }) => {
	return (
		<Box
			sx={{
				position: 'sticky',
				top: -16,
				zIndex: 1
			}}
		>
			<TextField
				variant="outlined"
				placeholder="Search"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon color="info" />
						</InputAdornment>
					)
				}}
				sx={{
					backgroundColor: '#002349',
					backdropFilter: 'blur(10px)',
					borderRadius: '4px',
					color: 'white.main',
					input: {
						py: 1,
						px: 0,
						'&::placeholder': {
							fontSize: '12px'
						}
					},
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							border: 'none'
						},
						'&:hover fieldset': {
							border: 'none'
						},
						'&.Mui-focused fieldset': {
							border: 'none'
						},
						'&.Mui-focused': {
							borderBottom: '10px solid green !important'
						}
					}
				}}
				{...props}
			/>
		</Box>
	);
};

const SearchFilter = ({ handleSearchData }: any) => {
    const [searchInputText, setSearchInputText] = useState('');

    const handleSearch = (e: any) => {
		e.stopPropagation();
		const searchKeyword = (e.target as HTMLInputElement).value.toLowerCase();
		setSearchInputText(searchKeyword);
		if (searchKeyword?.length >= 3 || !searchKeyword) {
			handleSearchData?.(searchKeyword);
		}
	};

    return (
		<SearchField
            id="searchField"
            autoComplete="off"
            variant="standard"
            placeholder="Search"
            value={searchInputText}
            onChange={(e: any) => {
                handleSearch(e);
            }}
        />
	);
};

export default SearchFilter;

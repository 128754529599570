import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import { Grid, IconButton, Box, Chip, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PlusIcon from '@mui/icons-material/Add';
import moment from 'moment-timezone';
import { ThemeProvider, styled } from '@mui/material/styles';

import '../DataPipeline/dataPipeline.scss';
import '../DataEstate/data-estate.scss';
import { getKpiFrameworkConfigList } from '../../service/service';
import CustomDashboardIcon from '../../assets/Custom_Dashboard.svg';
import SortAscending from '../../assets/heroicons-solid_sort-descending-3.png';
import SortDecending from '../../assets/heroicons-solid_sort-descending-1.png';
import useStyles from '../landingScreen/headerStyles';
import Loader from '../Loader/loader';
import theme from './theme';
import DialogueModal from 'components/Modal/DialogModal';
import { KPIForm } from './kpiForm';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

interface KPIConfig extends GridValidRowModel {
	id: string;
	name: string;
	description: string;
	last_run_time: string;
	next_run_time: string;
	frequency: string;
	isActive: boolean;
}

const StyledBox = styled(Box)(({ theme }) => ({
	height: '100vh',
	width: '100%',
	backgroundColor: theme.palette.primary.main,
	padding: 0,
	'&:focus': { outline: 'none' }
}));

const StyledDataGrid = styled(DataGrid)({
	border: 'none',
	'& .MuiDataGrid-columnSeparator': {
		color: theme.palette.primary.main
	},
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.text.primary,
		fontWeight: 700,
		fontFamily: 'Montserrat !important',
		borderBottom: 'none',
		boxShadow: 'none',
		'& .MuiDataGrid-columnHeaderTitle': {
			fontWeight: '700 !important',
			fontFamily: 'Montserrat !important'
		},
		'& .MuiDataGrid-columnHeader': {
			borderBottom: 'none !important'
		}
	},
	'& .MuiDataGrid-cell': {
		color: theme.palette.text.primary,
		fontFamily: 'Montserrat',
		fontWeight: 500,
		position: 'relative',
		borderTop: 'none !important',
		borderBottom: 'none !important'
	},
	'& .MuiDataGrid-cell.bordered-cell::after': {
		content: '""',
		position: 'absolute',
		top: '5px',
		bottom: '5px',
		right: 0,
		width: '2px',
		backgroundColor: 'rgba(255, 255, 255, 0.12)'
	},
	'& .MuiDataGrid-row': {
		backgroundColor: theme.palette.secondary.main,
		marginBottom: '2px',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main
		},
		borderBottom: 'none  !important'
	},
	'& .MuiDataGrid-filler': {
    	display: 'none', 
 	},
	'& .MuiDataGrid-sortIcon': {
		display: 'none'
	}
});

const KPIConfigList = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [configList, setConfigList] = useState<KPIConfig[]>([]);
	const [sortModel, setSortModel] = useState<GridSortModel>([]);
	const [showCreateModal, setShowCreateModal] = useState(false);
	

	const fetchKpiConfigs = async () => {
		setLoading(true);
		try {
			const result = await getKpiFrameworkConfigList();
			setConfigList(result.data);
		} catch (error) {
			console.error('Error fetching dashboard data:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchKpiConfigs();
	}, []);

	const handleSortClick = (field: string) => {
		setSortModel((prevSortModel) => {
			const isAscending = prevSortModel.some((model) => model.field === field && model.sort === 'asc');
			return [{ field, sort: isAscending ? 'desc' : 'asc' }];
		});
	};

	const getSortIcon = (field: string) => {
		const sortDirection = sortModel.find((model) => model.field === field)?.sort;
		return sortDirection === 'asc' ? SortAscending : SortDecending;
	};

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'KPI ID',
			flex: 2,
			cellClassName: 'bordered-cell',
			renderHeader: (params) => renderHeader(params),
			renderCell: ({ row }) => (
				<div style={{ cursor: 'pointer' }} onClick={() => handleItemClick(row)}>
					{row._id}
				</div>
			)
		},
		{
			field: 'name',
			headerName: 'KPI Name',
			flex: 2,
			cellClassName: 'bordered-cell',
			renderHeader: (params) => renderHeader(params),
			renderCell: ({ row }) => (
				<div style={{ cursor: 'pointer' }} onClick={() => handleItemClick(row)}>
					{row.name}
				</div>
			)
		},
		{
			field: 'description',
			headerName: 'KPI Description',
			flex: 2,
			cellClassName: 'bordered-cell'
		},
		{
			field: 'last_run_time',
			headerName: 'Last run time',
			flex: 1,
			cellClassName: 'bordered-cell',
			renderHeader: (params) => renderHeader(params),
			renderCell: ({ row }) => (row.last_run_time ? moment(row.last_run_time).utc().format('MM-DD-YY HH:mm:ss') : '')
		},
		{
			field: 'next_run_time',
			headerName: 'Next run time',
			flex: 1,
			cellClassName: 'bordered-cell',
			renderHeader: (params) => renderHeader(params),
			renderCell: ({ row }) => (row.next_run_time ? moment(row.next_run_time).utc().format('MM-DD-YY HH:mm:ss') : '')
		},
		{
			field: 'frequency',
			headerName: 'KPI Frequency',
			flex: 1,
			cellClassName: 'bordered-cell',
			renderHeader: (params) => renderHeader(params),
			renderCell: ({ row }) => (`${row.update_frequency_value} ${row.update_frequency_value ===1 ? row.update_frequency_unit.slice(0,-1) : row.update_frequency_unit}`)
		},
		{
			field: 'isActive',
			headerName: 'Active',
			flex: 1,
			cellClassName: 'bordered-cell',
			renderHeader: (params) => renderHeader(params),
			renderCell: ({ row }) => (<Chip style={{backgroundColor: '#001e3e', marginTop: 8}} label = {row.isActive ? 'Yes' : 'No'}/>)
		},
		{
			field: 'action',
			headerName: 'Actions',
			flex: 1,
			// renderHeader: (params) => renderHeader(params),
			renderCell: ({row}) => (
				<div>
					<Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%' }}>
						<IconButton
							color="primary"
							aria-label="open"
							sx={{
								color: '#009BD8',
								padding: '12px'
							}}
						>
							<EditIcon />
							<DeleteIcon />
							{row.isActive ? <UnpublishedIcon/> : <CheckCircleIcon/>}
						</IconButton>
					</Box>
				</div>
			)
		}
	];

	const iconStyles = {
		base: {
			cursor: 'pointer',
			width: '16px',
			height: '16px',
			marginLeft: '5px'
		},
		ascending: {
			marginTop: '0px'
		},
		descending: {
			marginTop: '5px'
		}
	};

	const renderHeader = (params: any) => {
		const isAscending = sortModel.some((model) => model.field === params.colDef.field && model.sort === 'asc');

		const iconStyle = {
			...iconStyles.base,
			...(isAscending ? iconStyles.ascending : iconStyles.descending)
		};

		return (
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
				<span>{params.colDef.headerName}</span>
				<img
					src={getSortIcon(params.colDef.field)}
					alt="sort"
					onClick={() => handleSortClick(params.colDef.field)}
					style={iconStyle}
				/>
			</div>
		);
	};

	const handleItemClick = (kpiConfig: KPIConfig) => {
		console.log(kpiConfig)
		window.location.href = `/kpiFramework/configs/${kpiConfig._id}`;
	};

	const renderBreadcrumbs = () => (
		<div style={{ display:'flex', marginLeft: 0, marginBottom: 16, marginTop: '-33px', justifyContent: 'space-between' }}>
			<span
				className="job-menu"
				onClick={() => (window.location.href = '/kpiFramework/configs')}
				style={{ cursor: 'pointer' }}
			>
				<img
					src={CustomDashboardIcon}
					className={classes.jobIcon}
					style={{ marginTop: '-6px' }}
					alt="Custom Dashboards Icon"
				/>
				<span style={{color: 'white', fontWeight: 600, fontSize: 18}}>KPI Lists</span>
			</span>
			<Button variant='contained' startIcon={<PlusIcon/>} onClick={() => setShowCreateModal(true)}>Add KPI Config</Button>
		</div>
	);

	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			{renderBreadcrumbs()}
			<div className="program-list-container">
				<Grid>
					{loading ? (
						<Loader />
					) : (
						<ThemeProvider theme={theme}>
							<StyledBox tabIndex={-1}>
								<StyledDataGrid
									rows={configList}
									columns={columns as readonly GridColDef[]}
									sortModel={sortModel}
									onSortModelChange={(model) => setSortModel(model)}
									hideFooter
									disableColumnMenu
									disableRowSelectionOnClick
									getRowId={(row) => row._id}
								/>
							</StyledBox>
						</ThemeProvider>
					)}
				</Grid>
			</div>
			<DialogueModal title='Add KPI Config' isOpen={showCreateModal} handleClose={() => setShowCreateModal(false)}>
				<KPIForm onCreateKPI={() => {setShowCreateModal(false); fetchKpiConfigs()}}/>
			</DialogueModal>
		</div>
	);
};

export default KPIConfigList;

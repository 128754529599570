import React from 'react';
import { Dialog, DialogTitle, DialogContent, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type DialogueModalProps = {
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  isOpen: boolean;
  title: string;
  handleClose: (e?: any) => void;
  isShowCloseButton?: boolean;
  children?: React.ReactNode;
}

const DialogueModal: React.FC<DialogueModalProps> = ({
  isOpen,
  title,
  handleClose,
  isShowCloseButton = true,
  width,
  children
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth={width ? width : "sm"}
    >
      <DialogTitle
        sx={{
          bgcolor: 'primary.main',
          color: 'white.main',
          p: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>{title}</Typography>
        {isShowCloseButton && (
          <IconButton
            onClick={handleClose}
            sx={{
              color: 'white.main',
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ p: 2, textAlign: 'center' }}>
          {children}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogueModal;
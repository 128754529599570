
import { Box, Tab, Tabs, Typography } from '@mui/material';
import './ibp-obs.scss';
import React from 'react';
import IbpMonitor from './ibp-monitor';
import IBPCost from './ibp-cost';
import IbpPlatform from './ibp-platform';
import IbpDataQuality from './ibp-dq';

import EyeIcon from '../../assets/lucide_info.svg';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

export default function IbpExecutive() {

	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};
	return (
		<div className="jobs-tabledata ibp-executive-tabs">
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs className="TabsControl" value={value} onChange={handleChange}>
						<Tab label="Monitoring" {...a11yProps(0)} />
						<Tab label="Cost" {...a11yProps(1)} />
						<Tab label={<React.Fragment> <span>Platform * <img src={EyeIcon} className='eye-icon-styles' title='* - Under Development' /></span></React.Fragment>} {...a11yProps(2)} />
						<Tab label={<React.Fragment> <span>Data Quality * <img src={EyeIcon} className='eye-icon-styles' title='* - Under Development' /></span></React.Fragment>} {...a11yProps(3)} />
					</Tabs>
				</Box>

				<CustomTabPanel value={value} index={0}>
					<IbpMonitor />
				</CustomTabPanel>

				<CustomTabPanel value={value} index={1}>
					<IBPCost />
				</CustomTabPanel>

				<CustomTabPanel value={value} index={2}>
					<IbpPlatform />
				</CustomTabPanel>

				<CustomTabPanel value={value} index={3}>
					<IbpDataQuality />
				</CustomTabPanel>
			</Box>
		</div>
	);
}

import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
	Button,
	ButtonGroup,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Popover,
	Select,
	Stack,
	Typography
} from '@mui/material';
import { powerBICapacityMetricsData } from '../../service/service';
import { powerBICapacityDetailData } from '../../service/service';
import '../PowerBIObservability/powerBIObs.scss';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import { useSearchParams } from 'react-router-dom';
import { powerBICapacitySearch } from '../../service/service';
import ReactECharts from 'echarts-for-react';
import Loader from 'components/Loader';
import NumberWidget from 'components/Widgets/NumberWidget';
import LoaderComponent from 'components/Loader';
import RowTypography from 'components/Table/RowTypography';
import StatusChip from 'components/Table/StatusChip';
import BarChartWidget from 'components/Widgets/BarChartWidget';
import SectorCheckFilter from 'components/Dropdown/SectorCheckFilter';
import PageHeader from 'components/Header/Page';
import GatewayObservabilityLogo from 'assets/powerbi_gateway_obs.svg';
import CustomDropdown from 'components/Dropdown';
import ThroughputChart from './ThroughputChart';

const GatewayObservability = () => {
	interface Row {
        gateway: string;
		sector: string;
		vmName: string;
		networkThroughput: string;
		hosted: string;
		environment: string;
		reportsImpacted: string;
		dataSourceType: string;
		dataSource: string;
		dataSourceStatus: string;
		scheduleRefreshers: string;
		duration: string;
		countQueries: string;
		normalCompletionTime: string;
		actualCompletionTime: string;
		missedSLA: string;
	}

	function getColumns(): Array<Column<Row>> {
		return [
            {
				key: 'gateway',
				name: 'Gateway',
				width: 180,
				minWidth: 180
			},
			{
				key: 'sector',
				name: 'Sector',
				width: 190,
				minWidth: 190,
			},
			{
				key: 'vmName',
				name: 'VM Name',
				width: 150,
				minWidth: 150,
			},
			{
				key: 'networkThroughput',
				name: 'Network Throughput(MBps)',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'hosted',
				name: 'Hosted',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'environment',
				name: 'Environment',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'reportsImpacted',
				name: 'Reports Impacted',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'dataSourceType',
				name: 'Data Source Type',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'dataSource',
				name: 'Data Source',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'dataSourceStatus',
				name: 'Data Source Status',
				width: 200,
				minWidth: 200,
                formatter: ({ row }) => <StatusChip status={row.dataSourceStatus} />
			},
			{
				key: 'scheduleRefreshers',
				name: 'Schedule Refreshers',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'duration',
				name: 'Average Data Processing Duration',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'countQueries',
				name: 'Count of Queries',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'normalCompletionTime',
				name: 'Normal Completion Time',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'actualCompletionTime',
				name: 'Actual Completion Time',
				width: 230,
				minWidth: 230,
			},
			{
				key: 'missedSLA',
				name: 'Missed SLA',
				width: 180,
				minWidth: 180,
			},
		];
	}
	interface RowThroughput {
		gateway: string,
		source: string,
		throughput: {gateway: number; source: number}[],
		averageThroughput: string,
		currentThroughput: string,
	}

	function getColumnsThroughput(): Array<Column<RowThroughput>> {
		return [
			{
				key: 'gateway',
				name: 'Gateway',
				width: 190,
				minWidth: 190,
			},
			{
				key: 'source',
				name: 'Source',
				width: 200,
				minWidth: 200,
			},
			{
				key: 'throughput',
				name: 'Throughput',
				width: 400,
				minWidth: 400,
				cellClass: 'center-text',
				formatter: ({ row }) => (
					<ThroughputChart data={row.throughput} />
				  ), 
			},
			{
				key: 'averageThroughput',
				name: 'Average Throughput',
				width: 180,
				minWidth: 180,
			},
			{
				key: 'currentThroughput',
				name: 'Current Throughput',
				width: 180,
				minWidth: 180,
			}
		];
	}

	const [gatewayDetails, setGatewayDetails] = useState<Row[]>([]);
	const [throughputDetails, setThroughputDetails] = useState<RowThroughput[]>([]);
	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [tableLoadingThroughput, setTableLoadingThroughput] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [drillDownStatusfilterInfo, setDrilldownStatusFilterInfo] = useState([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	const hasDrillDown = searchParams.has('isDrillDowned');
	// const [metricsData, setMetricsData] = useState<any>({
	// 	healthyCount: 0,
	// 	throttledCount: 0,
	// 	frozenCount: 0,
	// 	riskCount: 0,
	// 	frozenRecoveredCount: 0,
	// 	totalCount: 0
	// });
	const [chartOption, setChartOption] = useState<any>();
	const [capacityStatusFilter, setCapacityStatusFilter] = useState<string | null>(null);
	const checkedListRef = useRef<any>([]);

	const defaultColumnNames = [
		'Gateway',
		'Sector',
		'VM Name',
		'Network Throughput(MBps)',
		'Hosted',
		'Environment',
		'Reports Impacted',
		'Data Source Type',
		'Data Source',
		'Data Source Status',
		'Schedule Refreshers',
		'Average Data Processing Duration',
		'Count of Queries',
		'Normal Completion Time',
		'Actual Completion Time',
		'Missed SLA'
	];

	const handleOkClick = () => {
		const updatedCheckedList = [...checkedListRef.current]; // Just copying the checkedList

		if (updatedCheckedList.length > 0) {
			setCurrSectorFilter(updatedCheckedList);
		} else {
			setCurrSectorFilter([]);
		}
		// updateSharedValue({ sectorFilter: updatedCheckedList });
		// fetchData();
		setFilterInfo([]);
		sortingInfo.sortBy = '';
		sortingInfo.sortDir = '';
		gatewayDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		gatewayThroughputFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		//fetchCapacityData();
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		gatewayDetailsFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
		gatewayThroughputFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		gatewayDetailsFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
		gatewayThroughputFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
	};
	const handleSorting = (sortingInfo: any) => {
		gatewayDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		gatewayThroughputFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		console.log(filterInfo, 'filterinfo');
		gatewayDetailsFunc(1, pageSize, sortingInfo, parseFilter(filterInfo));
		gatewayThroughputFunc(1, pageSize, sortingInfo, parseFilter(filterInfo))
	};
	// const handleSearch = (searchDetails: any, columnKey: any) => {
	// 	// incidentSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	// };

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	async function gatewayDetailsFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
		console.log(jobFilterInfo, 'jobFilterInfo');
		// Reset page number if page size has changed
		if (pageSize !== size) {
			pageNumber = 1;
		}

		const dummyGatewayDetailsData = [
            {
                gateway: 'Gateway 1',
                sector: 'PBNA',
                vmName: 'VM Name',
                networkThroughput: 'Value',
                hosted: 'On Prem',
                environment: 'PROD',
                reportsImpacted: '1000',
                dataSourceType: 'DS Type 1',
                dataSource: 'DS 1',
                dataSourceStatus: 'ONLINE',
                scheduleRefreshers: '1000',
                duration: '1 Hr',
                countQueries: '100',
                normalCompletionTime: '12.00 PM',
                actualCompletionTime: '12.00 PM',
                missedSLA: '12.00 PM',
            },
            {
                gateway: 'Gateway 2',
                sector: 'PFNA',
                vmName: 'VM Name',
                networkThroughput: 'Value',
                hosted: 'Cloud',
                environment: 'DEV',
                reportsImpacted: '1000',
                dataSourceType: 'DS Type 2',
                dataSource: 'DS 2',
                dataSourceStatus: 'OFFLINE',
                scheduleRefreshers: '1000',
                duration: '2 Days',
                countQueries: '100',
                normalCompletionTime: '12.00 PM',
                actualCompletionTime: '12.00 PM',
                missedSLA: '12.00 PM',
            },
            {
                gateway: 'Gateway 3',
                sector: 'CGF',
                vmName: 'VM Name',
                networkThroughput: 'Value',
                hosted: 'On Prem',
                environment: 'QA',
                reportsImpacted: '1000',
                dataSourceType: 'DS Type 3',
                dataSource: 'DS 3',
                dataSourceStatus: 'OFFLINE',
                scheduleRefreshers: '1000',
                duration: '30 Mins',
                countQueries: '100',
                normalCompletionTime: '12.00 PM',
                actualCompletionTime: '12.00 PM',
                missedSLA: '12.00 PM',
            },
            {
                gateway: 'Gateway 4',
                sector: 'AMESA/APAC',
                vmName: 'VM Name',
                networkThroughput: 'Value',
                hosted: 'On Prem',
                environment: 'PROD',
                reportsImpacted: '1000',
                dataSourceType: 'DS Type 4',
                dataSource: 'DS 4',
                dataSourceStatus: 'ONLINE',
                scheduleRefreshers: '1000',
                duration: '2 Days',
                countQueries: '100',
                normalCompletionTime: '12.00 PM',
                actualCompletionTime: '12.00 PM',
                missedSLA: '12.00 PM',
            },
            {
                gateway: 'Gateway 5',
                sector: 'EUROPE',
                vmName: 'VM Name',
                networkThroughput: 'Value',
                hosted: 'Cloud',
                environment: 'DEV',
                reportsImpacted: '1000',
                dataSourceType: 'DS Type 5',
                dataSource: 'DS 5',
                dataSourceStatus: 'OFFLINE',
                scheduleRefreshers: '1000',
                duration: '30 Mins',
                countQueries: '100',
                normalCompletionTime: '12.00 PM',
                actualCompletionTime: '12.00 PM',
                missedSLA: '12.00 PM',
            },
            {
                gateway: 'Gateway 6',
                sector: 'EUROPE',
                vmName: 'VM Name',
                networkThroughput: 'Value',
                hosted: 'Cloud',
                environment: 'QA',
                reportsImpacted: '1000',
                dataSourceType: 'DS Type 6',
                dataSource: 'DS 6',
                dataSourceStatus: 'ONLINE',
                scheduleRefreshers: '1000',
                duration: '1 Hr',
                countQueries: '100',
                normalCompletionTime: '12.00 PM',
                actualCompletionTime: '12.00 PM',
                missedSLA: '12.00 PM',
            },
            {
                gateway: 'Gateway 7',
                sector: 'EUROPE',
                vmName: 'VM Name',
                networkThroughput: 'Value',
                hosted: 'Cloud',
                environment: 'QA',
                reportsImpacted: '1000',
                dataSourceType: 'DS Type 7',
                dataSource: 'DS 7',
                dataSourceStatus: 'ONLINE',
                scheduleRefreshers: '1000',
                duration: '1 Hr',
                countQueries: '100',
                normalCompletionTime: '12.00 PM',
                actualCompletionTime: '12.00 PM',
                missedSLA: '12.00 PM',
            },
            {
                gateway: 'Gateway 8',
                sector: 'EUROPE',
                vmName: 'VM Name',
                networkThroughput: 'Value',
                hosted: 'Cloud',
                environment: 'QA',
                reportsImpacted: '1000',
                dataSourceType: 'DS Type 8',
                dataSource: 'DS 8',
                dataSourceStatus: 'ONLINE',
                scheduleRefreshers: '1000',
                duration: '1 Hr',
                countQueries: '100',
                normalCompletionTime: '12.00 PM',
                actualCompletionTime: '12.00 PM',
                missedSLA: '12.00 PM',
            },
            {
                gateway: 'Gateway 9',
                sector: 'EUROPE',
                vmName: 'VM Name',
                networkThroughput: 'Value',
                hosted: 'Cloud',
                environment: 'QA',
                reportsImpacted: '1000',
                dataSourceType: 'DS Type 9',
                dataSource: 'DS 9',
                dataSourceStatus: 'ONLINE',
                scheduleRefreshers: '1000',
                duration: '1 Hr',
                countQueries: '100',
                normalCompletionTime: '12.00 PM',
                actualCompletionTime: '12.00 PM',
                missedSLA: '12.00 PM',
            },
            {
                gateway: 'Gateway 10',
                sector: 'EUROPE',
                vmName: 'VM Name',
                networkThroughput: 'Value',
                hosted: 'Cloud',
                environment: 'QA',
                reportsImpacted: '1000',
                dataSourceType: 'DS Type 10',
                dataSource: 'DS 10',
                dataSourceStatus: 'ONLINE',
                scheduleRefreshers: '1000',
                duration: '1 Hr',
                countQueries: '100',
                normalCompletionTime: '12.00 PM',
                actualCompletionTime: '12.00 PM',
                missedSLA: '12.00 PM',
            }
        ];
        setGatewayDetails(dummyGatewayDetailsData);
        paginationData.totalElements = 1;
            paginationData.totalPages = 1;
            setPage(pageNumber);
            setPageSize(size);
            setSortingInfo(sortingInfo);
            setLoading(false);
	}

	async function powerBICapacitySearchFunc(searchDetails: any, columnKey: any) {
		const res = await powerBICapacitySearch(
			searchDetails,
			columnKey,
			parseFilter(filterInfo),
			checkedListRef.current,
			drillDownStatusfilterInfo
		);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}
	const handleSearch = (searchDetails: any, columnKey: any) => {
		console.log(searchDetails, columnKey);
		if (searchDetails?.length >= 3) powerBICapacitySearchFunc(searchDetails, columnKey);
	};
	const handleCapacityClick = (status: string) => {
		setCapacityStatusFilter(status);
		let statusArray = [];
		statusArray.push(status);
		setFilterInfo([]);
		sortingInfo.sortBy = '';
		sortingInfo.sortDir = '';
		// Fetch data and filter by status
		gatewayDetailsFunc('1', pageSize, sortingInfo, { capacityStatus: statusArray });
	};

	// Delete below dummyData once API is set
	const dummyData = {
		months: ['Jan 24', 'Feb 24', 'Mar 24', 'Apr 24', 'May 24', 'Jun 24', 'Jul 24', 'Aug 24', 'Sep 24', 'This Month'],
		usageLevels: {
			'00-35%': [10, 15, 12, 13, 14, 11, 12, 13, 14, 15],
			'36-60%': [12, 10, 11, 10, 11, 12, 12, 11, 10, 12],
			'61-80%': [14, 16, 15, 14, 13, 14, 16, 15, 14, 13],
			'81-90%': [10, 12, 11, 10, 12, 11, 10, 12, 11, 12],
			'91%+': [10, 9, 11, 12, 10, 13, 11, 12, 10, 11]
		}
	};
	const fetchDummyData: () => Promise<typeof dummyData> = async () => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(dummyData);
			}, 500);
		});
	};

	async function gatewayThroughputFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
		// Reset page number if page size has changed
		if (pageSize !== size) {
			pageNumber = 1;
		}
	
		const dummythroughputData:RowThroughput[] = [
			{
				gateway: 'Gateway 1',
				source: 'Source 1',
				throughput: [
					{ gateway: 10, source: 10 },
					{ gateway: 5, source: 22 },
					{ gateway: 20, source: 28 },
					{ gateway: 10, source: 15 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 15 },
					{ gateway: 8, source: 10 },
					{ gateway: 8, source: 12 },
					{ gateway: 5, source: 10 },
					{ gateway: 8, source: 15 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 20 },
				  ],
				averageThroughput: '1002',
				currentThroughput: '234',
			},
			{
				gateway: 'Gateway 2',
				source: 'Source 2',
				throughput: [
					{ gateway: 10, source: 10 },
					{ gateway: 5, source: 22 },
					{ gateway: 20, source: 28 },
					{ gateway: 10, source: 15 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 15 },
					{ gateway: 8, source: 10 },
					{ gateway: 8, source: 12 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 15 },
					{ gateway: 8, source: 10 },
					{ gateway: 8, source: 12 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 20 },
				  ],
				averageThroughput: '1002',
				currentThroughput: '234',
			},
			{
				gateway: 'Gateway 3',
				source: 'Source 3',
				throughput: [
					{ gateway: 10, source: 10 },
					{ gateway: 5, source: 22 },
					{ gateway: 20, source: 28 },
					{ gateway: 10, source: 15 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 15 },
					{ gateway: 8, source: 10 },
					{ gateway: 8, source: 12 },
					{ gateway: 5, source: 10 },
					{ gateway: 8, source: 15 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 20 },
				  ],
				averageThroughput: '1002',
				currentThroughput: '234',
			},
			{
				gateway: 'Gateway 4',
				source: 'Source 4',
				throughput: [
					{ gateway: 10, source: 10 },
					{ gateway: 5, source: 22 },
					{ gateway: 20, source: 28 },
					{ gateway: 10, source: 15 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 15 },
					{ gateway: 8, source: 10 },
					{ gateway: 8, source: 12 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 15 },
					{ gateway: 8, source: 10 },
					{ gateway: 8, source: 12 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 20 },
				  ],
				averageThroughput: '1002',
				currentThroughput: '234',
			},
			{
				gateway: 'Gateway 5',
				source: 'Source 5',
				throughput: [
					{ gateway: 10, source: 10 },
					{ gateway: 5, source: 22 },
					{ gateway: 20, source: 28 },
					{ gateway: 10, source: 15 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 15 },
					{ gateway: 8, source: 10 },
					{ gateway: 8, source: 12 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 15 },
					{ gateway: 8, source: 10 },
					{ gateway: 8, source: 12 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 20 },
				  ],
				averageThroughput: '1002',
				currentThroughput: '234',
			},
			{
				gateway: 'Gateway 6',
				source: 'Source 6',
				throughput: [
					{ gateway: 10, source: 10 },
					{ gateway: 5, source: 22 },
					{ gateway: 20, source: 28 },
					{ gateway: 10, source: 15 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 15 },
					{ gateway: 8, source: 10 },
					{ gateway: 8, source: 12 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 15 },
					{ gateway: 8, source: 10 },
					{ gateway: 8, source: 12 },
					{ gateway: 5, source: 10 },
					{ gateway: 10, source: 20 },
				  ],
				averageThroughput: '1002',
				currentThroughput: '234',
			},
		]
		setThroughputDetails(dummythroughputData);
		paginationData.totalElements = 1;
			paginationData.totalPages = 1;
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setLoading(false);
	}


	async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		// const res = await jobSearch(searchDetails, jobFilterInfo, columnKey);
		// if (res.success) {
		// 	setFilterSearchInfo(res.data);
		// }
	}

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		localStorage.removeItem('ml-summary-sector-filter');
		// let storedSectorFilter = localStorage.getItem('ml-summary-sector-filter')
		// 	? JSON.parse(localStorage.getItem('ml-summary-sector-filter') ?? '')
		// 	: null;
		// 	console.log("Fetched Data1:", storedSectorFilter );
		// if (storedSectorFilter) {
		// 	setCheckedList(storedSectorFilter);
		// 	setCurrSectorFilter(storedSectorFilter);
		// }
        gatewayDetailsFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		gatewayThroughputFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/powerBI/gatewayObservability/dashboard') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('filter-bg');
				activeId.classList.remove('root');
			}
		}
		
	}, []);

	const infoCardData = [
		{
			title: 'Gateways Online',
			// handleEvent: () => handleCapacityClick('HEALTHY'),
			number: '4',
			totalNumber: '6'
		},
		{
			title: 'Gateways Offline',
			// handleEvent: () => handleCapacityClick('THROTTLED'),
			number: '6',
			totalNumber: '6'
		},
		{
			title: 'Gateways With Performance Issue',
			// handleEvent: () => handleCapacityClick('FROZEN'),
			number: '3',
			totalNumber: '6'
		},
		{
			title: 'Total Number of Refreshers (In Last 7 days)',
			// handleEvent: () => handleCapacityClick('RISK'),
			number: '200',
            totalNumber: '',
		},
		{
			title: 'Dataset Refreshers Missed SLA',
			// handleEvent: () => handleCapacityClick('FROZEN 24'),
			number: '23',
            totalNumber: ''
		}
	];

	return (
		<Stack mt="80px" width="100%">
			<PageHeader title="Gateway Observability" icon={GatewayObservabilityLogo} lastSync={''} iconSize={30}>
				<SectorCheckFilter
					sectorStorageName="ml-summary-sector-filter"
					handleOkClick={handleOkClick}
					checkedListRef={checkedListRef}
				/>
			</PageHeader>
			<Stack p={2} spacing={2}>
				<Grid container spacing={1}>
					<Grid item container xs={12} spacing={1}>
						{infoCardData.map((info, idx) => (
							<Grid key={info.title} item xs={12} md={4} sx={{ maxWidth: { md: '20%', xs: '100%' } }}>
                                <NumberWidget
									isLoading={loading}
									title={info.title}
									tooltipLabel=""
									// handleEvent={info.handleEvent}
									ratioNumber={{
										number: info.number,
										totalNumber: info.totalNumber
									}}
									containerProps={{
										minHeight: '50px',
									}}
                                    unitIcon={info.title ==  'Total Number of Refreshers (In Last 7 days)' ? 'upArrow' : '' }
                                    unitIconColor='green'
									refreshersRate={info.title ==  'Total Number of Refreshers (In Last 7 days)' ? '10%' : ''}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
				{!tableLoading ? (
					<CustomizedDataGrid
						title="Details"
						tableColumns={getColumns()}
						tableData={gatewayDetails}
						tableType="gateway-details-ver"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={gatewayDetails.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={tableLoading}
					/>
				) : (
					<Stack justifyContent="center" alignItems="center" minHeight="100px">
						<LoaderComponent />
					</Stack>
				)}
				{!tableLoadingThroughput ? (
					<CustomizedDataGrid
						title="Details"
						tableColumns={getColumnsThroughput()}
						tableData={throughputDetails}
						tableType="throughput-details-ver"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						searchDataValues={searchDetails}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={throughputDetails.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={tableLoadingThroughput}
						rowHeight={100} 
						headerRowHeight={35}
					/>
				) : (
					<Stack justifyContent="center" alignItems="center" minHeight="100px">
						<LoaderComponent />
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default GatewayObservability;

import KPIConfigList from "components/KpiFramework/kpiConfigList";
import KPIDetails from "components/KpiFramework/kpiDetails";
import { KPIForm } from "components/KpiFramework/kpiForm";
import PrivateRoute from "utils/privateRoute";


const KPIFrameworkRoutes = [
	{
		key: 'kpi_store',
		route: '/kpiFramework/configs',
		component: (
			<PrivateRoute
				Component={KPIConfigList}
			/>
		)
	},
    {
		key: 'kpi_store_details',
		route: '/kpiFramework/configs/:id',
		component: (
			<PrivateRoute
				Component={KPIDetails}
			/>
		)
	},
    {
		key: 'kpi_store_details',
		route: '/kpiFramework/configs/new',
		component: (
			<PrivateRoute
				Component={KPIForm}
			/>
		)
	},
]

export default KPIFrameworkRoutes;
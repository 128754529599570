import React, { useEffect, useState } from 'react';
import { Stack, Box, Typography, Button } from '@mui/material';
import { ReactComponent as ResourceMapping } from '../../../../src/assets/resourceMappingNew.svg';
import styled from '@emotion/styled';
import AsyncAutoComplete from '../../Inputs/ResourceMapping/AsyncAutocomplete';
import { getFinopsResourceMappingList } from 'service/service';

import { CellExpanderFormatter } from 'components/cell_expander_formatter';
import RowTypography from 'components/Table/RowTypography';
import { CustomizedDataGrid } from 'components/customized_data_grid';

const StyledConfirmationIcon = styled(ResourceMapping)({
	width: '5rem',
	height: '5rem'
});

interface Option {
	value: string;
	label: string;
}

interface Row {
	id: string;
	[key: string]: any;
}

interface CustomFormModalProps {
	columnNames: any;
	queryPreference: any;
	bulkSelectedRows: any;
  setBulkSelectedRows: any;
	onClose: () => void;
	onSave: (formData: FormData, changedCells: any, rowsToUpdate: any) => void;
	onCancel: () => void;
}

interface FormData {
	Program: string;
	ProjectName: string;
	Sector: string;
	Environment: string;
}

const BulkEditModalConfirm: React.FC<CustomFormModalProps> = ({
	onClose,
	onSave,
	onCancel,
	bulkSelectedRows,
  setBulkSelectedRows,
	queryPreference,
	columnNames,
}) => {
  const isTreeView = queryPreference.split('_')[1] === 'tree';
	const defaultColumnNames = ['Resource', 'Sector', 'Environment', 'Project Name', 'Program'];

	const initialFormData: FormData = {
		Program: '',
		ProjectName: '',
		Sector: '',
		Environment: ''
	};

	const [formData, setFormData] = useState<FormData>(initialFormData);
	const [showbulkEditInput, setShowbulkEditInput] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
	console.log(bulkSelectedRows)
	console.log(selectedRows)
	const handleChange = (field: keyof FormData, value: string) => {
		setFormData((prevState) => ({
			...prevState,
			[field]: value
		}));
	};

	const handleCancel = () => {
		setFormData(initialFormData);
		onCancel();
	};

	const handleSave = () => {
      let updatedRows;
    
    //   if (isTreeView) {
    //     // Tree View Logic
    //     updatedRows = Object.keys(bulkSelectedRows).reduce((acc, rowId) => {
    //       let row = { ...bulkSelectedRows[rowId] };
    
    //       // Update child rows if they exist
    //       if (row.values && Array.isArray(row.values)) {
    //         row.values = row.values.map((childRow: any) => {
    //           const updatedChildRow = { ...childRow };
    
    //           // Apply formData to child rows
    //           (Object.keys(formData) as Array<keyof FormData>).forEach((key) => {
    //             if (formData[key]) {
    //               updatedChildRow[key] = formData[key];
    //             }
    //           });
    
    //           return updatedChildRow;
    //         });
    //       }
    
    //       acc[rowId] = row;
    //       return acc;
    //     }, {} as typeof bulkSelectedRows);
    //   } else {

        // Flat View Logic
        updatedRows = Object.keys(bulkSelectedRows).reduce((acc, rowId) => {
          const row = { ...bulkSelectedRows[rowId] };
					if (row.values) {
						return acc;
					}    
          (Object.keys(formData) as Array<keyof FormData>).forEach((key) => {
            if (formData[key]) {
              row[key] = formData[key];
							acc.changedCells = {
								...acc.changedCells,
								[rowId]: {
									...(acc.changedCells.rowId),
									[key]: true,
								}
							}
            }
          });
    
          acc[rowId] = row;
          return acc;
        }, {changedCells:{}} as typeof bulkSelectedRows);
    //   }
			const {changedCells, ...rowsToUpdate} = updatedRows;
      setBulkSelectedRows(rowsToUpdate);
      onSave(formData, changedCells, rowsToUpdate);
      setShowbulkEditInput(false);
    };

	// const fetchOptions = async (input: string): Promise<Option[]> => {
	// 	const optionArray = await getFinopsResourceMappingList(input);
	// 	return optionArray.success
	// 		? optionArray.data.result.filter(Boolean).map((item: string) => ({ value: item, label: item }))
	// 		: [];
	// };

	const getInputOptions = async (input: string, field: string) => {
		const fieldsRes = await getFinopsResourceMappingList({ searchField: field, searchText: input });
		const options: Option[] = fieldsRes.data.result.filter(Boolean).map((item: string) => ({ value: item, label: item }));
		return options;
	};

  function toggleSubRow(name: string, resourceTypes: any) {
		setSelectedRows((prev) => {
			const rows = [...prev];
			const rowIndex = rows.findIndex((r: any) => r.name === name);
			const row = rows[rowIndex];
			const newRows = [...rows];
			let children: Row[] = [];
			let childRows: Row[] = [];
			if (!row.isExpanded) {
				if (resourceTypes?.length > 0) {
					childRows = resourceTypes;
				}
				children = childRows.map((x: any) => {
					return { ...x, isChildren: true };
				});
				newRows.splice(Number(rowIndex) + 1, 0, ...children);
				newRows[rowIndex] = { ...row, isExpanded: true, children };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = true;
			} else {
				children = row.children ?? [];
				newRows.splice(Number(rowIndex) + 1, children.length);
				newRows[rowIndex] = { ...row, isExpanded: false };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = false;
			}
			return newRows;
		});
	}
	
	function getColumns(editedRows: Record<string, any>): Array<any> {

		const [groupBy] = queryPreference.split('_');
		const selectedColumns = ['Program', 'ProjectName', 'Sector', 'Environment'];
		const filteredColumns = columnNames.filter(
			(col: any) => selectedColumns.includes(col.name) || col.name.toLowerCase() === groupBy.toLowerCase()
		);

		return filteredColumns.map((col: any, idx: number) => ({
			key: col.name,
			name: col.label,
			formatter({ row, isCellSelected, column }: any) {
				const isGroupBy = column.key.toLowerCase() === groupBy.toLowerCase();
				const hasChildren = !!row.values;
				return (
					<>
						{idx === 0 && isGroupBy && hasChildren && (
							<CellExpanderFormatter
								isCellSelected={isCellSelected}
								expanded={row.isExpanded === true}
								onCellExpand={() => {
									toggleSubRow(row.name, row.values);
								}}
							/>
						)}
						{'   '}
						<RowTypography
							isSuccessor={!row.values}
						>
							{hasChildren ? (isGroupBy ? row.name : '') : row[column.key]}
						</RowTypography>
					</>
				);
			},
			width: 200
		}));
	}

	const renderAutoComplete = (label: string, field: keyof FormData) => (
		<AsyncAutoComplete
			textFieldLabel={label}
			inputValue={formData[field]}
			onChange={(event, value) => handleChange(field, value)}
			mappingType="finopsResourceMapping"
			columnName={field}
			options={[]}
			fieldValueOptionsCallback={async (input: string) => getInputOptions(input, field)}
		/>
	);

  useEffect(() => {
    setSelectedRows(Object.values(bulkSelectedRows).filter((item: any)=> !item.values));
  }, [bulkSelectedRows])
  

	return (
		<>
			{showbulkEditInput ? (
				<Stack spacing={2}>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent="center"
						alignItems="center"
						spacing={2}
						sx={{ width: '100%', pt: '2rem' }}
					>
						{renderAutoComplete('Program', 'Program')}
						{renderAutoComplete('ProjectName', 'ProjectName')}
					</Stack>
					<Stack
						direction={{ xs: 'column', sm: 'row' }}
						justifyContent="center"
						alignItems="center"
						spacing={2}
						sx={{ width: '100%' }}
					>
						{renderAutoComplete('Sector', 'Sector')}
						{renderAutoComplete('Environment', 'Environment')}
					</Stack>
					<Stack direction="row" sx={{ justifyContent: 'flex-end', gap: 2 }}>
						<Button variant="pepwiseOutline" sx={{ width: '8rem' }} onClick={handleCancel}>
							CANCEL
						</Button>
						<Button variant="pepwiseSecondary" sx={{ width: '8rem' }} onClick={handleSave}>
							SAVE
						</Button>
					</Stack>
				</Stack>
			) : (
				<Stack>
					<Stack sx={{ flexDirection: 'row', gap: 2 }}>
						<Box sx={{ alignItems: 'center', justifyContent: 'flex-start', width: '5rem' }}>
							<StyledConfirmationIcon />
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
							<Typography variant="h6" sx={{ fontWeight: 500 }}>
								Resource Bulk Update Successful.
							</Typography>
							{/* <Typography sx={{ alignSelf: 'flex-start', color: 'light.diff' }}>
								{editCount} Resources updated successfully
							</Typography> */}
						</Box>
					</Stack>
					<Stack sx={{ flexDirection: 'column', gap: 2, textAlign: 'left' }}>
						<CustomizedDataGrid
							tableColumns={getColumns(selectedRows)}
							tableData={selectedRows}
							defaultColumnNames={defaultColumnNames}
							autoSetDraggedColumns={false}
							isRenderPagination = {false}
							isShowMoreButton={false}
							style={{maxHeight: '250px'}}
						/>
						<Button variant="pepwiseSecondary" sx={{ width: '8rem', alignSelf: 'flex-end' }} onClick={onClose}>
							CONFIRM
						</Button>
					</Stack>
				</Stack>
			)}
		</>
	);
};

export default BulkEditModalConfirm;

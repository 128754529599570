import { Box, Button, Dialog, Grid, Stack } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ReactComponent as EditIcon } from '../../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-img.svg';
import { ReactComponent as ViewIcon } from '../../../assets/view-detail-img.svg';
import { CustomizedDataGrid } from '../../customized_data_grid';
import Loader from '../../Loader/loader';
import { Link } from 'react-router-dom';
import PageHeader from 'components/Header/Page';
import { ReactComponent as AddIcon } from '../../../assets/plus-icon.svg';
import DashboardIcon from '../../../assets/material-symbols_dashboard.svg';
import '../../DataEstate/data-estate-dashboard.scss';
import { ResourceMappingModal } from './modalComponent';
import { fetchResourceMappingPrograms } from 'service/service';
import { CellExpanderFormatter } from 'components/cell_expander_formatter';
import { Column } from 'react-data-grid';
import SearchFilter from 'components/Dropdown/SearchFilter';

const Programs = () => {
	const programExpandList = useRef<Row[]>([]);
	const [noRefresh, setNoRefresh] = useState(false);
	const [programList, setProgramList] = useState<any[]>([]);
	const [openModal, setOpenModal] = useState<any>({ isOpen: false });
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const defaultColumnNames = ['Program & Project', 'Owner', 'Description', 'Actions'];

	interface Row {
		id: string;
		programId: any;
		programName: string;
		programDescription: string;
		programOwner: string;
		projectId: any;
		projects:[];
		projectName:any,
		projectDescription: string;
		projectOwner: string;
		isExpanded?: boolean;
		isExpandedChild?: boolean;
		children: Row[];
		ischildren?: boolean;
		selected: boolean;
	}

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'programName',
				name: 'Program & Project',
				minWidth: 200,
				width: 515,
				formatter({ row, isCellSelected }) {
					const hasChildren = row.projects;
					const hasChildrenofChild = row.projectName;
					return (
						<>
							{hasChildren && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.programName, row.projects);
									}}
								/>
							)}
							{'   '}
							<Link
								to=''
								state={row}
								style={{ paddingLeft: hasChildren ? '5px' : '10px', paddingRight: '10px' }}
							>
								 {hasChildren ? row.programName : ''}
							</Link>
							<Link
								to=''
								state={row}
								style={{ paddingLeft: hasChildrenofChild ? '5px' : '10px' }}
							>
								 {row.projectName}
							</Link>
						</>
					);
				}
			},
			{
				key: 'programOwner',
				name: 'Owner',
				minWidth: 200,
				width: 500,
				formatter({ row }) {
					const hasChildren = row.projects;
					const hasChildrenofChild = row.projectName;
					return (
						<>
							{'   '}
							<Link
								to=''
								state={row}
								style={{ paddingLeft: hasChildren ? '5px' : '10px', paddingRight: '10px' }}
							>
								 {row.programOwner}
							</Link>
							<Link
								to=''
								state={row}
								style={{ paddingLeft: hasChildrenofChild ? '5px' : '10px' }}
							>
								 {row.projectOwner}
							</Link>
						</>
					);
				}
				
			},
			{
				key: 'programDescription',
				name: 'Description',
				minWidth: 200,
				// maxWidth: 700,
				formatter({ row }) {
					const hasChildren = row.projects;
					const hasChildrenofChild = row.projectName;
					return (
						<>
							{'   '}
							<Link
								to=''
								state={row}
								style={{ paddingLeft: hasChildren ? '5px' : '10px', paddingRight: '10px' }}
							>
								 {row.programDescription}
							</Link>
							<Link
								to=''
								state={row}
								style={{ paddingLeft: hasChildrenofChild ? '5px' : '10px' }}
							>
								 {row.projectDescription}
							</Link>
						</>
					);
				}
			},
			{
				key: 'actions',
				name: 'Actions',
				width: 100,
				resizable: false,
				formatter: (props: any) => {
					const hasChildren = props.row.projects;
					const prgId = props.row.programId;
					const prjId = props.row.projectId;
					const prgName = props.row.programName;
					const prjName = props.row.projectName;
					const urlId = hasChildren ? props.row.programId : props.row.projectId;
					const urlName = hasChildren ? props.row.programName : props.row.projectName;
					return (
						<Stack direction="row" alignItems="center" height="100%">
							<Box lineHeight={0}>
								<EditIcon title="Edit" style={{ width: '24px', height: '24px' }} onClick={(e) => handleAction('EDIT', e, props, hasChildren)}/>
							</Box>
							<Box lineHeight={0}>
								<DeleteIcon title="Delete" style={{ width: '24px', height: '24px' }} onClick={(e) => handleAction('DELETE', e, props, hasChildren)} />
							</Box>
							<Box component={Link} lineHeight={0} to={hasChildren ? `/finops/resourcemapping/programs/${prgId}/${encodeURIComponent(prgName)}` : 
								`/finops/resourcemapping/projects/${prgId}/${prjId}/${encodeURIComponent(prgName)}/${encodeURIComponent(prjName)}`}>
								<ViewIcon title="Click to view" style={{ width: '24px', height: '24px' }} />
							</Box>
						</Stack>
					);
				}
			}
		];
	};

	const handleSorting = (e: any) => {
		programListFunc(page, pageSize, e);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		programListFunc(value, pageSize, sortingInfo);
	};

	const handlepageSizeChange = (event: any) => {
		programListFunc(page, event.target.value, sortingInfo);
	};

	const handleAction = (action: any, e: any, props: any, hasChildren: any) => {
		e.preventDefault();
		e.stopPropagation();
		setOpenModal({
			isOpen: true,
			type: (hasChildren ? 'Program' : 'Project'),
			action,
			data: props.row
		});
	};

	const handleEditClose = (isCanceled: boolean = false) => {
		setOpenModal({
			isOpen: false
		});
		setNoRefresh(isCanceled);
	};

	const handleAddProgramClick = useCallback(() => {
		setOpenModal({
			isOpen: true,
			type: 'Program',
			action: 'ADD',
			data: ''
		});
	}, []);

	async function programListFunc(pageNumber: any, size: any, sortingInfo: any, searchText?: any) {
		setLoading(true);
		if (pageSize !== size) {
			pageNumber = 1;
		}
		const res = await fetchResourceMappingPrograms(pageNumber.toString(), size.toString(), sortingInfo, searchText);
		if (res.success) {
			setLoading(false);
			setProgramList(res.data.programs);
			programExpandList.current = res.data.programs;
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}

	let progName: any = '';
	function toggleSubRow(name: string,resourceTypes:any) {
		const rows = programExpandList.current;
		const rowIndex = rows.findIndex((r: any) => r.programName === name);
		const row = rows[rowIndex];
		const newRows = [...rows];
		let children: Row[] = [];
		let childRows: Row[] = [];
		progName = name;
		if (!row.isExpanded) {
			if (resourceTypes.length>0) {
				childRows = resourceTypes;
			}
			children = childRows.map((x: any) => {
				return { ...x, isChildren: true };
			});
			newRows.splice(Number(rowIndex) + 1, 0, ...children);
			newRows[rowIndex] = { ...row, isExpanded: true, children };
			localStorage.setItem('boxRows', JSON.stringify(newRows));
			row.isExpanded = true;
		}
		else
		{
			children = row.children;
			newRows.splice(Number(rowIndex) + 1, children?.length);
			newRows[rowIndex] = { ...row, isExpanded: false };
			localStorage.setItem('boxRows', JSON.stringify(newRows));
			row.isExpanded = false;
		}
		programExpandList.current = newRows;
		setProgramList([...newRows]);
	}

	const handleSearchData = (serachText: string) => {
		try {
			programListFunc(page, pageSize, sortingInfo, serachText);
		} catch (error) {
			console.error(error);
		}
	};
	
	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/finops/resourcemapping/programs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		if(!openModal.isOpen && !noRefresh)
			programListFunc(page, pageSize, sortingInfo);
		setNoRefresh(false);
	}, [openModal]);
	
	return (
		<Box bgcolor="blue.darker" mt="60px">
			{loading && <Loader />}
			<PageHeader title="Programs" icon={DashboardIcon}>
				<Stack direction="row" gap={1} alignItems="center">
				<SearchFilter handleSearchData = {handleSearchData} />
				<Button variant="pepwiseSecondary" onClick={handleAddProgramClick} startIcon={<AddIcon />}>
					ADD PROGRAM
				</Button>
				</Stack>
			</PageHeader>
			<Stack p={2}>
				<Dialog open={openModal.isOpen} onClose={() => handleEditClose(true)} fullWidth={true} maxWidth="md">
					<ResourceMappingModal handleEditClose={handleEditClose} programData={openModal}/>
				</Dialog>
				<Grid item xs={12}>
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={programList}
						tableType="program-list"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={programList.length}
						showExport={false}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isShowMoreButton = {false}
					/>
				</Grid>
			</Stack>
		</Box>
	);
};

export default Programs;

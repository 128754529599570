import { useState, useEffect, useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import IdleFilterIcon from '../../assets/filter_idle.svg';
 import DisabledIdleFilterIcon from '../../assets/filter_idle_disabled.svg';
import SortAIcon from '../../assets/appliedsortA-Z.svg';
import SortZIcon from '../../assets/appliedsortZ-A.svg';
import FilterGreenDot from '../../assets/filter_applied_green_dot.svg';
import { ReactComponent as ClearColumnFilterIcon } from '../../assets/clearColumnFilter.svg';
import { ReactComponent as AscIcon } from '../../assets/ascending.svg';
import { ReactComponent as AscActiveIcon } from '../../assets/ascending_selected.svg';
import { ReactComponent as DescIcon } from '../../assets/descending.svg';
import { ReactComponent as DescActiveIcon } from '../../assets/descending_selected.svg';
import '../HeaderRenderers/DraggableHeaderRenderer.css';
// import { headerRenderer } from 'react-data-grid';
import type { HeaderRendererProps } from 'react-data-grid';

import Menu from '@mui/material/Menu';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, FormControlLabel, TextField, Button, InputAdornment, Stack, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import useStyles from '../jobs/jobs_styles';
import searchIcon from '../../assets/akar-icons_search-1.svg';
import ClickAwayListener from '@mui/base/ClickAwayListener';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment-timezone';
import debounce from 'lodash.debounce';
interface DraggableHeaderRendererProps<R> extends HeaderRendererProps<R> {
	onColumnsReorder: (sourceKey: string, targetKey: string) => void;
	onContextMenu: (selectedColumns: any) => void;
	onFilterMenu: (sortingInfo: any) => void;
	onFilterCheck: (filterInfo: any, columnKey: any) => void;
	onFilterSearch: (searchDetails: any, columnKey: any) => void;
	onHeaderSelectAll: (event: any) => void;
	onFilterClick: (selectedColumn: any) => void;
	filterData: any;
	searchData: any;
	columnNames: any;
	visibleColumns: any;
	currSorting: any;
	currFilter: any;
	tableColumnKeys: any;
	tableType: any;
	sectorsList: any;
}

export function DraggableHeaderRenderer<R>({
	onColumnsReorder,
	onContextMenu,
	onFilterMenu,
	onFilterCheck,
	onFilterSearch,
	onHeaderSelectAll,
	onFilterClick,
	filterData,
	searchData,
	columnNames,
	visibleColumns,
	column,
	currSorting,
	currFilter,
	tableColumnKeys,
	tableType,
	sectorsList,
	...props
}: DraggableHeaderRendererProps<R>) {
	const [{ isDragging }, drag] = useDrag({
		type: 'COLUMN_DRAG',
		item: { key: column.key },
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	});
	const [contextMenu, setContextMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const classes = useStyles();
	const [{ isOver }, drop] = useDrop({
		accept: 'COLUMN_DRAG',
		drop({ key }: { key: string }) {
			onColumnsReorder(key, column.key);
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	});

	const filterPopup = (event: any) => {
		const filterClickedColumn = event.target.parentNode?.dataset?.headerKey
		const validTableTypes = [
			'PCOBS_ResourceGroup',
			'all_used_workbooks',
			'tableausetDetailsList',
			'Performance',
			'tableauUsers',
			'projects',
			'workbooks',
			'datasources',
			'views',
			'flows',
			'job-schedules-1'
		  ];
		if (validTableTypes.includes(tableType)  && filterClickedColumn) {
			onFilterClick(filterClickedColumn)
		} 
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 300,
						mouseY: parseInt(event.clientY) + 12
				  }
				: null
		);
		if(checkedList.length == filterSearchData[filterClickedColumn].length)
		{
			setItemsChecked(true);
		}
		else
		{
			setItemsChecked(false);
		}
	};

	const onHandleHeaderSelectAllCheckbox = (event: any) => {
		onHeaderSelectAll(event)
	}

	const handleFilterAction = (event: any) => {
		event.preventDefault();
		onFilterMenu(event.target.parentNode.dataset);
		setFilterMenu(null);
	};

	const handleClearColunmAction = (event: any) => {
		currFilter[column.key] = [];
		onFilterCheck(currFilter, '');
		setCheckedList([]);
		setItemsChecked(false);
		handleClose(event);
	};

	const contextClick = (event: any) => {
		event.preventDefault();
		setContextMenu(
			contextMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [fromDateTime, setFromDateTime] = useState<any>(moment().startOf('day'));
	const [toDateTime, setToDateTime] = useState<any>(moment().endOf('day'));
	const dateTimeFields = ['createdAt', 'updatedAt', 'deletedAt', 'training_date'];
	const url = window.location.pathname;
	const jobName = url.substring(url.lastIndexOf('/') + 1);

	let filterDisabledColumns: any = [];
	if (tableType == 'Presto-details' || tableType == 'Presto') {
		filterDisabledColumns = [
			'query'
		]
	} else if (tableType === 'PCOBS_ResourceGroup') {
        filterDisabledColumns = [
            'selected',
			'ClusterName',
			'ClusterJobSource'
        ]
    } else if (tableType === 'IBP_JobRuns'){
		filterDisabledColumns = [
            'ticketStatus',
			'destination',
			'source'
        ]
	}else if (tableType === 'project-list'){
		filterDisabledColumns = [
            'action'
        ]
	}else if (tableType === 'added-schedules'){
		filterDisabledColumns = [
            'slaDate', 'slaTime', 'scheduleInfo', 'priJobStatus', 'jobCondition'
        ]
	}
	else if (tableType === 'databricks'){
		filterDisabledColumns = ['time']
	}else if (tableType === 'job-schedules-1'){
		filterDisabledColumns = [
            'Start Time (CST)', 'SLA Completion Time (CST)', 'Avg Exec. Time', 'jobCondition'
        ]
	}else if (tableType === 'incident-details-ver'){
		filterDisabledColumns = [
            'outageStartTime', 'outageEndTime', 'outageDuration'
        ]
	} else if (tableType === 'impact-teradata'){
		filterDisabledColumns = [
            'iterationDate',
			'runEndTime',
			'reasonForDelay',
			'slaEndTime'
        ]
	} 
	else if (tableType === 'program-list') {
		filterDisabledColumns = [
            'action', 'IP_Delayed_Start', 'IP_Long_Running', 'Failed', 'Success', 'SLA_Time'
        ]
	} else if (tableType === 'programs-dashboard') {
		filterDisabledColumns = [
            'Sectors', 'SLA_Status', 'Project_Name', 'action', 'IP_Delayed_Start', 'IP_Long_Running', 'Failed', 'Success', 'SLA_Time'
        ]
	}
	else if (tableType === 'resource-details'){
		filterDisabledColumns = [
			'selected',
            'BillingInformation'
        ]
	}
	// if (tableType === 'DBSummary') {
	// 	filterDisabledColumns = [
	// 		'workspace_name',
	// 	'project_name',
	// 	'cluster_category',
	// 	'cluster_name',
	// 	'job_name',
	// 	'timestamp_state_start',
	// 	'timestamp_state_end',
	// 	'total_dbus',
	// 	'total_DBU_cost'
	// 	];
	// } else if (tableType === 'CPUUtilization') {
	// 	filterDisabledColumns = [
	// 		'workspace_name',
	// 		'project_name',
	// 		'cluster_type',
	// 		'cluster_name',
	// 		'job_name',
	// 		'avg_spark_task_runtime_hr',
	// 		'avg_job_worker_potential_core_hrs',
	// 		'avg_job_run_cluster_util_percentile'
	// 	];
	// } else if (tableType === 'MemoryUtilization') {
	// 	filterDisabledColumns = [
	// 		'workspace_name',
	// 		'project_name',
	// 		'cluster_type',
	// 		'cluster_name',
	// 		'job_name',
	// 		'start_date_utc',
	// 		'end_date_utc',
	// 		'gc_differential',
	// 		'job_count'
	// 	];
	// }
	// else if (tableType === 'DBWorkspace') {
	// 	filterDisabledColumns = [
	// 		'workspace_name',
	// 		'cluster_type',
	// 		'cluster_name',
	// 		'total_dbus',
	// 		'total_DBU_cost',
	// 		'total_compute_cost'
	// 	];
	// }
	// else if (tableType === 'DBProject') {
	// 	filterDisabledColumns = [
	// 		'project_name',
	// 		'workspace_name',
	// 		'cluster_type',
	// 		'cluster_name',
	// 		'total_dbus',
	// 		'total_DBU_cost'
	// 	];
	// }
	// else if (tableType === 'Performance') {
	// 	filterDisabledColumns = [
	// 		'jobId',
	// 		'jobStatus',
	// 		'jobType',
	// 		'projectName',
	// 		'averageExecution',
	// 		'lastExecutionDuration'
	// 	];
	// }


	const handleClose = (event: any) => {
		if (event.target.checked) {
			visibleColumns.push(event.target.name);
		} else {
			visibleColumns = visibleColumns.filter((x: string) => x !== event.target.name);
		}
		setContextMenu(null);
		onContextMenu(visibleColumns);
		setFilterMenu(null);
		if (currFilter && currFilter[column.key]) setCheckedList(currFilter[column.key]);
		else setCheckedList([]);
		setInputText('');
	};

	// FILTER LOGIC START

	const handleOkClick = (event: any) => {
		// Updating the full filter query
		if (dateTimeFields.includes(column.key) && fromDateTime && toDateTime) {
			currFilter[column.key] = [fromDateTime, toDateTime];
		} else {
			if (checkedList.length > 0) {
				currFilter[column.key] = checkedList;
			} else {
				// resetting the filter
				currFilter[column.key] = [];
			}
		}
		onFilterCheck(currFilter, column.key);
		handleClose(event);
	};

	const [itemsChecked, setItemsChecked] = useState(false);

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			const allChecked = filterSearchData[column.key]?.length === checkedList.length + newlyChecked.length;
			setItemsChecked(allChecked);
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}	
	};
	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked && filterSearchData[column.key]) { 
			for (const item of filterSearchData[column.key]) {
				if (item !== null && item !== undefined) {
					collection.push(item.toString());
				}			}
		}
		setCheckedList(checked ? collection : []);
		setItemsChecked(checked);
	};
	const handleSearch = (e: KeyboardEvent): void => {
		e.stopPropagation();
		const searchValue = (e.target as HTMLInputElement).value.toLowerCase();
		setInputText(searchValue);
		if (
			searchValue.length > 1 &&
			[
				'application',
				'subApplication',
				'name',
				'fullName',
				'gpid',
				'email',
				'prjName',
				'prjDescription',
				'dsName',
				'flowName',
				'viewName',
				'viewUrlName',
				'workbookName',
				'projectName',
				'contentName',
				'jobStatus',
				'jobType',
				'contentType',
				'lastExecutionDuration',
				'cluster_name',
				'project_name',
				'workspace_name',
				'scheduleName',
				'scheduleType',
				'scheduleFrequency',
				'schedulePriority',
				'job_name',
				'login_user',
				'instance',
				'project',
				'extract',
				'extractType',
				'views',
				'Resource',
				'ResourceGroup',
				'ProjectName',
				'DFProjectcode',
				'BillingInformation',
				'Subscription',
				'MeterCategory',
				'MeterName',
				'Job_Name',
				'sector',
				'workspaceName',
				'itemName',
				'capacityName',
				'capacityStatus',
				'itemKind'
			].includes(column.key)
		) {
			onFilterSearch(searchValue, column.key);
		}
	};
	const [inputText, setInputText] = useState('');

	const debouncedResults = useMemo(() => {
		return debounce(handleSearch, 300);
	}, []);
	useEffect(() => {
		return () => {
			debouncedResults.cancel();
		};
	});
	const filterSearchData = { ...filterData };
	if (filterData && filterData[column.key] && filterData[column.key].length > 0) {
		if (filterSearchData) {
			if(column.key == 'sector'){
				if(sectorsList?.length>0)
		        {
				filterSearchData[column.key] = sectorsList;
				}
			}
			else
			{
			filterSearchData[column.key] = filterData[column.key].filter((filterSearch: any) => {
				if (inputText === '') {
					return filterSearch !== null && filterSearch !==undefined ? filterSearch.toString() : null;
				} else if (filterSearch) {
					if (column.key === 'criticalFlag' && (inputText === 'y' || inputText === 'ye' || inputText === 'yes')) {
						return filterSearch === true;
					} else if (column.key === 'criticalFlag' && (inputText === 'n' || inputText === 'no')) {
						return filterSearch === false;
					} else {
						return filterSearch.toLowerCase().includes(inputText)
					}
				}
			});
			}
		}
	}
	
	if (
		searchData &&
		searchData.name &&
		searchData.name.length > 0 &&
		[
			'application',
			'subApplication',
			'name',
			'fullName',
			'email',
			'gpid',
			'prjName',
			'prjDescription',
			'dsName',
			'flowName',
			'viewName',
			'viewUrlName',
			'workbookName',
			'projectName',
			'contentName',
			'jobStatus',
			'jobType',
			'contentType',
			'lastExecutionDuration',
			'cluster_name',
			'project_name',
			'workspace_name',
			'scheduleName',
			'scheduleType',
			'scheduleFrequency',
			'schedulePriority',
			'job_name',
			'login_user',
			'instance',
			'project',
			'extract',
			'extractType',
			'views',
			'Resource',
			'ResourceGroup',
			'ProjectName',
			'DFProjectcode',
			'BillingInformation',
			'Subscription',
			'MeterCategory',
			'MeterName',
			'sector',
			'workspaceName',
			'itemName',
			'capacityName',
			'capacityStatus',
			'itemKind'
		].includes(column.key) &&
		inputText.length > 1
	) {
		filterSearchData[column.key] = searchData.name;
	}

	// Date Range
	const handleDateTimeChange = (field: any, dateTime: any): void => {
		if (field === 'fromDateTime') setFromDateTime(dateTime);
		else setToDateTime(dateTime);
	};
	// Date Range

	// FILTER LOGIC END
	useEffect(() => {
		// Updating the full filter query from the previous filter
		if (currFilter && currFilter[column.key]) {
			if (dateTimeFields.includes(column.key)) {
				if (currFilter[column.key][0]) setFromDateTime(currFilter[column.key][0]);
				if (currFilter[column.key][1]) setToDateTime(currFilter[column.key][1]);
			} else {
				setCheckedList(currFilter[column.key]);
			}
		}
	}, [currFilter]);

	return (
		<>
			<div
				ref={(ref) => {
					drag(ref);
					drop(ref);
				}}
				style={{
					opacity: isDragging ? 0.5 : 1,
					backgroundColor: isOver ? '#0c0f25' : undefined,
					cursor: 'move'
				}}
				onContextMenu={contextClick}
			>
				<div className="filter-icon">
					{/* header select all checkbox */}
					{column.name == 'HeaderCheckbox' ? (
						<input
                        type="checkbox"
                        className="transparent-checkbox"
						id="HeaderCheckbox"
                        onChange={onHandleHeaderSelectAllCheckbox}
                    />
					) : (
						<span>{column.name}</span>
					)}
					{/* <span>{column.name}</span> */}
					
					{!filterDisabledColumns.includes(column.key) ? (
						<span data-header-key={column.key} onClick={filterPopup}>
							{currFilter && currFilter[column.key] && currFilter[column.key].length ? (
								<img src={FilterGreenDot} className="filter-green-dot" />
							) : (
								''
							)}
							{currSorting ? (
								currSorting.sortBy !== column.key ? (
									<img src={IdleFilterIcon} />
								) : currSorting.sortDir === 'asc' ? (
									<img src={SortAIcon} />
								) : (
									<img src={SortZIcon} />
								)
							) : (
								''
							)}
						</span>
					 ) : (
						<span>&nbsp;</span>
					)} 
				</div>
				<ClickAwayListener onClickAway={handleClose}>
					<Menu
						open={contextMenu !== null}
						onClose={handleClose}
						anchorReference="anchorPosition"
						anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
					>
						{columnNames.map((col: string, idx: number) => (
							<MenuItem key={col}>
								<FormControlLabel
									control={
										<Checkbox
											checked={visibleColumns.includes(col)}
											onChange={handleClose}
											disabled={idx === 0}
											name={col}
											sx={{
												color: '#00D08D',
												'&.Mui-checked': {
													color: '#00D08D !important'
												},
												'&.Mui-disabled': {
													color: 'rgba(0, 0, 0, 0.80) !important'
												}
											}}
										/>
									}
									label={col}
								/>
							</MenuItem>
						))}
					</Menu>
				</ClickAwayListener>
			</div>
			<Popover
				open={filterMenu !== null}
				onClose={handleClose}
				onMouseLeave={handleClose}
				className="filterContainer"
				anchorReference="anchorPosition"
				anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
			>
				<MenuItem className="filter-row" onClick={handleFilterAction} data-sort-by={column.key} data-sort-dir="asc">
					<Stack direction="row" alignItems="flextStart" spacing={2} data-sort-by={column.key} data-sort-dir="asc">
						{currSorting && currSorting.sortBy === column.key && currSorting.sortDir === 'asc' ? (
							<AscActiveIcon />
						) : (
							<AscIcon />
						)}
						<Typography>Sort A to Z</Typography>
					</Stack>
				</MenuItem>
				<MenuItem className="filter-row" onClick={handleFilterAction} data-sort-by={column.key} data-sort-dir="desc">
					<Stack direction="row" alignItems="flextStart" spacing={2} data-sort-by={column.key} data-sort-dir="desc">
						{currSorting && currSorting.sortBy === column.key && currSorting.sortDir === 'desc' ? (
							<DescActiveIcon />
						) : (
							<DescIcon />
						)}
						<Typography>Sort Z to A</Typography>
					</Stack>
				</MenuItem>
				{(filterSearchData && filterSearchData[column.key]) || dateTimeFields.includes(column.key) ? (
					<div className="clear-filter-sec">
						<MenuItem
							className="filter-row"
							onClick={handleClearColunmAction}
							disabled={!(currFilter && currFilter[column.key] && currFilter[column.key].length)}
						>
							<Stack direction="row" alignItems="flextStart" spacing={2}>
								<ClearColumnFilterIcon />
								<Typography>Clear Column Filter</Typography>
							</Stack>
						</MenuItem>
					</div>
				) : (
					''
				)}
				{dateTimeFields.includes(column.key) ? (
					<div className="dateFilter-div">
						<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
							<FormControl className="dateFilter-formControl" sx={{ m: 1 }}>
								<DateTimePicker
									label="From Date"
									showToolbar={true}
									PopperProps={jobName === 'run_history' ? { placement: 'top' } : { placement: 'auto' }}
									toolbarTitle={
										<>
											Select Date Time{' '}
											<div className="time-picker-zone">{`Timezone: ${
												moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
											}`}</div>
										</>
									}
									className="dateFilter"
									ampm={false}
									value={moment(fromDateTime).tz('America/New_York')}
									onChange={(dateTime) => {
										handleDateTimeChange('fromDateTime', dateTime);
									}}
									renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
								/>
							</FormControl>
							<FormControl className="dateFilter-formControl" style={{ marginBottom: '0px' }} sx={{ m: 1 }}>
								<DateTimePicker
									label="To Date"
									showToolbar={true}
									PopperProps={{
										placement: 'auto'
									}}
									toolbarTitle={
										<>
											Select Date Time{' '}
											<div className="time-picker-zone">{`Timezone: ${
												moment().isDST() ? 'EDT(UTC-4)' : 'EST(UTC-5)'
											}`}</div>
										</>
									}
									className="dateFilter"
									ampm={false}
									value={moment(toDateTime).tz('America/New_York')}
									minDate={moment(fromDateTime).tz('America/New_York')}
									onChange={(dateTime) => {
										handleDateTimeChange('toDateTime', dateTime);
									}}
									renderInput={(params: any) => <TextField name="endDateTime" {...params} />}
								/>
							</FormControl>
						</LocalizationProvider>
					</div>
				) : (
					filterSearchData &&
					filterSearchData[column.key] && (
						<div className="text-filter-sec">
							<div className="text-filter-row">
								<TextField
									id="searchField"
									autoComplete="off"
									variant="standard"
									placeholder={`Search ${String(column.name)}`}
									className={classes.textfield1}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<img className={classes.searchBar} src={searchIcon} />
											</InputAdornment>
										)
									}}
									inputProps={{
										style: {
											height: '25px',
											color: '#F5F5F5'
										}
									}}
									onChange={(e: any) => {
										debouncedResults(e);
									}}
								/>
							</div>
							<div className="text-filter-row">
								<div className="selectAll-sec">
									<MenuItem className="selectAll">
										<FormControlLabel
											className={classes.checkbox}
											label={`All (${filterSearchData[column.key]?.length || 0})`}
											control={
												<Checkbox
													color="success"
													value="all"
													checked={itemsChecked}
													onChange={(e) => {
														selectAllCheck(e);
													}}
												/>
											}
										/>
									</MenuItem>
									<div className="checkbox-sec">
									{filterSearchData[column.key] &&
										filterSearchData[column.key].sort((a:any, b:any) => {
											// Check if 'a' or 'b' is checked (checked items should come first)
											const isCheckedA = checkedList.includes(a);
											const isCheckedB = checkedList.includes(b);
											if (isCheckedA && !isCheckedB) return -1; // 'a' should come before 'b'
											if (!isCheckedA && isCheckedB) return 1;  // 'b' should come before 'a'
											return 0; // If both are checked or both unchecked, no change in order
											}).map((filter: any) => (
												<MenuItem key={filter}>
													<FormControlLabel
														className={classes.checkbox}
														label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
														control={
															<Checkbox
																color="success"
																value={filter}
																checked={
																	(filter === true && checkedList.includes('true')) ||
																	(filter === false && checkedList.includes('false')) ||
																	checkedList.includes(filter) || checkedList.includes(filter.toString())
																}
																onChange={(e) => {
																	handleChange(e);
																}}
															/>
														}
													/>
												</MenuItem>
											))}
									</div>
								</div>
							</div>
						</div>
					)
				)}
				{(filterSearchData && filterSearchData[column.key]) || dateTimeFields.includes(column.key) ? (
        <div className="buttonSec">
            {itemsChecked || checkedList.length > 0 ? (
                <>
                    <Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
                        cancel
                    </Button>
                    <Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
                        ok
                    </Button>
                </>
            ) : 
			 <>
			<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
				cancel
			</Button>
			<Button variant="contained" className={classes.okBtn} disabled>
				ok
			</Button>
		</>}
        </div>
    ) : ''}
			</Popover>
		</>
	);
}

import { Palette } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import colors from './base/colors';
import pxToRem from './functions/pxToRem';

export const pepWiseTypography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
	fontFamily: '"Montserrat", "lato", "Helvetica", "Arial", "sans-serif"',
	subtitle1: {
		fontSize: pxToRem(12),
		fontWeight: 600
	},
	subtitle2: {
		fontSize: pxToRem(14),
		fontWeight: 500,
		fontStyle: 'italic'
	},
	navMenu: {
		fontSize: pxToRem(16),
		color: colors.white.main
	},
	pageTitle: {
		fontSize: pxToRem(20),
		fontWeight: 600,
	},
	breadcrumbs: {
		fontSize: pxToRem(18),
		fontWeight: 600,
	},
	widgetTitle: {
		fontSize: pxToRem(16),
		fontWeight: 700,
		fontFamily: 'Montserrat',
		// restrict line breaks to 2
		display: '-webkit-box',
		WebkitBoxOrient: 'vertical',
		WebkitLineClamp: 2,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		WebkitBoxAlign: 'start'
	},
	widgetValue: {
		fontSize: pxToRem(50),
		fontWeight: 500,
		fontFamily: 'Montserrat'
	},
	widgetRatioValue: {
		fontSize: pxToRem(32),
		fontWeight: 600,
		fontFamily: 'Montserrat'
	},
	widgetUnit: {
		fontSize: pxToRem(28),
		fontWeight: 500,
		marginLeft: '10px',
		fontFamily: 'Montserrat'
	},
	overlayWidgetTitle: {
		fontSize: pxToRem(16),
		fontWeight: 700,
		lineHeight: '19.5px',
		fontFamily: 'Montserrat'
	},
	overlayWidgetValue: {
		fontSize: pxToRem(50),
		fontWeight: 500,
		lineHeight: '22.4px',
		fontFamily: 'Montserrat'
	},
	overlayWidgetUnit: {
		fontSize: pxToRem(28),
		fontWeight: 500,
		lineHeight: '14px',
		marginLeft: '10px',
		fontFamily: 'Montserrat'
	},
	tableColumnFont: {
		fontSize: pxToRem(12),
		fontWeight: 500,
		fontFamily: 'Montserrat'
	},
	tableRowFont: {
		fontSize: pxToRem(12),
		fontWeight: 300,
		fontFamily: 'Montserrat'
	},
	footerSubtitle: {
		fontSize: pxToRem(12),
		fontWeight: 500,
		fontStyle: 'italic'
	},
};

import { useState, useEffect } from 'react';
import { DataGrid, GridColDef, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import { Grid, IconButton, Box, Paper, Divider, Chip } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import moment from 'moment-timezone';
import { ThemeProvider, styled } from '@mui/material/styles';
import DialogModal from '../Modal/DialogModal'

import '../DataPipeline/dataPipeline.scss';
import '../DataEstate/data-estate.scss';
import { getKpiFrameworkConfigDetails } from '../../service/service';
import CustomDashboardIcon from '../../assets/Custom_Dashboard.svg';
import SortAscending from '../../assets/heroicons-solid_sort-descending-3.png';
import SortDecending from '../../assets/heroicons-solid_sort-descending-1.png';
import useStyles from '../landingScreen/headerStyles';
import Loader from '../Loader/loader';
import theme from './theme';
import { useParams } from 'react-router-dom';
import { JsonViewer } from '@textea/json-viewer';

interface KPIConfig extends GridValidRowModel {
	id: string;
	name: string;
	description: string;
	last_run_time: string;
	next_run_time: string;
	frequency: string;
	isActive: boolean
}

interface KPIResult extends GridValidRowModel {
	id: string;
	metricName: string;
	metricValue: string;
}

const StyledBox = styled(Box)(({ theme }) => ({
	height: '100vh',
	width: '100%',
	backgroundColor: theme.palette.primary.main,
	padding: 0,
	'&:focus': { outline: 'none' }
}));

const StyledDataGrid = styled(DataGrid)({
	border: 'none',
	'& .MuiDataGrid-columnSeparator': {
		color: theme.palette.primary.main
	},
	'& .MuiDataGrid-columnHeaders': {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.text.primary,
		fontWeight: 700,
		fontFamily: 'Montserrat !important',
		borderBottom: 'none',
		boxShadow: 'none',
		'& .MuiDataGrid-columnHeaderTitle': {
			fontWeight: '700 !important',
			fontFamily: 'Montserrat !important'
		},
		'& .MuiDataGrid-columnHeader': {
			borderBottom: 'none !important'
		}
	},
	'& .MuiDataGrid-cell': {
		color: theme.palette.text.primary,
		fontFamily: 'Montserrat',
		fontWeight: 500,
		position: 'relative',
		borderTop: 'none !important',
		borderBottom: 'none !important'
	},
	'& .MuiDataGrid-cell.bordered-cell::after': {
		content: '""',
		position: 'absolute',
		top: '5px',
		bottom: '5px',
		right: 0,
		width: '2px',
		backgroundColor: 'rgba(255, 255, 255, 0.12)'
	},
	'& .MuiDataGrid-row': {
		backgroundColor: theme.palette.secondary.main,
		marginBottom: '15px',
		'&:hover': {
			backgroundColor: theme.palette.secondary.main
		},
		borderBottom: 'none  !important'
	},
	'& .MuiDataGrid-filler': {
    	display: 'none', 
 	},
	'& .MuiDataGrid-sortIcon': {
		display: 'none'
	}
});

const KPIDetails = () => {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [configDetails, setConfigDetails] = useState<KPIConfig>();
	const [kpiResults, setKpiResults] = useState<KPIResult[]>([]);
	const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const {id: configId} = useParams<{id: string}>()
	const [showSourceDialog, setShowSourceDialog] = useState(false)
	const [showTargetDialog, setShowTargetDialog] = useState(false)

	useEffect(() => {
		const fetchKpiConfigDetails = async () => {
			setLoading(true);
			try {
				const result = configId ? await getKpiFrameworkConfigDetails(configId?.toString()) : {data : []};
				setConfigDetails(result.data?.kpiConfig || {});
				if(result.data.kpiResults?.length){
					setKpiResults(result.data.kpiResults)
				}
			} catch (error) {
				console.error('Error fetching dashboard data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchKpiConfigDetails();
	}, []);

	const handleSortClick = (field: string) => {
		setSortModel((prevSortModel) => {
			const isAscending = prevSortModel.some((model) => model.field === field && model.sort === 'asc');
			return [{ field, sort: isAscending ? 'desc' : 'asc' }];
		});
	};

	const getSortIcon = (field: string) => {
		const sortDirection = sortModel.find((model) => model.field === field)?.sort;
		return sortDirection === 'asc' ? SortAscending : SortDecending;
	};

	const columns: GridColDef[] = kpiResults?.length ? Object.keys(kpiResults[0].kpiResult).map(metricName => {
		return {
			field: metricName,
			headerName: metricName,
			flex: 2,
			cellClassName: 'bordered-cell',
			renderHeader: (params) => renderHeader(params),
			renderCell: ({ row }) => (
				<div style={{ cursor: 'pointer' }} onClick={() => handleItemClick(row)}>
					{Array.isArray(row.kpiResult[metricName]) ? row.kpiResult[metricName].length : row.kpiResult[metricName]}
				</div>
			)
		}
	}) : []

	columns.push({
		field: 'createdAt',
		headerName: 'Created At',
		flex: 2,
		cellClassName: 'bordered-cell',
		renderHeader: (params) => renderHeader(params),
		renderCell: ({ row }) => (
			<div style={{ cursor: 'pointer' }} onClick={() => handleItemClick(row)}>
				{moment(row.createdAt).utc().format('MM-DD-YY HH:mm:ss')}
			</div>
		)
	})
	
	const iconStyles = {
		base: {
			cursor: 'pointer',
			width: '16px',
			height: '16px',
			marginLeft: '5px'
		},
		ascending: {
			marginTop: '0px'
		},
		descending: {
			marginTop: '5px'
		}
	};

	const renderHeader = (params: any) => {
		const isAscending = sortModel.some((model) => model.field === params.colDef.field && model.sort === 'asc');

		const iconStyle = {
			...iconStyles.base,
			...(isAscending ? iconStyles.ascending : iconStyles.descending)
		};

		return (
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
				<span>{params.colDef.headerName}</span>
				<img
					src={getSortIcon(params.colDef.field)}
					alt="sort"
					onClick={() => handleSortClick(params.colDef.field)}
					style={iconStyle}
				/>
			</div>
		);
	};

	const handleItemClick = (kpiConfig: KPIConfig) => {
		console.log(kpiConfig)
		window.location.href = `/kpiFramework/configs/${kpiConfig.id}`;
	};

	const renderBreadcrumbs = () => (
		<div className="breadcrumb-custom" style={{ position: 'absolute', marginTop: '-33px' }}>
			<span
				className="job-menu"
				onClick={() => (window.location.href = '/kpiFramework/configs')}
				style={{ cursor: 'pointer' }}
			>
				<img
					src={CustomDashboardIcon}
					className={classes.jobIcon}
					style={{ marginTop: '-3px' }}
					alt="Custom Dashboards Icon"
				/>
				<span style={{color: 'white', fontWeight: 300, fontSize: 18, opacity: 0.6, marginRight: 8}}>KPI List</span>
			</span>
			<span className="symbol">&gt;</span> <span  style={{color: 'white', fontWeight: 600, fontSize: 18}}>{configDetails?.name}</span>
		</div>
	);

	return (
		<div className="dashboard-panel-dataestate job-schedules-wrap">
			{renderBreadcrumbs()}
			<DialogModal title='Source Config' isOpen={showSourceDialog} handleClose={() => setShowSourceDialog(false)}>
				<JsonViewer style={{textAlign: 'left'}} theme={'dark'} value={configDetails?.source_config}/>
			</DialogModal>
			<DialogModal title='Target Config' isOpen={showTargetDialog} handleClose={() => setShowTargetDialog(false)}>
				<JsonViewer style={{textAlign: 'left'}} theme={'dark'} value={configDetails?.target_config}/>
			</DialogModal>
			<div style={{marginTop: 16, marginBottom: 32,  backgroundColor: '#003771', padding: 8}}>
				<p>KPI Details</p>
				<div style={{display:'flex', flexDirection: 'row'}}>
					<div style={{flexDirection: 'column', padding: 8, flex: 3, gap: 2}}>
						<div style={{display:'flex', flexDirection: 'row'}}>
							<p style={{flex: 4, opacity: .5}}>KPI Name</p>
							<p style={{flex: 8, textAlign: 'left'}}>{configDetails?.name}</p>
						</div>
						<div style={{display:'flex', flexDirection: 'row'}}>
							<p style={{flex: 4, opacity: .5}}>ID</p>
							<p style={{flex: 8, textAlign: 'left'}}>{configDetails?._id}</p>
						</div>
						<div style={{display:'flex', flexDirection: 'row'}}>
							<p style={{flex: 4, opacity: .5}}>Active</p>
							<div style={{flex: 8, marginTop: 16}}><Chip style={{backgroundColor: '#001e3e'}} label = {configDetails?.isActive ? 'Yes' : 'No'}/></div>
						</div>
					</div>
					<Divider orientation='vertical' flexItem style={{borderColor: 'rgba(255, 255, 255, 0.12)'}}/>
					<div style={{flexDirection: 'column', padding: 8, flex: 3, gap: 2}}>
						<div style={{display:'flex', flexDirection: 'row'}}>
							<p style={{flex: 4, opacity: .5}}>Frequency</p>
							<p style={{flex: 8, textAlign: 'left'}}>{configDetails?.update_frequency_value} {configDetails?.update_frequency_value == 1 ? configDetails?.update_frequency_unit.slice(0,-1) : configDetails?.update_frequency_unit}</p>
						</div>
						<div style={{display:'flex', flexDirection: 'row'}}>
							<p style={{flex: 4, opacity: .5}}>Source</p>
							<p style={{flex: 8, textAlign: 'left'}}>{configDetails?.source_config?.type}</p>
							<IconButton onClick={() => setShowSourceDialog(true)}> <PreviewIcon/></IconButton>
						</div>
						<div style={{display:'flex', flexDirection: 'row'}}>
							<p style={{flex: 4, opacity: .5}}>Target type</p>
							<p style={{flex: 8, textAlign: 'left'}}>{configDetails?.target_config?.type}</p>
							<IconButton onClick={() => setShowTargetDialog(true)}> <PreviewIcon/></IconButton>
						</div>
					</div>
					<Divider orientation='vertical' flexItem style={{borderColor: 'rgba(255, 255, 255, 0.12)'}}/>
					<div style={{flexDirection: 'column', padding: 8, flex: 3, gap: 2}}>
						<div style={{display:'flex', flexDirection: 'row'}}>
							<p style={{flex: 4, opacity: .5}}>Updated At</p>
							<p style={{flex: 8, textAlign: 'left'}}>{moment(configDetails?.updatedAt).utc().format('MM-DD-YY HH:mm:ss')}</p>
						</div>						
						<div style={{display:'flex', flexDirection: 'row'}}>
							<p style={{flex: 4, opacity: .5}}>Last run time</p>
							<p style={{flex: 8, textAlign: 'left'}}>{moment(configDetails?.last_run_time).utc().format('MM-DD-YY HH:mm:ss')}</p>
						</div>
						<div style={{display:'flex', flexDirection: 'row'}}>
							<p style={{flex: 4, opacity: .5}}>Next run time</p>
							<p style={{flex: 8, textAlign: 'left'}}>{moment(configDetails?.next_run_time).utc().format('MM-DD-YY HH:mm:ss')}</p>
						</div>
					</div>
					<Divider orientation='vertical' flexItem style={{borderColor: 'rgba(255, 255, 255, 0.12)'}}/>
					<div style={{flexDirection: 'column', padding: 8, flex: 3, gap: 2}}>
						<div style={{display:'flex', flexDirection: 'row'}}>
							<p style={{flex: 4, opacity: .5}}>Description</p>
							<p style={{flex: 8, textAlign: 'left'}}>{configDetails?.description}</p>
						</div>
					</div>
				</div>
			</div>
			<p>KPI Output</p>
			<div className="program-list-container">
				<Grid>
					{loading ? (
						<Loader />
					) : (
						<ThemeProvider theme={theme}>
							<StyledBox tabIndex={-1}>
								<StyledDataGrid
									rows={kpiResults}
									columns={columns as readonly GridColDef[]}
									sortModel={sortModel}
									onSortModelChange={(model) => setSortModel(model)}
									hideFooter
									disableColumnMenu
									disableRowSelectionOnClick
									getRowId={(row) => row._id}
								/>
							</StyledBox>
						</ThemeProvider>
					)}
				</Grid>
			</div>
		</div>
	);
};

export default KPIDetails;

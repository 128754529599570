import React, { useEffect, useState, useRef } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Box, Chip, Grid, Stack } from '@mui/material';
import { fetchDEIncidentDetails } from '../../service/service';
import { CustomizedDataGrid } from '../customized_data_grid';
import type { Column } from 'react-data-grid';
import '../DataEstate/data-estate.scss';
import { OverflowTooltip } from '../overflow_tooltip';
import moment from 'moment';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import PageHeader from 'components/Header/Page';
import IncidentIcon from '../../assets/Incident Impact Analysis.svg';
import LoaderComponent from 'components/Loader';

export default function IncidentDetails() {
	interface Row {
		incidentId: string,
		relatedProblem: string,
		shortDescription: string,
		configurationItem: string,
		classDescription: string,
		openedDate: string,
		closedDate: string,
		resolvedDate: string,
		priority: string,
		businessCriticality: string,
		assignmentGroup: string,
		assignedTo: string,
		vendorName: string,
		status: string,
		outageType: string
	}

	const renderStatus = (status: any) => {
		switch (status) {
			case 'closed':
				return <Chip label={status} color="success" />;
			case 'open':
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'incidentId',
				name: 'Incident ID',
				minWidth: 120,
				width: 120,
				formatter(props) {
					if (props.row.assignmentGroup.includes('EDW PLATFORM TERADATA') || // teradata 
						(props.row.assignmentGroup.includes('ENTERPRISE DATA WAREHOUSE') || // databrick
						props.row.assignmentGroup.includes('ADVANCED ANALYTICS PLATFORM SUPPORT') && props.row.incidentId == 'INC09974786' || props.row.incidentId == 'INC10192559') // databrick
					) {
						return (
							<Link to={`/dataestate/incident-impact-analysis/${props.row.incidentId}`} state={props.row} className="jobsNameList">
								{props.row.incidentId}
							</Link>
						);
					} else
						return props.row.incidentId
				},
			},
			// {
			// 	key: 'relatedProblem',
			// 	name: 'Related Problem',
			// 	width: 140,
			// 	minWidth: 140
			// },
			{
				key: 'shortDescription',
				name: 'Short Description',
				width: 230,
				minWidth: 200,
				formatter(props) {
					const data = props.row.shortDescription ? props.row.shortDescription : '-';
					return <div title={props.row.shortDescription} className='has-ellipsis'>{data}</div>;
				}
			},
			{
				key: 'configurationItem',
				name: 'Configuration Item Name',
				width: 230,
				minWidth: 200
			},
			{
				key: 'classDescription',
				name: 'Class Description',
				width: 230,
				minWidth: 200
			},
			{
				key: 'openedDate',
				name: 'Open Date',
				minWidth: 150,
				formatter(props: any) {
					const openedDate = props.row.openedDate;
					if (openedDate !== 'undefined' && openedDate !== null) {
						return moment(openedDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'outageStartTime',
				name: 'Outage Start Time',
				minWidth: 150,
				formatter(props: any) {
					const outageStartTime = props.row.outageStartTime;
					if (outageStartTime !== 'undefined' && outageStartTime !== null) {
						return moment(outageStartTime).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'outageEndTime',
				name: 'Outage End Time',
				minWidth: 150,
				formatter(props: any) {
					const outageEndTime = props.row.outageEndTime;
					if (outageEndTime !== 'undefined' && outageEndTime !== null) {
						return moment(outageEndTime).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'outageDuration',
				name: 'Outage Duration',
				minWidth: 150,
				formatter(props: any) {
					const outageDuration = props.row.outageDuration;
					if (outageDuration !== 'undefined' && outageDuration !== null) {
						return decimalHoursToHMS(outageDuration);
					}
					return '';
				}
			},
			{
				key: 'outageType',
				name: 'Outage Type',
				width: 150,
				minWidth: 150
			},
			{
				key: 'closedDate',
				name: 'Close Date',
				minWidth: 150,
				formatter(props: any) {
					const closedDate = props.row.closedDate;
					if (closedDate !== 'undefined' && closedDate !== null) {
						return moment(closedDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'resolvedDate',
				name: 'Resolve Date',
				minWidth: 150,
				formatter(props: any) {
					const resolvedDate = props.row.resolvedDate;
					if (resolvedDate !== 'undefined' && resolvedDate !== null) {
						return moment(resolvedDate).format('MM-DD-YY HH:mm:ss');
					}
					return '';
				}
			},
			{
				key: 'priority',
				name: 'Priority',
				minWidth: 130,
				width: 130,
			},
			{
				key: 'businessCriticality',
				name: 'Business Criticality',
				width: 190,
				minWidth: 190
			},
			{
				key: 'assignmentGroup',
				name: 'Assignment Group',
				width: 230,
				minWidth: 200,
				formatter(props) {
					const data = props.row.assignmentGroup ? props.row.assignmentGroup : '-';
					return <div title={props.row.assignmentGroup} className='has-ellipsis'>{data}</div>;
				}
			},
			{
				key: 'assignedTo',
				name: 'Assigned To',
				minWidth: 125,
				width: 125
			},
			// {
			// 	key: 'vendorName',
			// 	name: 'Vendor Name',
			// 	minWidth: 130,
			// 	width: 130,
			// },
		];
	}
	const [incidentDetails, setIncidentDetails] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [customMonth, setCustomMonth] = useState('');
	const [filterMonth, setFilterMonth] = useState('0Y');

	const defaultColumnNames = [
		'Incident ID',
		// 'Related Problem',
		'Short Description',
		'Configuration Item Name',
		'Open Date',
		'Outage Start Time',
		'Outage End Time',
		'Outage Duration',
		'Resolve Date',
		'Close Date',
		'Priority',
		'Business Criticality',
		'Assignment Group',
		'Assigned To',
		// 'Vendor Name',
		'Status'
	];

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		incidentsDetailFunc(value, pageSize, sortingInfo, parseFilter(filterInfo), "");
	};

	const handlepageSizeChange = (event: any) => {
		incidentsDetailFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo), "");
	};
	const handleSorting = (sortingInfo: any) => {
		incidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo), "");
	};

	function decimalHoursToHMS(decimalHours: any) {
		// Calculate hours
		const hours = Math.floor(decimalHours);
		// Calculate remaining minutes from the decimal part
		const minutes = Math.floor((decimalHours - hours) * 60);
		// Calculate seconds from the remaining decimal part of minutes
		const seconds = Math.round(((decimalHours - hours) * 60 - minutes) * 60);
	 
		// Format the output
		if (hours === 0 && minutes === 0) {
			return `${seconds ? seconds : 1} Seconds`;
		} else if (hours === 0) {
			return `${minutes} Minutes and ${seconds} Seconds`;
		} else {
			return `${hours} Hours, ${minutes} Minutes, and ${seconds} Seconds`;
		}
	}

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		incidentsDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo), "");
	};
	const handleSearch = (searchDetails: any, columnKey: any) => {
		incidentSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	async function incidentsDetailFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any, month : any) {
	    setLoading(true);
		if (pageSize !== size) {
			pageNumber = 1;
		}
		const sector:any =[];
		const res = await fetchDEIncidentDetails(sector,pageNumber.toString(),size.toString(), month);
		if (res.success) {
			if(res.data) {
				setIncidentDetails(res.data.data);
				setPaginationData(res.data.page); 
				setPage(pageNumber);
				setPageSize(size);
				setSortingInfo(sortingInfo);
				setLoading(false);
			}
		}
	}
	// async function incidentsDetailFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any) {
	// 	// setLoading(true);
	// 	if (pageSize !== size) {
	// 		pageNumber = 1;
	// 	}
	// 	const dummyIncidentData =
	// 	[
	// 		{
    //             incidentId: 'INC09193065',
    //             relatedProblem: 'PRB00077403',
    //             shortDescription: ' PBNA| PFNA |CGF || Performance Issues in Teradata',
    //             configurationItem: 'TERADATA PROD',
    //             classDescription: 'NA',
    //             openedDate: '2024-07-15 12:25:22',
    //             closedDate: '2024-07-18 19:24:59',
    //             resolvedDate: '2024-07-15 18:31:58',
    //             priority: '3 - Moderate',
    //             businessCriticality: '2 - somewhat critical',
    //             serviceClassification: 'APP MGMT SERVICES - DATABASE TERADATA',
    //             assignmentGroup: ' EDW PLATFORM TERADATA',
    //             assignedTo: 'vishnu at',
    //             vendorName: 'N/A',
    //             status: 'Closed'
    //         }
	// 	];
	// 	setIncidentDetails(dummyIncidentData);
	// 	paginationData.totalElements = 1;
	// 	paginationData.totalPages = 1;
	// 	setPage(pageNumber);
	// 	setPageSize(size);
	// 	setSortingInfo(sortingInfo);
	// 	setLoading(false);
	// }

	useEffect(() => {
		incidentsDetailFunc(page, pageSize, sortingInfo, parseFilter(filterInfo),"");
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/incident-details') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		
	}, []);

	async function incidentSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		// const res = await jobSearch(searchDetails, jobFilterInfo, columnKey);
		// if (res.success) {
		// 	setFilterSearchInfo(res.data);
		// }
	}
	const handleDateChange = (selectedDate: string) => {
		incidentsDetailFunc(1, pageSize, sortingInfo, parseFilter(filterInfo),  selectedDate );
	};

	return (
		<Box mt="45px">
			<PageHeader title="Incident Details" icon={IncidentIcon} lastSync={""}>
				<DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={handleDateChange}
				/>					
			</PageHeader>
			<Grid p={2} mt={2}>
				{!loading ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={ incidentDetails && incidentDetails.length !== 0 ? incidentDetails : 0 }
						tableType="incident-details-ver"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						searchDataValues={searchDetails}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={ incidentDetails && incidentDetails.length !== 0 ? incidentDetails.length : 0 }
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isFetching={loading}
						showExport={false}
						fileName={`Jobs.xlsx`}
						isDateRangeFilter={false}
						isDisabled={true}
					/>
				) : (
					<LoaderComponent />
				)}
			</Grid>
		</Box>
	);
}

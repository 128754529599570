import { useFocusRef } from 'react-data-grid';
import PlusIcon from '../assets/plusIcon.svg';
import MinusIcon from '../assets/minusIcon.svg';
import './cellExpanderFormatter.css';
interface CellExpanderFormatterProps {
	isCellSelected: boolean;
	expanded: boolean;
	onCellExpand: () => void;
}

export function CellExpanderFormatter({ isCellSelected, expanded, onCellExpand }: CellExpanderFormatterProps) {
	const { ref, tabIndex } = useFocusRef<HTMLSpanElement>(isCellSelected);

	function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
		if (e.key === ' ' || e.key === 'Enter') {
			e.preventDefault();
			onCellExpand();
		}
	}

	return (
		<>
			<span className='toggle-wrap' onClick={onCellExpand} onKeyDown={handleKeyDown}>
				<span ref={ref} tabIndex={tabIndex} className="expandIcon">
					{expanded ? <img src={MinusIcon} /> : <img src={PlusIcon} />}
				</span>
			</span>
		</>
	);
}

import { Box, Stack, Typography } from '@mui/material';
import NoPrgramFound from '../../assets/no-program-found.svg';

const ShowInfoWidget = ({ title, height = '50vh' }: { title: string; height: string }) => {
	return (
		<Stack justifyContent="center" alignItems="center" height={height}>
			<Box component="img" src={NoPrgramFound} alt="" />
			<Typography variant="pageTitle" color="dark.main">
				{title}
			</Typography>
		</Stack>
	);
};

export default ShowInfoWidget;

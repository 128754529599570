import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import CustomDropdown from '.';

type SectorFilterProps = {
	sectorName: string;
	setSectorName: React.Dispatch<React.SetStateAction<string>>;
	sectorOptionsList: string[];
	handleChange?: (value: string) => void;
};

const sectorFilter = {
	backgroundColor: '#003771',
	//position: 'fixed',
	right: '35px',
	minWidth: '170px !important',
	zIndex: 999,
	'& fieldset': {
		display: 'none',
	},
	'& .MuiSelect-icon': {
		top: '0px !important',
		transition: 'none !important',
		height: '40px',
		width: '40px',
		right: '0px',
		position: 'absolute',
	},
	'& .MuiSelect-iconOpen': {
		transform: 'none',
	},
}

const SectorFilter = ({ 
	sectorName,
	setSectorName,
	sectorOptionsList,
	handleChange}: SectorFilterProps) => {

	const handleSectorChange = (e: any) => {
		const value = e.target.value;
		setSectorName(value);
		handleChange?.(value);
	};

	const options = [ { label: 'All Sectors', value: 'all' },
		...sectorOptionsList.map(option => ({
			label: option,
			value: option,
		  }))
	];

	return (
		<CustomDropdown
		value={sectorName}
		handleChange={handleSectorChange}
		options={options}
		dropdownIcon = {FilterIcon}
		boxProps={{
			sx: sectorFilter,
		}} />
	);
};

export default SectorFilter;

import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
// import { Grid, Paper } from '@mui/material';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { Stack } from '@mui/system';

import { RunHistory } from './run_history';
import { Overview } from './overview';
// import { JobLineage } from './job_Lineage';
import JobIcon from '../../assets/jobs_icon.png';

import { getJobOverview, getJobDependenciesChart } from '../../service/service';
// import moment from 'moment-timezone';
import { dashboardUrl } from '../../service/config';
import { ReactComponent as MoreVertIcon } from '../../assets/system-uicons_circle-menu_disabled.svg';
import IconButton from '@mui/material/IconButton';
import '../jobs/jobDetails.css';
import { Stack } from '@mui/material';
import { PaginationDetailsJobLinage } from '../pagination_details_jobLineage';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import Loader from '../Loader/loader';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{<Box>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

export default function jobDetails() {
	const location = useLocation();
	const params = useParams();
	const dataFetchedRef = useRef(false);
	const [job, setJob] = useState(location.state);
	const [succssorPageCountValue, setSuccssorPageCount] = useState(1);
	const [predecessorPageCountValue, setpredecessorPageCount] = useState(1);
	const [pageChangeSuccessor, setPageChangeSuccessor] = useState(1);
	const [pageChangePredecessor, setPageChangepredecessor] = useState(1);
	const [countTot, setCountTot] = useState(10);
	const [pageSuccessor, setPageSuccessor] = useState(1);
	const [pagePredecessor, setPagePredecessor] = useState(1);
	const [succssorTotal, setSuccssorTotal] = useState();
	const [predecessorTotal, setPredecessorTotal] = useState();
	const [error, setError] = useState(false);
	// const [metricDetails, setMetricDetails] = useState<any>([]);
	// Tab
	const [tabValue, setTabValue] = useState(0);
	// scroll issue on initial load
	const [view, setView] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setView(true);
		}, 8000);
	}, []);

	const navigate = useNavigate();
	const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
		setTabValue(newValue);
		switch (newValue) {
			case 0:
				// navigate(`/jobs/${params.jobId}/overview`);
				window.location.href = `/jobs/${params.jobId}/overview`;
				break;
			case 1:
				navigate(`/jobs/${params.jobId}/run_history`);
				break;
			case 2:
				navigate(`/jobs/${params.jobId}/job_dependencies`);
				break;
			default:
				navigate(`/jobs/${params.jobId}`);
				break;
		}
	};
	// Tab

	const afterUpdate = (jobData: any) => {
		fetchJobData();
	};

	async function fetchJobData() {
		if (!params.jobId) return false;
		const res = await getJobOverview(params && params.jobId);
		if (res.success) {
			const jobRes = res.data;
			console.log(jobRes,'jobRes=')
			setJob(jobRes);
			// const metrices = [
			// 	{
			// 		name: 'Total runs',
			// 		value: jobRes.totalRunCount,
			// 		key: 'totalRunCount',
			// 		className: 'total'
			// 	},
			// 	{
			// 		name: 'Successful',
			// 		value: jobRes.successCount,
			// 		key: 'successCount',
			// 		className: 'success'
			// 	},
			// 	{
			// 		name: 'Not Started',
			// 		value: jobRes.pendingCount || 0,
			// 		key: 'pendingCount',
			// 		className: 'pending'
			// 	},
			// 	{
			// 		name: 'Failed/Terminated',
			// 		value: jobRes.nonSuccessCount,
			// 		key: 'failedCount',
			// 		className: 'failed'
			// 	},
			// 	{
			// 		name: 'Others',
			// 		value: jobRes.othersCount || 0,
			// 		key: 'othersCount',
			// 		className: 'others'
			// 	}
			// ];
			// setMetricDetails(metrices);
		}
	}
	async function fetchDependency() {
		if (!params.jobId) return false;
		const res = await getJobDependenciesChart(params && params.jobId);
		setSuccssorPageCount(res.data.succssorPageCount);
		setpredecessorPageCount(res.data.predecessorPageCount);
		setSuccssorTotal(res.data.succssorTotalCount);
		setPredecessorTotal(res.data.predecessorTotalCount);
	}
	const handlePageChangeSuccssor = (event: React.ChangeEvent<unknown>, value: number) => {
		setPageChangeSuccessor(value);
		setPageSuccessor(value);
	};
	const handlePageChangePredecessor = (event: React.ChangeEvent<unknown>, value: number) => {
		setPageChangepredecessor(value);
		setPagePredecessor(value);
	};
	const handleCount = (event: any) => {
		const value = event.target.value;
		if (value.trim() === '') {
			setError(true);
			setCountTot(1);
		} else {
			setError(false);
			setCountTot(value);
		}

		if (typeof succssorTotal !== 'undefined') {
			const successorPageCountvar = Math.ceil(succssorTotal / event.target.value);
			setSuccssorPageCount(successorPageCountvar);
		}
		if (typeof predecessorTotal !== 'undefined') {
			const predecessorPageCountvar = Math.ceil(predecessorTotal / event.target.value);
			setpredecessorPageCount(predecessorPageCountvar);
		}
	};

	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		localStorage.setItem('overviewPageHit', 'true');
		fetchJobData();
		fetchDependency();
		if (location.pathname.includes('run_history')) setTabValue(1);
		else if (location.pathname.includes('job_dependencies')) setTabValue(2);
		else setTabValue(0);
	}, [params.jobId]);

	return job && job.id ? (
		<div className="page-table job_details_page_table">
			<div className="breadcrumb">
				<img className="verAlign" src={JobIcon} />
				<Link to={`/jobs`} className="titleLink verAlign">
					Jobs
				</Link>
				<span >
					<ArrowForwardIosIcon className="symbol"/>
				</span>{' '}
				<span className="page-title verAlign">{job.name}</span>
			</div>
			<Box sx={{ width: '100%', paddingTop: '10px'}}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						float: 'right',
						borderBottom: 'none',
						marginTop: '-44px', marginRight:'-27	px' 
					}}
				>
					<Tabs className="JDTabs" value={tabValue} onChange={handleTabChange}>
						<Tab label="Overview" {...a11yProps(0)} />
						<Tab className="tab-zero-width" label="Execution History" {...a11yProps(1)} />
						<Tab label="Job Lineage" {...a11yProps(2)} sx={{ width: 'fit-content !important' }} />
					</Tabs>
					<Box>
						{!location.pathname.includes('run_history') && (
							<Box>
								<IconButton
									aria-label="more"
									id="more-button"
									aria-haspopup="true"
									style={{
										zIndex: 999,
										borderRadius: 0,
										backgroundColor: '#1b1e35',
										marginTop: '5px',
										marginLeft: '7px'
									}}
									disabled={true}
								>
									<MoreVertIcon
										style={{
											height: 15,
											width: 20,
											zIndex: 999
										}}
									/>
								</IconButton>
							</Box>
						)}
					</Box>
				</Box>

				{/* {tabValue === 1 ? (
					<Grid container item xs={12} className="run-history--grid">
						<Grid container item xs={6} className="run-history--top">
							<Grid item xs={4}>
								<div className="attrTitle">Observed Since</div>
								<div className="attrValue">{job.createdAt && moment(job.createdAt).format()}</div>
							</Grid>
							<Grid item xs={4}>
								<div className="attrTitle">Job Alias</div>
								<div className="attrValue editJobName">{job.friendlyName}</div>
							</Grid>
							<Grid item xs={3}>
								<div className="attrTitle">Scheduler</div>
								<div className="attrValue">{job.schedulerName}</div>
							</Grid>
						</Grid>
						<Grid container item xs={6}>
							{metricDetails.map((metric: any) => (
								<Grid item xs={2} key={metric.name} className="job-widgets job-widgets-xs-5">
									<Paper elevation={2}>
										<Box>
											{metric.name}
											<Stack>
												<p className={metric.className}>{metric.value}</p>
											</Stack>
										</Box>
									</Paper>
								</Grid>
							))}
						</Grid>
					</Grid>
				) : (
					''
				)} */}
				<br />
				<TabPanel value={tabValue} index={0}>
					<Overview jobId={params.jobId} afterUpdate={afterUpdate} jobOverview={job} tabValue={tabValue} />
				</TabPanel>
				<TabPanel value={tabValue} index={1}>
					<RunHistory jobId={params.jobId} jobData={job} tabValue={tabValue} />
				</TabPanel>
				<TabPanel value={tabValue} index={2}>
				<>{console.log('JobdependencyDashboard')}</>
					{/* <JobLineageChart jobId={params.jobId} /> */}
					{view && (
						<>
							<Stack>
								{/* <iframe
									className="job-dependency-iframe-container"
									src={`${dashboardUrl.jobLineage}&var-authToken=${jwtToken}&var-jobId=${params.jobId}&var-count=${countTot}&var-successorPage=${pageChangeSuccessor}&var-predecessorPage=${pageChangePredecessor}`}
								/> */}
								<AnalyticsVisualization
									params={{
										dashboardUrl: dashboardUrl.jobLineage,
										className: 'jobDependencyIframeContainer',
										otherParams: {
											'var-jobId': params.jobId,
											'var-count': countTot,
											'var-successorPage': pageChangeSuccessor,
											'var-predecessorPage': pageChangePredecessor
										}
									}}
								/>
							</Stack>
							<PaginationDetailsJobLinage
								succssorPageCountValue={succssorPageCountValue}
								predecessorPageCountValue={predecessorPageCountValue}
								handlePageChangeSuccssor={handlePageChangeSuccssor}
								handleCount={handleCount}
								handlePageChangePredecessor={handlePageChangePredecessor}
								pageSuccessor={pageSuccessor}
								pagePredecessor={pagePredecessor}
								succssorTotal={succssorTotal}
								predecessorTotal={predecessorTotal}
								countTot={countTot}
								error={error}
							/>
						</>
					)}
				</TabPanel>
			</Box>
		</div>
	) : (
		<Loader />
	);
}

import {
	Box,
	Button,
	Dialog,
	Grid,
	Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ResourceMappingModal } from './modalComponent';
import { ReactComponent as EditIcon } from '../../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-img.svg';
import { ReactComponent as AddIcon } from '../../../assets/plus-icon.svg';
import {
	getFinopsResourceMappingList,
	getProjectDetailsById,
} from '../../../service/service';
import { CustomizedDataGrid } from '../../customized_data_grid';
import { Column } from 'react-data-grid';
import Loader from '../../Loader/loader';
import { Link, useParams } from 'react-router-dom';
import 'react-date-range/dist/styles.css'; // main style file
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../../assets/material-symbols_dashboard.svg';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import DataInfoSecondary from 'components/Widgets/DataInfoSecondary';
import SearchFilter from 'components/Dropdown/SearchFilter';

const ProjectDetails = () => {

	interface Row {
		Id: string,
		[key: string]: any;
		Resource: string;
		ResourceGroup: string;
		Environment: string;
		ProjectName: string;
		BillingInformation: string;
		Subscription: string;
		selected: boolean;
		DNAI_Value: String;
		ConsumedService: string;
		Allocpercent: string;
	}

	const { programId, projectId, program, project } = useParams();
	
	const [controller] = usePepwiseAuthController();
	const { role } = controller;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleMenuClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
	};

	const [openModal, setOpenModal] = useState<any>({ isOpen: false });
	const [projectDetail, setProjectDetail] = useState<any>({});
	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [resourceList, setResourceList] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterInfo, setFilterInfo] = useState([]);
	const [filterOptions, setFilterOptions] = useState<any>({});
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState('100');

	const handleTableAction = (props: any, type: string) => {
		if (type == 'delete') {
			setOpenModal({
				isOpen: true,
				type: 'Schedule',
				action: 'DELETE',
				from: 'delete',
				data: props
			});
		}
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	const defaultColumnNames = [
		'Resource',
		'Resource Group',
		'D&AI',
		'Environment',
		'Sector',
		'Allocation %',
		'Subscription',
		'Application Service',
		'Billing Entity',
	];

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'Resource',
				name: 'Resource',
				width: 200
			},
			{
				key: 'ResourceGroup',
				name: 'Resource Group',
				width: 200
			},
			{
				key: 'DNAI_Value',
				name: 'D&AI',
				width: 200
			},
			{
				key: 'Environment',
				name: 'Environment',
				width: 200
			},
			{
				key: 'Sector',
				name: 'Sector',
				width: 200
			},
			{
				key: 'Allocpercent',
				name: 'Allocation %',
				width: 200
			},
			{
				key: 'Subscription',
				name: 'Subscription',
				width: 200
			},
			{
				key: 'ConsumedService',
				name: 'Application Service',
				width: 200
			},
			{
				key: 'BillingInformation',
				name: 'Billing Entity',
				width: 200
			},
		];
	}

	const getProjectDetails = async () => {
		setLoading(true);
		let result = await getProjectDetailsById({ projectId, programId });
		setLoading(false);
		const projectDetails = {
			...result.data,
			programName: decodeURIComponent(window.location.pathname.split('/')[6]),
		};
		setProjectDetail(projectDetails);

		projectMappedResourcesFunc(page, pageSize, parseFilter(filterInfo));
	};

	const passInfoToParent = (data: any) => {
		getProjectDetails();
	};

	const handleEditClose = (data: any) => {
		setOpenModal({
			isOpen: false
		});
	};

	const handleSorting = (sortingInfo: any) => {
		projectMappedResourcesFunc(page, pageSize, sortingInfo);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: string) => {
		projectMappedResourcesFunc(value, pageSize, sortingInfo);
	};
	const handlepageSizeChange = (event: any) => {
		projectMappedResourcesFunc(page, event.target.value, sortingInfo);
	};

	async function projectMappedResourcesFunc(pageNumber: any, pageSize: any, sortingInfo: any) {
		setTableLoading(true);
		const res = await getFinopsResourceMappingList({
			page: pageNumber,
			size: pageSize,
			sortingInfo: sortingInfo.sortBy,
			orderBy: sortingInfo.sortDir,
			searchField: 'detail',
			viewType: 'flat',
			// params: ["ipp", ""]
			// sql: "(ProjectName = ? and Program = ?)"
			params: ["ipp"],
			sql: "(ProjectName = ?)"
		});
		if (res.success) {
			setTableLoading(false);
			setResourceList(res.data.result);
			setPaginationData(res.data.page);
			setPage(res.data.page.currentPage);
			setPageSize(res.data.page.size);
			setSortingInfo(sortingInfo);
		}
	}

	const handleSearchData = (searchText: string) => {
		try {
			// getProjectsByProgram(page.toLocaleString(), pageSize, sortingInfo, searchText);
		} catch (error) {
			console.error(error);
		}
	};

	const handleAction = (module: string, type: string, data: any, e: any) => {
		e.preventDefault();
		e.stopPropagation();
		handleMenuClose(e);
		data = {
			...data,
			programName:  decodeURIComponent(window.location.pathname.split('/')[6])
		};
		// debugger
		switch (type) {
			case 'edit':
				setOpenModal({
					isOpen: true,
					type: module,
					action: 'EDIT',
					from: 'view',
					data: module === 'Project' ? projectDetail : data
				});
				break;
			case 'delete':
				setOpenModal({
					isOpen: true,
					type: module,
					action: 'DELETE',
					from: 'delete',
					data: module === 'Project' ? projectDetail : data
				});
				break;
			// case 'view':
			// 	window.location.href = `/data-pipeline/search-jobs/${programId}/${projectId}/${encodeURIComponent(
			// 		program || ''
			// 	)}/${encodeURIComponent(project || '')}`;
			// 	break;
			default:
				break;
		}
	};

	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/finops/resourcemapping/projects')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		if (!openModal.isOpen) {
			getProjectDetails();
		}
	}, [openModal]);

	return (
		<Box bgcolor="blue.darker" mt="60px">
			<PageHeader
				icon={DashboardIcon}
				title={[
					{
						text: 'Programs',
						link: '/finops/resourcemapping/programs'
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[6]),
						link: `/finops/resourcemapping/programs/${window.location.pathname
							.split('/')[4]
							.replace(/%20/g, ' ')}/${window.location.pathname.split('/')[6].replace(/%20/g, ' ')}`
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[7])
					}
				]}
				childrenContainerProps={{
					minWidth: (role === 'Data Operations Admin' || role === 'PepWise System User') ? '395px' : '230px'
				}}
			>
				<Stack direction="row" gap={1} alignItems="center">
					<SearchFilter handleSearchData = {handleSearchData} />
					<Link to={`/finops/resourcemapping/search?programId=${programId}&projectId=${projectId}
					&programName=${encodeURIComponent(program || '')}&projectName=${encodeURIComponent(project || '')}`}>
						<Button variant="pepwiseSecondary" startIcon={<AddIcon />}>ADD RESOURCES</Button>
					</Link>
				</Stack>
			</PageHeader>
			<Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md">
				<ResourceMappingModal
					handleEditClose={handleEditClose}
					programData={openModal}
					passInfoToParent={passInfoToParent}
				/>
			</Dialog>
			<Stack spacing={1} p={2}>
				<Grid container spacing={1} mt={1}>
					<Grid item xs={12} lg={12}>
						<DataInfoSecondary
							infoCards={[
								{
									label: 'Project Name',
									value: projectDetail?.projectName,
									isLoading: loading,
									valueProps: {
										lineHeight: '24px'
									}
								},
								{
									label: 'Project Owner',
									value: projectDetail?.projectOwner,
									isLoading: loading,
									valueProps: {
										lineHeight: '24px'
									}
								},
								{
									label: 'Description',
									value: projectDetail?.projectDescription,
									isLoading: loading,
									valueProps: {
										lineHeight: '24px'
									}
								},
							]}
							infoTooltip={{
								titleValues: [
									{
										label: 'Project Owner',
										value: projectDetail?.projectOwner ? projectDetail.projectOwner : loading ? 'Loading...' : 'NA'
									},
									{
										label: 'Description',
										value: projectDetail?.projectDescription ? projectDetail.projectDescription : loading ? 'Loading...' : 'NA'
									}
								],
								dateValues: [
									{ label: 'Date Created', value: projectDetail?.createdAt ?? 'NA' },
									{ label: 'Last Updated', value: projectDetail?.updatedAt ?? 'NA' }
								]
							}}
							infoOptionMenu={{
								onClick: handleClick,
								onClose: handleMenuClose,
								menuItems: !projectDetail.Clarity_Project_Id
									? [
											{
												key: 'edit',
												label: 'Edit',
												icon: <EditIcon />,
												onMenuClick: (e) => handleAction('Project', 'edit', {}, e)
											},
											{
												key: 'delete',
												label: 'Delete',
												icon: <DeleteIcon />,
												onMenuClick: (e) => handleAction('Project', 'delete', {}, e)
											}
									  ]
									: [
											{
												key: 'delete',
												label: 'Delete',
												icon: <DeleteIcon />,
												onMenuClick: (e) => handleAction('Project', 'delete', {}, e)
											}
									  ]
							}}
							isAdmin = {(role === 'Data Operations Admin' || role === 'PepWise System User') ? true : false}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} p={1}>
					{!tableLoading ? (
					<CustomizedDataGrid
					title={'Resources'}
					tableColumns={getColumns()}
					tableData={resourceList}
					tableType="resource-details"
					defaultColumnNames={defaultColumnNames}
					filterDataOptions={filterOptions}
					onSorting={handleSorting}
					currSorting={sortingInfo}
					currFilter={filterInfo}
					page={page}
					pageSize={pageSize}
					paginationData={paginationData}
					listLength={resourceList.length}
					handlepageSizeChange={handlepageSizeChange}
					handlePageChange={handlePageChange}
					isDateRangeFilter={false}
					isDisabled={false}
					showExport={false}
					isFetching={tableLoading}
					isShowMoreButton = {false}
					/>
					): (
						<Loader />
					)}
				</Grid>
			</Stack>
		</Box>
	);
};

export default ProjectDetails;


import {
	Box,
	Button,
	Dialog,
	Grid,
	Snackbar,
	Stack,
	TextField,
	Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DataPipelineModal } from './modal-component';
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg';
import '../DataPipeline/dataPipeline.scss';
import {
	getProgramById,
	getProjectListByProgram
} from '../../service/service';
import { CustomizedDataGrid } from '../customized_data_grid';
import moment from 'moment';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';
import DataInfoSecondary from 'components/Widgets/DataInfoSecondary';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import ShowInfoWidget from 'components/Widgets/ShowInfoWidget';
import RowTypography from 'components/Table/RowTypography';
import StatusChip from 'components/Table/StatusChip';
import eventEmitter from 'SnackBarEventEmitter';
import DataInfoColorWidget from 'components/Widgets/DataInfoColorWidget';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const ProgramDetails = () => {
	const [controller] = usePepwiseAuthController();
	const { role } = controller;
	interface Row {
		id: number;
		Job_Name: string;
		Platform_Id: number;
		Start_Time: string;
		Normal_Completion_Time: number;
		slaStatus: number;
		jobStatus: string;
		estimatedTime: string;
		Days: number;
		actualStartTime: string;
		actualEndTime: string;
		scheduleStartTime: number;
		Date: string;
		successor: number;
	}
	const [currentFilterType, setCurrentFilterType] = useState<string>('');
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [isOrderChanged, setIsOrderChanged] = useState(false);
	const [accordionActiveId, setAccordionActiveId] = useState<any>(null);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [anchorElDetails, setAnchorElDetails] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorElDetails);
	
	const handleThreeDotsClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
		setAccordionActiveId(null);
	};

	const { id, name } = useParams();

	const handleDetailsThreeDotsClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(event.currentTarget);
	};
	const handleDetailsThreeDotsClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(null);
	};

	const [startDate, setStartDate] = useState(moment().startOf('day').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().endOf('day').format('YYYY-MM-DD'));
	const [projectList, setProjectList] = useState<any>([]);
	const [openModal, setOpenModal] = useState<any>({ isOpen: false, message: '' });
	const [programDetail, setProgramDetail] = useState<any>({});
	const [loading, setLoading] = useState(false);
	const [projectId, setProjectId] = useState('');
	const [tableLoading, setTableLoading] = useState(false);
	const [jobSchedulesList, setjobSchedulesList] = useState([]);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const defaultColumnNames = ['Sectors', 'Project', 'Delayed Start', 'Long Running', 'Failed', 'Success', 'Project SLA Status', 'SLA Time (CST)', 'Action'];
	const [filterData, setFilterData] = useState<any[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [expanded, setExpanded] = useState<any>(false);
	const { prId, prName } = useParams();
	const [nameState, setNameState] = useState(prName);
	const [toast, setToast] = useState<any>({
		open: false,
		message: ''
	});

	const navigate = useNavigate();

	const getColumns = () => {
		return [
			{
				key: 'Sectors',
				name: 'Sectors',
				formatter(props: any) {
					const sectorList = props.row.Sectors ? props.row.Sectors.split(', ') : [];
					if (sectorList && sectorList.length) {
						return sectorList.map((sector: string, idx: number) => <RowTypography sx={{ backgroundColor: 'rgba(41, 155, 255, 0.15)', color: '#fff', padding: '4px 10px', marginRight: '5px', borderRadius: '20px' }} key={idx} title={sector}>{sector}</RowTypography>);
					} else {
						return '--'
					}
				},
				minWidth: 150,
				maxWidth: 250
			},
			{
				key: 'Project_Name',
				name: 'Project',
				formatter(props: any) {
					return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${programDetail?.program_Name}/${props.row.Project_Name}`} state={props.row} title={props.row.Friendly_Name}>{props.row.Project_Name}</Link>
				},
				minWidth: 200,
				maxWidth: 500
			},
			{
				key: 'IP_Delayed_Start',
				name: 'Delayed Start',
				formatter(props: any) {
					if (props.row.IP_Delayed_Start && props.row.IP_Delayed_Start != 'NA') {
						return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${programDetail?.program_Name}/${props.row.Project_Name}?jobStatus=Delay Start`} state={props.row}>
							<StatusChip displayNumber={true} status='IP_Delayed_Start' value={props.row.IP_Delayed_Start ? props.row.IP_Delayed_Start : '--'} />
						</Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'IP_Long_Running',
				name: 'Long Running',
				formatter(props: any) {
					if (props.row.IP_Long_Running && props.row.IP_Long_Running != 'NA') {
						return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${programDetail?.program_Name}/${props.row.Project_Name}?jobStatus=Long Running`} state={props.row}>
							<StatusChip displayNumber={true} status='IP_Long_Running' value={props.row.IP_Long_Running ? props.row.IP_Long_Running : '--'} />
						</Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'Failed',
				name: 'Failed',
				formatter(props: any) {
					if (props.row.Failed && props.row.Failed != 'NA') {
						return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${programDetail?.program_Name}/${props.row.Project_Name}?jobStatus=Failure`} state={props.row}>
							<StatusChip displayNumber={true} status='JobsFailed' value={props.row.Failed ? props.row.Failed : '--'} />
						</Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'Success',
				name: 'Success',
				formatter(props: any) {
					if (props.row.Success && props.row.Success != 'NA') {
						return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${programDetail?.program_Name}/${props.row.Project_Name}?jobStatus=Successful`} state={props.row}>
							<StatusChip displayNumber={true} status='JobsSuccess' value={props.row.Success ? props.row.Success : '--'} />
						</Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'SLA_Status',
				name: 'Project SLA Status',
				formatter(props: any) {					
					if (props.row.SLA_Status == 'NA' || props.row.SLA_Status == '--') {
						return <div style={{textAlign: 'center'}}>{props.row.SLA_Status}</div>
					} else if (props.row.SLA_Status == '') {
						return ''
					}
					return <StatusChip displayNumber={true} status='ProjectSLAStatus' value={props.row.SLA_Status} />
				},
				minWidth: 200
			},
			{
				key: 'SLA_Time',
				name: 'SLA Time (CST)',
				formatter(props: any) {
					return (
						<RowTypography textTransform="capitalize">
							{props.row.SLA_Time && props.row.SLA_Date != null ? 
								props.row.SLA_Date > 0 ? moment(props.row.Schedule_Date).add(props.row.SLA_Date, 'day').format('YYYY-MM-DD')
								: moment(props.row.Schedule_Date).format('YYYY-MM-DD')
							: '' } {' '}
							{props.row.SLA_Time
								? props.row.SLA_Time < 10
									? `000${props.row.SLA_Time}`.replace(/(.{2})$/, ':$1')
									: props.row.SLA_Time < 100
										? `00${props.row.SLA_Time}`.replace(/(.{2})$/, ':$1')
										: props.row.SLA_Time.toString().replace(/(.{2})$/, ':$1')
								: '--'}
						</RowTypography>
					);
				},
				minWidth: 150
			},
			{
				key: 'action',
				name: 'Action',
				resizable: false,
				minWidth: 100,
				formatter: (props: any) => {
					return (
						<Stack direction="row" alignItems="center" height="100%">
							{(role === 'Data Operations Admin' || role === 'PepWise System User') && (
								<><EditIcon style={{cursor: 'pointer'}} onClick={(e) => handleAction('Project', 'edit', props.row, e)} /> 
								<DeleteIcon style={{cursor: 'pointer'}} onClick={(e) => handleAction('Project', 'delete', props.row, e)} /></>
							)}
						</Stack>
					);
				}
			}
		];
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType);
	};

	const handleSearch = (e: any) => {
		console.log('FILTER', e);
	};

	const handleSorting = (sortingInfo: any) => {
		console.log('FILTER', sortingInfo);
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, page, pageSize, startDate, endDate, sortingInfo);
		// projectMappedJobScheduleFunc(page, pageSize, startDate, endDate, parseFilter(filterInfo), sortingInfo);
		setSortingInfo(sortingInfo)
	};

	const onClickWidget = (type: string) => {
		setCurrentFilterType(type);
		getProjectsByProgram(parseFilter(filterInfo), type);
	}

	eventEmitter.on('showSnackbar', (message: string) => {
		setLoading(false);
		setTableLoading(false)
	});

	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/data-pipeline/programs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		getProgramDetails();
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType);
	}, []);

	useEffect(() => {
		if (openModal && !openModal.isOpen && openModal.closeType && openModal.closeType == 'updated') {
			getProgramDetails();
			getProjectsByProgram(parseFilter(filterInfo), currentFilterType);
		}
	}, [openModal]);

	const passInfoToParent = (data: any) => {
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, 1, 100);
	};

	const getProgramDetails = async () => {
		setLoading(true);
		let result = await getProgramById(id);
		setLoading(false);
		setProgramDetail(result.data?.programData ? result.data.programData[0] : '');
		if (!result.data.programData.length) {
			navigate(`/data-pipeline/programs`)
		}
	};

	const getProjectsByProgram = async (filterInfo?: any, currentFilterType?: string, page?: number, pageSize?: number, startDate?: string, endDate?: string, sortingInfo?: any) => {
		setTableLoading(true);
		let result = await getProjectListByProgram(id, page, pageSize, currentFilterType, filterInfo, startDate, endDate, sortingInfo);
		setTimeout(() => {
			let table = document.getElementById(`project_list${id}`);
			if (table) {
				const rdgElement = table.querySelector('.rdg') as HTMLElement | null;
				if (rdgElement) {
					let jobsLength = result.data.projectList.length;
					let height = 80;
					if (jobsLength === 1) height = jobsLength * 80;
					else if (jobsLength > 1 && jobsLength <= 3) height = jobsLength * 65;
					else if (jobsLength > 3 && jobsLength <= 5) height = jobsLength * 55;
					else if (jobsLength > 5 && jobsLength <= 10) height = jobsLength * 50;
					else if (jobsLength > 10 && jobsLength <= 15) height = jobsLength * 40;
					else height = jobsLength * 30;
					rdgElement.style.height = `${height}px`;
				}
			}
		}, 100);
		setPaginationData(result.data.page);
		setTableLoading(false);
		const projData = result.data.projectList.map((list: any) => {
			list['activeRow'] = 0;
			const selectedSectors = list.Sectors && list.Sectors.split(', ');
			list['Sectors'] = selectedSectors && selectedSectors.length >= 10 ? ['All 10 Sectors'] : list.Sectors;
			return list;
		});
		const filtersData: any = {
			Sectors: [], Project_Name: [], SLA_Status: []
		}
		if(result.data.filterOptions){
			Object.entries(result.data.filterOptions).filter((filter: any) => {
				if (filter[0] == 'sector' || filter[0] == 'Sector_Name') {
					filtersData['Sectors'] = filter[1]
				} 
				else if (filter[0] == 'Project_Name' || filter[0] == 'projectName') {
					filtersData['Project_Name'] = filter[1]
				} else if (filter[0] == 'SLA_Status' || filter[0] == 'projectSlaStatus') {
					filtersData['SLA_Status'] = filter[1].filter((status: string) => status)
				}
			})
		}
		setFilterData(filtersData);
		setFilterInfo(filterInfo)
		setProjectList(projData);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, value, pageSize, startDate, endDate);
	};
	const handlepageSizeChange = (event: any) => {
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, page, event.target.value, startDate, endDate);
	};

	const handleEditClose = (closeType: any) => {
		setOpenModal({
			...openModal,
			isOpen: false,
			closeType: closeType
		});
		setSelectedRows([]);
	};

	const onCreateProgramClick = (event: any) => {
		setOpenModal({
			...openModal,
			isOpen: true,
			type: 'Project',
			action: 'CREATE'
		});
	};

	const handleAction = (module: string, type: string, data: any, e: any) => {
		e.preventDefault();
		e.stopPropagation();
		handleThreeDotsClose(e);
		handleDetailsThreeDotsClose(e);
		data = {
			...data,
			ProgramName: programDetail?.program_Name,
			ProgramID: data.Program_ID
		};

		switch (type) {
			case 'edit':
				setOpenModal({
					...openModal,
					isOpen: true,
					type: module,
					action: 'EDIT',
					data: module === 'Program' ? data : data
				});
				break;
			case 'delete':
				setOpenModal({
					...openModal,
					isOpen: true,
					type: module,
					action: 'DELETE',
					data: module === 'Program' ? data : data
				});
				break;
			case 'view':
				window.location.href = `/data-pipeline/projects/${data.Program_ID}/${data.Project_Id}/${encodeURIComponent(
					data.ProgramName
				)}/${encodeURIComponent(data.Project_Name)}`;
				break;
			default:
				break;
		}
	};

	const handleClose = () => {
		setToast({
			...toast,
			open: false
		});
	};

	const getToastData = (data: any) => {
		setToast({
			...toast,
			message: data.data.message
		});
	};

	const handleDateSelect = (stDate: any) => {
		const sd = moment(stDate).startOf('day').format('YYYY-MM-DD');
		const ed = moment(stDate).startOf('day').format('YYYY-MM-DD');
		setStartDate(sd)
		setEndDate(ed)
		getProjectsByProgram(parseFilter(filterInfo), currentFilterType, 1, 100, sd, ed);
	}

	return (
		<Box mt="45px" bgcolor="blue.darker">
			<PageHeader
				icon={DashboardIcon}
				title={[
					{
						text: 'Programs',
						link: '/data-pipeline/programs'
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[4].replace(/%20/g, ' '))
					}
				]}
			>	
			{<LocalizationProvider className="outerDateContainer" dateAdapter={AdapterMoment}>
						<DatePicker
							showToolbar={false}
							components={{
								OpenPickerIcon: DatePickerIcon
							}}
							PopperProps={{ placement: 'auto' }}
							minDate={moment(new Date('2024-10-01T06:00:00Z'))}
							disableFuture={true}
							className="dateFilterDataPipeline"
							value={moment(startDate)}
							onChange={(dateTime) => {
								handleDateSelect(dateTime);
							}}
							renderInput={(params: any) => <TextField name="startDateTime" {...params} />}
						/>
					</LocalizationProvider>
						}
				{/* <ReactDateRangeFilter isDisabled={true} setStartDate={setStartDate} setEndDate={setEndDate} handleDateSelect={handleDateSelect} /> */}

				{(role === 'Data Operations Admin' || role === 'PepWise System User') && (
					<Button variant="pepwiseSecondary" onClick={onCreateProgramClick}>
						ADD PROJECT
					</Button>
				)}
			</PageHeader>

			<Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md">
				<DataPipelineModal
					handleEditClose={handleEditClose}
					programData={openModal}
					getToastData={getToastData}
					passInfoToParent={passInfoToParent}
				/>
			</Dialog>
			<Box p={3} pr={2} mt={3}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<DataInfoSecondary
							infoCards={[
								{
									label: 'Program Name',
									value: programDetail?.program_Name,
									isLoading: !programDetail?.program_Name,
									valueProps: {
										lineHeight: '24px'
									}
								}
							]}
							infoTooltip={{
								dateValues: [
									{ label: 'Date Created', value: programDetail?.Created_Dt ?? 'NA' },
									{ label: 'Last Updated', value: programDetail?.Updated_Dt ?? 'NA' }
								]
							}}
							infoOptionMenu={{
								onClick: handleDetailsThreeDotsClick,
								onClose: handleDetailsThreeDotsClose,
								menuItems: [
									{
										key: 'edit',
										label: 'Edit',
										icon: <EditIcon />,
										onMenuClick: (e) => handleAction('Program', 'edit', programDetail, e)
									},
									{
										key: 'delete',
										label: 'Delete',
										icon: <DeleteIcon />,
										onMenuClick: (e) => handleAction('Program', 'delete', programDetail, e)
									}
								]
							}}
							isAdmin = {(role === 'Data Operations Admin' || role === 'PepWise System User') ? true : false}
						/>
					</Grid>
					<Grid item xs={4} md={2}>
						<DataInfoColorWidget title='Total Projects' style={{cursor: programDetail?.ProjectCount ? 'pointer' : 'default' }} onClick={(e) => programDetail?.ProjectCount ? onClickWidget('') : false } value={programDetail?.ProjectCount || 0} boxColor='' isLoading={loading} />
					</Grid>					
					<Grid item xs={4} md={2}>
						<DataInfoColorWidget style={{cursor: programDetail?.SLA_Missed_Count ? 'pointer' : 'default' }} onClick={(e) => programDetail?.SLA_Missed_Count ? onClickWidget('SLA Missed') : false } title='SLA Missed' value={programDetail?.SLA_Missed_Count || 0} boxColor='orangeDark' isLoading={loading} />
					</Grid>					
					<Grid item xs={4} md={2}>
						<DataInfoColorWidget style={{cursor: programDetail?.SLA_Met_Count ? 'pointer' : 'default' }} onClick={(e) => programDetail?.SLA_Met_Count ? onClickWidget('SLA Met') : false } title='SLA Met' value={programDetail?.SLA_Met_Count || 0} boxColor='green' isLoading={loading} />
					</Grid>
				</Grid>
				{programDetail?.ProjectCount > 0 && !tableLoading ? (
					<Box sx={{position: 'relative'}}>
					<Typography mt={2} variant="overlayWidgetTitle" style={{position: 'absolute'}} component="span"> Projects</Typography>
					{projectList.length > 0 && (
						<Box>
							<CustomizedDataGrid
								tableColumns={getColumns()}
								tableData={projectList}
								tableType="program-list"
								defaultColumnNames={defaultColumnNames}
								onSorting={handleSorting}
								currSorting={sortingInfo}
								filterDataOptions={filterData}
								searchDataValues={searchDetails}
								onFilterCheck={handleFilter}
								currFilter={filterInfo}
								onFilterSearch={handleSearch}
								page={page}
								pageSize={pageSize}
								paginationData={paginationData}
								listLength={projectList.length}
								handlepageSizeChange={handlepageSizeChange}
								handlePageChange={handlePageChange}
								isFetching={tableLoading}
								showExport={false}
								isDateRangeFilter={false}
								isDisabled={false}
								isShowMoreButton
							/>
						</Box>
					)}
					{ !projectList.length && <Stack justifyContent="center" alignItems="center" height="60vh">
						<Typography variant="pageTitle" color="dark.main">
							No data for the selected filters
						</Typography>
					</Stack>}
					</Box>
				) : tableLoading ? (
					<Stack justifyContent="center" alignItems="center" height="60vh">
						<Typography variant="pageTitle" color="dark.main">
							Loading...
						</Typography>
					</Stack>
				) : (
					<ShowInfoWidget title="Add Project" height="60vh" />
				)}
			</Box>
			<Snackbar
				open={ toast && openModal.message ? true : false }
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				autoHideDuration={6000}
				onClick={handleClose}
				message={openModal.message}
			/>
		</Box>
	);
};

export default ProgramDetails;

import React from 'react';
import ReactECharts from 'echarts-for-react';

interface ThroughputData {
  gateway: number;
  source: number;
}

interface ThroughputChartProps {
  data: ThroughputData[];
}

const ThroughputChart: React.FC<ThroughputChartProps> = ({ data }) => {
  const chartOptions = {
    xAxis: {
      data: data.map(item => item.gateway),
      axisLine: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      axisLabel: { show: false },
    },
    yAxis: {
      axisLine: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      axisLabel: { show: false },
    },
    series: [
      {
        data: data.map(item => item.source),
        type: 'line',
        lineStyle: {
          color: '#01C008',
          width: 2,
        },
        areaStyle: {
          color: '#01C008',
          opacity: 0.5,
        },
        symbol: 'none',
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  };

  return (
    <ReactECharts
      option={chartOptions}
      style={{ width: '350px', height: '80px' }}
      notMerge={true} 
      lazyUpdate={true} 
    />
  );
};

export default ThroughputChart;
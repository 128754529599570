import { createContext, ReactNode, useContext, useReducer, Dispatch, useMemo } from 'react';
import { CacheDataActions, CacheDataContextType, CacheDataState } from './cacheData/types/CacheDataContext';
import { dataEstateReducer, dataEstateState } from './cacheData/DataEstate';
import { drawerReducer, drawerState } from './cacheData/SideDrawer';
import { commonDataReducer, commonDataState } from './cacheData/CommonData';
import { resourceMappingReducer, resourceMappingState } from './cacheData/resourceMapping';

// Create context
const CacheDataContext = createContext<CacheDataContextType | undefined>(undefined);

const initialDataState: CacheDataState = {
	...drawerState,
	...commonDataState,
	...dataEstateState,
	...resourceMappingState
};

// Data reducer
function cacheDataReducer(state: CacheDataState, action: CacheDataActions): CacheDataState {
	switch (action.type) {
		case 'CLEAR_DATA_CACHE':
			return initialDataState;
	}
	const drawerState = drawerReducer(state, action);
	if (drawerState !== state) {
		return drawerState;
	}
	const commonDataState = commonDataReducer(state, action);
	if (commonDataState !== state) {
		return commonDataState;
	}
	const estateState = dataEstateReducer(state, action);
	if (estateState !== state) {
		return estateState;
	}
	const resourceState = resourceMappingReducer(state, action);
	if (resourceState !== state) {
		return resourceState;
	}

	throw new Error(`Unhandled action type: ${action.type}`);
}

// Provider component
export function CacheDataProvider({ children }: { children: ReactNode }) {
	const initialState = initialDataState;

	const [controller, dispatch] = useReducer(cacheDataReducer, initialState);

	const value = useMemo(() => [controller, dispatch] as CacheDataContextType, [controller, dispatch]);

	return <CacheDataContext.Provider value={value}>{children}</CacheDataContext.Provider>;
}

// Hook to use the CacheDataContext
export function useCacheDataController() {
	const context = useContext(CacheDataContext);
	if (!context) {
		throw new Error('useCacheDataController must be used within a CacheDataProvider');
	}
	return context;
}

// Action creators
export const clearDataCache = (dispatch: Dispatch<CacheDataActions>) => {
	dispatch({
		type: 'CLEAR_DATA_CACHE'
	});
};

import { Box, Typography, Stack } from '@mui/material';
import LoaderComponent from 'components/Loader';
import ReactECharts from 'echarts-for-react';
import { useEffect, useRef } from 'react';

type GuageWidgetProps = {
	color?: string;
	isLoading: boolean;
	title: string;
	data?: any | null;
	minTitleHeight?: `${number}px`;
};

const defaultOptions = {
	backgroundColor: '#003771',
	series: [
		{
			type: 'gauge',
			center: ['50%', '45%'],
			min: 0,
			max: 0,
			splitNumber: 5,
			itemStyle: {
				color: '#e5e5e5'
			},
			progress: {
				show: false
			},
			pointer: {
				show: false
			}
		}
	]
};

const GuageWidget = ({
	color = '#1b1e35',
	isLoading = false,
	title = '',
	minTitleHeight = '38px',
	data = null
}: Readonly<GuageWidgetProps>) => {
	const chartRef = useRef<any>(null);

	useEffect(() => {
		const handleResize = () => {
			if (chartRef.current) {
				chartRef.current.getEchartsInstance().resize();
			}
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<Box display="flex" flexGrow={1} height="100%" position="relative">
			<Stack minHeight="100px" p={2} bgcolor="primary.main" fontFamily="Montserrat" borderRadius="5px" flexGrow={1}>
				<Stack direction="row" gap={2} alignItems="start" minHeight={minTitleHeight}>
					<Typography variant="widgetTitle">{title}</Typography>
				</Stack>
				<Box flexGrow={1}>
					{!isLoading ? (
						<ReactECharts
							ref={chartRef}
							option={data && Object.keys(data) ? data : defaultOptions}
							theme={{
								backgroundColor: { color }
							}}
						/>
					) : (
						<LoaderComponent />
					)}
				</Box>
			</Stack>
		</Box>
	);
};

export default GuageWidget;

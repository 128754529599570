import { Box, Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';

type TitleObject = {
	text: string;
	link?: string; // Optional link if the title is clickable
};

type PageHeaderProps = {
	title: string | Array<string> | Array<TitleObject>;
	icon: string;
	iconSize?: number;
	lastSync?: string | null;
	children?: React.ReactNode;
	childrenContainerProps?: StackProps;
	isDataRefresh?: boolean;
};

const breadCrumbStyle: TypographyProps = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	maxWidth: '125px'
};

const PageHeader = ({ title, icon, iconSize, lastSync, children, childrenContainerProps, isDataRefresh = false }: PageHeaderProps) => {
	return (
		<Stack
			direction={{ xs: 'column', md: 'row' }}
			justifyContent={{ xs: 'center', md: 'space-between' }}
			position="sticky"
			top={80}
			zIndex={100}
			bgcolor="blue.darker"
			px={2}
		>
			<Stack direction="row" gap={0.5} alignItems="center">
				<Box component="img" src={icon} alt="icon" {...(!!iconSize && { width: iconSize })} />
				{typeof title === 'string' ? (
					<Typography variant="breadcrumbs">{title}</Typography>
				) : (
					<Stack direction="row" alignItems="center" flexWrap="wrap">
						{title.map((item, idx) =>
							typeof item === 'string' ? (
								<Stack key={`${item}-${idx}`} direction="row" alignItems="center">
									<Typography
										variant="breadcrumbs"
										{...((idx < title.length - 1 || item.length > 60) && breadCrumbStyle)}
									>
										{item}
									</Typography>
									{idx < title.length - 1 && <ChevronRightIcon />}
								</Stack>
							) : (
								<Stack key={`${item.text}-${idx}`} direction="row" alignItems="center">
									{item.link ? (
										<Box
											component={Link}
											to={item.link}
											sx={{
												color: 'light.main',
												textDecoration: 'none',
												...((idx < title.length - 1 || item.text.length > 60) && breadCrumbStyle)
											}}
										>
											<Typography variant="breadcrumbs">{item.text}</Typography>
										</Box>
									) : (
										<Typography
											variant="breadcrumbs"
											{...((idx < title.length - 1 || item.text.length > 60) && breadCrumbStyle)}
										>
											{item.text}
										</Typography>
									)}
									{idx < title.length - 1 && <ChevronRightIcon />}
								</Stack>
							)
						)}
					</Stack>
				)}

				{!!lastSync && (
					<Stack direction="row" color="light.main" fontSize="12px" gap={0.5} ml={1}>
						<ReloadIcon /> {isDataRefresh ? <> Last Data Refresh: {lastSync} </> : <> Last Sync: {lastSync}</>}
					</Stack>
				)}
			</Stack>
			<Stack
				direction="row"
				gap={2}
				alignItems="center"
				justifyContent={{ xs: 'flex-end', md: 'normal' }}
				pr={{ xs: 1, md: 'initial' }}
				{...childrenContainerProps}
			>
				{children}
			</Stack>
		</Stack>
	);
};

export default PageHeader;

import { useRef, useState } from 'react';
import {
	Box,
	BoxProps,
	Button,
	FormControl,
	IconProps,
	InputLabel,
	MenuItem,
	Popover,
	Select,
	selectClasses,
	Stack,
	SvgIconProps
} from '@mui/material';
import moment from 'moment';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type DateFilterDropdownProps = {
	filterMonth: string;
	customMonth: string;
	setFilterMonth: React.Dispatch<React.SetStateAction<string>>;
	setCustomMonth: React.Dispatch<React.SetStateAction<string>>;
	handleChange: (selectedDate: string, isCustomDate?: boolean) => void;
	boxProps?: BoxProps;
	disableFilter?: boolean;
};

const iconProps: SvgIconProps = {
	sx:{
		cursor: 'pointer',

	}
}

const DateFilterDropdown = ({
	filterMonth,
	customMonth,
	setFilterMonth,
	setCustomMonth,
	handleChange,
	boxProps,
	disableFilter
}: DateFilterDropdownProps) => {
	const { sx, ...props } = boxProps || {};
	const isCustomMonth = useRef(false);
	const [isDateOpen, setIsDateOpen] = useState(false);
	const [filterDefaultMonth, setFilterDefaultMonth] = useState('0M');
	const [customYear, setCustomYear] = useState(moment().format('YYYY'));

	const [dateFilterMenu, setDateFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const handleDateFilter = (selectedDate: string) => {
		setIsDateOpen(false);
		isCustomMonth.current = false;
		if (selectedDate === 'customMonth') {
			isCustomMonth.current = true;
			setIsDateOpen(true);
			return;
		}
		setFilterDefaultMonth(selectedDate);
		setCustomMonth('');
		setFilterMonth(selectedDate);
		handleChange(selectedDate);
	};

	const dateFilterMenuClick = (event: any) => {
		setDateFilterMenu(
			dateFilterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 260,
						mouseY: parseInt(event.clientY) - 50
				  }
				: null
		);
	};
	const handleDateFilterMenuClose = () => {
		setDateFilterMenu(null);
	};

	const handleMonthOnclick = (value: string) => {
		isCustomMonth.current = false;
		setIsDateOpen(false);
		setDateFilterMenu(null);
		const selectedDate = customYear + '-' + value;
		setFilterMonth('customMonth');
		setCustomMonth(selectedDate);
		handleChange(selectedDate, true);
	};
	const handleYearAdd = () => {
		let selectedYear = Number(customYear);
		selectedYear++;
		setCustomYear(selectedYear.toString());
	};
	const handleYearMinus = () => {
		let selectedYear = Number(customYear);
		selectedYear--;
		setCustomYear(selectedYear.toString());
	};
	const handleCustomOnclick = (e: any) => {
		if (e.target.textContent == 'Custom Month') {
			isCustomMonth.current = true;
			dateFilterMenuClick(e);
		}
		if (isCustomMonth.current) setIsDateOpen(true);
		else setIsDateOpen(false);

		if (e.type == 'click' && e.target.className.includes('MuiModal-backdrop')) {
			isCustomMonth.current = false;
			setDateFilterMenu(null);
			setIsDateOpen(false);
			if (customMonth == '') {
				setFilterMonth(filterDefaultMonth);
			}
		}
	};

	return (
		<Box
			sx={{
				backgroundColor: 'blue.dark',
				borderRadius: '4px',
				fontSize: '15px',
				zIndex: 999,
				minWidth: '200px',
				textAlign: 'left',
				height: 'fit-content',
				...sx
			}}
			{...props}
		>
			<FormControl fullWidth>
				<Select
					value={filterMonth}
					label="Date"
					onChange={(e) => {
						handleDateFilter(e.target.value);
					}}
					IconComponent={CalendarMonthIcon}
					open={isDateOpen}
					onClick={(e) => handleCustomOnclick(e)}
					onOpen={() => setIsDateOpen(true)}
					onClose={() => setIsDateOpen(false)}
					sx={{
						[`& .${selectClasses.icon}`]: {
							transform: 'none'
						}
					}}
					disabled = {disableFilter}
				>
					<MenuItem value={'0M'}>This Month</MenuItem>
					<MenuItem value={'1M'}>Last Month</MenuItem>
					<MenuItem value={'3M'}>Last 3 Months</MenuItem>
					<MenuItem value={'6M'}>Last 6 Months</MenuItem>
					<MenuItem value={'9M'}>Last 9 Months</MenuItem>
					<MenuItem value={'0Y'}>This Year</MenuItem>
					<MenuItem value={'1Y'}>Last Year</MenuItem>
					<MenuItem value={'2Y'}>Last 2 Years</MenuItem>
					<MenuItem value={'customMonth'}>Custom Month</MenuItem>
				</Select>
			</FormControl>
			{isCustomMonth.current && (
				<Popover
					open={dateFilterMenu !== null}
					onClose={handleDateFilterMenuClose}
					anchorReference="anchorPosition"
					anchorPosition={
						dateFilterMenu !== null ? { top: dateFilterMenu.mouseY, left: dateFilterMenu.mouseX } : undefined
					}
				>
					<Box
						sx={{
							backgroundColor: 'blue.darker',
							borderRadius: '4px',
							width: '175px',
							minHeight: '30px',
						}}
					>
						<Stack p={1} direction='row' alignItems='center' justifyContent='space-between'>
							<InputLabel>{customYear}</InputLabel>
							<Stack direction='row' gap={0.5}>
								<KeyboardArrowUpIcon {...iconProps} onClick={handleYearAdd} />
								<KeyboardArrowDownIcon {...iconProps} onClick={handleYearMinus} />
							</Stack>
						</Stack>
						<div className="custom-dis">
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('01')}>
								January
							</Button>
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('02')}>
								February
							</Button>
						</div>
						<div className="custom-dis">
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('03')}>
								March
							</Button>
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('04')}>
								April
							</Button>
						</div>
						<div className="custom-dis">
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('05')}>
								May
							</Button>
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('06')}>
								Jun
							</Button>
						</div>
						<div className="custom-dis">
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('07')}>
								July
							</Button>
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('08')}>
								August
							</Button>
						</div>
						<div className="custom-dis">
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('09')}>
								September
							</Button>
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('10')}>
								October
							</Button>
						</div>
						<div className="custom-dis">
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('11')}>
								November
							</Button>
							<Button variant="outlined" className="custom-month-btn" onClick={() => handleMonthOnclick('12')}>
								December
							</Button>
						</div>
					</Box>
				</Popover>
			)}
		</Box>
	);
};

export default DateFilterDropdown;

import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import UnderDevelopment from '../../assets/underConstruct.svg';
import { UnderDevelopmentProps } from './type';

const UnderDevelopmentWidget = ({ title = '' }: Readonly<UnderDevelopmentProps>) => {
    return (
        <Box display="flex" flexGrow={1} height="100%" position="relative">
        <Stack
            minHeight="100px"
            p={2}
            bgcolor="primary.main"
            fontFamily="Montserrat"
            borderRadius="5px"
            flexGrow={1}
        >
            <Stack direction="row" gap={2} alignItems="start" minHeight="38px">
                <Typography variant="widgetTitle">{title}</Typography>
            </Stack>
            <Box
                flexGrow={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
            >
                <Box 
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    color="text.secondary"
                    sx={{ height: '80%', mt: '10px', mx: '0'}}
                >

                    <Box 
                        component="img" 
                        src={UnderDevelopment} 
                        alt="Under Development" 
                        maxWidth="100%" 
                        sx={{ mb: 2 }} 
                    />
                    <Typography variant="body1" fontSize="1rem" color="light.main">
                        Under development!
                    </Typography>
                </Box>
            </Box>
        </Stack>
    </Box>
    );
};

export default UnderDevelopmentWidget;
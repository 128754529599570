export const getCurrentAndPreviousMonthNames = () => {
    const date = new Date();
    const currentMonthIndex = date.getMonth();

    // Month names array
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const currentMonth = monthNames[currentMonthIndex === 0 ? 11 : currentMonthIndex - 1];
    const previousMonth = monthNames[currentMonthIndex === 0 ? 11 : currentMonthIndex - 2];

    return { currentMonth, previousMonth };
};
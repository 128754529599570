import { useEffect, useRef } from 'react';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl } from '../../service/config';
import './mdip-obs.scss';

export default function mdipJobsListing() {
    
	return (
		<div className="page-table jobs_tabledata">
			<AnalyticsVisualization
				params={{
					dashboardUrl: dashboardUrl.MDIPEXECUTIVE,
					className: 'datapipelineCustomDashboardContainer',
					otherParams: '',
					seamless: true
				}}
			/>
		</div>
	);
}

import { lazy } from 'react';
import PrivateRoute from 'utils/privateRoute';
import ProgramDetails from 'components/DataPipeline/program-details';
import ProjectDetails from 'components/DataPipeline/project-details';
import AddSchedules from 'components/DataPipeline/add-schedules';
import CustomDashboard from 'components/DataPipeline/customDashboard';
import ProgramList from 'components/DataPipeline/program-list';
import CustomDashboardDetails from 'components/DataPipeline/customDashboardDetails';
import programOpsDashboard from 'components/DataPipeline/programOpsDashboard';
import ProgramsDashboard from 'components/DataPipeline/programs-dashboard';

// const JobDetails = lazy(() => import('components/jobs/job_details'));

const DataPipelineRoutes = [
	{
		key: 'dpipe_prgrm',
		route: '/data-pipeline/programs',
		component: (
			<PrivateRoute
				Component={ProgramsDashboard}
				restrictedRoles={['D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dpipe_prgrm_id_name',
		route: '/data-pipeline/programs/:id/:name',
		component: (
			<PrivateRoute
				Component={ProgramDetails}
				restrictedRoles={['D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dpipe_prgrm_prj_id_prgm_proj',
		route: '/data-pipeline/projects/:programId/:projectId/:program/:project',
		component: (
			<PrivateRoute
				Component={ProjectDetails}
				restrictedRoles={['D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dpipe_srch_prgmid_prjid_name',
		route: '/data-pipeline/search-jobs/:prgmid/:prjid/:prgname/:prjname',
		component: (
			<PrivateRoute
				Component={AddSchedules}
				restrictedRoles={['Platform Operations Admin/SRE', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dpipe_cust_dash_tiles',
		route: '/data-pipeline/programOpsDashboard',
		component: (
			<PrivateRoute
				Component={programOpsDashboard}
				restrictedRoles={['Platform Operations Admin/SRE', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dpipe_cust_dash',
		route: '/data-pipeline/programOps/list/:programName',
		component: (
			<PrivateRoute
				Component={CustomDashboard}
				restrictedRoles={['Platform Operations Admin/SRE', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	},
	{
		key: 'dpipe_dashboard_details',
		route: '/data-pipeline/programOps/details/:dashboardName', 
		component: (
			<PrivateRoute
				Component={CustomDashboardDetails} 
				restrictedRoles={['Platform Operations Admin/SRE', 'D&AI Executive User', 'Program Executive']}
			/>
		)
	}	
];

export default DataPipelineRoutes;

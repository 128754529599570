import { BoxProps, SelectChangeEvent } from '@mui/material';
import { setCurrentConversionUnit } from 'Context/contexts/cacheData/CommonData';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import CustomDropdown from '.';

type ConversionUnitDropdownProps = {
	handleChange?: (value: string) => void;
	boxProps?: BoxProps;
};

const ConversionUnitDropdown = ({ handleChange, boxProps }: ConversionUnitDropdownProps) => {
	const [controller, dispatch] = useCacheDataController();
	const { conversionUnit } = controller;

	const handleByteChange = (event: SelectChangeEvent<string>) => {
		const value = event.target.value;
		setCurrentConversionUnit(dispatch, value);
		handleChange?.(value);
	};

	return (
		<CustomDropdown
			value={conversionUnit}
			handleChange={handleByteChange}
			options={[
				{ value: 'PB', label: 'PB' },
				{ value: 'TB', label: 'TB' },
				{ value: 'GB', label: 'GB' }
			]}
			boxProps={boxProps}
		/>
	);
};

export default ConversionUnitDropdown;

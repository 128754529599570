import { Box, Typography, Stack, StackProps, ButtonGroup, Button } from '@mui/material';
import MoreOptionMenu, { MoreOptionMenuProps } from 'components/Dropdown/MoreOptionSecondary';
import DateInfoTooltip, { DateInfoTooltipProps } from 'components/Tooltip/DateInfoTooltip';
import { ReactComponent as InfoGreyIcon } from '../../assets/icon-grey-info.svg';
import { ReactComponent as MoreIcon } from '../../assets/more-vertical.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus-icon.svg';
import { Link } from 'react-router-dom';

type SLAItemType =
	| {
		label: string;
		count: number;
		onBtnClick: (event?: any) => void;
	}
	| {
		label: string;
		count: number;
		onBtnClick: (event?: any) => void;
	};

type TitleStatusProps = {
	title: string;
	isEditEnabled?: boolean;
	programId: string;
	projectCount: number;
	projectSLAs?: SLAItemType[];
	statusCount?: number;
	bgColor?: string;

	minTitleHeight?: `${number}px`;
	hasTooltip?: boolean;

	infoTooltip?: Omit<DateInfoTooltipProps, 'children'>;
	infoOptionMenu?: MoreOptionMenuProps;

	onClickAddProject?: (event: any) => void;

	handleEvent?: () => void;
	ratioNumber?: {
		number: number | string;
		totalNumber: number | string;
	};
	containerProps?: StackProps;
};

let statusCount = 0;

const TitleStatus = ({
	bgColor = 'primary.main',
	title = '',
	programId,
	projectCount,
	projectSLAs,
	statusCount,
	minTitleHeight = '45px',
	infoTooltip,
	infoOptionMenu,
	isEditEnabled,
	handleEvent,
	onClickAddProject,
	containerProps,
}: Readonly<TitleStatusProps>) => {
	return (
		<Box
			display="flex"
			flexGrow={1}
			height="100%"
			position="relative"
			{...(handleEvent && { onClick: handleEvent, sx: { cursor: 'pointer' } })}
		>
			<Stack
				minHeight="100px"
				p={2}
				bgcolor={bgColor}
				fontFamily="Montserrat"
				borderRadius="5px"
				flexGrow={1}
				{...containerProps}
				sx={{
					position: 'relative',
					'&:hover .hover-content': {
						opacity: 1
					},
					...containerProps?.sx,
				}}
			>
				<Stack minHeight={minTitleHeight}>
					<Typography variant="widgetTitle" style={{ fontSize: '22px', width: '300px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', display: 'inline-block'}} sx={{'& a': {color: '#eee'}}}>
						<Link title={title} to={`/data-pipeline/programs/${programId}/${title}`} style={{color: '#e5e5e5', textDecoration: 'none'}}>{title}</Link>
					</Typography>
					<Box sx={{position: 'absolute', right: '25px', '& .MuiIconButton-root': { verticalAlign: 'top', paddingLeft: '15px'}}}>
						{!!infoTooltip && (
							<DateInfoTooltip {...infoTooltip}>
								<InfoGreyIcon />
							</DateInfoTooltip>
						)}
						{!!infoOptionMenu && isEditEnabled && (
							<MoreOptionMenu {...infoOptionMenu}>
								<MoreIcon />
							</MoreOptionMenu>
						)}
					</Box>
				</Stack>
				<Stack minHeight="20px" flexGrow={1} justifyContent="center">
				
					{projectCount > 0 && <><Typography variant="footerSubtitle" mb={1} style={{color: 'rgba(245, 245, 245, 1)'}}>Projects SLAs</Typography>
						<ButtonGroup sx={{
							boxShadow: 'none',
							'& button': {
								borderRadius: '4px',
								color: '#fff',
								fontSize: '14px',
								fontWeight: '600',
								padding: '5px',
								width: '33%',
								textTransform: 'uppercase',
								'@media screen and (max-width: 1300px)': {
									fontSize: '12px'
								}
							},
							'& .color-missed': {
								backgroundColor: '#FD783B',
								'&:hover': {
									backgroundColor: '#fa6622'
								}
							},
							'& .color-met': {
								backgroundColor: '#01C008',
								'&:hover': {
									backgroundColor: '#00a506'
								}
							},
							'& .color-others': {
								backgroundColor: '#B7CF48',
								'&:hover': {
									backgroundColor: '#a6c70c'
								}
							}
						}}>
							
							{projectSLAs?.map(({ label, count, onBtnClick }) => (
								count && <Button
									className={label == 'SLA Missed' ? 'color-missed' : label == 'SLA Met' ? 'color-met' : 'color-others'}
									key={label}
									onClick={(event) => {
										onBtnClick('menuItem');
									}}
								>{count} {label}</Button>
							))}
						</ButtonGroup>
						{ !statusCount ? <Typography variant="tableColumnFont">No Data</Typography> : '' }
					</>
					}
					
					{projectCount == 0 && <Button
						sx={{
							border: '1px solid #009BD8',
							borderRadius: '5px',
							color: '#009BD8',
							fontSize: '12px',
							fontWeight: '700',
							width: '160px',
							'& svg path': {
								fill: '#009BD8'
							},
							'&:hover': {

							}
						}}
						onClick={onClickAddProject}
					><PlusIcon /> ADD PROJECT</Button>
					}
				</Stack>
			</Stack>
		</Box>
	);
};

export default TitleStatus;

import {Box, Button, Dialog, Grid, Stack} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ResourceMappingModal } from './modalComponent';
import { ReactComponent as EditIcon } from '../../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/delete-img.svg';
import { getProgramDetailsById, getProjectListByProgramId } from '../../../service/service';
import { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../../customized_data_grid';
import Loader from '../../Loader/loader';
import { useParams, useNavigate, Link } from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css';
import DataInfoSecondary from 'components/Widgets/DataInfoSecondary';
import PageHeader from 'components/Header/Page';
import DashboardIcon from '../../../assets/material-symbols_dashboard.svg';
import SearchFilter from 'components/Dropdown/SearchFilter';
import { ReactComponent as ViewIcon } from '../../../assets/view-detail-img.svg';
import RowTypography from 'components/Table/RowTypography';
import { ReactComponent as AddIcon } from '../../../assets/plus-icon.svg';

const ProgramDetails = () => {
	const [selectedRows, setSelectedRows] = useState<any>([]);
	const [accordionActiveId, setAccordionActiveId] = useState<any>(null);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [anchorElDetails, setAnchorElDetails] = React.useState<null | HTMLElement>(null);
	const [projectList, setProjectList] = useState<any>([]);
	const [openModal, setOpenModal] = useState<any>({ isOpen: false, message: '' });
	const [programDetail, setProgramDetail] = useState<any>({});
	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const navigate = useNavigate();
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const { id, name } = useParams();

	const defaultColumnNames = ['Project', 'Project Owner', 'Description', 'Actions'];

	interface Row {
		id: string;
		projectName: string;
		projectOwner: string;
		projectDescription: string;
	}

	const handleThreeDotsClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
		setAccordionActiveId(null);
	};

	const handleDetailsThreeDotsClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(event.currentTarget);
	};
	const handleDetailsThreeDotsClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(null);
	};

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'projectName',
				name: 'Project',
				minWidth: 150,
				maxWidth: 530
			},
			{
				key: 'projectOwner',
				name: 'Project Owner',
				minWidth: 150,
				width: 480,
			},
			{
				key: 'projectDescription',
				name: 'Description',
				minWidth: 300,
				width: 700
			},
			{
				key: 'action',
				name: 'Actions',
				width: 100,
				resizable: false,
				formatter: (props: any) => (
					<Stack direction="row" alignItems="center" height="100%">
						<Box lineHeight={0}>
							<EditIcon title="Edit" style={{ width: '24px', height: '24px' }} onClick={(e) => handleAction('Project', 'edit', props, e)}/>
						</Box>
						<Box lineHeight={0}>
							<DeleteIcon title="Delete" style={{ width: '24px', height: '24px' }} onClick={(e) => handleAction('Project', 'delete', props, e)}/>
						</Box>
						<Box component={Link} lineHeight={0} to={`/finops/resourcemapping/projects/
							${id}/${props.row.projectId}/${encodeURIComponent(programDetail.programName)}/
							${encodeURIComponent(props.row.projectName)}`}>
							<ViewIcon title="Click to view" style={{ width: '24px', height: '24px' }} />
						</Box>
					</Stack>
				)
			}
		];
	}

	const handleTableAction = (props: any, type: string) => {
		if (type == 'delete') {
			setOpenModal({
				isOpen: true,
				type: 'Schedule',
				action: 'DELETE',
				from: 'delete',
				data: [props]
			});
		}
	};

	const passInfoToParent = (data: any) => {
		// projectMappedJobScheduleFunc(projectId, 1, 100);
	};

	const handleSorting = (e: any) => {
		getProjectsByProgram(page, pageSize, e);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		getProjectsByProgram(value, pageSize, sortingInfo);
	};

	const handlepageSizeChange = (event: any) => {
		getProjectsByProgram(page, event.target.value, sortingInfo);
	};
	
	const getProgramDetails = async () => {
		setLoading(true);
		let result = await getProgramDetailsById(id);
		setLoading(false);
		setProgramDetail(result.data ? result.data : '');
		navigate(`/finops/resourcemapping/programs/${id}/${encodeURIComponent(result.data.programName)}`);
	};

	const getProjectsByProgram = async (pageNumber: any, size: any, sortingInfo: any, searchText?: any) => {
		setLoading(true);
		let res = await getProjectListByProgramId(id, pageNumber, size, sortingInfo, searchText);
		if (res.success) {
			setLoading(false);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			const projData = res.data.projects.map((list: any) => {
				list['activeRow'] = 0;
				return list;
			});
			setProjectList(projData);
		}
	};

	const handleEditClose = (data: any) => {
		setOpenModal({
			...openModal,
			isOpen: false
		});
		setSelectedRows([]);
	};

	const onAddProjectClick = (event: any) => {
		setOpenModal({
			...openModal,
			isOpen: true,
			type: 'Project',
			action: 'ADD'
		});
	};

	const handleAction = (module: string, type: string, data: any, e: any) => {
		// debugger
		e.preventDefault();
		e.stopPropagation();
		if(data.row === undefined) {
			data = {
				...data,
				programName: programDetail.programName,
				programId: programDetail.programId,
				
			};
		}
		else {
			data = {
				...data,
				programName: programDetail.programName,
				programId: programDetail.programId,
				projectId: data.row.projectId,
				projectName: data.row.projectName,
				projectOwner: data.row.projectOwner,
				projectDescription: data.row.projectDescription,
			};
		}
		switch (type) {
			case 'edit':
				setOpenModal({
					...openModal,
					isOpen: true,
					type: module,
					action: 'EDIT',
					data: data
				});
				break;
			case 'delete':
				setOpenModal({
					...openModal,
					isOpen: true,
					type: module,
					action: 'DELETE',
					from: 'view',
					data: data
				});
				break;
			case 'view':
				// window.location.href = `/finops/resourcemapping/projects/${data.programId}/${data.projectId}/${encodeURIComponent(
				// 	data.programName
				// )}/${encodeURIComponent(data.projectName)}`;
				break;
			default:
				break;
		}
	};

	const handleSearchData = (searchText: string) => {
		try {
			getProjectsByProgram(page, pageSize, sortingInfo, searchText);
		} catch (error) {
			console.error(error);
		}
	};
	
	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/finops/resourcemapping/programs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		if (!openModal.isOpen) {
			getProgramDetails();
			getProjectsByProgram(page, pageSize, sortingInfo);
		}
	}, [openModal]);

	return (
		<Box mt="45px" p={2} bgcolor="blue.darker">
			{loading && <Loader />}
			<PageHeader icon={DashboardIcon}
				title={[
					{
						text: 'Programs',
						link: '/finops/resourcemapping/programs'
					},
					{
						text: decodeURIComponent(window.location.pathname.split('/')[5].replace(/%20/g, ' '))
					}
				]}
			>	
			<Stack direction="row" gap={1} alignItems="center">
				<SearchFilter handleSearchData = {handleSearchData} />
					<Button variant="pepwiseSecondary" onClick={onAddProjectClick} startIcon={<AddIcon />}>
						ADD PROJECT
					</Button>
				</Stack>
			</PageHeader>

			<Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md">
				<ResourceMappingModal
					handleEditClose={handleEditClose}
					programData={openModal}
					passInfoToParent={passInfoToParent}
				/>
			</Dialog>
			<Stack spacing={2} mt={4}>
				<Grid container spacing={1}>
					<Grid item xs={12} md={12}>
						<DataInfoSecondary
							infoCards={[
								{
									label: 'Program Name',
									value: programDetail?.programName,
									isLoading: !programDetail?.programName,
									valueProps: {
										lineHeight: '24px'
									}
								},
								{
									label: 'Program Owner',
									value: programDetail?.programOwner,
									isLoading: !programDetail?.programOwner,
									valueProps: {
										lineHeight: '24px'
									}
								},
								{
									label: 'Program Description',
									value: programDetail?.programDescription,
									isLoading: !programDetail?.programDescription,
									valueProps: {
										lineHeight: '24px'
									}
								}
							]}
							infoTooltip={{
								dateValues: [
									{ label: 'Date Created', value: programDetail?.createdAt ?? 'NA' },
									{ label: 'Last Updated', value: programDetail?.updatedAt ?? 'NA' }
								]
							}}
							infoOptionMenu={{
								onClick: handleDetailsThreeDotsClick,
								onClose: handleDetailsThreeDotsClose,
								menuItems: [
									{
										key: 'edit',
										label: 'Edit',
										icon: <EditIcon />,
										onMenuClick: (e) => handleAction('Program', 'edit', {}, e)
									},
									{
										key: 'delete',
										label: 'Delete',
										icon: <DeleteIcon />,
										onMenuClick: (e) => handleAction('Program', 'delete', {}, e)
									}
								]
							}}
							isAdmin = {true}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} p={1}>
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={projectList}
						tableType="project-list"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={projectList.length}
						showExport={false}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isShowMoreButton = {false}
						title="Projects"
					/>
				</Grid>
			</Stack>
		</Box>
	);
};

export default ProgramDetails;

export function formatLargeNumbers(value: any, restrictToMillions: boolean, title: string, isDatabrick: boolean = false) {
    if (restrictToMillions && (value >= 1000000 || value >= 1000)) {
        return (title == 'Cost Trend' || isDatabrick == true) ? '$' + (value / 1000000).toFixed(2) + 'MM': (value / 1000000).toFixed(2) + 'MM';
    } else if (!restrictToMillions && value >= 1000000) {
        return (value / 1000000).toFixed(2) + 'MM';
    } else if (!restrictToMillions && value >= 1000) {
        return (value / 1000).toFixed(2) + 'K';
    } else {
        return value.toString();
    }
}

export function formatDateAndValue([dateString, value]: [string, number], restrictToMillions: boolean, title: string, isDatabrick: boolean) {
    const [year, month] = dateString.split('-');
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = monthNames[parseInt(month, 10) - 1];
    const formattedValue = formatLargeNumbers(value, restrictToMillions, title);
    return `${monthName}-${year}: ${formattedValue}`;
}

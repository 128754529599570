import { createTheme } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';

const theme = createTheme({
	palette: {
		primary: {
			main: '#001e3e',
			contrastText: '#ffffff'
		},
		secondary: {
			main: '#003771',
			contrastText: '#ffffff'
		},
		background: {
			default: '#001e3e'
		},
		text: {
			primary: '#ffffff',
			secondary: '#ffffff'
		}
	} as PaletteOptions
});

export default theme;

import { Stack, StackProps } from '@mui/material';
import React from 'react';

type OutlinedButtonProps = {
	children: React.ReactNode | string;
	disabled?: boolean;
} & StackProps;

const OutlinedButton: React.FC<OutlinedButtonProps> = ({ children, disabled, ...rest }) => {
	return (
		<Stack
			{...rest}
			sx={{
				pointerEvents: disabled ? 'none' : 'initial',
				color: disabled ? 'light.diff' : 'white.main',
				borderRadius: '4px',
				border: '1px solid',
				borderColor: 'secondary.main',
				width: '40px',
				height: '40px',
				textTransform: 'capitalize',
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				cursor: disabled ? 'not-allowed' : 'pointer',
				...rest.sx,
			}}
		>
			{children}
		</Stack>
	);
};

export default OutlinedButton;

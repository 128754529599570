import { createTheme, ThemeOptions } from '@mui/material/styles';
import colors from 'assets/theme/base/colors';
import { pepWiseTypography } from './typographyUtil';

// Create a new theme by extending the default theme
const themeOptions: ThemeOptions = {
	palette: { ...colors },
	typography: { ...pepWiseTypography },
	breakpoints: {
		values: {
			xs: 0,
			sm: 800,
			md: 1075,
			lg: 1400,
			xl: 1550
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
					textTransform: 'none'
				}
			},
			variants: [
				{
					props: { variant: 'pepwiseSecondary' },
					style: {
						backgroundColor: '#009BD8',
						borderRadius: '4px',
						color: colors.white.main,
						fontWeight: '700',
						textTransform: 'uppercase',
						fontFamily: 'Montserrat',
						'&:hover': {
							backgroundColor: '#009BD8'
						}
					}
				},
				{
					props: { variant: 'pepwiseSecondaryOutline' },
					style: {
						backgroundColor: 'transparent',
						color: '#009BD8',
						borderRadius: '4px',
						width: '100%',
						fontWeight: '700',
						textTransform: 'capitalize',
						fontFamily: 'Montserrat',
						'&:hover': {
							backgroundColor: '#009BD8',
							color: colors.white.main
						}
					}
				},
				{
					props: { variant: 'pepwiseSecondaryOutline2' },
					style: {
						backgroundColor: 'transparent',
						color: colors.white.main,
						borderRadius: '4px',
						border: '1px solid #009BD8',
						width: '100%',
						fontWeight: '700',
						textTransform: 'uppercase',
						fontFamily: 'Montserrat',
					}
				},
				{
					props: { variant: 'pepwiseError' },
					style: {
						backgroundColor: colors.error.main,
						borderRadius: '4px',
						color: colors.white.main,
						fontWeight: '700',
						textTransform: 'capitalize',
						fontFamily: 'Montserrat',
						'&:hover': {
							backgroundColor: colors.error.main
						}
					}
				},
				{
					props: { variant: 'pepwiseErrorOutline' },
					style: {
						backgroundColor: 'transparent',
						borderRadius: '4px',
						border: '1px solid',
						borderColor: colors.error.main,
						color: colors.error.main,
						fontWeight: '700',
						textTransform: 'capitalize',
						fontFamily: 'Montserrat',
						'&:hover': {
							backgroundColor: colors.error.main,
							color: colors.white.main
						}
					}
				},
				{
					props: { variant: 'pepwiseOutline' },
					style: {
						borderRadius: '4px',
						color: colors.white.main,
						fontWeight: '600',
						textTransform: 'capitalize',
						fontFamily: 'Montserrat'
					}
				}
			]
		},
		MuiTypography: {
			styleOverrides: {
				caption: {
					color: colors.text.main,
					fontSize: '11px',
					lineHeight: 'normal'
				}
			}
		},
		MuiCalendarOrClockPicker: {
			styleOverrides: {
				root: {
					color: colors.text.main
				}
			}
		},
		MuiClockNumber: {
			styleOverrides: {
				root: {
					color: colors.text.main
				}
			}
		},
		PrivatePickersToolbarText: {
			styleOverrides: {
				root: {
					color: colors.text.main
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '12px',
					color: colors.text.main,
					backgroundColor: '#1B1E35 !important'
				},
				arrow: {
					color: '#1B1E35  !important'
				}
			}
		},
		MuiChip: {
			variants: [
				{
					props: { variant: 'chartBadge' },
					style: {
						'& .MuiChip-label': {
							fontSize: '12px',
							fontWeight: 600,
							color: '#001E3E !important'
						},
						'& .MuiChip-icon': {
							color: '#001E3E !important',
							fontSize: '18px',
							marginRight: '-8px'
						},
						'& .MuiChip-root': {
							backgroundColor: '#009BD8 !important',
							width: 'fit-content !important'
						}
					}
				}
			]
		},
		MuiDrawer: {
			styleOverrides: {
				paper: {
					margin: '0px !important'
				}
			}
		},
		MuiSelect: {
			styleOverrides: {
				icon: {
					color: 'white'
				},
				select: {
					'&.Mui-disabled': {
						color: colors.light.diff,
						WebkitTextFillColor: colors.light.diff,
						borderColor: colors.light.diff,
					},
					'&:hover .Mui-disabled': {
						color: colors.light.diff,
						WebkitTextFillColor: colors.light.diff,
						borderColor: colors.light.diff,
					},
					padding: '10px 14px',
					'&:hover': {
						borderColor: 'transparent'
					},
					'&:focus': {
						borderColor: 'transparent', // Make border transparent when focused
						outline: 'none' // Optionally, remove the outline
					}
				},
			}
		},
		MuiPaper: {
			styleOverrides: {
				elevation: {
					ul: {
						height: 'min-content !important',
						maxheight: '130px !important'
					}
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: 'transparent'
					},
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: 'transparent'
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: 'transparent'
					},
					'&.Mui-disabled': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.light.diff,
            },
            '&:hover .MuiInputBase-input': {
              color: colors.light.diff,
              WebkitTextFillColor: colors.light.diff,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.light.diff,
            },
            '& .MuiInputBase-input': {
              color: colors.light.diff,
              WebkitTextFillColor: colors.light.diff,
            },
          },
				},
			}
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					'&.Mui-disabled': {
						color: colors.light.diff,
						WebkitTextFillColor: colors.light.diff,
					}
				}
			}
		},
		MuiRadio: {
			variants: [
				{
					props: { variant: 'pepwiseRadio' },
					style: {
						color: colors.text.main,
						'&.Mui-checked': {
							color: '#009BD8'
						},
						'& .MuiSvgIcon-root': {
							'&.Mui-checked': {
								backgroundColor: '#009BD8',
								borderColor: '#009BD8'
							}
						}
					}
				}
			]
		},
		MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.light.diff,
          '&.Mui-checked': {
            color: colors.light.diff,
            '& .MuiSvgIcon-root': {
              backgroundColor: 'transparent',
              color: colors.info.main,
            },
          },
        },
      },
    },
	}
};

const theme = createTheme(themeOptions);

export default theme;

import React, { useEffect, useState, useRef } from 'react';
import { FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import { getResourceGroups, getResourceGroupSectors, patchResourceGroup, getResourceListMapping, getResourceSearchFilterColumn } from '../../../service/service';
import moment from 'moment-timezone';
import axios from 'axios'; 
import { TextareaAutosize } from '@mui/material';

import type { Column } from 'react-data-grid';
import { CustomizedDataGrid } from '../../customized_data_grid';
import '../resourceGroups/resourceGroup.scss';
import Loader from '../../Loader/loader';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../../assets/material-symbols_edit-rounded.svg';
import Swal from 'sweetalert2';
import { textEditor } from 'react-data-grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { BulkUpdate } from './bulkUpdate';
import { ReactComponent as SearchIcon } from '../../../assets/search-icon.svg';



interface ResourceGroupListingProps {
	sharedValue: any;
	updateSharedValue: (value: any) => void;
}

	const ResourceGroupListing: React.FC<ResourceGroupListingProps> = ({ sharedValue, updateSharedValue } : any) => {
	const swalOptions = {
		showCancelButton: true,
		confirmButtonText: 'Yes, update it!',
		cancelButtonText: 'No, cancel',
		customClass: {
			container: 'custom-swal-container',
			title: 'custom-swal-title',
			htmlContainer: 'custom-swal-html-container',
			confirmButton: 'custom-swal-confirm-button',
			cancelButton: 'custom-swal-cancel-button'
		}
	};
	interface Row {
		id: string;
		Resource: string;
		ResourceGroup: string;
		DFProjectcode: string;
		DFSectorname: string;
		Environment: string;
		ProjectName: string;
		BillingInformation: string;
		Subscription: string;
		MeterCategory: string;
		MeterName: string;
        selected: boolean;
		DAI: String;
	}
	function getColumns(): Array<Column<Row>> {
		return [
			{
                key: 'selected',
                name: 'HeaderCheckbox',
                formatter: ({ row }) => (
                    <input 
                        type="checkbox"
                        className="transparent-checkbox row-checkbox"
                        checked={row.selected}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            const updatedRow = { ...row, selected: checked };
							const headerCheckboxInput = document.getElementById('HeaderCheckbox') as HTMLInputElement;
							if (checked) {
								selectedRows.push(row)
								selectedRowsId.push(row.id)
							} else {
								selectedRowsId.splice(selectedRowsId.indexOf(row.id), 1)
								
								if (headerCheckboxInput) {
									headerCheckboxInput.checked = false;	
								}
							}
					
							selectedRows = selectedRows.filter((eachRow:any) => { 
								return selectedRowsId.includes(eachRow.id)

							})
							if (selectedRows.length == resourceGroupList.length) {
								if (headerCheckboxInput) {
									headerCheckboxInput.checked = true;	
								}
							}
							setBulkSelectedRows(selectedRows)

                            setResourceGroupList(prevRows => prevRows.map(prevRow => prevRow.id === updatedRow.id ? updatedRow : prevRow));
                        }}
                    />
                ),
                minWidth: 40,
            },
			{
				key: 'Resource',
				name: 'Resource',
				minWidth: 160
			},
			{
				key: 'ResourceGroup',
				name: 'Resource Group',
				minWidth: 150
			},
			{
				key: 'DFSectorname',
				name: 'Sector',
				minWidth: 140,
				maxWidth: 140,
				width: 140,
				cellClass: 'has-editor',
				formatter(props) {
					return (
						<>
							{props.row.DFSectorname?.length ? (
								<span className="">{props.row.DFSectorname}</span>
							) : (
								<span className="editable_ph">Select Sector</span>
							)}{' '}
							<EditIcon className="edit-icon" title="Double click to edit sector" />
						</>
					);
				},
				editor: (p) => (
					<div>
						<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className="row-select-drpdwn">
							<Select
								labelId="Select Sector"
								id="sector_dd"
								defaultOpen={true}
								value={p.row.DFSectorname}
								onChange={(e: any) => {
									p.onRowChange({ ...p.row, DFSectorname: e.target.value }, true);
								}}
								label="Select Sector"
							>
								{sectorOptionsList.map((sector) => (
									<MenuItem key={sector} value={sector}>
										{sector}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				)
			},

			{
				key: 'Environment',
				name: 'Environment',
				minWidth: 160,
				maxWidth: 160,
				width: 160,
				cellClass: 'has-editor',
				formatter(props) {
					return (
						<>
							{props.row.Environment?.length ? (
								<span className="">{props.row.Environment}</span>
							) : (
								<span className="editable_ph">Select Environment</span>
							)}
							<EditIcon className="edit-icon" title="Double click to edit Environment" />
						</>
					);
				},
				editor: (p) => (
					<div>
						<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }} className="row-select-drpdwn">
							<Select
								labelId="Select environment"
								id="environment_dd"
								defaultOpen={true}
								value={p.row.Environment}
								onChange={(e: any) => {
									p.onRowChange({ ...p.row, Environment: e.target.value }, true);
								}}
								label="Select environment"
							>
								{['Prod', 'Non-prod'].map((environment) => (
									<MenuItem key={environment} value={environment}>
										{environment}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				)
			},
			{
				key: 'ProjectName',
				name: 'Project',
				minWidth: 140,
				width: 140,
				formatter(props) {
					return (
						<>
						{props.row.ProjectName?.length ? (
								<span className="">{props.row.ProjectName}</span>
							) : (
								<span className="editable_ph">Enter Project</span>
							)}
							<EditIcon className="edit-icon" title="Double click to edit project" />
						</>
					);
				},
				cellClass: 'has-editor',
				editor: textEditor
			},
			{
				key: 'DFProjectcode',
				name: 'Project Number',
				minWidth: 180,
				width: 140,
				formatter(props) {
					return (
						<>
							{props.row.DFProjectcode?.length ? (
								<span className="">{props.row.DFProjectcode}</span>
							) : (
								<span className="editable_ph">Enter Project Number</span>
							)}
							<EditIcon className="edit-icon" title="Double click to edit Project Number" />
						</>
					);
				},
				cellClass: 'has-editor',
				editor: textEditor
			},
			{
				key: 'BillingInformation',
				name: 'Billing Information',
				minWidth: 200,
				width: 140,
				formatter(props) {
					return (
						<>
						{props.row.BillingInformation?.length ? (
								<span className="">{props.row.BillingInformation}</span>
							) : (
								<span className="editable_ph">Enter Billing Information</span>
							)}
							<EditIcon className="edit-icon" title="Double click to edit Billing Information" />
						</>
					);
				},
				cellClass: 'has-editor',
				editor: textEditor
			},
			{
				key: 'Subscription',
				name: 'Subscription',
				minWidth: 200,
				width: 140
			},
			{
				key: 'MeterCategory',
				name: 'Meter Category',
				minWidth: 200,
				width: 140
			},
			{
				key: 'MeterName',
				name: 'Meter Name',
				minWidth: 250,
				width: 140
			},
			{
				key: 'DAIResource',
				name: 'D&AI',
				minWidth: 250,
				width: 140
			}
		];
	}
	const defaultColumnNames = [
		'HeaderCheckbox',
		'Resource',
		'Resource Group',
		'Sector',
		'Environment',
		'Project',
		'Project Number',
		'Billing Information',
		'Subscription',
		'Meter Category',
		'Meter Name',
		'D & AI'
	];

	let selectedRows: any = [];
	let selectedRowsId: any = [];
	const [bulkSelectedRows, setBulkSelectedRows] = useState<Row[]>([]);


	const dataFetchedRef = useRef(false);
	const [resourceGroupList, setResourceGroupList] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');

	const [searchFilterDetails, setFilterSearchInfo] = useState([]);

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState({} as any);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		listResourceGroups(value, pageSize, sortingInfo, parseFilter(filterInfo), searchQuery);
	};

	const handlepageSizeChange = (event: any) => {
		listResourceGroups('1', event.target.value, sortingInfo, parseFilter(filterInfo), searchQuery);
	};

	const [searchQuery, setSearchQuery] = useState<{ column: string; value: string }>({ column: '', value: '' });
	const [isSearchApplied, setIsSearchApplied] = useState(false);
	const [showPopup, setShowPopup] = useState(false);

	const handlePopupOpen = () => {
		setShowPopup(true);
	};

	const handlePopupClear = () => {
		setSearchQuery({ ...searchQuery, column: '' });
		setIsSearchApplied(false);
		setShowPopup(false);
	};

	const handlePopupClose = () => {
		// setSearchQuery({ ...searchQuery, column: '' });
		setShowPopup(false);
	};

	const handleApplyClick = async () => {
			setLoading(true);
			setIsSearchApplied(true);
			const element = document.getElementById('column');
			if (element instanceof HTMLTextAreaElement) {
				const value = element.value;
				setSearchQuery({ column: value, value: value });
				console.log(searchQuery, value)
				const response = await getResourceListMapping(
					'1',
					'100',
					sortingInfo,
					parseFilter(filterInfo),
					searchQuery.toString(),
					value.toString()
				);
					console.log(response)
				if (response.success) {
					handlePopupClose();
					
					const listData = response.data.result.map((row: any) => ({ ...row, selected: false }));
					setResourceGroupList(listData);
					setPaginationData(response.data.page);
					setPage(page);
					setPageSize(pageSize);
					setSortingInfo(sortingInfo);
					setFilterData(response.data.filterOptions);
					setFilterInfo(filterInfo);
					setLoading(false);
					const resourceGroupsState = {
						page: response.data.page,
						filterOptions: response.data.filterOptions,
						pageSize: page,
						sortingInfo,
						filterInfo: filterInfo
					};
					localStorage.setItem('resourceGroupsState ', JSON.stringify(resourceGroupsState));
					// listResourceGroups(page, pageSize, sortingInfo, parseFilter(filterInfo), searchQuery);
				} else {
					console.error('Error:', response);
					Swal.fire({
						icon: 'error',
						html: response.response.data.data.message.message
					});
					setLoading(false);
				}
			} else {
				console.error('Element with ID "column" not found or not a text area');
			}
	};

	const handleClearFilters = () => {
		setSearchQuery({ ...searchQuery, column: '' });
		setIsSearchApplied(false);
		listResourceGroups(1, 100, sortingInfo, parseFilter(filterInfo), '');
	};

	const resetSearchParams = () => {
		if (hasDrillDown) {
			searchParams.delete('bysector');
			searchParams.delete('sector');
			searchParams.delete('taggedproject');
			searchParams.delete('taggedsector');
			searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

	const handleHeaderSelectAll = (event: any) => {
		selectedRowsId = [];
		selectedRows = [];
		resourceGroupList.map((row: any) => {
			row.selected = event.target.checked;
			if (event.target.checked) {
				selectedRowsId.push(row.id);
			}
		});
		if (event.target.checked) {
			selectedRows = resourceGroupList;
		}
		setResourceGroupList(resourceGroupList);
		
		let inputElements: any = document.getElementsByClassName('row-checkbox');

		for (let i = 0; i < inputElements.length; i++) {
			if (inputElements[i] instanceof HTMLInputElement) {
				inputElements[i].checked = event.target.checked;
			}
		}
		setBulkSelectedRows(selectedRows)
	}

	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		resetSearchParams();
		listResourceGroups(1, pageSize, sortingInfo, parseFilter(filterInfo), searchQuery);
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};	

	async function searchListResourceGroups(searchKeyword: any, columnKey: any) {		
		const drillDownSector = hasDrillDown ? searchParams.get('sector') : '';
		console.log(drillDownSector)
		const drillDownBySector = hasDrillDown ? searchParams.get('bysector') : '';
		const drillDownTaggedToProject = hasDrillDown ? searchParams.get('taggedtoproject') : '';
		const drillDownTaggedToSector = hasDrillDown ? searchParams.get('taggedtosector') : '';

		const res = await getResourceSearchFilterColumn(
			parseFilter(filterInfo),
			drillDownTaggedToProject,
			drillDownSector,
			drillDownBySector,
			drillDownTaggedToSector,
			null,
			searchKeyword,
			columnKey
			);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

	const handleFilterSearch = (searchKeyword: any, columnKey: any) => {
		console.log(searchKeyword, columnKey)
		console.log(searchQuery)
		if(searchKeyword?.length >= 3)
		searchListResourceGroups(searchKeyword, columnKey);
	};

	const handleEditCell = (rows: any[], editData: any) => {
		const key = editData.column.key;
		const patchData: any = rows[editData.indexes[0]];
		patchData[key] = rows[editData.indexes[0]][key];
		const editedColName = editData.column.name;
		let editedValue = rows[editData.indexes[0]][key];
		Swal.fire({
			title: `${editedColName} - <i>${editedValue}</i>`,
			text: `Do you want to update ${editedColName} of resource group ${patchData.ResourceGroup}?`,
			...swalOptions
		}).then((result: any) => {
			if (result.isConfirmed) {
				updateResourceGroup(patchData, rows, key);
			}
		});
	};
	async function updateResourceGroup(patchData: any, rows: Row[], key: any) {
		setLoading(true);
		const res = await patchResourceGroup(patchData);
		if (res.success) {
			setLoading(false);
			setResourceGroupList(rows);
			if (key === 'Environment') updateSharedValue();
			listResourceGroups(page, pageSize, sortingInfo, parseFilter(filterInfo), searchQuery);
		}
		setLoading(false);
	}

	async function fetchSectorOptionsData() {
		const sectorRes = await getResourceGroupSectors('1', '100', '');
		if (sectorRes.success) {
			setSectorOptionsList(sectorRes.data.sectors);
		}
	}
		
	const handleAndGetFilterOptions = async (column: any) => {
		let filterOptions: any = {}
		const res = await getResourceSearchFilterColumn(
			parseFilter(filterInfo), '', '', '', '', null, '', column);
		if (res.success) {
			filterOptions[column] = res.data?.name || [];
			setFilterData(filterOptions)
		}
	}

		
	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		fetchSectorOptionsData();
		let storedResourceGroupsState = localStorage.getItem('resourceGroupsState ')
			? JSON.parse(localStorage.getItem('resourceGroupsState ') ?? '')
			: null;
		if (storedResourceGroupsState && storedResourceGroupsState.pageNumber && storedResourceGroupsState.pageSize) {
			listResourceGroups(
				1,
				storedResourceGroupsState.pageSize,
				storedResourceGroupsState.sortingInfo,
				parseFilter(storedResourceGroupsState.filterInfo),
				storedResourceGroupsState.searchQuery
			);
		} else {
			listResourceGroups(page, pageSize, sortingInfo, parseFilter(filterInfo), searchQuery);
		}

		const activeId = document.getElementById('root');
		if (activeId) {
			const mainContent = document.getElementsByClassName('main-content')[0];

			if (mainContent) {
				// Use type assertion to let TypeScript know that myElement has a style property
				(mainContent as HTMLElement).style.overflow = 'scroll';
				(mainContent as HTMLElement).style.height = 'calc(100vh - 35px)';
			}

			activeId.classList.add('jobs-list-table');
			activeId.classList.remove('root');
			document.body.classList.add('jobs-exe-datas');
			document.body.classList.add('jobs-list-datas');
		}
	}, [sharedValue]);




	async function listResourceGroups(pageNumber: any, size: any, sortingInfo: any, resourceGroupFilterInfo: any, searchQuery: any) {
		setLoading(true);
		const drillDownSector = hasDrillDown ? searchParams.get('sector') : '';
		console.log(drillDownSector)
		const drillDownBySector = hasDrillDown ? searchParams.get('bysector') : '';
		const drillDownTaggedToProject = hasDrillDown ? searchParams.get('taggedtoproject') : '';
		const drillDownTaggedToSector = hasDrillDown ? searchParams.get('taggedtosector') : '';
		
		Object.keys(resourceGroupFilterInfo).forEach(key => {
			resourceGroupFilterInfo[key] = resourceGroupFilterInfo[key].filter((value: any) => value !== null)
		});
		
		const res = await getResourceGroups(
			pageNumber.toString(),
			size.toString(),
			sortingInfo,
			resourceGroupFilterInfo,
			drillDownTaggedToProject,
			drillDownSector,
			drillDownBySector,
			drillDownTaggedToSector,
			null, //resource_group_id,
			searchQuery
		);
		if (res.success) {
			const listData = res.data.result.map((row: any) => ({ ...row, selected: false }));
			setResourceGroupList(listData);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setFilterData(res.data.filterOptions);
			setFilterInfo(resourceGroupFilterInfo);
			setLoading(false);
			const resourceGroupsState = {
				page: res.data.page,
				filterOptions: res.data.filterOptions,
				pageSize: size,
				pageNumber,
				sortingInfo,
				filterInfo: resourceGroupFilterInfo
			};
			localStorage.setItem('resourceGroupsState ', JSON.stringify(resourceGroupsState));
		}
	}
	const handleSorting = (sortingInfo: any) => {
		listResourceGroups(page, pageSize, sortingInfo, parseFilter(filterInfo), searchQuery);
	};
	const [editOpen, setEditOpen] = React.useState(false);

	const handleEditOpen = () => {
		console.log(selectedRows)
		setEditOpen(true);

	};
	const handleEditClose = () => {
		setEditOpen(false);
		// afterUpdate();
		listResourceGroups(page, pageSize, sortingInfo, parseFilter(filterInfo), searchQuery);

	};

	const handleCancel = () => {
        const updatedRows = resourceGroupList.map(row => ({ ...row, selected: false }));
        setResourceGroupList(updatedRows);
    };

	return (
		<div className="resourceGroups_tabledata page-table">
			<h3>
				Resources{' '}
				{/* {searchParams.get('taggedtoproject')
					? searchParams.get('taggedtoproject') 
						? 'not Tagged'
						: 'Tagged'
					: ''} */}
				<span className="search-icon-wrap" onClick={handlePopupOpen}>
						<SearchIcon className="search-icon" />
					{ isSearchApplied && <span className="highlight-icon"></span> }
				</span>
				{ isSearchApplied && <h5 className="clear-filter" onClick={handleClearFilters}>Clear Search Filter</h5> }
			</h3>
			<Grid>
			{showPopup && (
                    <div className="popup-container">
                        <div className="popup-header" style={{ backgroundColor: 'rgba(27, 30, 53, 1)' }}>
                            Search From Resources
							<button className="close-button" onClick={handlePopupClose}>X</button> {/* Close button */}
                        </div>
                        <fieldset className="popup-content" style={{ border: '1px solid rgba(229, 229, 229, 1)' }}>
                            <legend>Enter Search Query</legend>
                            <div>
                                <TextareaAutosize
                                    id="column"
                                    value={searchQuery.column}
                                    onChange={(e) => setSearchQuery({ ...searchQuery, column: e.target.value })}
									style={{ width: '100%', height:'100px'}}
                                />
                            </div>
                        </fieldset>
                        <div className="popup-actions">
                            <Button onClick={handlePopupClear} style={{ color: 'rgba(0, 208, 141, 1)' }}>Clear</Button>
                            <Button onClick={handleApplyClick} style={{ color: 'rgba(0, 208, 141, 1)' }}disabled={!searchQuery.column.trim()}
							>
						Apply
						</Button>
						</div>
                    </div>
                )}

				{!loading ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={resourceGroupList}
						tableType="PCOBS_ResourceGroup"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						onFilterCheck={handleFilter}
						onHeaderSelectAll={handleHeaderSelectAll}
						onFilterClick={handleAndGetFilterOptions}
						currFilter={filterInfo}
						onCellEdit={handleEditCell}
						searchDataValues={searchFilterDetails}
						onFilterSearch={handleFilterSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={resourceGroupList?.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isDateRangeFilter={false}
						isDisabled={false}
						isFetching={loading}
					/>
				) : (
					<Loader />
				)}
			</Grid>
			<Dialog open={editOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md" className="edit-job-modal">
				<BulkUpdate bulkData={bulkSelectedRows} handleEditClose={handleEditClose} />
			</Dialog>

			<div className="button-container">
				{resourceGroupList.some((row) => row.selected) && (
					<>
						<button className="custom-button" onClick={handleCancel}>
							CANCEL
						</button>
						<button className="custom-button-1" style={{ marginLeft: '10px' }} onClick={handleEditOpen}>
							BULK UPDATE
						</button>
					</>
				)}
			</div>
			<div> </div>
		</div>
	);
};

export default ResourceGroupListing;


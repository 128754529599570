import { lazy } from 'react';
import { DrawerDetailsProps, DrawerType, DrawerTypes } from './constants';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import { setDrawerComponent } from 'Context/contexts/cacheData/SideDrawer';

const useDataEstateSideDrawer = () => {
	const [_, dispatch] = useCacheDataController();

	const handleSetTotalPipeline = (props: DrawerDetailsProps) => {
		const TotalPipeline = lazy(() => import('./components/TotalPipeline'));
		setDrawerComponent(dispatch, <TotalPipeline selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};
	const handleOverallDataGrowth = (props: DrawerDetailsProps) => {
		const OverallDataGrowth = lazy(() => import('./components/OverallDataGrowth'));
		setDrawerComponent(dispatch, <OverallDataGrowth selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};
	const handleSetPowerUsers = (props: DrawerDetailsProps) => {
		const PowerBIUsers = lazy(() => import('./components/PowerBIUsers'));
		setDrawerComponent(dispatch, <PowerBIUsers selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};
	const handleSetTotalCluster = (props: DrawerDetailsProps) => {
		const Clusters = lazy(() => import('./components/Clusters'));
		setDrawerComponent(dispatch, <Clusters selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};
	const handleSetIncident = (props: DrawerDetailsProps) => {
		const MajorIncidents = lazy(() => import('./components/MajorIncidents'));
		setDrawerComponent(dispatch, <MajorIncidents selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};

	const handleSetTableauUsers = (props: DrawerDetailsProps) => {
		const TableauUsers = lazy(() => import('./components/TableauUsers'));
		setDrawerComponent(dispatch, <TableauUsers selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};

	const handleSetDAPlateforms = (props: DrawerDetailsProps) => {
		const DAPlateforms = lazy(() => import('./components/D&AIPlateforms'));
		setDrawerComponent(dispatch, <DAPlateforms selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};

	const handleSetUnplannedDowntime = (props: DrawerDetailsProps) => {
		const UnplannedDowntime = lazy(() => import('./components/UnplannedDowntime'));
		setDrawerComponent(dispatch, <UnplannedDowntime selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};

	const handleSetOutage = (props: DrawerDetailsProps) => {
		const Outages = lazy(() => import('./components/Outages'));
		setDrawerComponent(dispatch, <Outages selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};

	const handleSetPowerBiGrowth = (props: DrawerDetailsProps) => {
		const PowerBiUserGrowth = lazy(() => import('./components/PowerBiGrowth'));
		setDrawerComponent(dispatch, <PowerBiUserGrowth selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};

	const handleSetTableauGrowth = (props: DrawerDetailsProps) => {
		const TableauUserGrowth = lazy(() => import('./components/TableauGrowth'));
		setDrawerComponent(dispatch, <TableauUserGrowth selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};

	const handleSetYTD = (props: DrawerDetailsProps) => {
		const YtdSpend = lazy(() => import('./components/YtdSpend'));
		setDrawerComponent(dispatch, <YtdSpend selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};

	const handleSetAvgMonthlyCostTrend = (props: DrawerDetailsProps) => {
		const AvgMonthCost = lazy(() => import('./components/AvgMonthlyCostTrend'));
		setDrawerComponent(dispatch, <AvgMonthCost selectedDate={props.selectedDate} sector={props.sector} title={props.title}/>);
	};

	const handleSetComponent = (type: DrawerType, props: DrawerDetailsProps) => {
		switch (type) {
			case DrawerTypes.TOTAL_PIPELINES:
				handleSetTotalPipeline(props);
				break;
			case DrawerTypes.OVERALL_DATA_GROWTH:
				handleOverallDataGrowth(props);
				break;
				case DrawerTypes.POWERBI_USERS:
				handleSetPowerUsers(props);
				break;
			case DrawerTypes.TOTAL_CLUSTER:
				handleSetTotalCluster(props);
				break;
			case DrawerTypes.MAJOR_INCIDENT_LAST_MONTH:
				handleSetIncident(props);
				break;
			case DrawerTypes.TABLEAU_USERS:
				handleSetTableauUsers(props);
				break;
			case DrawerTypes.DAI_PLATEFORMS:
				handleSetDAPlateforms(props);
				break;
			case DrawerTypes.UNPLANNED_DOWNTIME:
				handleSetUnplannedDowntime(props);
				break;
			case DrawerTypes.OUTAGES:
				handleSetOutage(props);
				break;
			case DrawerTypes.POWERBI_USER_GROWTH:
				handleSetPowerBiGrowth(props);
				break;
			case DrawerTypes.TABLEAU_USER_GROWTH:
				handleSetTableauGrowth(props);
				break;
			case DrawerTypes.YTD_SPEND:
				handleSetYTD(props);
				break;
			case DrawerTypes.AVG_MONTHLY_COST_TREND:
				handleSetAvgMonthlyCostTrend(props);
				break;
			default:
				return null;
		}
	};

	return {
		handleSetComponent
	};
};

export default useDataEstateSideDrawer;

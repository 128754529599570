import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Grid, Box, Chip, TextField, FormControl, Button, Select, Menu, MenuItem, IconButton } from '@mui/material';

import { ibpJobListing, ibpJobRunsListing, ibpJobSearch } from '../../service/service';
import { ReactComponent as MoreVertIcon } from '../../assets/system-uicons_circle-menu_disabled.svg';
import convertSeconds from '../../utils/convertSeconds';

import JobIcon from '../../assets/jobs_icon.png';
import IBPLogo from '../../assets/ibp.svg';
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import { CustomizedDataGrid } from '../customized_data_grid';
import { OverflowTooltip } from '../overflow_tooltip';
import moment from 'moment-timezone';

import type { Column } from 'react-data-grid';
// import '../jobs/jobs.css';
import './ibp-obs.scss';
import Loader from '../Loader/loader';
import { scheduler } from 'timers/promises';

import { GDOList } from '../common-util';
import { textEditor } from 'react-data-grid';

const IBPExecutedJobs = ({ gdoName , marketName }: any) => {
	interface Row {
		runStatus: string;
		commonStatus: string;
		reasonForDelay: string;
		actionTaken: string;
		ticketNumber: string;
		userStatus: string;
		downstream: string;
		slaStatus: string;
		_id: string;
		name: string;
		jobId: string;
		status: string;
		exitCode: string;
		runNum: string;
		application: string;
		subApplication: string;
		runStartTime: string;
		runEndTime: string;
		slaEndTime: string;
		criticalFlag: boolean;
		machine: string;
		createdAt: string;
		updatedAt: string;
		updatedBy: string;
		boxName: string;
		jobType: string;
		children?: Row[];
		ischildren?: boolean;
		isExpanded?: boolean;
		actualDuration: string;
		overRun: boolean;
		thresholdPercent: string;
		durationDelay: string;
		avgRunTime: number;
		estimatedEndTime: string;
		totalRuns: number;
	}

	const renderStatus = (status: any) => {
		switch (status) {
			case 'SUCCESS':
			case 'Successful':
			case 'COMPLETED':
				return <Chip label={status} color="success" />;
			case 'FAILURE':
			case 'Failure':
			case 'TERMINATED':
			case 'Terminated':
			case 'SUSPENDED':
				return <Chip label={status} color="secondary" />;
			case 'NOT STARTED':
			case 'Not Started':
				return <Chip label={status} style={{ backgroundColor: '#1B1E35CC', color: '#71758F' }} />;
			case 'RUNNING':
			case 'ACTIVATED':
			case 'In Progress':
			case 'Hold':
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
			default:
				return <Chip label={status} style={{ backgroundColor: '#299BFF26', color: '#299BFF' }} />;
		}
	};

	const renderThreshold = (threshold: any) => {
		let color = '#2DC8A8';
		if (threshold > 80 && threshold <= 100) color = '#FFC258';
		else if (threshold > 100) color = '#FE8083';
		return (
			<span color="success" style={{ color: color }}>
				{threshold} %
			</span>
		);
	};

	function getColumns(): Array<Column<Row>> {
		return [
			{
				key: 'name',
				name: 'Job',
				width: 440,
				minWidth: 440,
				// formatter({ row, isCellSelected }) {
				// 	const hasChildren = row.jobType === 'BOX';
				// 	return (
				// 		<>
				// 			<Link
				// 				className="jobsNameList"
				// 				to={`/jobs/${row.jobId}`}
				// 				state={row}
				// 				style={{ paddingLeft: hasChildren ? '5px' : '5px' }}
				// 			>
				// 				{row.name}
				// 			</Link>
				// 		</>
				// 	);
				// }
			},
			{
				key: 'source',
				name: 'Source',
				width: 200,
				minWidth: 200
			},
			{   
				key: 'destination',
				name: 'Destination',
				width: 200,
				minWidth: 200
			},
			{
				key: 'runStartTime',
				name: 'Start Time',
				width: 200,
				minWidth: 200,
				formatter(props) {
					const startDate = props.row.runStartTime;
					if (startDate) {
						return `${moment().subtract(1,'day').format('MM-DD-YY')} ${moment(startDate).format('HH:mm:ss')}`;
					}
					return '';
				}
			},
			{
				key: 'runEndTime',
				name: 'End Time',
				width: 200,
				minWidth: 200,
				formatter(props) {
					const endDate = props.row.runEndTime;
					if (endDate) {
						return `${moment().subtract(1,'day').format('MM-DD-YY')} ${moment(endDate).format('HH:mm:ss')}`;
					}
					return '';
				}
			},
			{
				key: 'runStatus',
				name: 'Job Status',
				width: 200,
				minWidth: 200,
				formatter(props) {
					const status = props.row.runStatus;
					return renderStatus(status);
				}
			},
			
			// {
			// 	key: 'avgRunTime',
			// 	name: 'Avg Run Time',
			// 	minWidth: 150,
			// 	width: 180,
			// 	// maxWidth: 200,
			// 	formatter(props) {
			// 		const avgRunTime = props.row.avgRunTime;
			// 		return convertSeconds(avgRunTime);
			// 	}
			// },
			// {
			// 	key: 'actualDuration',
			// 	name: 'Run Time',
			// 	minWidth: 160,
			// 	width: 150
			// 	// maxWidth: 200
			// },
			// {
			// 	key: 'ticketStatus',
			// 	name: 'Ticket Status',
			// 	minWidth: 150,
			// 	width: 180,
			// 	// maxWidth: 200,
			// 	formatter(props) {
			// 		const status = props.row.runStatus;
			// 		if(status == 'Failure'){
			// 		const avgRunTime = Math.floor(Math.random() * 90000) + 10000;
			// 		return "INC"+avgRunTime;
			// 		}
			// 	}
			// },
			// {
			// 	key: 'totalRuns',
			// 	name: 'No. of Executions',
			// 	minWidth: 160,
			// 	width: 150
			// 	// maxWidth: 200
			// },
			// {
			// 	key: 'ticketNumber',
			// 	name: 'Ticket No',
			// 	width: 120,
			// 	minWidth: 120,
			// 	editor: textEditor,
			// 	cellClass: 'has-editor',
			// 	formatter(props) {
			// 		return props.row.ticketNumber ? <OverflowTooltip>{props.row.ticketNumber}</OverflowTooltip> : 'Ticket No?';
			// 	}
			// },
			// {
			// 	key: 'downstream',
			// 	name: 'Follow Up',
			// 	width: 120,
			// 	minWidth: 120,
			// 	editor: textEditor,
			// 	cellClass: 'has-editor',
			// 	formatter(props) {
			// 		return props.row.downstream ? <OverflowTooltip>{props.row.downstream}</OverflowTooltip> : 'Follow Up?';
			// 	}
			// },
			// {
			// 	key: 'sector',
			// 	name: 'Sector',
			// 	width: 100,
			// 	minWidth: 100
			// },
			// {
			// 	key: 'system',
			// 	name: 'System',
			// 	width: 100,
			// 	minWidth: 100
			// },
			// {
			// 	key: 'schedulerName',
			// 	name: 'Scheduler',
			// 	width: 100,
			// 	minWidth: 100
			// },
			// {
			// 	key: 'application',
			// 	name: 'Application',
			// 	formatter(props) {
			// 		return props.row.application || ' ';
			// 	},
			// 	minWidth: 115,
			// 	width: 115
			// 	// maxWidth: 130
			// },
			// {
			// 	key: 'subApplication',
			// 	name: 'Sub Application',
			// 	formatter(props) {
			// 		return props.row.subApplication || ' ';
			// 	},
			// 	minWidth: 135,
			// 	width: 135
			// 	// maxWidth: 180
			// },
			// {
			// 	key: 'commonStatus',
			// 	name: 'Common Status',
			// 	minWidth: 155,
			// 	width: 155,
			// 	// maxWidth: 220,
			// 	cellClass: 'center-text',
			// 	formatter(props) {
			// 		const status = props.row.commonStatus;
			// 		return renderStatus(status);
			// 	}
			// },
			// {
			// 	key: 'machine',
			// 	name: 'Machine',
			// 	minWidth: 125,
			// 	width: 125,
			// 	// maxWidth: 220,
			// 	formatter(props) {
			// 		return props.row.machine;
			// 	}
			// },
			// {
			// 	key: 'jobType',
			// 	name: 'Job Type',
			// 	minWidth: 95
			// 	// maxWidth: 160
			// },
			// {
			// 	key: 'overRun',
			// 	name: 'Over Run',
			// 	minWidth: 95,
			// 	formatter(props) {
			// 		return props.row.overRun ? 'Yes' : 'No';
			// 	},
			// 	// maxWidth: 160
			// },
			// {
			// 	key: 'thresholdPercent',
			// 	name: 'Threshold %',
			// 	minWidth: 120,
			// 	width: 150,
			// 	// maxWidth: 200,
			// 	cellClass: 'center-text',
			// 	formatter(props) {
			// 		const thresholdPercent = props.row.thresholdPercent;
			// 		return renderThreshold(thresholdPercent);
			// 	}
			// },
			// {
			// 	key: 'durationDelay',
			// 	name: 'Duration of Delay',
			// 	minWidth: 150,
			// 	width: 180
			// 	// maxWidth: 200
			// }
		];
	}

	const defaultColumnNames = [
		'Job',
		'Source',
		'Destination',
		'Start Time',
		'End Time',
		'Job Status',
		// 'Run Time',
		// 'Avg Run Time',		
		// 'Ticket Status',
		// 'No. of Executions'
		//'Process',
		//'GDO',
		//'Box Name',
		//'Threshold %',
		//'Sector',
		//'System',
		//'SLA Status',
		//'Normal Completion Time',
		//'Estimated End Time',
		//'Critical',
		//'Schedule Time',
		//'Schedule Date',
		//'Reason',
		//'Action Taken',
		//'Follow Up',
		//'Last Updated By',
		//'Last Updated At'
	];
	const dataFetchedRef = useRef(false);
	const [jobsList, setJobList] = useState<Row[]>([]);
	const [loading, setLoading] = useState(false);
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
    const location = useLocation();
    const [job, setJob] = useState(location.state);
    const [gdoList, setGdoList] = useState<any[]>([]);

	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState([]);
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [tableTitle, setTableTitle] = useState('Executions');
	const hasDrillDowned = searchParams.has('isDrillDowned');
	const [activeClassBA, setActiveClassBA] = useState(false);

    
	moment.updateLocale(moment.locale(), { invalidDate: '' });

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		jobListingFunc(value, pageSize, sortingInfo, parseFilter(filterInfo));
	};

	const handlepageSizeChange = (event: any) => {
		jobListingFunc('1', event.target.value, sortingInfo, parseFilter(filterInfo));
	};
	const handleSorting = (sortingInfo: any) => {
		jobListingFunc(page, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	const resetSearchParams = () => {
		if (hasDrillDowned) {
			searchParams.delete('status');
			searchParams.delete('isDrillDowned');
			setSearchParams(searchParams);
		}
	};

	const handleFilter = (filterInfo: any) => {
		console.log(filterInfo)
		resetSearchParams();
		setFilterInfo(filterInfo);
		jobListingFunc(1, pageSize, sortingInfo, parseFilter(filterInfo));
	};
	const handleSearch = (searchDetails: any, columnKey: any) => {
		jobSearchFunc(searchDetails, parseFilter(filterInfo, columnKey), columnKey);
	};

	const parseFilter = (filterInfo: any, name?: string) => {
		// Call only filterable columns and reset the filtering in case of wrong/empty column
		const parsedFilter: any = {};
		Object.keys(filterInfo).forEach((key: any) => {
			if (key !== name && filterInfo[key] && filterInfo[key].length) {
				parsedFilter[key] = filterInfo[key];
			}
		});
		return parsedFilter;
	};

	function pickRandomItems(arr: any, count: any) {
		const copyArray = [...arr];
	
		if (count >= copyArray.length) {
			return copyArray;
		}
	
		for (let i = copyArray.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[copyArray[i], copyArray[j]] = [copyArray[j], copyArray[i]];
		}
	
		return copyArray.slice(0, count);
	}

	const handleDrilldown = (category: string, scheduler: string, count: any) => {
		setTableTitle(`${category} ${scheduler}`)
		setJobList(pickRandomItems(jobsList, count))
		setPage(1);
		setPaginationData(
			{
				"totalElements": count,
				"totalPages": count/100
			  }
		);
	}

	useEffect(() => {
		console.log(gdoName)
        setGdoList(GDOList);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		let storedJobState = null //localStorage.getItem('ibp_jobState') ? JSON.parse(localStorage.getItem('ibp_jobState') ?? '') : null;
		const filterQuerySkeleton = {} as any;
		const _status = searchParams.get('status');
		const _startDaily = moment(new Date()).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
		const _endDaily = moment(new Date()).startOf('day').endOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
		const _scheduleDate = searchParams.get('fromDate');
		const _toDate = searchParams.get('toDate');
		const startDt = moment(_scheduleDate).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
		const endDt = moment(_toDate).endOf('day').subtract(1, 'hours').format('YYYY-MM-DD HH:mm');
		if (hasDrillDowned) {
			switch (_status) {
				case 'Critical':
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'Non Critical':
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'New Jobs':
					filterQuerySkeleton.createdAt = [`${_startDaily}`, `${_endDaily}`];
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				case 'Non Critical New Jobs':
					filterQuerySkeleton.createdAt = [`${_startDaily}`, `${_endDaily}`];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'Critical Exceed Estimated Duration Trend':
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.execExceedEstimatedDurationTrend = ['Yes'];
					break;
				case 'Critical Exceed Threshold Trend':
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.execExceedThresholdTrend = ['Yes'];
					break;
				case 'Critical Failed Trend':
					filterQuerySkeleton.criticalFlag = ['true'];
					filterQuerySkeleton.execFailedTrend = ['Yes'];
					break;
				case 'Non Critical Exceed Estimated Duration Trend':
					filterQuerySkeleton.criticalFlag = ['false'];
					filterQuerySkeleton.execExceedEstimatedDurationTrend = ['Yes'];
					break;
				case 'Non Critical Exceed Threshold Trend':
					filterQuerySkeleton.criticalFlag = ['false'];
					filterQuerySkeleton.execExceedThresholdTrend = ['Yes'];
					break;
				case 'Non Critical Failed Trend':
					filterQuerySkeleton.criticalFlag = ['false'];
					filterQuerySkeleton.execFailedTrend = ['Yes'];
					break;
				case 'Non Critical New Jobs Graph':
					filterQuerySkeleton.createdAt = [`${startDt}`, `${endDt}`];
					filterQuerySkeleton.criticalFlag = ['false'];
					break;
				case 'New Jobs Graph':
					filterQuerySkeleton.createdAt = [`${startDt}`, `${endDt}`];
					filterQuerySkeleton.criticalFlag = ['true'];
					break;
				default:
					break;
			}
		} else {
			getColumns().forEach((column: any) => {
				filterQuerySkeleton[column.key] = [];
			});
		}
		setFilterInfo(filterQuerySkeleton);
		if (localStorage.getItem('selectedAlert')) {
			storedJobState = localStorage.getItem('alertJobState')
				? JSON.parse(localStorage.getItem('alertJobState') ?? '')
				: null;
			localStorage.removeItem('alertJobState');
		}
		if (hasDrillDowned) {
			console.log('if');
			jobListingFunc(page, pageSize, sortingInfo, parseFilter(filterQuerySkeleton));
		} else if (storedJobState && storedJobState.pageNumber && storedJobState.pageSize) {
			console.log('else if');
			if (localStorage.getItem('overviewPageHit') && storedJobState.jobs) {
				localStorage.removeItem('overviewPageHit');
				setJobList(storedJobState.jobs);
			} else {
				jobListingFunc(1, storedJobState.pageSize, storedJobState.sortingInfo, parseFilter(storedJobState.filterInfo), gdoName);
			}
			setPaginationData(storedJobState.page);
			setFilterData(storedJobState.filterOptions);
			setPage(1);
			setPageSize(storedJobState.pageSize);
			setSortingInfo(storedJobState.sortingInfo);
			setFilterInfo(storedJobState.filterInfo);
		} else {
			console.log('else');
			jobListingFunc(page, pageSize, sortingInfo, parseFilter(filterQuerySkeleton), gdoName);
		}

		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		const jobName = url.substring(url.lastIndexOf('/') + 1);
		if (url === '/jobs' || (jobName !== url && url === '/jobs/' + jobName && activeId)) {
			document.body.classList.add('jobs-list-datas');
			document.body.classList.remove('jobs-exe-datas');
		}
	}, [gdoName]);

	async function jobSearchFunc(searchDetails: any, jobFilterInfo: any, columnKey: any) {
		const res = await ibpJobSearch(searchDetails, jobFilterInfo, columnKey);
		if (res.success) {
			setFilterSearchInfo(res.data);
		}
	}

    const onGdoFilter = (gdoName: string) => {
        jobListingFunc(page, pageSize, sortingInfo, parseFilter(filterInfo), gdoName);
    }

	async function jobListingFunc(pageNumber: any, size: any, sortingInfo: any, jobFilterInfo: any, gdoName?: string) {
		setLoading(true);
		if (pageSize !== size) {
			pageNumber = 1;
		}
		const res = await ibpJobRunsListing(pageNumber.toString(), size.toString(), sortingInfo, jobFilterInfo, gdoName, marketName);
		if (res.success) {
			setJobList(res.data.jobs);
			setTimeout(() => {
				let table = document.getElementById(`ibp_jobs_tabledata_${gdoName?.replace(' ', '_')}`)
				if (table) {
					const rdgElement = table.querySelector('.rdg') as HTMLElement | null;
						if (rdgElement) {
							let jobsLength = res.data.jobs.length;
							let height = 80;
							if (jobsLength === 1) height = jobsLength * 80;
							else height = jobsLength * 55;
						rdgElement.style.height = `${height}px`
					}
				}
			}, 100);
			setPaginationData(res.data.page);
			setFilterData(res.data.filterOptions);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
			setLoading(false);
			if (!localStorage.getItem('selectedAlert')) {
				const ibp_jobState = {
					jobs: res.data.jobs,
					page: res.data.page,
					filterOptions: res.data.filterOptions,
					pageSize: size,
					pageNumber,
					sortingInfo,
					filterInfo: jobFilterInfo
				};
				localStorage.setItem('ibp_jobState', JSON.stringify(ibp_jobState));
			}
			localStorage.removeItem('selectedAlert');
		}
	}



	return (
		<div style={{marginTop: 0, marginRight: 16, width: '99%'}} className="page-table ibp_jobs_tabledata jobs_tabledata page-table-ibp-obs" id={`ibp_jobs_tabledata_${gdoName.replace(' ', '_')}`}>
			<Grid>
				{!loading ? (
					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={jobsList}
						tableType="IBP_JobRuns"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						searchDataValues={searchDetails}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={jobsList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isFetching={loading}
						showExport={true}
						fileName={`Jobs.xlsx`}
						isDateRangeFilter={false}
						isDisabled={false}
					/>
				) : (
					<Loader />
				)}
			</Grid>
		</div>
	);
}
export default IBPExecutedJobs;
import React, { useState } from 'react';
import { LocalizationProvider, TimePicker, TimePickerProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { TimerOptions } from 'timers';

const CustomTimePicker: React.FC<TimePickerProps<Date, Date>> = ({
    label,
    value,
    onChange,
    ampm,
    InputProps = {},
    renderInput
}) => {


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        label={label}
        value={value}
        onChange={onChange}
        ampm={ampm} // 24-hour format
       
        renderInput={renderInput}
      />
    </LocalizationProvider>
  );
};

export default CustomTimePicker;

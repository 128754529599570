export const DrawerTypes = {
  TOTAL_PIPELINES: 'TOTAL_PIPELINES',
  OVERALL_DATA_GROWTH: 'OVERALL_DATA_GROWTH',
  TOTAL_CLUSTERS: 'TOTAL_CLUSTERS',
  POWERBI_USERS: 'POWERBI_USERS',
  TABLEAU_USERS: 'TABLEAU_USERS',
  TOTAL_CLUSTER: 'TOTAL_CLUSTER',
  MAJOR_INCIDENT_LAST_MONTH: 'MAJOR_INCIDENTS_LAST_MONTH',
  TOTAL_OUTAGES_LAST_MONTH: 'TOTAL_OUTAGES_LAST_MONTH',
  DAI_PLATEFORMS: 'DAI_PLATEFORMS',
  UNPLANNED_DOWNTIME: 'UNPLANNED_DOWNTIME',
  OUTAGES: 'OUTAGES',
  POWERBI_USER_GROWTH: 'POWERBI_USER_GROWTH',
  TABLEAU_USER_GROWTH: 'TABLEAU_USER_GROWTH',
  YTD_SPEND: 'YTD_SPEND',
  AVG_MONTHLY_COST_TREND: 'AVG_MONTHLY_COST_TREND',
} as const;

export type DrawerType = typeof DrawerTypes[keyof typeof DrawerTypes];

export type DrawerDetailsProps = {
  title: string;
  selectedDate: string;
  sector: any;
}
import { useEffect, useRef, useState } from 'react';
import { CustomizedDataGrid } from 'components/customized_data_grid';
import { getFinopsResourceMappingList } from 'service/service';
import { CellExpanderFormatter } from 'components/cell_expander_formatter';
import RowTypography from 'components/Table/RowTypography';
import LoaderComponent from 'components/Loader';
import { Box, Button, Stack } from '@mui/material';
import SearchFilterDropdown from 'components/Inputs/ResourceMapping/SearchFilterDropdown';
import colors from 'assets/theme/base/colors';
import DialogueModal from 'components/Modal/DialogModal';
import BulkEditModalConfirm from './BulkEditModalConfirm';

interface Row {
	id: string;
	[key: string]: any;
	Resource: string;
	ResourceGroup: string;
	DFProjectcode: string;
	DFSectorname: string;
	Environment: string;
	ProjectName: string;
	BillingInformation: string;
	Subscription: string;
	MeterCategory: string;
	MeterName: string;
	selected: boolean;
	DAI: String;
	ClusterName: string;
	SqlEndpointId: string;
}

interface Option {
	value: string;
	label: string;
}

const defaultColumnNames = [
	'HeaderCheckbox',
	'Resource',
	'Resource Group',
	'Sector',
	'Environment',
	'Project Name',
	'Project Number',
	'Billing Information',
	'Subscription',
	'Meter Category',
	'Meter Name',
	'D & AI'
];

const columnNames = [
	{ name: 'Resource', label: 'Resource', isEditable: false },
	{ name: 'ResourceGroup', label: 'Resource Group', isEditable: false },
	{ name: 'Subscription', label: 'Subscription', isEditable: false },
	{ name: 'ConsumedService', label: 'Consumed Service', isEditable: false },
	{ name: 'MeterCategory', label: 'Meter Category', isEditable: false },
	{ name: 'BusinessSector', label: 'Business Sector', isEditable: false },
	{ name: 'ProjectName', label: 'Project Name', isEditable: true },
	{ name: 'ClusterName', label: 'Cluster Name', isEditable: false },
	{ name: 'Sector', label: 'Sector', isEditable: true },
	{ name: 'Environment', label: 'Environment', isEditable: true },
	{ name: 'Program', label: 'Program', isEditable: true },
	{ name: 'SqlEndpointId', label: 'Sql Endpoint Id', isEditable: false },
	{ name: 'ClusterJobSource', label: 'Cluster Job Source', isEditable: false },
	{ name: 'DatabricksInstancePoolId', label: 'Databricks Instance Pool Id', isEditable: false }
];

const parseFilter = (filterInfo: any, name?: string) => {
	// Call only filterable columns and reset the filtering in case of wrong/empty column
	const parsedFilter: any = {};
	Object.keys(filterInfo).forEach((key: any) => {
		if (key !== name && filterInfo[key] && filterInfo[key].length) {
			parsedFilter[key] = filterInfo[key];
		}
	});
	return parsedFilter;
};

const getInputOptions = async (input: string, field: string) => {
	const fieldsRes = await getFinopsResourceMappingList({ searchField: field, searchText: input });
	const options: Option[] = fieldsRes.data.result.filter(Boolean).map((item: string) => ({ value: item, label: item }));
	return options;
};

const initialEditRowState = {
	rowsToUpdate: {},
}

const tableTitles: Record<string, string> = {
	ResourceGroup_tree: 'Resource Groups',
	SqlEndpointId_tree: 'DBSQL Clusters',
	ClusterName_inttree: 'Interactive Clusters',
	ClusterName_jobtree: 'Job Clusters',
};

const TableSection = ({ queryPreference, queryProps }: any) => {
	const [tableTitle, setTableTitle] = useState('Resources');
	const isTreeView = queryPreference.split('_')[1] === 'tree';
	function toggleSubRow(name: string, resourceTypes: any) {
		setTableDataList((prev) => {
			const rows = [...prev];
			const rowIndex = rows.findIndex((r: any) => r.name === name);
			const row = rows[rowIndex];
			const newRows = [...rows];
			let children: Row[] = [];
			let childRows: Row[] = [];
			if (!row.isExpanded) {
				if (resourceTypes?.length > 0) {
					childRows = resourceTypes;
				}
				children = childRows.map((x: any) => {
					return { ...x, isChildren: true };
				});
				newRows.splice(Number(rowIndex) + 1, 0, ...children);
				newRows[rowIndex] = { ...row, isExpanded: true, children };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = true;
			} else {
				children = row.children ?? [];
				newRows.splice(Number(rowIndex) + 1, children.length);
				newRows[rowIndex] = { ...row, isExpanded: false };
				localStorage.setItem('boxRows', JSON.stringify(newRows));
				row.isExpanded = false;
			}
			return newRows;
		});
	}

	function getColumns(editedRows: Record<string, any>): Array<any> {
		// For checkBox
		const checkboxColumn = {
			key: 'selected',
			name: 'HeaderCheckbox',
			formatter: ({ row }: any) => (
				<input
				type="checkbox"
				className="transparent-checkbox row-checkbox"
				checked={!!bulkSelectedRows[row.id]}
				onChange={(e) => {
					const checked = e.target.checked;
					const updatedRow = { ...row, selected: checked };
					const headerCheckboxInput = document.getElementById('HeaderCheckbox') as HTMLInputElement;
		
					setBulkSelectedRows((prevSelectedRows) => {
						const updatedSelectedRows = { ...prevSelectedRows };

						if (checked) {

							// **Flat View**: Add the row directly
							updatedSelectedRows[row.id] = row;

							// **Tree View**: If row has children (values), add each child row
							if (row.values && Array.isArray(row.values)) {
								row.values.forEach((childRow: any) => {
									updatedSelectedRows[childRow.id] = childRow; // Add child row, not parent
								});
							}
						} else {
							// Deselect the row (Flat or Tree)
							let removeRowIds = [row.id];

							// **Tree View**: If row has children, add their IDs for removal
							if (row.values && Array.isArray(row.values)) {
								removeRowIds = removeRowIds.concat(row.values.map((childRow: any) => childRow.id)); // Add child rows for removal
							}

							// **Flat View**: Remove the row itself (if not a tree view)
							// Use for...in loop to remove rows
							for (let key in updatedSelectedRows) {
								if (removeRowIds.includes(key)) {
									delete updatedSelectedRows[key];
								}
							}
						}

						 // Update header checkbox state
						 const allRowsSelected = Object.keys(updatedSelectedRows).length ===
						 tableDataList.reduce((count, row) => {
							 // Count all rows (including parent rows and child rows in the tree)
							 return count + (row.values ? row.values.length + 1 : 1); // +1 for the parent row, +children if available
						 }, 0);

						if (headerCheckboxInput) {
							headerCheckboxInput.checked = allRowsSelected;
						}

						return updatedSelectedRows;
					});


						setTableDataList((prevRows) =>
							prevRows.map((prevRow) => (prevRow.id === updatedRow.id ? updatedRow : prevRow))
						);

					}}
				/>
			),
			minWidth: 40
		};

		const [groupBy] = queryPreference.split('_');
		const columns = [];
		for (const value of columnNames) {
			const field = value.name;
			if (field.toLowerCase() !== groupBy.toLowerCase()) {
				columns.push(value);
			} else {
				columns.unshift(value);
			}
		}
		const columnArray: any = [
			...columns.map((col: any, idx: number) => ({
				// For all other columns
				key: col.name,
				name: col.label,
				editable: () => col.isEditable,
				formatter({ row, isCellSelected, onRowChange, column }: any) {
					const isGroupBy = column.key.toLowerCase() === groupBy.toLowerCase();
					const hasChildren = !!row.values;
					const isCellEdited =
						(isTreeView ? nonEditedUnGroupList.current : nonEditedList.current)[row.id]?.[column.key] !==
						row[column.key];
					if (!hasChildren && isEditing && col.isEditable) {
						return (
							<SearchFilterDropdown
								mappingType="finopsResourceMapping"
								columnName={column.key}
								isCellSelected={isCellSelected}
								value={row[column.key]}
								onChange={(newValue: string) => {
									onRowChange(
										{
											...row,
											[column.key]: newValue
										},
										false
									);
								}}
								options={[]}
								fieldValueOptionsCallback={async (input: string) => getInputOptions(input, column.key)}
								{...(isCellEdited && {
									cellInputStyle: {
										color: `#FFA500`
									}
								})}
							/>
						);
					}
					return (
						<>
							{idx === 0 && isGroupBy && hasChildren && (
								<CellExpanderFormatter
									isCellSelected={isCellSelected}
									expanded={row.isExpanded === true}
									onCellExpand={() => {
										toggleSubRow(row.name, row.values);
									}}
								/>
							)}
							{'   '}
							<RowTypography
								isSuccessor={!row.values}
								{...(row.id && editedRows[row.id]?.[column.key] && { color: `#FFA500 !important` })}
								isEditing={isEditing}
							>
								{hasChildren ? (isGroupBy ? row.name : '') : row[column.key]}
							</RowTypography>
						</>
					);
				},
				width: 200
			}))
		];
		if (!isEditing && tableDataList.length !== 0) {
			columnArray.unshift(checkboxColumn);
		}
		return columnArray;
	}

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [bulkSelectedRows, setBulkSelectedRows] = useState<Record<string, Row>>({});

	const [tableDataList, setTableDataList] = useState<any[]>([]);
	const nonEditedList = useRef<Record<string, Row>>({});
	const nonEditedUnGroupList = useRef<Record<string, Row>>({});
	const [loading, setLoading] = useState(false);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);

	const [filterData, setFilterData] = useState<Row[]>([]);
	const [filterInfo, setFilterInfo] = useState({} as any);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [editedRows, setEditedRows] = useState<any>(initialEditRowState);
	const nonEditedRows = useRef<any>(initialEditRowState);
	async function listTableData({ pageNumber, size, sortingInfo, resourceGroupFilterInfo, queryProps }: any) {
		if (queryProps.query === null) {
			return;
		}
		try {
			setLoading(true);
			Object.keys(resourceGroupFilterInfo).forEach((key) => {
				resourceGroupFilterInfo[key] = resourceGroupFilterInfo[key].filter((value: any) => value !== null);
			});

			const [groupBy, viewType] = queryPreference.split('_');

			const res = await getFinopsResourceMappingList({
				page: pageNumber,
				size,
				sortingInfo: sortingInfo.sortBy,
				orderBy: sortingInfo.sortDir,
				...(queryProps.isSearch
					? {
							searchField: 'quicksearch',
							searchText: queryProps.query
					  }
					: {
							searchField: 'detail',
							...queryProps.query
					  }),
				...((viewType === 'tree' || viewType === 'inttree' || viewType === 'jobtree') && {
					groupBy
				}),
				viewType
			});

			if (res.success) {
				const listData = res.data.result.map((row: any, index: number) => ({
					...row,
					selected: false,
					id: crypto.randomUUID(),
					values: row.values
						? row.values.map((secondRow: any, index: number) => ({
								...secondRow,
								selected: false,
								id: crypto.randomUUID()
						  }))
						: undefined
				}));
				setTableDataList(listData);
				setPaginationData(res.data.page);
				setPage(res.data.page.currentPage);
				setPageSize(res.data.page.size);
				setSortingInfo(sortingInfo);
				setFilterData(res.data.filterOptions);
				setFilterInfo(resourceGroupFilterInfo);
				setLoading(false);
				const resourceGroupsState = {
					page: res.data.page,
					filterOptions: res.data.filterOptions,
					pageSize: size,
					pageNumber,
					sortingInfo,
					filterInfo: resourceGroupFilterInfo
				};
				localStorage.setItem('resourceGroupsState ', JSON.stringify(resourceGroupsState));
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	}

	const handleEditCell = (rows: any, editData: any) => {
		const editedColumnkey = editData.column.key;
		const editedRow = rows[editData.indexes[0]];
		setEditedRows((prevEditedRows: any) => ({
			...prevEditedRows,
			rowsToUpdate: {
				...prevEditedRows.rowsToUpdate,
				[editedRow.id]: editedRow,
			},
			[editedRow.id]: {
				...prevEditedRows[editedRow.id],
				[editedColumnkey]: true,
			},
		}));
		if (isTreeView) {
			let parentRow: any = {};
			for (let i = editData.indexes[0]; i >= 0; i--) {
				if (rows[i]?.name) {
					parentRow = rows[i];
					break;
				}
			}
			const index = parentRow.values.findIndex((row: any) => row.id === editedRow.id);
			parentRow.values[index][editedColumnkey] = editedRow[editedColumnkey];
			if (parentRow.children?.[index]?.[editedColumnkey]) {
				parentRow.children[index][editedColumnkey] = editedRow[editedColumnkey];
			}
		}
		setTableDataList(rows);
	};

	const setNonEditedTableList = () => {
		if (isTreeView) {
			const defaultList: Record<string, Row> = {};
			const unGroupedList: Record<string, Row> = {};
			tableDataList.forEach((row) => {
				defaultList[row.id] = row;
				if (row.values) {
					row.values.forEach((value: Row) => {
						unGroupedList[value.id] = value;
					})
				}
			});
			nonEditedList.current = structuredClone(defaultList);
			nonEditedUnGroupList.current = structuredClone(unGroupedList);
		} else {
			nonEditedList.current = structuredClone(
				tableDataList.reduce((acc, row) => {
					acc[row.id] = row;
					return acc;
				}, {})
			);
		}
	}
	
	// save edited rows
	const handleSave = async () => {
		console.log('save', editedRows);
		// const { rowsToUpdate, ...changedCells } = editedRows;
		// try {
		//   const response = await getFinopsResourceMappingList(rowsToUpdate);
		//   if (response.status === 200) {
		// 	console.log('Data saved successfully:', response.data);
		//   }
		// } catch (error) {
		//   console.error('Error saving data:', error);
		// }
		
		// deepCopy with structuredClone
		nonEditedRows.current = structuredClone(editedRows);
		setNonEditedTableList();
		setIsEditing(false);
	};

	const handleCancel = async () => {

		setIsEditing(false);
		setTableDataList(structuredClone(Object.values(nonEditedList.current)));
		setEditedRows(structuredClone(nonEditedRows.current));
	};

	const handleEditMode = async () => {
		setNonEditedTableList();
		setIsEditing(true);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		listTableData({
			pageNumber: value,
			size: pageSize,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	};

	const handlepageSizeChange = (event: any) => {
		listTableData({
			pageNumber: 1,
			size: event.target.value,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	};
	const handleSorting = (sortingInfo: any) => {
		listTableData({
			pageNumber: page,
			size: pageSize,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	};
	const handleFilter = (filterInfo: any) => {
		setFilterInfo(filterInfo);
		listTableData({
			pageNumber: 1,
			size: pageSize,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	};

	//Handle for select all rows
	const handleHeaderSelectAll = (event: any) => {
		const isChecked = event.target.checked;

		const updatedBulkSelectedRows = isChecked
        ? tableDataList.reduce((acc: Record<string, any>, row: any) => {
			acc[row.id] = row;
              if (row.values && Array.isArray(row.values)) {
                  // For tree view, add each nested row as seperate row
                  row.values.forEach((nestedRow: any) => {
                      acc[nestedRow.id] = nestedRow;
                  });
              }
              return acc;
          }, {})
        : {};

			setBulkSelectedRows(updatedBulkSelectedRows);
	};

	const handleModalSave = (formData: any, changedCells: any, rowsToUpdate: any) => {

		setEditedRows((prev: any) => {

			const changedCellsClone = structuredClone(changedCells);
			const value = Object.keys(changedCellsClone).reduce((acc: any, key) => {
				acc[key] = {
					...acc[key],
					...changedCellsClone[key],
				};
				return acc;
			}, {...prev});
		
			nonEditedRows.current = structuredClone(value);
			return value;
		});
		setTableDataList((prev: any) => {
			const clonedValue = structuredClone(prev);
			if (isTreeView) {
				const defaultList: Record<string, Row> = {};
				const unGroupedList: Record<string, Row> = {};
				prev.forEach((row: any, idx: number) => {
					if (rowsToUpdate[row.id]) {
						defaultList[row.id] = rowsToUpdate[row.id];
						clonedValue.splice(idx, 1, rowsToUpdate[row.id]);
					} else {
						defaultList[row.id] = row;
					}
					if (row.values) {
						row.values.forEach((value: Row, valIdx: number) => {
							if (rowsToUpdate[value.id]) {
								unGroupedList[value.id] = rowsToUpdate[value.id];
								clonedValue[idx].values.splice(valIdx, 1, rowsToUpdate[value.id]);
							} else {
								unGroupedList[value.id] = value;
							}
						})
					}
				});
				nonEditedList.current = structuredClone(defaultList);
				nonEditedUnGroupList.current = structuredClone(unGroupedList);
			} else {
				nonEditedList.current = structuredClone(
					prev.reduce((acc: any, row: any, idx: number) => {
						if (rowsToUpdate[row.id]) {
							acc[row.id] = rowsToUpdate[row.id];
							clonedValue.splice(idx, 1, rowsToUpdate[row.id]);
						} else {
							acc[row.id] = row;
						}
						return acc;
					}, {})
				);
			}
			return clonedValue;
		})
		console.log('Form Data Saved:', formData);
	};

	useEffect(() => {
	console.log('bulkSelected rows', bulkSelectedRows)
	}, [bulkSelectedRows])
	

	useEffect(() => {
		setTableTitle(tableTitles[queryPreference] ?? 'Resources');
		listTableData({
			page,
			pageSize,
			sortingInfo,
			resourceGroupFilterInfo: parseFilter(filterInfo),
			queryProps
		});
	}, [queryProps]);

	const handleModalClose = () => {
		setBulkSelectedRows({});
		setIsModalOpen(false);
		const headerCheckboxInput = document.getElementById('HeaderCheckbox') as HTMLInputElement;
		if (headerCheckboxInput) {
			headerCheckboxInput.checked = false;
		}
	}

	return loading ? (
		<LoaderComponent pt={10} />
	) : (
		<>
			<Stack justifyContent="flex-end">
				{isEditing ? (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: 'auto', gap: 2 }}>
						<Button variant="pepwiseSecondaryOutline2" sx={{ width: '8rem' }} onClick={handleCancel}>
							Cancel
						</Button>
						<Button variant="pepwiseSecondary" sx={{ width: '8rem' }} onClick={handleSave}>
							Save
						</Button>
					</Box>
				) : tableDataList.length > 0 ? (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: 'auto', gap: 2 }}>
						<Button
							variant="pepwiseSecondaryOutline2"
							sx={{ width: '8rem', alignSelf: 'flex-end' }}
							onClick={handleEditMode}
						>
							Edit
						</Button>
						<Button
							disabled={Object.keys(bulkSelectedRows).length === 0}
							variant="pepwiseSecondary"
							sx={{ width: '10rem', alignSelf: 'flex-end' }}
							onClick={() => setIsModalOpen(true)}
						>
							BULK UPDATE
						</Button> 
					</Box>
				) : (
					<></>
				)}
			</Stack>
			<CustomizedDataGrid
				title={tableTitle}
				tableColumns={getColumns(nonEditedRows.current)}
				tableData={tableDataList}
				defaultColumnNames={defaultColumnNames}
				onCellEdit={handleEditCell}
				onSorting={handleSorting}
				currSorting={sortingInfo}
				filterDataOptions={filterData}
				onHeaderSelectAll={handleHeaderSelectAll}
				onFilterCheck={handleFilter}
				currFilter={filterInfo}
				page={page}
				pageSize={pageSize}
				paginationData={paginationData}
				listLength={tableDataList?.length}
				handlepageSizeChange={handlepageSizeChange}
				handlePageChange={handlePageChange}
				isDateRangeFilter={false}
				isDisabled={false}
				isFetching={loading}
				autoSetDraggedColumns={false}
				isEditing={isEditing}
			/>
			<DialogueModal
				isOpen={isModalOpen}
				title="Resources Bulk Update"
				handleClose={handleModalClose}
				width="sm"
			>
				<BulkEditModalConfirm
					onClose={handleModalClose}
					onSave={handleModalSave}
					onCancel={()=>setIsModalOpen(false)}
					bulkSelectedRows={bulkSelectedRows}
					setBulkSelectedRows={setBulkSelectedRows}
					queryPreference={queryPreference}
					columnNames={columnNames}
				/>
			</DialogueModal>
		</>
	);
};

export default TableSection;

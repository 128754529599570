import colors from 'assets/theme/base/colors';
import { DefaultOperatorName, defaultOperators } from 'react-querybuilder';

export const dropdownSxProps = {
	'&.MuiInputBase-root': {
		border: 'none'
	},
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: `${colors.dark.dark} !important`
	}
};

export const autocompleteSxProps = {
	'& .MuiInputBase-root': {
		border: 'none'
	},
	'& .MuiInputBase-input': {
		minWidth: '250px !important'
	},
	'& .MuiOutlinedInput-root': {
		py: '3px'
	},
	'& .MuiFormHelperText-root': {
		mt: 0
	},
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: `${colors.dark.dark} !important`
	}
};

export const textfieldSxProps = {
	'& .MuiInputBase-root': {
		border: 'none'
	},
	'& .MuiInputBase-input': {
		minWidth: '250px !important',
		padding: '6px'
	},
	'& .MuiOutlinedInput-root': {
		py: '3px'
	},
	'& .MuiFormHelperText-root': {
		mt: 0
	},
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: `${colors.dark.dark} !important`
	}
};

export const defaultRuleValue = {
	field: '',
	operator: '=',
	value: ''
};

//field types
export const inputFieldType = {
	textfield: 'textfield',
	autocomplete: 'autocomplete'
};

// operator mapping based on input type
export const operatorMapping: { [key: string]: DefaultOperatorName[] } = {
	number: ['=', '!=', '<', '>', '<=', '>=', 'null', 'notNull', 'between', 'notBetween'],
	string: ['in', 'notIn', '=', '!=', 'contains', 'null', 'notNull', 'beginsWith', 'endsWith'],
	boolean: ['=', '!=']
};

// get operator function
export const getOperators = (fieldType: string) => {
	return defaultOperators.filter((op) => operatorMapping[fieldType]?.includes(op.name));
};

export const ruleUnGroupOnRemove = (query: any, groupedRules: any, path: any) => {
	if (path.length <= 1 || groupedRules.length !== 3) {
		return query;
	}
	const newQuery = structuredClone(query);
	const removedRuleIdx = path[path.length - 1];

	const remainingRule = groupedRules[removedRuleIdx === 0 ? 2 : 0];
	// go back twice in the path to reach parent group and get ref to parent
	const parentGroupRef = path.slice(0, -2).reduce((acc: any, index: any) => acc.rules[index], newQuery);
	const idxOfGroupInParent = path[path.length - 2];
	// Replace the current group with the remaining rule in the parent group
	parentGroupRef.rules.splice(idxOfGroupInParent, 1, remainingRule);

	return newQuery;
};

export const sortArrayOfArrays = (arr: any) => {
	return arr.sort((a: any, b: any) => {
		for (let i = 0; i < Math.max(a.length, b.length); i++) {
			if (a[i] === undefined) return -1;
			if (b[i] === undefined) return 1;
			if (a[i] < b[i]) return -1;
			if (a[i] > b[i]) return 1;
		}
		return 0;
	});
};

export const getAllGroupPaths = (queryRules: any, currentPath: number[] = []): number[][] => {
	const paths: number[][] = [];

	queryRules.forEach((item: any, index: number) => {
		if (item.rules && Array.isArray(item.rules)) {
			paths.push(...getAllGroupPaths(item.rules, [...currentPath, index]));
		}
	});

	return [currentPath, ...paths];
};

const getAllPaths = (queryRules: any, currentPath: number[] = []): number[][] => {
	const paths: number[][] = [];

	queryRules.forEach((item: any, index: number) => {
		if (item.rules && Array.isArray(item.rules)) {
			paths.push(...getAllPaths(item.rules, [...currentPath, index]));
		} else if (typeof item !== 'string') {
			paths.push([...currentPath, index]);
		}
	});

	return paths;
};

export const arePathsConsecutive = (queryRules: any, selectedPaths: number[][]): boolean => {
	const fullPaths = getAllPaths(queryRules);

	// check if all the rules are selected or not
	if (fullPaths.length === selectedPaths.length) return false;

	// Find the first and last indexes of selectedPaths in the fullPaths
	const firstPathIndex = fullPaths.findIndex((path) => {
		return path.join() === selectedPaths[0].join();
	});
	const lastPathIndex = fullPaths.findIndex((path) => path.join() === selectedPaths[selectedPaths.length - 1].join());

	if (firstPathIndex === -1 || lastPathIndex === -1) return false;

	// Slice fullPaths to only include paths between the first and last indexes of selectedPaths
	const slicedFullPaths = fullPaths.slice(firstPathIndex, lastPathIndex + 1);

	// Compare slicedFullPaths and selectedPaths as strings
	const trimmedStr = slicedFullPaths.map((path) => path.join('.')).join(',');
	const selectedStr = selectedPaths.map((path) => path.join('.')).join(',');

	return trimmedStr === selectedStr;
};

// Function to check whether selected rule's wrapper group is fully needed to select
const isFullGroupNeeded = (sortedPaths: number[][], currentPath: number[]): boolean => {
	const { minLengthPath, isGroupSelected, groupExistMaxLength } = sortedPaths.reduce(
		(acc, path) => {
			// Find the minimum length path
			if (path.length < acc.minLengthPath.length) {
				acc.minLengthPath = path;
			}

			// Check if this group is selected or not
			if (acc.isGroupSelected === -1 && path.slice(0, -1).join('.') === currentPath.join('.')) {
				acc.isGroupSelected = 1;
			}

			// Check if there are any groups that start with currentPath
			if (path.join('.').startsWith(currentPath.join('.'))) {
				const pathlength = path.length;
				if (acc.groupExistMaxLength < pathlength) {
					acc.groupExistMaxLength = pathlength;
				}
			}

			return acc;
		},
		{
			minLengthPath: sortedPaths[0],
			isGroupSelected: -1,
			groupExistMaxLength: 0
		}
	);
	// if deep nested rule and outer rule is selected, then must select all rules inside this group
	if (isGroupSelected === -1 && groupExistMaxLength - minLengthPath.length <= 1) return false;
	if (currentPath.length + 1 > minLengthPath.length) {
		return true;
	}
	if (currentPath.length + 1 === minLengthPath.length) {
		return currentPath.join('.') !== minLengthPath.slice(0, -1).join('.');
	}
	return false;
};

const isGroupFullySelected = (group: any, selectedPaths: number[][], currentPath: number[] = []): boolean => {
	if (isFullGroupNeeded(selectedPaths, currentPath)) {
		const groupLength = group.rules?.length ?? 0;
		const filteredPaths: any = {};
		// Track indexes for each rule/group in the group
		selectedPaths.forEach((path) => {
			const pathString = path.join('.');
			if (pathString.startsWith(currentPath.join('.')) && path.length === currentPath.length + 1) {
				filteredPaths[pathString] = 1;
			}
		});
		// Since combinator is also included in groups, we need to add those numbers to selectedIndexes length
		if (Object.keys(filteredPaths).length * 2 - 1 !== groupLength) return false;
	}
	// Check nested groups within this group
	for (let i = 0; i < group.rules!.length; i++) {
		const item = group.rules![i];
		if (typeof item !== 'string' && item.rules) {
			if (!isGroupFullySelected(item, selectedPaths, [...currentPath, i])) {
				return false;
			}
		}
	}

	return true;
};

const arePathsInSameGroup = (query: any, selectedPaths: number[][]): boolean => {
	if (selectedPaths.length === 0) return false;

	// Find the minimum length among selected paths
	const minLength = Math.min(...selectedPaths.map((path) => path.length)) - 1;
	const parentPath = minLength === 0 ? 1 : minLength;

	// Slice each path up to the parent path
	const slicedPaths = selectedPaths.map((path) => path.slice(0, parentPath));
	const firstPathStr = slicedPaths[0].join('.');
	if (slicedPaths.every((path) => path.join('.') === firstPathStr)) {
		const count = {
			[firstPathStr]: 0
		};
		getAllPaths(query.rules).forEach((path) => count[path.slice(0, parentPath).join('.')]++);
		return count[firstPathStr] === slicedPaths.length;
	}
	return false;
};

// check if outer grouping is possible
const canOuterGroupSelectedRules = (query: any, selectedPaths: any[]): boolean => {
	const sortedPaths = sortArrayOfArrays([...selectedPaths]);

	// check if all selected rules are consecutive side by side
	const isConsecutive = arePathsConsecutive(query.rules, sortedPaths);
	if (!isConsecutive) return false;

	// check if all rules in a group is selected fully
	const allGroupsValid = query.rules.every((rule: any, idx: any) => {
		if (typeof rule !== 'string' && rule.rules) {
			return isGroupFullySelected(rule, sortedPaths, [idx]);
		}
		return true;
	});
	if (!allGroupsValid) return false;

	// check if selected rules are different paths or same group itself
	if (arePathsInSameGroup(query, sortedPaths)) return false;
	return true;
};

// Function to check if the selected rules can be grouped
export const canGroupSelectedRules = (query: any, selectedPaths: any) => {
	console.log('selectedPaths', sortArrayOfArrays([...selectedPaths]));
	// At least two rules need to be selected to form a group
	if (selectedPaths.length < 2)
		return {
			canGroup: false,
			groupType: ''
		};

	// Check if all selected paths are at the same depth level
	const firstDepthLevel = selectedPaths[0].length;
	if (!selectedPaths.every((path: any) => path.length === firstDepthLevel)) {
		//TODO: call canOuterGroupSelectedRules, when the function is finished.
		return {
			canGroup: false,
			groupType: 'outer'
		};
		// return {
		// 	canGroup: canOuterGroupSelectedRules(query, selectedPaths),
		// 	groupType: 'outer'
		// };
	}

	// Sort selectedPaths by their last index to check for side-by-side rules
	const sortedPaths = [...selectedPaths].sort((a: any, b: any) => a[a.length - 1] - b[b.length - 1]);

	// Check if selected paths are consecutive
	for (let i = 1; i < sortedPaths.length; i++) {
		const prevIndex = sortedPaths[i - 1][sortedPaths[i - 1].length - 1];
		const currentIndex = sortedPaths[i][sortedPaths[i].length - 1];
		const isNotSameGroup = sortedPaths[i].slice(0, -1).toString() !== sortedPaths[i - 1].slice(0, -1).toString();

		// If there's a gap between indexes, they are not side by side
		if (currentIndex !== prevIndex + 2 && !isNotSameGroup) {
			return {
				canGroup: false,
				groupType: ''
			};
		} else if (currentIndex !== prevIndex + 2 && isNotSameGroup) {
			//TODO: call canOuterGroupSelectedRules, when the function is finished.
			return {
				canGroup: false,
				groupType: 'outer'
			};
			// return {
			// 	canGroup: canOuterGroupSelectedRules(query, selectedPaths),
			// 	groupType: 'outer'
			// };
		} else if (isNotSameGroup) {
			return {
				canGroup: false,
				groupType: ''
			};
		}
	}

	// Check if the selected paths form a full existing group
	const parentGroupPath = sortedPaths[0].slice(0, -1);
	const parentGroupRef = parentGroupPath.reduce((acc: any, pathIdx: any) => acc?.rules[pathIdx], query);

	const isFullGroup =
		parentGroupRef.rules.length === sortedPaths.length * 2 - 1 &&
		sortedPaths.every((path: any, idx: number) => {
			const ruleIndex = path[path.length - 1];
			return typeof parentGroupRef.rules[ruleIndex - idx * 2] !== 'string';
		});

	if (isFullGroup) {
		return {
			canGroup: false,
			groupType: ''
		};
	}

	return {
		canGroup: true,
		groupType: 'inner'
	};
};

// Function to retrieve item based on path
const getItemByPath = (obj: any, path: number[]) =>
	path.reduce((acc, index) => (acc && acc.rules ? acc.rules[index] : null), obj);

export const outerGroupSelectedRules = (query: any, selectedPaths: any) => {
	const newQuery = structuredClone(query);
	const sortedPaths = sortArrayOfArrays([...selectedPaths]);
	const minLength = Math.min(...sortedPaths.map((path: any) => path.length));
	const newGroupPath = sortedPaths[0].length === 1 ? sortedPaths[0] : sortedPaths[0].slice(0, minLength);

	const firstPath = selectedPaths[0];
	const newGroup: any = {
		id: `group-${Date.now()}`,
		rules: []
	};

	return newQuery;
};

// Function to group selected rules
export const groupSelectedRules = (query: any, selectedPaths: any) => {
	const newQuery = structuredClone(query);
	selectedPaths.sort((a: any, b: any) => a[a.length - 1] - b[b.length - 1]);

	// get the parent of the first selected path to know where to insert the new group
	const parentPath = selectedPaths[0].slice(0, -1);
	const parentGroupRef = parentPath.reduce((acc: any, index: any) => acc.rules[index], newQuery);

	const rulesToGroup = selectedPaths
		.map((path: any, idx: any) => {
			if (
				idx !== 0 &&
				path[path.length - 1] !== 0 &&
				typeof parentGroupRef.rules[path[path.length - 1] - 1] === 'string'
			) {
				return [parentGroupRef.rules[path[path.length - 1] - 1], parentGroupRef.rules[path[path.length - 1]]];
			}
			return parentGroupRef.rules[path[path.length - 1]];
		})
		.flat();

	// Remove the selected rules from the parent rules array
	selectedPaths.forEach((path: any, idx: any) => {
		const ruleIndex = path[path.length - 1];

		if (idx !== 0 && ruleIndex !== 0 && typeof parentGroupRef.rules[ruleIndex - 1] === 'string') {
			// Mark the combinator and the rule as null
			parentGroupRef.rules[ruleIndex - 1] = null;
			parentGroupRef.rules[ruleIndex] = null;
		} else {
			// Mark only the rule as null
			parentGroupRef.rules[ruleIndex] = null;
		}
	});

	// Remove all null values from the array
	parentGroupRef.rules = parentGroupRef.rules.filter((item: any) => item !== null);

	const newGroup = {
		id: `group-${Date.now()}`,
		rules: rulesToGroup
	};

	// Add the group
	parentGroupRef.rules.splice(selectedPaths[0][selectedPaths[0].length - 1], 0, newGroup);

	return newQuery;
};

export const ungroupRules = (query: any, groupPath: any) => {
	const newQuery = structuredClone(query);

	const parentGroup = groupPath.slice(0, -1).reduce((acc: any, index: any) => acc.rules[index], newQuery);
	const groupIndex = groupPath[groupPath.length - 1];
	const groupToUngroup = parentGroup.rules[groupIndex];

	if (!groupToUngroup || !Array.isArray(groupToUngroup.rules)) {
		return newQuery;
	}

	const rulesToMove = groupToUngroup.rules;

	parentGroup.rules.splice(groupIndex, 1); // Remove the group

	// move all the group rules out of the group
	parentGroup.rules.splice(groupIndex, 0, ...rulesToMove);

	return newQuery;
};

export const getCombinatorByPath = (query: any, combinatorPath: number[]) => {
	return combinatorPath.reduce((acc: any, index: number) => {
		if (typeof acc.rules[index] === 'string') {
			return acc.rules[index];
		}
		return acc.rules[index];
	}, query);
};

import { useState, useEffect, useRef } from 'react';
import ResourceGroupListing from './resourceGroups/listing';
import ResouceMappingSummary from './widgets/resouceMappingSummary';
import './resourceMapping.scss';
import ResourceMappingIcon from '../../assets/resourceMapping.svg';
import { Stack } from '@mui/material';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';

const PlatformCostObsResouceMapping = () => {
	const [controller] = usePepwiseAuthController();
  const { role } = controller;
	const dataFetchedRef = useRef(false);
	const [sharedValue, setSharedValue] = useState('');
	const updateSharedValue = (newValue: any) => {
		setSharedValue(newValue);
	};
	useEffect(() => {
		const activeId = document.getElementById('root');
		console.log(window.location.pathname);
		if (window.location.pathname.includes('/dataestate') && role !== "Program Executive") {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
	}, []);
	useEffect(() => {
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
	});
	return (
		<div className="pcobs-summary-panel">
			<Stack>
				<span className="pcobs-menu">
					<img src={ResourceMappingIcon} className="pcobs-icon" />
					<span className="pcobs-header-name">Resource Mapping</span>
				</span>
			</Stack>
			<ResouceMappingSummary sharedValue={sharedValue} updateSharedValue={updateSharedValue}></ResouceMappingSummary>
			<ResourceGroupListing sharedValue={sharedValue} updateSharedValue={updateSharedValue}/>
		</div>
	);
};

export default PlatformCostObsResouceMapping;

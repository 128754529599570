import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Dialog, Grid, Snackbar, Typography } from "@mui/material";

import PageHeader from "components/Header/Page";
import DashboardIcon from '../../assets/material-symbols_dashboard.svg';
import { usePepwiseAuthController } from "Context/contexts/AuthContext";
import { getProgramList, getProjectListByProgram } from "service/service";
import TitleStatus from "components/Widgets/TitleStatus";
import { ReactComponent as EditIcon } from '../../assets/edit-img.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-img.svg';
import Loader from "components/Loader/loader";
import { DataPipelineModal } from "./modal-component";
import { CustomizedDataGrid } from "components/customized_data_grid";
import RowTypography from "components/Table/RowTypography";
import { Link } from "react-router-dom";
import StatusChip from "components/Table/StatusChip";
import moment from "moment";

const ProgramsDashboard = () => {
	const [openModal, setOpenModal] = useState<any>({ isOpen: false });
	const [programList, setProgramList] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [currentProgram, setCurrentProgram] = useState<any>({});
	const [currentProjectSLAFilter, setCurrentProjectSLAFilter] = useState<string>('');
	const [currentFilterType, setCurrentFilterType] = useState<string>('');
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [projectList, setProjectList] = useState<any>([]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [controller] = usePepwiseAuthController();
	const { role } = controller;
	const [anchorElDetails, setAnchorElDetails] = React.useState<null | HTMLElement>(null);
	const defaultColumnNames = ['Sectors', 'Project', 'Delayed Start', 'Long Running', 'Failed', 'Success', 'Project SLA Status', 'SLA Time (CST)'];
	const [filterData, setFilterData] = useState<any[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [searchDetails, setFilterSearchInfo] = useState([]);
	const [filterInfo, setFilterInfo] = useState([]);	
	const [toast, setToast] = useState<any>({
		open: false,
		message: ''
	});

	const messagesEndRef = useRef<null | HTMLDivElement>(null);
	const scrollToBottom = () => {
		console.log('scroll')
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const getColumns = () => {
		return [
			{
				key: 'Sectors',
				name: 'Sectors',
				formatter(props: any) {
					const sectorList = props.row.Sectors ? props.row.Sectors.split(', ') : [];
					if (sectorList && sectorList.length) {
						return sectorList.map((sector: string, idx: number) => <RowTypography sx={{ backgroundColor: 'rgba(41, 155, 255, 0.15)', color: '#fff', padding: '4px 10px', marginRight: '5px', borderRadius: '20px' }} key={idx} title={sector}>{sector}</RowTypography>);
					} else {
						return '--'
					}
				},
				minWidth: 150,
				maxWidth: 250
			},
			{
				key: 'Project_Name',
				name: 'Project',
				formatter(props: any) {
					return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${currentProgram.Program_Name}/${props.row.Project_Name}`} state={props.row} title={props.row.Friendly_Name}>{props.row.Project_Name}</Link>
				},
				minWidth: 200,
				maxWidth: 500
			},
			{
				key: 'IP_Delayed_Start',
				name: 'Delayed Start',
				formatter(props: any) {
					if (props.row.IP_Delayed_Start && props.row.IP_Delayed_Start != 'NA') {
						return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${currentProgram.Program_Name}/${props.row.Project_Name}?jobStatus=Delay Start`} state={props.row}>
							<StatusChip displayNumber={true} status='IP_Delayed_Start' value={props.row.IP_Delayed_Start ? props.row.IP_Delayed_Start : '--'} />
						</Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'IP_Long_Running',
				name: 'Long Running',
				formatter(props: any) {
					if (props.row.IP_Long_Running && props.row.IP_Long_Running != 'NA') {
						return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${currentProgram.Program_Name}/${props.row.Project_Name}?jobStatus=Long Running`} state={props.row}>
							<StatusChip displayNumber={true} status='IP_Long_Running' value={props.row.IP_Long_Running ? props.row.IP_Long_Running : '--'} />
						</Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'Failed',
				name: 'Failed',
				formatter(props: any) {
					if (props.row.Failed && props.row.Failed != 'NA') {
						return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${currentProgram.Program_Name}/${props.row.Project_Name}?jobStatus=Failure`} state={props.row}>
							<StatusChip displayNumber={true} status='JobsFailed' value={props.row.Failed ? props.row.Failed : '--'} />
						</Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'Success',
				name: 'Success',
				formatter(props: any) {
					if (props.row.Success && props.row.Success != 'NA') {
						return <Link to={`/data-pipeline/projects/${props.row.Program_ID}/${props.row.Project_Id}/${currentProgram.Program_Name}/${props.row.Project_Name}?jobStatus=Successful`} state={props.row}>
							<StatusChip displayNumber={true} status='JobsSuccess' value={props.row.Success ? props.row.Success : '--'} />
						</Link>
					} else {
						return <div style={{textAlign: 'center'}}>--</div>
					}
				},
				minWidth: 100
			},
			{
				key: 'SLA_Status',
				name: 'Project SLA Status',
				formatter(props: any) {
					if (props.row.SLA_Status == 'NA' || props.row.SLA_Status == '--') {
						return <div style={{textAlign: 'center'}}>{props.row.SLA_Status}</div>
					} else if (props.row.SLA_Status == '') {
						return ''
					}
					return <StatusChip displayNumber={true} status='ProjectSLAStatus' value={props.row.SLA_Status} />
				},
				minWidth: 150
			},
			{
				key: 'SLA_Time',
				name: 'SLA Time (CST)',
				formatter(props: any) {
					return (
						<RowTypography textTransform="capitalize">
							{props.row.SLA_Time && props.row.SLA_Date != null ? 
								props.row.SLA_Date > 0 ? moment(props.row.Schedule_Date).add(props.row.SLA_Date, 'day').format('YYYY-MM-DD')
								: moment(props.row.Schedule_Date).format('YYYY-MM-DD')
							: '' } {' '}
							{props.row.SLA_Time
								? props.row.SLA_Time < 10
									? `000${props.row.SLA_Time}`.replace(/(.{2})$/, ':$1')
									: props.row.SLA_Time < 100
										? `00${props.row.SLA_Time}`.replace(/(.{2})$/, ':$1')
										: props.row.SLA_Time.toString().replace(/(.{2})$/, ':$1')
								: '--'}
						</RowTypography>
					);
				},
				minWidth: 150
			}
		];
	};

	const handleFilter = (e: any) => {
		console.log('FILTER', e);
	};

	const handleSearch = (e: any) => {
		console.log('FILTER', e);
	};

	const handleSorting = (e: any) => {
		console.log('FILTER', e);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		getProjectsByProgram(currentProgram.Program_id, value, pageSize);
	};

	const getToastData = (data: any) => {
		setToast({
			...toast,
			message: data.data.message
		});
	};

	const handleClose = () => {
		setToast({
			...toast,
			open: false
		});
	};

	const handlepageSizeChange = (e: any) => {
		console.log('FILTER', e);
	};
	const onCreateProgramClick = useCallback(() => {
		setOpenModal({
			isOpen: true,
			type: 'Program',
			action: 'CREATE',
			data: ''
		});
	}, []);

	const handleDetailsThreeDotsClick = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(event.currentTarget);
	};
	const handleDetailsThreeDotsClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorElDetails(null);
	};

	const handleProjectSLA = (programData: any, filterType: string) => {
		setCurrentFilterType(filterType);
		setCurrentProjectSLAFilter(filterType);
		getProjectsByProgram(programData, 1, 100, filterType)
	}

	const onClickAddProject = (event: any, prgId: string) => {
		setOpenModal({
			...openModal,
			isOpen: true,
			type: 'Project',
			action: 'CREATE',
			data: { ProgramID: prgId }
		});
	}

	const handleEditClose = useCallback((closeType: any) => {
		setOpenModal({
			isOpen: false,
			closeType: closeType
		});
	}, []);

	const handleAction = (module: string, type: string, data: any, e: any) => {
		e.preventDefault();
		e.stopPropagation();
		handleDetailsThreeDotsClose(e);
		data = {
			...data,
			ProgramName: data.Program_Name,
			ProgramID: data.Program_id
		};
		switch (type) {
			case 'edit':
				setOpenModal({
					isOpen: true,
					type: module,
					action: 'EDIT',
					data: data
				});
				break;
			case 'delete':
				setOpenModal({
					isOpen: true,
					type: module,
					action: 'DELETE',
					data: data
				});
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const activeId = document.getElementById('root');
		if (window.location.pathname.includes('/data-pipeline/programs')) {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
		getProgramData();
	}, []);

	useEffect(() => {
		if (openModal && !openModal.isOpen && openModal.closeType && openModal.closeType == 'updated') {
			getProgramData();
		}
	}, [openModal]);

	const getProgramData = async () => {
		setLoading(true);
		let data: any = await getProgramList();
		setLoading(false);
		setProgramList(data.data.programData);
	};

	const getProjectsByProgram = async (data: any, page: number = 1, pageSize: number, filterType?: string) => {
		setTableLoading(true);
		setCurrentProgram(data);
		let result = await getProjectListByProgram(data.Program_id, page, pageSize, filterType);
		setTimeout(() => {
			let table = document.getElementById(`project_list${data.Program_id}`);
			if (table) {
				const rdgElement = table.querySelector('.rdg') as HTMLElement | null;
				if (rdgElement) {
					let jobsLength = result.data.projectList.length;
					let height = 80;
					if (jobsLength === 1) height = jobsLength * 80;
					else if (jobsLength > 1 && jobsLength <= 3) height = jobsLength * 65;
					else if (jobsLength > 3 && jobsLength <= 5) height = jobsLength * 55;
					else if (jobsLength > 5 && jobsLength <= 10) height = jobsLength * 50;
					else if (jobsLength > 10 && jobsLength <= 15) height = jobsLength * 40;
					else height = jobsLength * 30;
					rdgElement.style.height = `${height}px`;
				}
			}
		}, 100);
		setPaginationData(result.data.page);
		setTableLoading(false);
		const projData = result.data.projectList.map((list: any) => {
			list['activeRow'] = 0;
			const selectedSectors = list.Sectors && list.Sectors.split(', ');
			list['Sectors'] = selectedSectors && selectedSectors.length >= 10 ? ['All 10 Sectors'] : list.Sectors;
			return list;
		});
		setProjectList(projData);
		setTimeout(() => {
			scrollToBottom();
		}, 500);
	};

	return (
		<Box bgcolor="blue.darker" mt="60px">
			<PageHeader title="Programs Dashboard" icon={DashboardIcon} lastSync={'Today'} isDataRefresh={true}>
				{(role === 'Data Operations Admin' || role === 'PepWise System User') && (
					<Button variant="pepwiseSecondary" onClick={onCreateProgramClick}>
						CREATE PROGRAM
					</Button>
				)}
			</PageHeader>

			<Box p={3} mt={2}>
				{!loading ? (
					<Grid container spacing={2}>

						{programList.map((program: any, idx: number) => (
							<Grid key={idx} container item xs={12} sm={4}>
								<TitleStatus
									title={program.Program_Name}
									programId={program.Program_id}
									isEditEnabled={(role === 'Data Operations Admin' || role === 'PepWise System User') ? true : false}
									projectCount={program.ProjectCount}
									projectSLAs={[
										{ label: 'SLA Missed', count: program.SLA_Missed_Count, onBtnClick: (e) => handleProjectSLA(program, 'SLA Missed') },
										{ label: 'In Progress', count: program.In_Progress_Count, onBtnClick: (e) => handleProjectSLA(program, 'In Progress') },
										{ label: 'SLA Met', count: program.SLA_Met_Count, onBtnClick: (e) => handleProjectSLA(program, 'SLA Met') }
									]}
									statusCount={program?.In_Progress_Count ? program.In_Progress_Count + program.SLA_Missed_Count + program.SLA_Met_Count : program.SLA_Missed_Count + program.SLA_Met_Count}
									infoTooltip={{
										dateValues: [
											{ label: 'Date Created', value: program?.Created_dt ?? '' },
											{ label: 'Last Updated', value: program?.Updated_dt ?? '' }
										]
									}}
									infoOptionMenu={{
										onClick: handleDetailsThreeDotsClick,
										onClose: handleDetailsThreeDotsClose,
										menuItems: [
											{
												key: 'edit',
												label: 'Edit',
												icon: <EditIcon />,
												onMenuClick: (e) => handleAction('Program', 'edit', program, e)
											},
											{
												key: 'delete',
												label: 'Delete',
												icon: <DeleteIcon />,
												onMenuClick: (e) => handleAction('Program', 'delete', program, e)
											}
										],
										sx: { width: '20px', height: '20px' }
									}}
									onClickAddProject={(e) => onClickAddProject(e, program.Program_id)}
								/>
							</Grid>
						))
						}

					</Grid>
				) : (
					<Loader />
				)
				}
			</Box>

			{projectList.length > 0 && (
				<Box p={3} sx={{position: 'relative'}}>
					<Typography variant="overlayWidgetTitle" style={{ color: '#E5E5E5', position: 'absolute', top: '30px' }} ref={messagesEndRef}>{currentProgram.Program_Name}
						<Typography variant="subtitle2" component="span" style={{ marginLeft: '12px', color: '#F5F5F5', fontWeight: 400 }}>{currentProjectSLAFilter} Projects</Typography></Typography>

					<CustomizedDataGrid
						tableColumns={getColumns()}
						tableData={projectList}
						tableType="programs-dashboard"
						defaultColumnNames={defaultColumnNames}
						onSorting={handleSorting}
						currSorting={sortingInfo}
						filterDataOptions={filterData}
						searchDataValues={searchDetails}
						onFilterCheck={handleFilter}
						currFilter={filterInfo}
						onFilterSearch={handleSearch}
						page={page}
						pageSize={pageSize}
						paginationData={paginationData}
						listLength={projectList.length}
						handlepageSizeChange={handlepageSizeChange}
						handlePageChange={handlePageChange}
						isFetching={tableLoading}
						showExport={false}
						isDateRangeFilter={false}
						isDisabled={false}
						isShowMoreButton
					/>
				</Box>
			)}
			{tableLoading && <Loader />}

			<Dialog open={openModal.isOpen} onClose={handleEditClose} fullWidth={true} maxWidth="md">
				<DataPipelineModal handleEditClose={handleEditClose} programData={openModal} 
					getToastData={getToastData}
				/>
			</Dialog>
			<Snackbar
				open={toast && openModal.message}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				autoHideDuration={6000}
				onClick={handleClose}
				message={openModal.message}
			/>
		</Box>
	);
}

export default ProgramsDashboard;
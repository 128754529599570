import React, { useState, useEffect, useRef, useCallback } from 'react';
import { styled } from '@mui/system';
import { ReactComponent as SearchIcon } from '../../../../src/assets/search-icon.svg';
import colors from 'assets/theme/base/colors';
import LoaderComponent from 'components/Loader';
import { debounce } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import { setResourceMappingDataCache } from 'Context/contexts/cacheData/resourceMapping';

const MyComponentWrapper = styled('div')({
	position: 'relative',
	display: 'inline-block',
	width: '100%'
});

const DropdownContainer = styled('div')({
	position: 'absolute',
	top: '100%',
	left: 0,
	backgroundColor: `${colors.primary.main}`,
	width: '19rem',
	border: 'none',
	borderRadius: '4px',
	zIndex: 1000,
	maxHeight: '200px',
	overflowY: 'auto',
	overflowX: 'hidden',
	whiteSpace: 'normal'
});

const StyledInput = styled('input')({
	padding: '0.5rem',
	color: '#fff',
	width: '10rem',
	outline: 'none',
	fontSize: '14px',
	backgroundColor: 'transparent',
	border: 'none'
});

const SearchInput = styled('input')({
	padding: '5px',
	width: '18rem',
	border: 'none',
	color: '#fff',
	outline: 'none',
	fontSize: '16px',
	boxSizing: 'border-box',
	backgroundColor: `${colors.primary.main}`
});

const SearchWrapper = styled('div')({
	display: 'flex',
	alignItems: 'center',
	borderBottom: `2px solid ${colors.dark.dark}`
});

const StyledSearchIcon = styled(SearchIcon)({
	width: '3rem',
	height: '3rem'
});

const DropdownList = styled('ul')({
	padding: 0
});

const DropdownULItem = styled('li')({
	color: '#fff',
	padding: '1rem',
	display: 'block',
	textDecoration: 'none',
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: `${colors.primary.dark}`
	}
});

const DivWrapper = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
});
const InputWrapper = styled('div')({
	display: 'flex',
	border: `1px solid ${colors.dark.dark}`,
	justifyContent: 'space-between',
});

const IconWrapper = styled('div')({
	display: 'flex',
	cursor: 'pointer',
	alignItems: 'center',
});
interface Option {
	value: string;
	label: string;
}
interface SearchFilterDropdownProps {
	mappingType: string;
	cellInputStyle?: React.CSSProperties | undefined;
	columnName: string;
	value: string;
	onChange: (value: string) => void;
	isCellSelected?: boolean;
	options?: Option[];
	fieldValueOptionsCallback?: (input: string) => Promise<Option[]>;
}

const useDebouncedHandleBlur = (
	callback: (
		toggleState: boolean,
		defOpt?: Option[],
		callbackFn?: React.Dispatch<React.SetStateAction<Option[]>>,
		loadingCallback?: React.Dispatch<React.SetStateAction<boolean>>
	) => void,
	delay: number
) => {
	const timeoutRef = useRef<number | null>(null);

	const debouncedHandleBlur = (
		toggleState: boolean,
		defOpt?: Option[],
		callbackFn?: React.Dispatch<React.SetStateAction<Option[]>>,
		loadingCallback?: React.Dispatch<React.SetStateAction<boolean>>
	) => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = window.setTimeout(() => {
			callback(toggleState, defOpt, callbackFn, loadingCallback);
		}, delay);
	};

	return debouncedHandleBlur;
};

const SearchFilterDropdown: React.FC<SearchFilterDropdownProps> = ({
	isCellSelected = false,
	mappingType,
	cellInputStyle,
	columnName,
	value,
	onChange,
	options = [],
	fieldValueOptionsCallback
}) => {
	const buttonRef = useRef<HTMLInputElement>(null);
	const searchInputRef = useRef<HTMLInputElement>(null);
	const listRef = useRef<HTMLUListElement | null>(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [defaultOptions, setDefaultOptions] = useState<Option[]>(options);
	const [filteredItems, setFilteredItems] = useState<Option[]>(options);
	const [loading, setLoading] = useState(false);
	const [activeIndex, setActiveIndex] = useState<number>(-1);

	const [controller, dispatch] = useCacheDataController();
	const {resourceMapping} = controller;
	console.log('resourceMapping context', resourceMapping);

	const toggleDropdown = useDebouncedHandleBlur(
		(
			toggleState: boolean,
			defOpt?: Option[],
			callbackFn?: React.Dispatch<React.SetStateAction<Option[]>>,
			loadingCallback?: React.Dispatch<React.SetStateAction<boolean>>
		) => {
			if (toggleState && defOpt?.length === 0) {
				loadingCallback?.(true);
				fetchOptions('')
					.then((opts) => callbackFn?.(opts))
					.finally(() => loadingCallback?.(false));
			}
			setIsDropdownOpen(toggleState);
		},
		10
	);
	const isFetchOptionEnabled = !!fieldValueOptionsCallback && searchTerm.length > 2;

	const fetchOptions = async (searchInput: string): Promise<Option[]> => {
		try {
			if (fieldValueOptionsCallback) {
				const optionArray = await fieldValueOptionsCallback(searchInput);
				let searchInputValue = searchInput.toLowerCase();
				const searchOptionsKey = `${columnName}-${searchInputValue}`;// combination of search field and search input value
				// Store the fetched options in the resourceMapping context
				dispatch({
					type: 'CACHE_SEARCH_INPUT_OPTIONS_DATA',
					resourceMappingTableType: mappingType, // resource mapping table type
					searchFieldSearchInput: searchOptionsKey,
					value: optionArray // The fetched options
				});
				return optionArray;
			}
			return [];
		} catch (error) {
			console.error('Error fetching options:', error);
			return [];
		}
	};

	useEffect(() => {
		if (!isFetchOptionEnabled && defaultOptions.length > 0) {
			setFilteredItems(defaultOptions);
		}
	}, [defaultOptions]);

	const debouncedFetchOption = useCallback(
		debounce((newValue) => {
			setActiveIndex(-1); // Reset active index when filtering

			let searchInput = newValue.toLowerCase();
			// Check if the mappingType-newValue exists in the resourceMapping context
			const searchOptionsKey = `${columnName}-${searchInput}`; // combination of search field and search input value
			const existingSearchFilterOptions = resourceMapping?.[mappingType]?.[searchOptionsKey];

			// If options exist in the resourceMapping, use them from context directly
			if (existingSearchFilterOptions) {
				setFilteredItems(existingSearchFilterOptions);
			} else if (!!fieldValueOptionsCallback && newValue.length > 2 && !existingSearchFilterOptions) {
				setLoading(true);
				fetchOptions(newValue)
					.then((option) => {
						setFilteredItems(option);
					})
					.finally(() => setLoading(false));
			} else {
				setFilteredItems(defaultOptions.filter((item) => item.value.toLowerCase().includes(newValue.toLowerCase())));
			}
		}, 300),
		[fieldValueOptionsCallback, defaultOptions, resourceMapping, dispatch]
	);

	// Handle search input change with debounce
	const handleSearchInputChange = (e: any) => {
		const value = e.target.value;
		setSearchTerm(value);
		debouncedFetchOption(value);
	};

	useEffect(() => {
		if (isCellSelected && buttonRef.current) {
			buttonRef.current.focus();
		}
	}, [isCellSelected]);

	// drop down list keyboard event handling
	const handleKeyDown = (e: React.KeyboardEvent<Element>) => {
		if (!isDropdownOpen) return;

		switch (e.key) {
			case 'ArrowDown':
				e.stopPropagation();
				e.preventDefault();
				setActiveIndex((prev) => (prev < filteredItems.length - 1 ? ++prev : 0));
				break;
			case 'ArrowUp':
				e.stopPropagation();
				e.preventDefault();
				if (activeIndex === 0 && searchInputRef.current) {
					searchInputRef.current.focus();
					setActiveIndex(-1);
				} else {
					setActiveIndex((prev) => (prev > 0 ? prev - 1 : filteredItems.length - 1));
				}
				break;
			case 'Enter':
				e.preventDefault();
				if (activeIndex >= 0 && filteredItems[activeIndex]) {
					handleSelect(filteredItems[activeIndex].value);
				}
				break;
			case 'Escape':
				e.preventDefault();
				setIsDropdownOpen(false);
				break;
			// case 'Tab':
			// 	setIsDropdownOpen(false);
			// 	break;
			default:
				break;
		}
	};

	// Use the activeIndex to focus the list item
	useEffect(() => {
		if (activeIndex >= 0 && listRef.current) {
			const activeItem = listRef.current.querySelector(`[data-index="${activeIndex}"]`);
			if (activeItem) {
				(activeItem as HTMLElement).focus();
			}
		}
	}, [activeIndex]);

	const handleSelect = (item: string) => {
		onChange(item);
		setSearchTerm('');
		toggleDropdown(false);
		setActiveIndex(-1);
	};

	return (
		<MyComponentWrapper>
			<InputWrapper tabIndex={-1}>
				<StyledInput
					ref={buttonRef}
					type="text"
					value={value}
					style={cellInputStyle}
					onFocus={() => toggleDropdown(true, defaultOptions, setDefaultOptions, setLoading)}
					onBlur={() => toggleDropdown(false)}
					onClick={() => {
						!isDropdownOpen && toggleDropdown(true);
						// timeout is given to overcome the out-of-focus issue of input when react-grid handles event.
						setTimeout(() => {
							if (searchInputRef.current) {
								searchInputRef.current.focus();
							}
						}, 10);
					}}
					onChange={(e) => onChange(e.target.value)}
				/>
				<IconWrapper onClick={() => toggleDropdown(!isDropdownOpen)}>
					{isDropdownOpen ? <ArrowDropUp /> : <ArrowDropDown />}
				</IconWrapper>
			</InputWrapper>
			{isDropdownOpen && (
				<DropdownContainer tabIndex={-1} onBlur={() => toggleDropdown(false)} onFocus={() => toggleDropdown(true)}>
					<SearchWrapper>
						<StyledSearchIcon />
						<SearchInput
							ref={searchInputRef}
							type="text"
							placeholder="Search..."
							value={searchTerm}
							autoFocus
							onChange={handleSearchInputChange}
							onKeyDown={handleKeyDown}
						/>
					</SearchWrapper>
					<DropdownList ref={listRef}>
						{loading ? (
							<DivWrapper>
								<LoaderComponent size={20} />
							</DivWrapper>
						) : filteredItems.length === 0 ? (
							<DivWrapper>No options found</DivWrapper>
						) : (
							filteredItems.map((item, index) => (
								<DropdownULItem
									key={item.value}
									onClick={() => handleSelect(item.value)}
									onMouseDown={(e) => e.preventDefault()}
									data-index={index}
									tabIndex={activeIndex === index ? 0 : -1} // Only the active item is focusable
									onKeyDown={handleKeyDown}
								>
									{item.label}
								</DropdownULItem>
							))
						)}
					</DropdownList>
				</DropdownContainer>
			)}
		</MyComponentWrapper>
	);
};

export default SearchFilterDropdown;

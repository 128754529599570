import PrivateRoute from 'utils/privateRoute';
import MDIPDashboard from 'components/MDIP/mdip-executive';
import MDIPJob from 'components/MDIP/mdip-job'


const MDIPCapabilitiesRoutes = [
	{
		key: 'mdip_dash',
		route: '/mdip/dashboard',
		component: (
			<PrivateRoute
				Component={MDIPDashboard}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User'
				]}
			/>
		)
	},
	{
		key: 'mdip_job',
		route: '/mdip/job',
		component: (
			<PrivateRoute
				Component={MDIPDashboard}
				restrictedRoles={[
					'Platform Operations Admin/SRE',
					'Data Operations Admin',
					'Data Operations User',
					'D&AI Executive User'
				]}
			/>
		)
	}

];

export default MDIPCapabilitiesRoutes;

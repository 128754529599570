import axios from 'axios';
import { useEffect } from 'react';
import { url ,databricksurl} from '../../service/config';
import { dashboardUrl } from '../../service/config';
import { useLocation } from 'react-router-dom';
import {
	usePepwiseAuthController,
	setAuthenticated,
	setAuthToken,
	setAuthType,
	setRole,
	setCapabilities
  } from "Context/contexts/AuthContext";
  import { getDecodedToken } from 'service/service';
import { useRedirectLogin } from 'hooks/useRedirectHooks';

export default function LoginScreen() {
	const location = useLocation();
	const {redirectAfterLogin} = useRedirectLogin()
	const [, dispatch] = usePepwiseAuthController();

	useEffect(() => {
		axios
			.get(`${url}/user/me`)
			.then((response) => {
				if (response.status === 200) {
					const { success, data } = response.data;
					if (success === true) {
						
						const auth = getDecodedToken();
						if (auth) {
							setAuthType(dispatch, 'sso');
							setAuthToken(dispatch, auth);
							setAuthenticated(dispatch, true);
							setRole(dispatch, auth.role?.name)
							setCapabilities(dispatch, auth.role?.capabilities)
						}
						setAuthenticated(dispatch, true);
						axios.get(`${databricksurl}`).then((databricksresponse) => {console.log('Databricks Call completed');})
						sessionStorage.setItem('auth', JSON.stringify(data));
						redirectAfterLogin();

						// const grafanaWindow = window.open(dashboardUrl.grafanaFix, '_top');
                        // if (grafanaWindow) {
                        //     console.log('Close-GrafanaLoginLanding');
                        //     setTimeout(() => {
                        //         console.log('close-Grafana1LoginLanding');
                        //         grafanaWindow.close();
                        //         console.log('close-Grafana2LoginLanding');
                        //         setTimeout(() => {
                        //             window.location.href = redirectUrl;
                        //         }, 1000);
                        //     }, 10000); 
                        // }
					} else {
						window.location.href = '/sso/login';
					}
				} else if (response.status === 302) {
					const redirectUrl = response.headers.location;
					if (redirectUrl) {
						window.location.href = redirectUrl;
					} else {
						console.error('Error in response header');
					}
				}
			})
			.catch((error) => {
				console.error('Login error:', error);
			});
	}, [location]);

	return null;
}

import React from 'react';
import { Autocomplete, AutocompleteProps, TextFieldProps } from '@mui/material';
import CustomTextField from './CustomTextField';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow-triangle-down.svg';

interface CustomAutocompleteProps extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> {
	textFieldLabel?: string | JSX.Element;
	customTextFieldProps?: TextFieldProps;
	renderInput?: (params: any) => React.ReactNode;
}

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
	textFieldLabel,
	customTextFieldProps,
	renderInput,
	...rest
}) => {
	return (
		<Autocomplete
			{...rest}
			sx={{
				'& .MuiAutocomplete-popupIndicator': {
					color: 'white.main'
				},
				'& .MuiAutocomplete-clearIndicator': {
					color: 'white.main'
				},
				'& .MuiInputBase-input.Mui-disabled': {
					WebkitTextFillColor: 'light.diff'
				},
				'& .MuiInputBase-root': {
					fontSize: '14px',
					fontWeight: '500'
				},
				...rest.sx
			}}
			popupIcon={<ArrowDownIcon />}
			renderInput={
				renderInput ||
				((params) => (
					<CustomTextField
						{...params}
						label={textFieldLabel}
						{...customTextFieldProps}
						InputProps={{ ...params.InputProps, ...customTextFieldProps?.InputProps }}
					/>
				))
			}
		/>
	);
};

export default CustomAutocomplete;

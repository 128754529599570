import { useState } from 'react';
import { Box, IconButton, IconButtonProps, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MoreVert } from '@mui/icons-material';

type MenuItemType =
	| {
			key: string;
			label: string;
			icon: JSX.Element;
			onMenuClick: (event?: any) => void;
			disabled?: boolean;
			isExportButton?: false;
	  }
	| {
			key: string;
			label: string;
			icon: JSX.Element;
			onMenuClick: () => Promise<unknown>;
			disabled?: boolean;
			isExportButton: true;
	  };

export type MoreOptionMenuProps = {
	menuItems: MenuItemType[];
	isDisabled?: boolean;
	onClick?: React.MouseEventHandler<HTMLElement> | undefined;
	onClose?: ((event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void) | undefined;
} & IconButtonProps;

function ExportButton({ onExport, children }: { onExport: () => Promise<unknown>; children: string }) {
	const [exporting, setExporting] = useState(false);
	return (
		<LoadingButton
			size="small"
			loading={exporting}
			loadingPosition="start"
			loadingIndicator="Exporting…"
			disabled={exporting}
			onClick={async () => {
				setExporting(true);
				await onExport();
				setExporting(false);
			}}
		>
			{children}
		</LoadingButton>
	);
}

const MoreOptionMenu: React.FC<MoreOptionMenuProps> = ({ menuItems, onClick, onClose, ...props }) => {
	const [moreEl, setMoreEl] = useState<null | HTMLElement>(null);

	const handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
		setMoreEl(event.currentTarget);
		onClick?.(event);
	};
	const handleMoreClose = (event: any, reason: any) => {
		onClose?.(event, reason);
		setMoreEl(null);
	};

	return (
		<>
			<IconButton
				aria-label="more"
				id="more-button"
				aria-controls={!!moreEl ? 'more-menu' : undefined}
				aria-expanded={!!moreEl ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleMoreClick}
				{...props}
				sx={{
					padding: '4px 2px',
					borderRadius: 0,
					width: '42px',
					height: '45px',
					color: 'white.main',
					...props.sx
				}}
			>
				<MoreVert />
			</IconButton>
			<Menu
				id="more-menu"
				MenuListProps={{
					'aria-labelledby': 'more-button'
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
				anchorEl={moreEl}
				open={!!moreEl}
				onClose={handleMoreClose}
				sx={{
					'& .MuiPaper-root': {
						overflow: 'hidden',
						backgroundColor: '#003771 !important',
						border: '1px solid #83B9F9 !important',
						borderRadius: '4px',
						boxShadow: 'none'
					},
					'& .MuiList-root': {
						padding: '4px 0',
						height: 'auto'
					},
					'& .MuiMenuItem-root': {
						fontSize: '16px',
						fontWeight: 400,
						height: '35px',
						py: 3,
						'&:hover': {
							backgroundColor: '#002349',
							borderRadius: '4px',
							color: '#fff'
						},
						'& .text': {
							marginLeft: '5px'
						}
					}
				}}
				PaperProps={{
					style: {
						minWidth: '13ch'
					}
				}}
			>
				{menuItems.map(({ key, label, icon, onMenuClick, disabled, isExportButton }) => (
					<MenuItem
						key={key}
						disabled={disabled}
						onClick={(event) => {
							if (!isExportButton) {
								onMenuClick(event);
							}
							handleMoreClose(event, 'menuItem');
						}}
					>
						<ListItemIcon>{icon}</ListItemIcon>
						{isExportButton ? (
							<ExportButton onExport={onMenuClick}>{label}</ExportButton>
						) : (
							<Box component="span" color="white.main">
								{label}
							</Box>
						)}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default MoreOptionMenu;

import { Dispatch } from 'react';
import { CacheDataActions, CacheDataState } from '../types/CacheDataContext';
import { Option, ResourceMappingStateType } from './type';

export const resourceMappingState: ResourceMappingStateType = {
    resourceMapping:{}
};

export const resourceMappingReducer = (state: CacheDataState, action: CacheDataActions): CacheDataState => {
	switch (action.type) {
		case 'CACHE_SEARCH_INPUT_OPTIONS_DATA': {
			const { resourceMappingTableType, searchFieldSearchInput, value } = action;
            return {
                ...state,
                resourceMapping: {
                    ...state.resourceMapping,
                    [resourceMappingTableType]: {
                        ...state.resourceMapping[resourceMappingTableType],
                        [searchFieldSearchInput]: value,
                    },
                }
            };
		}
		default:
			return state;
	}
};

// Action creator to update resource mapping state
export const setResourceMappingDataCache = (
    dispatch: Dispatch<CacheDataActions>, 
    resourceMappingTableType: string, 
    searchFieldSearchInput: string, 
    value: Option[],
) => {
    dispatch({
        type: 'CACHE_SEARCH_INPUT_OPTIONS_DATA',
        resourceMappingTableType,
        searchFieldSearchInput,
        value
    });
};
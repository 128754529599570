import {
	Box,
	Grid,
	FormControl,
	FormControlLabel,
	Checkbox,
	debounce,
	Typography,
	FormLabel,
	Stack,
	TextField,
	InputAdornment,
	Tooltip
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import InfoIcon from '../../assets/lucide_info.svg';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteImage from '../../assets/delete-img-modal.svg';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import '../DataPipeline/dataPipeline.scss';
import { useState, useEffect, useMemo, ReactElement } from 'react';
import {
	createDataPipelineProgram,
	createDataPipelineProject,
	deleteDataPipelineProgram,
	deleteDataPipelineProject,
	deleteDataPipelineSchedule,
	editDataPipelineProgram,
	editDataPipelineProject,
	getClarityProjectList,
	getProgramById,
	getProgramSectorList,
	getProjectOwnerList
} from '../../service/service';
import { useNavigate } from 'react-router-dom';
import CustomTextField from 'components/Inputs/CustomTextField';
import CustomAutocomplete from 'components/Inputs/CustomAutocomplete';
import eventEmitter from 'SnackBarEventEmitter';
import { getHoursList } from 'utils/common-util';
import CustomTimePicker from 'components/Inputs/CustomTimePicker';

export function DataPipelineModal({ handleEditClose, programData, getToastData, passInfoToParent }: any) {
	const [sectorList, setSectorList] = useState<any[]>([]);
	const [loading, setLoading] = useState<any>({
		sector: false,
		project: false
	});
	const [saveBtnLoader, setSaveBtnLoader] = useState(false);
	const [isDisabled, setIsDisabled] = useState<any>({
		description: false,
		owner: false
	});
	const [inputValue, setInputValue] = useState('');
	const [projectOwner, setProjectOwner] = useState('');
	const [value, setValue] = useState(null);
	const [ownerList, setOwnerList] = useState<any>([]);
	const [isClarityProject, setIsClarityProject] = useState(false);
	const [projectList, setProjectList] = useState([]);
	const [projectOptionList, setProjectOptionList] = useState([]);
	const [dropdownLoading, setDropdownLoading] = useState<any>({
		project: false,
		owner: false
	});
	const [formData, setFormData] = useState<any>({
		id: '',
		Sectors: [],
		ProgramName: '',
		projectName: null,
		projectDescription: '',
		projectOwner: '',
		clarityId: '',
		slaDate: '',
		slaTime: '',
		reqSlaTime: '',
		friendlyName: ''
	});
	const [formErrors, setFormErrors] = useState({
		estimatedDurationError: ''
	});
	const [toastData, setToastData] = useState<any>({
		message: ''
	});

	const navigate = useNavigate();

	eventEmitter.on('showSnackbar', (message: string) => {
		setLoading(false);
		setDropdownLoading({
			project: false,
			owner: false
		});
	});

	const debouncedFetchOptions = useMemo(
		() =>
			debounce(async (value, type) => {
				setLoading({
					...loading,
					project: true
				});
				if (type === 'projectName') {
					getProjectList(value);
				} else {
					getOwnerList(formData.projectOwner);
				}
				setLoading({
					...loading,
					project: false
				});
			}, 500),
		[]
	);

	useEffect(() => {
		console.log(programData)
		if (programData.type === 'Program') {
			if (programData.action === 'EDIT') {
				getProgramDetails();
			}
		} else if (programData.type === 'Project') {
			if (programData.action === 'EDIT') {
				getProjectDetails();
				setIsClarityProject(programData.data.Clarity_Project_Id ? false : true);
			}
			getOwnerList();
			getSectorData();
		}
	}, []);

	useEffect(() => {
		if (programData.type === 'Project') {
			debouncedFetchOptions(projectOwner, 'projectOwner');
		}
	}, [projectOwner, debouncedFetchOptions]);

	useEffect(() => {
		if (programData.type === 'Project') {
			debouncedFetchOptions(inputValue, 'projectName');
		}
	}, [inputValue, debouncedFetchOptions]);

	const getProjectDetails = () => {
		const reqslatime = `${programData.data?.SLA_Time
			? programData.data?.SLA_Time < 10
				? `000${programData.data?.SLA_Time}`.replace(/(.{2})$/, ':$1')
				: programData.data?.SLA_Time < 100
				? `00${programData.data?.SLA_Time}`.replace(/(.{2})$/, ':$1')
				: programData.data?.SLA_Time.toString().replace(/(.{2})$/, ':$1')
			: 'NA'}`;
		setFormData({
			projectName: programData.data.Project_Name,
			projectDescription: programData.data.Project_Description,
			projectOwner: programData.data.Project_Owner,
			projectId: programData.data.Project_Id,
			Sectors: programData.data.Sectors ? programData.data.Sectors.split(', ') : [],
			friendlyName: programData.data.Friendly_Name,
			slaTime: `2024-07-21T${reqslatime}`,
			slaDate: programData.data.SLA_Date,
			reqSlaTime: programData.data?.SLA_Time
		});
	};

	const getOwnerList = async (name = '') => {
		setDropdownLoading({
			...dropdownLoading,
			owner: true
		});
		let result = await getProjectOwnerList(name);
		setDropdownLoading({
			...dropdownLoading,
			owner: false
		});
		let owner: any = [];
		result.data.projectOwnerNames.map((obj: string) => {
			let formattedData = (obj.includes('null') ? obj.replace('(null)', '') : obj).trim();
			owner.push(formattedData);
		});
		setOwnerList(owner);
	};

	const getProjectList = async (name = '') => {
		setDropdownLoading({
			...dropdownLoading,
			project: true
		});
		let result = await getClarityProjectList(name);
		setDropdownLoading({
			...dropdownLoading,
			project: false
		});
		setProjectList(result.data.projectList);
		setProjectOptionList(
			result.data.projectList.map((obj: any) => {
				return { label: obj.Project_Name, id: obj.Project_Code };
			})
		);
	};

	const handleInputChange = (event: any, name: any, newInputValue: any) => {
		if (name === 'projectName') {
			setInputValue(newInputValue);
			setFormData({
				...formData,
				[name]: newInputValue
			});
		} else if (name === 'projectOwner') {
			setProjectOwner(newInputValue);
			setFormData({
				...formData,
				[name]: newInputValue
			});
		} else {
			setFormData({
				...formData,
				[name]: newInputValue
			});
		}
	};

	const getProgramDetails = async () => {
		let result = await getProgramById(programData.data.Program_id || programData.data.Program_ID);
		programData.data = result.data.programData[0];
		setFormData({
			id: programData.data.Program_ID,
			// SectorName: result.data.programData[0].Sector,
			ProgramName: result.data.programData[0].program_Name
		});
	};

	const getSectorData = async () => {
		setLoading({
			...loading,
			sector: true
		});
		let sectors: any = await getProgramSectorList();
		setSectorList(sectors.data.sectors);
		setLoading({
			...loading,
			sector: false
		});
	};

	const editProgram = async () => {
		let data = {
			id: formData.id,
			// sector: formData.SectorName,
			programName: formData.ProgramName
		};
		await editDataPipelineProgram(data);
	};

	const editProject = async () => {
		let index = ownerList.findIndex((obj: any) => obj === formData.projectOwner)
		let data = {
			id: formData.projectId,
			projectOwner: formData.projectOwner,
			projectName: index >= 0 || !isClarityProject ? formData.projectName : '',
			projectDescription: formData.projectDescription,
			mulSector: formData.Sectors,
			friendlyName: formData.friendlyName,
			slaDate: formData.slaDate,
			slaTime: formData.reqSlaTime
		};
		let sectorIds: string[] = [];
		sectorList.filter((sector: any) => {
			if (formData.Sectors.includes(sector.Sector_Name)) {
				sectorIds.push(sector.Sector_Id);
			}
		})
		data['mulSector'] = sectorIds;
		await editDataPipelineProject(data);
	};

	const createProgram = async (data: any) => {
		await createDataPipelineProgram(data);

		setSaveBtnLoader(false);
	};

	const createProject = async (data: any) => {
		let result = await createDataPipelineProject(data);

		setSaveBtnLoader(false);
		return result;
	};

	const validateAliasInput = (event: any) => {
		const allowedChars = /^[a-zA-Z0-9\s_-]*$/;
		if (!allowedChars.test(event.key)) {
			event.preventDefault();
		}
	};

	const handlePaste = (event: any) => {
		event.preventDefault();
	};

	const onSubmit = async (event: any) => {
		if (saveBtnLoader) return;
		setSaveBtnLoader(true);
		event.preventDefault();
		let formPayload;
		let result: any;
		if (programData.action === 'CREATE') {
			if (programData.type === 'Program') {
				formPayload = {
					programName: formData?.ProgramName
					// sector: formData?.SectorName
				};
				result = await createProgram(formPayload);
			} else {
				let index = ownerList.findIndex((obj: any) => obj === formData.projectOwner);
				formPayload = {
					programID: programData?.data?.ProgramID || window.location.pathname.split('/')[3].replace(/%20/g, ' '),
					projectName: formData?.projectName?.label || formData?.projectName,
					clarityProId: formData?.clarityId || '',
					projectDescription: formData?.projectDescription,
					projectOwner: index >= 0 || !isClarityProject ? formData?.projectOwner : '',
					mulSector: formData?.Sectors,
					friendlyName: formData.friendlyName,
					slaDate: formData.slaDate,
					slaTime: formData.reqSlaTime
				};
				let sectorIds: string[] = [];
				sectorList.filter((sector: any) => {
					if (formData.Sectors.includes(sector.Sector_Name)) {
						sectorIds.push(sector.Sector_Id);
					}
				})
				formPayload['mulSector'] = sectorIds;

				result = await createProject(formPayload);
				if (result.data?.message) {
					getToastData(result);
				} else if(result.response?.status == 400) {
					getToastData({data: { message: result.response.data.data.message }});
				}
			}
		} else if (programData.action === 'EDIT') {
			if (programData.type === 'Program') {
				result = await editProgram();
			} else {
				if (inputValue) {
					formData['projectName'] = inputValue;
				}
				result = await editProject();
			}
		} else if (programData.action === 'DELETE') {
			if (programData.type === 'Program') {
				result = await deleteDataPipelineProgram(programData.data.Program_ID || programData.data.Program_id);
			} else if (programData.type === 'Project') {
				result = await deleteDataPipelineProject(programData.data.Project_Id);
			} else {
				const jobIds = programData.data.map((d: any) => d.jobId);
				const reqBody = {
					bulkProjectId: programData.data[0].Project_Id,
					bulkJobId: jobIds
				};
				result = await deleteDataPipelineSchedule(reqBody);
			}
		}
		setSaveBtnLoader(false);

		handleClose('updated');

		if (programData['from'] === 'view' && programData.type === 'Program') {
			navigate('/data-pipeline/programs');
		} else if (programData['from'] === 'delete' && programData.type === 'Project') {
			navigate(
				`/data-pipeline/programs/${window.location.pathname.split('/')[3]}/${window.location.pathname
					.split('/')[5]
					.replace(/%20/g, ' ')}`
			);
		} else if (programData['from'] === 'view' && programData.type === 'Project') {
			navigate(
				`/data-pipeline/programs/${window.location.pathname.split('/')[3]}/${window.location.pathname
					.split('/')[5]
					.replace(/%20/g, ' ')}`
			);
		} else if (programData['from'] === 'delete' && programData.type === 'Schedule') {
			passInfoToParent();
		}
	};

	const isFormValid = () => {
		// return formData.ProgramName && formData.ProgramName.length <= 100 && formData.SectorName;
		return formData.ProgramName && formData.ProgramName.length <= 100;
	};

	const isProjectFormValid = () => {
		// return true
		//formData.clarityId ? true :
		let index = ownerList.findIndex((obj: any) => obj === formData.projectOwner);
		return (
			formData.projectName &&
			formData.projectName?.length <= 100 &&
			formData.projectDescription &&
			formData.projectOwner &&
			formData.Sectors &&
			formData.Sectors?.length &&
			formData.friendlyName?.length <= 45 &&
			(index >= 0 || !isClarityProject)
		);
	};

	const handleOnBlur = (event: any, type: string) => {
		if (type === 'friendlyName') {
			setFormData({
				...formData,
				[type]: event.target.value.trim()
			});
		}
	};

	const handleChange = (event: any, newValue: any = '', type: string = '') => {
		if (newValue === null) {
			setIsDisabled({
				description: false,
				owner: false
			});
			if (!isClarityProject && type === 'projectName') {
				setFormData({
					...formData,
					projectName: '',
					projectOwner: '',
					projectDescription: ''
				});
				setIsDisabled({
					description: true,
					owner: true
				});
			}

			return;
		}
		if (type) {
			if (type === 'projectOwner') {
				setFormData({
					...formData,
					projectOwner: ''
				});
			} else if (type === 'projectDescription') {
				setFormData({
					...formData,
					projectDescription: ''
				});
			}

			if (type === 'projectName') {
				setIsDisabled({
					description: false,
					owner: false
				});
				if (newValue?.id) {
					let data: any = projectList.filter((obj: any) => obj.Project_Code === newValue.id);
					setFormData({
						...formData,
						projectDescription: data[0].Project_Description || '',
						projectOwner: data[0].EMPLOYEE_NAME,
						projectName: data[0].Project_Name,
						clarityId: data[0].Project_Code
					});
					setIsDisabled({
						description: true,
						owner: true
					});
				}
			} else if (type === 'projectOwner') {
				setFormData({
					...formData,
					[type]: newValue
				});
			} else if (type === 'Sectors') {
				let selectedSectors = formData.Sectors;
				if (event.target.checked) {
					selectedSectors.push(newValue);
				} else {
					selectedSectors.splice(selectedSectors.indexOf(newValue), 1);
				}
				setFormData({
					...formData,
					[type]: selectedSectors
				});
			} else if (type === 'slaTime') {
				// if (!newValue) {
				// this condition is not working for choosing the sla time so comment this.
				// 	// Handle clearing of the SLA time
				// 	setFormData({
				// 	  ...formData,
				// 	  slaTime: null,
				// 	  reqSlaTime: null,
				// 	});
				// 	return;
				//   }
				const dateObj = new Date(event);

				const hours = dateObj.getHours().toString().padStart(2, '0'); // 18 (UTC time)
				const minutes = dateObj.getMinutes().toString().padStart(2, '0'); // 40

				if (Number(hours) >= 0 && Number(minutes) >= 0) {
					const formattedDate = dateObj?.toISOString().split('T')[0];
					const reqTime = Number(`${hours}${minutes}`);
					const time = `${formattedDate}T${hours}:${minutes}`;
					setFormData({
						...formData,
						slaTime: time,
						reqSlaTime: reqTime
					});
				} else {
						setFormData({
						  ...formData,
						  slaTime: null, // Clear the time if invalid
						  reqSlaTime: null,
						});
				}
			} else if (type === 'friendlyName') {
				setFormData({
					...formData,
					[type]: event.target.value
				});
			} else if (type === 'slaDate') {
				setFormData({
					...formData,
					slaDate: event.target.value
				});
			}
		} else {
			const { name, value } =
				event && event.target
					? event.target.type === 'checkbox'
						? { name: event.target.name, value: event.target.checked }
						: event.target
					: '';
			setFormData({
				...formData,
				[name]: value
			});
		}

		setFormErrors({ estimatedDurationError: '' });
	};

	const handleClose = (type: string) => {
		handleEditClose(type);
	};

	const getTitle = () => {
		programData.action === 'DELETE'
			? `Deleting ${programData.type}: ${
					programData.type === 'Program' ? programData.data?.Program_Name : programData.data?.Project_Name
			  }`
			: programData.action === 'EDIT'
			? `Edit ${programData.type}: ${
					programData.type === 'Program' ? programData.data?.program_Name : programData.data?.Project_Name
			  }`
			: programData.type !== 'Project'
			? 'Add Project'
			: `Create New ${programData.type}`;
		switch (programData.action) {
			case 'DELETE':
				return `Deleting ${programData.type == 'Schedule' && programData.data.length > 1 ? 'Jobs' : ''} ${
					programData.type === 'Program'
						? (programData.data?.Program_Name || programData.data?.program_Name)
						: programData.type === 'Project'
						? programData.data?.Project_Name
						: programData.data.length > 1
						? ''
						: programData.data[0].jobName
				}`;
			case 'EDIT':
				return `Edit ${programData.type}: ${
					programData.type === 'Program' ? programData.data?.program_Name : programData.data?.Project_Name
				}`;

			default:
				return programData.type === 'Program' ? `Create New ${programData.type}` : 'Add Project';
		}
	};

	const getMsg = () => {
		return (
			<Box component="span">
				project from{' '}
				<Box component="span" sx={{ fontWeight: '800' }}>
					{programData.data.ProgramName}
				</Box>
				?
			</Box>
		);
	};

	const onCheckBoxChange = (e: any) => {
		const { checked } = e.target;
		setIsClarityProject(checked);
		setFormData({
			...formData,
			projectName: '',
			projectOwner: '',
			projectDescription: ''
		});
		if (checked) {
			setIsDisabled({
				description: false,
				owner: false
			});
		} else {
			setIsDisabled({
				description: true,
				owner: true
			});
		}
	};

	const getLabel = () => {
		return (
			<Box>
				Project Name*{' '}
				<Box component="span" sx={{ fontStyle: 'italic' }}>
					{' '}
					(Search & Select from Clarity)
				</Box>
			</Box>
		);
	};

	const getContent = () => {
		switch (programData.action) {
			case 'EDIT':
			case 'CREATE':
				if (programData.type === 'Program') {
					return (
						<Box component="form" onSubmit={onSubmit} noValidate p={2} bgcolor="blue.darker">
							<DialogContent>
								<Stack spacing={2}>
									<CustomTextField
										error={formData.ProgramName?.length > 100}
										label={
											<Box>
												Program Name *
												<Box component="span" fontSize="0.75rem">
													{' '}
													(Max 100 characters)
												</Box>
											</Box>
										}
										id="ProgramName"
										name="ProgramName"
										variant="outlined"
										autoComplete="off"
										value={formData.ProgramName}
										onChange={handleChange}
										fullWidth
										helperText="Program name can't contain more than 100 characters."
									/>
									{/* <FormControl fullWidth>
										<FormLabel sx={{ color: 'white.main', '&.Mui-focused': { color: 'white.main' } }}>
											Select Sector *
										</FormLabel>
										<RadioGroup name="SectorName" value={formData.SectorName} onChange={handleChange}>
											<Grid container maxWidth="sm">
												{loading.sector ? (
													<LoaderComponent />
												) : (
													sectorList.map((sector) => (
														<Grid item xs={3} key={sector}>
															<FormControlLabel
																value={sector}
																control={<Radio variant="pepwiseRadio" />}
																label={sector}
															/>
														</Grid>
													))
												)}
											</Grid>
										</RadioGroup>
									</FormControl> */}
								</Stack>
							</DialogContent>
							<DialogActions>
								<Button onClick={(e) => handleClose('close')} variant="pepwiseOutline">
									CANCEL
								</Button>
								<Button disabled={!isFormValid()} type="submit" variant="pepwiseSecondary">
									{saveBtnLoader ? 'SAVING...' : programData.action === 'EDIT' ? 'UPDATE & SAVE' : 'SAVE'}
								</Button>
							</DialogActions>
						</Box>
					);
				} else {
					return (
						<Box component="form" onSubmit={onSubmit} noValidate p={2} bgcolor="blue.darker">
							<DialogContent>
								<Grid container spacing={2}>
									{programData.action != 'EDIT' && (
										<Grid item xs={12}>
											<FormControlLabel
												label="Create New Project"
												control={
													<Checkbox
														sx={{
															color: 'white.main',
															'&.Mui-checked': {
																color: 'primary.focus',
																'& .MuiSvgIcon-root': {
																	color: 'white.main'
																}
															}
														}}
														value="all"
														checked={isClarityProject}
														onChange={(e) => {
															onCheckBoxChange(e);
														}}
													/>
												}
											/>
										</Grid>
									)}
									{!isClarityProject ? (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<CustomAutocomplete
													textFieldLabel={getLabel()}
													loading={dropdownLoading.project}
													options={projectOptionList}
													value={formData.projectName}
													disabled={programData.action == 'EDIT' ? true : false}
													onInputChange={(e, val) => handleInputChange(e, 'projectName', val)}
													onChange={(e, val) => handleChange(e, val, 'projectName')}
													noOptionsText="No project found"
												/>
											</FormControl>
										</Grid>
									) : (
										<Grid item xs={6}>
											<CustomTextField
												id="ProjectName"
												label={
													<Box display="flex" alignItems="center">
														Project Name *
														<Typography variant="body2" fontSize="0.75rem" sx={{ marginLeft: 1 }}>
															(Max 100 characters)
														</Typography>
													</Box>
												}
												name="projectName"
												variant="outlined"
												autoComplete="off"
												value={formData.projectName}
												error={formData.projectName?.length > 100}
												helperText="Project name can't contain more that 100 characters."
												onChange={handleChange}
											/>
										</Grid>
									)}
									<Grid item xs={6}>
										<CustomTextField
											id="FriendlyName"
											label={
												<Box display="flex" alignItems="center">
													Project Alias
													<Typography variant="body2" fontSize="0.75rem" sx={{ marginLeft: 1 }}>
														(Friendly Name)
													</Typography>
												</Box>
											}
											name="friendlyName"
											variant="outlined"
											autoComplete="off"
											value={formData.friendlyName}
											error={formData.friendlyName?.length > 45}
											helperText="Friendly Name can't contain more that 45 characters."
											onChange={(e) => handleChange(e, 'friendlyName')}
											onBlur={(e) => handleOnBlur(e, 'friendlyName')}
											onKeyDown={validateAliasInput}
											onPaste={handlePaste}
										/>
									</Grid>

									{!isClarityProject && programData.action != 'EDIT' ? (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<CustomTextField
													id="ProjectOwner"
													label="Project Owner"
													name="projectOwner"
													variant="outlined"
													autoComplete="off"
													value={formData.projectOwner}
													disabled
													required
													onChange={handleChange}
												/>
											</FormControl>
										</Grid>
									) : (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<CustomAutocomplete
													loading={dropdownLoading.owner}
													options={ownerList}
													noOptionsText="No user found"
													value={formData.projectOwner}
													onChange={(e, val) => handleChange(e, val, 'projectOwner')}
													onInputChange={(e, val) => handleInputChange(e, 'projectOwner', val)}
													disabled={isDisabled.owner || !isClarityProject && programData.action == 'EDIT' ? true : false}
													textFieldLabel="Project Owner *"
												/>
											</FormControl>
										</Grid>
									)}

									<Grid item xs={4}>
										<FormControl fullWidth>
											{/* <TextField id="" type='number' onChange={handleChange} value={formData.slaDate} label="" variant="outlined" /> */}

											<CustomTextField
												id="slaDate"
												label="SLA Date (Sch. Date+)"
												name="slaDate"
												variant="outlined"
												autoComplete="off"
												value={formData.slaDate}
												type="number"
												inputProps={{
													max: 1, // Maximum limit
													min: 0, // Optional: Minimum limit
													step: 1,
													maxLength: 1
												}}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<Tooltip title="Add" arrow>
																<Box style={{ height: '20px', width: '20px' }} component="img" src={InfoIcon} alt="" />
															</Tooltip>
														</InputAdornment>
													)
												}}
												//onChange={(e) => handleChange(e, '', 'slaDate')}
												onChange={(e) => {
													let value = e.target.value;

													if (value === '' || value === '0' || value === '1') {
														handleChange(e, '', 'slaDate');
													} else {
														e.target.value = '1';
														handleChange(e, '', 'slaDate');
													}
												}}
											/>
										</FormControl>
									</Grid>

									<Grid item xs={2}>
										<FormControl fullWidth>
											<CustomTimePicker
												label={'SLA Time'}
												onChange={(e) => handleChange(e, '', 'slaTime')}
												ampm={false}
												value={formData.slaTime || ''}
												renderInput={(params) => (
													<TextField
														{...params}
														InputProps={{
															...params.InputProps,
															sx: {
																'& .MuiOutlinedInput-notchedOutline': {
																	borderColor: 'white.main',
																	borderWidth: '1px',
																	borderStyle: 'solid'
																},
																'&:hover .MuiOutlinedInput-notchedOutline': {
																	borderColor: 'white.main'
																},
																'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
																	borderColor: 'white.main'
																},
																'& .MuiSvgIcon-root': {
																	color: 'white' // This will change the icon color to white
																}
															}
														}}
														sx={{
															background: 'primary.darker', // Background color to match design
															borderRadius: '8px',
															input: { color: 'white' }, // Text color
															label: { color: 'rgba(255, 255, 255, 0.7)' },
															fieldset: { borderColor: 'rgba(255, 255, 255, 0.3)' }, // Border color
															'& .MuiOutlinedInput-root': {
																color: '#fff', // White text color
																borderRadius: '5px',
																border: '1px solid white.main'
															},
															'& .MuiInputLabel-root': {
																color: 'white.main' // Light label color
															},
															'& .MuiOutlinedInput-notchedOutline': {
																borderColor: 'white.main' // Light border color
															},
															'&:hover .MuiOutlinedInput-notchedOutline': {
																borderColor: 'white.main'
															},
															'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
																borderColor: 'white.main'
															},
															'& .MuiSvgIcon-root': {
																color: 'white' // This will change the icon color to white
															}
														}}
													/>
												)}
											/>
										</FormControl>
									</Grid>
									{/* {!isClarityProject && programData.action != 'EDIT' ? (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<CustomTextField
													id="ProjectOwner"
													label="Project Owner"
													name="projectOwner"
													variant="outlined"
													autoComplete="off"
													value={formData.projectOwner}
													disabled
													required
													onChange={handleChange}
												/>
											</FormControl>
										</Grid>
									) : (
										<Grid item xs={6}>
											<FormControl fullWidth>
												<CustomAutocomplete
													loading={dropdownLoading.owner}
													options={ownerList}
													noOptionsText="No user found"
													value={formData.projectOwner}
													onChange={(e, val) => handleChange(e, val, 'projectOwner')}
													onInputChange={(e, val) => handleInputChange(e, 'projectOwner', val)}
													disabled={isDisabled.owner}
													textFieldLabel="Project Owner *"
												/>
											</FormControl>
										</Grid>
									)} */}
									<Grid item xs={12}>
									<CustomTextField
												id="ProjectDescription"
												label="Project Description"
												name="projectDescription"
												variant="outlined"
												autoComplete="off"
												disabled={!isClarityProject ? true : false}
												value={formData.projectDescription}
												required
												onChange={handleChange}
											/>
									</Grid>
									<Grid item xs={8}>
										<FormControl fullWidth>
											<FormLabel
												sx={{ marginBottom: '10px', color: 'white.main', '&.Mui-focused': { color: 'white.main' } }}
											>
												Select Sectors *
											</FormLabel>
											<Grid container maxWidth="sm">
												{loading.sector ? (
													'Loading...'
												) : (
													sectorList.map((sector) => (
														<Grid item xs={3} key={sector.Sector_Id}>
															<FormControlLabel
																label={sector.Sector_Name}
																control={
																	<Checkbox
																		checked={formData.Sectors.includes(sector.Sector_Name)}
																		onChange={(e) => {
																			handleChange(e, sector.Sector_Name, 'Sectors');
																		}}
																	/>
																}
															/>
														</Grid>
													))
												)}
											</Grid>
										</FormControl>
									</Grid>
								</Grid>
							</DialogContent>

							<DialogActions>
								<Button onClick={(e) => handleClose('close')} variant="pepwiseOutline">
									CANCEL
								</Button>
								<Button disabled={!isProjectFormValid()} type="submit" variant="pepwiseSecondary">
									{saveBtnLoader ? 'SAVING...' : programData.action === 'EDIT' ? 'UPDATE & SAVE' : 'SAVE'}
								</Button>
							</DialogActions>
						</Box>
					);
				}

			case 'DELETE':
				return (
					<Box p={2} bgcolor="blue.darker">
						<DialogContent>
							<Stack direction="row" spacing={2}>
								<Box component="img" src={DeleteImage} alt="" />
								<Stack spacing={2}>
									<Typography fontSize="28px" fontWeight={600}>
										Are you sure to delete{' '}
										{programData.type === 'Program' ? (
											'this ' + programData.type + '?'
										) : programData.type === 'Project' ? (
											<Box component="span">
												this project
												{/* <Box component="span" sx={{ fontWeight: '800' }}>
													{programData.data.ProgramName}
												</Box> */}
												?
											</Box>
										) : programData.data.length > 1 ? (
											programData.data.length + ' jobs from the project?'
										) : (
											' this job from the project?'
										)}
									</Typography>
									<Typography variant="body1">Once confirmed, the action can't be undone.</Typography>
								</Stack>
							</Stack>
						</DialogContent>

						<DialogActions>
							<Button onClick={(e) => handleClose('close')} variant="pepwiseOutline">
								NO, I WANT IT!
							</Button>
							<Button onClick={onSubmit} variant="pepwiseError">
								{saveBtnLoader ? 'DELETING...' : 'DELETE'}
							</Button>
						</DialogActions>
					</Box>
				);

			default:
				break;
		}
	};

	const getToastMsg = () => {
		return (
			<Box p={2} bgcolor="blue.darker">
				<DialogContent>
					<Typography>{toastData.message}</Typography>
				</DialogContent>

				<DialogActions>
					<Button onClick={(e) => handleClose('close')} variant="pepwiseOutline">
						NO, I WANT IT!
					</Button>
					<Button onClick={onSubmit} variant="pepwiseError">
						{saveBtnLoader ? 'Deleting...' : 'Delete'}
					</Button>
				</DialogActions>
			</Box>
		);
	};

	return (
		<>
			<DialogTitle bgcolor="primary.main" sx={{ p: '8px 16px !important' }}>
				<Box display="flex" alignItems="center">
					<Typography variant="widgetTitle" flexGrow={1}>
						{getTitle()}
					</Typography>
					<Box>
						<IconButton onClick={(e) => handleClose('close')} sx={{ color: 'white.main' }}>
							<CloseIcon />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>

			{!loading.project ? (
				toastData.message ? (
					getToastMsg()
				) : (
					getContent()
				)
			) : (
				<Box sx={{ padding: '40px 20px', textAlign: 'center', fontSize: '14px', backgroundColor: '#0c0f25' }}>
					Fetching Data...
				</Box>
			)}
		</>
	);
}

import moment from "moment";

export function getPercentageOfTwoNumbers(firstNumber: any, lastNumber: any) {
    // firstNumber indicates to Last month/year count
    // lastNumber indicates to latest month/year count
	let percentage = 0;
    if (lastNumber) {
        percentage = ((lastNumber - firstNumber) / firstNumber) * 100
    }
	return percentage;
}

export function formatByByte(selectByte: string, kpiValue: any) {
    return (selectByte === 'PB') ? kpiValue : (selectByte === 'TB') ? kpiValue*1024 : (kpiValue*1024)*1024; 
}

export function formatNumberWithCommas(number:any, isDecimal: boolean = false) {
    if (number != null && !isNaN(number)) {
        if(isDecimal) {
            const formattedNumber = Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            if (formattedNumber === "-0.00") {
                return "0.00";
            }
            return formattedNumber;
        }
        else
            return Number(number).toLocaleString('en-US');
    } else {
        if(isDecimal)
            return "0.00";
        else 
            return "0";
    }
}

export function getHoursList(timeGap: number) {
    // t = []; p=0; incr = 1, pl=0;
    // for(i = 0; i<=((60/30)*24); i++) {
    //     pl = p+''+i;
    // t.push(p+''+i+':'+(30*incr))
    // incr++;
    //     if (incr == (60/30)) {
    //         incr = 1;
    //     }
    //     if (i > 9) {
    //         p = 1;
    //     }
    // }
    return ['00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00','07:30',
        '08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30',
        '16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30'
    ]
}


export function convertShortDateToActualDateFormat(selectedDate: any) {
    let formattedDate = moment().format('YYYY-MM');
    if (selectedDate == '0M') {
        formattedDate = moment().format('YYYY-MM');
    } else if (selectedDate == '1M') {
        formattedDate = moment().subtract(1, 'months').format('YYYY-MM');
    } else if (selectedDate == '3M') {
        formattedDate = moment().subtract(3, 'months').format('YYYY-MM');
    } else if (selectedDate == '6M') {
        formattedDate = moment().subtract(6, 'months').format('YYYY-MM');
    } else if (selectedDate == '9M') {
        formattedDate = moment().subtract(9, 'months').format('YYYY-MM');
    } else if (selectedDate == '1Y') {
        formattedDate = moment().subtract(1, 'years').format('YYYY-MM');
    } else if (selectedDate == '2Y') {
        formattedDate = moment().subtract(2, 'years').format('YYYY-MM');
    } else {
        formattedDate = selectedDate
    }
    return formattedDate;
}

export const formatIsoDate = (isoString: string) => {
    const date = new Date(isoString);

    const padTo2Digits = (num: any) => String(num).padStart(2, '0');

    const month = padTo2Digits(date.getUTCMonth() + 1); // getUTCMonth() returns months from 0-11
    const day = padTo2Digits(date.getUTCDate());
    const year = String(date.getUTCFullYear()).slice(-2); // Get last two digits of year
    const hours = padTo2Digits(date.getUTCHours());
    const minutes = padTo2Digits(date.getUTCMinutes());
    const seconds = padTo2Digits(date.getUTCSeconds());

    return `${month}-${day}-${year}`;
};

export const dependInfoTransformData = (data: any) => {
    let allDatas: any = [];
    data.map((d: any, idx: number) => {
        d['className'] = 'l1-s'
        d['eleLevel'] = 'l'+ idx + '-second';
        d['priCyclic'] = d.depCyclic;
        allDatas.push(d)
        d.dependInfo && d.dependInfo.map((t: any) => {
            t['className'] = 'l1-t'
            t['eleLevel'] = 'l'+ idx + '-third';
            t['priCyclic'] = t.depCyclic;
            allDatas.push(t)
            t.dependInfo && t.dependInfo.map((f: any) => {
                f['className'] = 'l1-f'
                f['eleLevel'] = 'l'+ idx + '-fourth';
                f['priCyclic'] = f.depCyclic;
                allDatas.push(f)
                f.dependInfo && f.dependInfo.map((fv: any) => {
                    fv['className'] = 'l1-fv'
                    fv['eleLevel'] = 'l'+ idx + '-fifth';
                    fv['priCyclic'] = fv.depCyclic;
                    allDatas.push(fv)
                    fv.dependInfo && fv.dependInfo.map((six: any) => {
                        six['className'] = 'l1-six'
                        six['eleLevel'] = 'l'+ idx + '-sixth';
                        six['priCyclic'] = six.depCyclic;
                        allDatas.push(six)
                        six.dependInfo && six.dependInfo.map((sv: any) => {
                            sv['className'] = 'l1-sv'
                            sv['eleLevel'] = 'l'+ idx + '-seventh';
                            sv['priCyclic'] = sv.depCyclic;
                            allDatas.push(sv)
                            sv.dependInfo && sv.dependInfo.map((eg: any) => {
                                eg['className'] = 'l1-eg';
                                eg['eleLevel'] = 'l'+ idx + '-eighth';
                                eg['priCyclic'] = eg.depCyclic;
                                allDatas.push(eg)
                                eg.dependInfo && eg.dependInfo.map((nn: any) => {
                                    nn['className'] = 'l1-nn';
                                    nn['eleLevel'] = 'l'+ idx + '-nineth';
                                    nn['priCyclic'] = nn.depCyclic;
                                    allDatas.push(nn)                                    
                                    nn.dependInfo && nn.dependInfo.map((tn: any) => {
                                        tn['className'] = 'l1-tn';
                                        tn['eleLevel'] = 'l'+ idx + '-ten';
                                        tn['priCyclic'] = tn.depCyclic;
                                        allDatas.push(tn)
                                        tn.dependInfo && tn.dependInfo.map((el: any) => {
                                            el['className'] = 'l1-el';
                                            el['eleLevel'] = 'l'+ idx + '-eleven';
                                            el['priCyclic'] = el.depCyclic;
                                            allDatas.push(el)
                                            el.dependInfo && el.dependInfo.map((tw: any) => {
                                                tw['className'] = 'l1-tw';
                                                tw['eleLevel'] = 'l'+ idx + '-twelve';
                                                tw['priCyclic'] = tw.depCyclic;
                                                allDatas.push(tw)
                                                tw.dependInfo && tw.dependInfo.map((th: any) => {
                                                    th['className'] = 'l1-th';
                                                    th['eleLevel'] = 'l'+ idx + '-thirteen';
                                                    th['priCyclic'] = th.depCyclic;
                                                    allDatas.push(th)
                                                    th.dependInfo && th.dependInfo.map((ft: any) => {
                                                        ft['className'] = 'l1-ft';
                                                        ft['eleLevel'] = 'l'+ idx + '-fourteen';
                                                        ft['priCyclic'] = ft.depCyclic;
                                                        allDatas.push(ft)
                                                        ft.dependInfo && ft.dependInfo.map((ff: any) => {
                                                            ff['className'] = 'l1-ff';
                                                            ff['eleLevel'] = 'l'+ idx + '-fifteen';
                                                            ff['priCyclic'] = ff.depCyclic;
                                                            allDatas.push(ff)
                                                            ff.dependInfo && ff.dependInfo.map((st: any) => {
                                                                st['className'] = 'l1-st';
                                                                st['eleLevel'] = 'l'+ idx + '-sixteen';
                                                                st['priCyclic'] = st.depCyclic;
                                                                allDatas.push(st)
                                                            })
                                                        })
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
    })
    return allDatas;
}
import { useEffect, useRef, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { isEnvTypePROD } from '../../service/config';
import '../TableauObs/Summary/Summary.scss';
import moment from 'moment';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	dataEstateExecutiveSummary,
	dataEstateOverallSummary,
	fetchAnnualSpendDataSourceIncidents,
	fetchAzureMonthlyCost,
	fetchBIApplicationSummary,
	fetchDEStorageSummary,
	fetchDatabricksClustersCount,
	getDecodedToken,
	getOverviewSummaryMetrics,
	getSector
} from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import '../DataEstate/data-estate.scss';
import ArrowUp from '../../assets/ArrowUp.svg';
import ArrowDown from '../../assets/ArrowDown.svg';
import ArrowIcon from '../../assets/Arrow 253.svg';
import ArrowIconRed from '../../assets/growth arrow down.svg';
import ArrowIconUpRed from '../../assets/growth arrow up_red.svg';
import ArrowIconDownGreen from '../../assets/growth arrow down_green.svg';
import NumberWidget from '../Widgets/NumberWidget';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import { getPercentageOfTwoNumbers, formatNumberWithCommas } from '../../utils/common-util';
import useDataEstateSideDrawer from './SideDrawers/useDrawerComponent';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import { MetricDetails } from 'Context/contexts/cacheData/DataEstate/type';
import {
	setDataBricksClusters,
	setDEStorageSummary,
	setExecutiveMetricDetails,
	setMetricDetails,
	setOverviewMetrics,
	setPowerBiUserCount,
	setTableauPrevUserCount,
	setTableauUserCount,
	setTeradataDetails,
	setPowerbiUsageCount,
	setPowerBIPreviousUsageCount
} from 'Context/contexts/cacheData/DataEstate';
import { getCurrentAndPreviousMonthNames } from '../../utils/currentAndPreviousmonth'

const OverallSummary = () => {
	const navigate = useNavigate();
	const { handleSetComponent } = useDataEstateSideDrawer();
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	const [tableauUsersGrowth, setTableauUsersGrowth] = useState(0);
	const [powerBiGrowth, setPowerBiGrowth] = useState(0);
	const [powerbiUsageGrowth, setPowerbiUsageGrowth] = useState(0);
	//const [powerbiUsageCount, setPowerbiUsageCount] = useState(0);
	//const [powerBiPreviousUsageCount, setPowerBIPreviousUsageCount] = useState(0)

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);

	const [controller, dispatch] = useCacheDataController();
	const {
		metricDetails,
		executiveMetricDetails,
		databricksClusters,
		deStorageSummary,
		overviewMetrics,
		tableauUserCount,
		tableauPrevUserCount,
		powerBiUserCount,
		teraDataJobs,
		teraDataGrowthSize,
		teraDataSize,
		teraDataPrevSize,
		powerbiUsageCount,
		powerBiPrevUserCount
	} = controller;

	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if (sectorOptionsList.length === checkedList.length + newlyChecked.length) {
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		setCurrSector(checkedList);
		handleClose(event);
	};
	

	const { currentMonth, previousMonth } = getCurrentAndPreviousMonthNames();

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
			setSectorOptionsList(filteredSectorOptions);
		}
	}
	const [loading, setLoading] = useState(false);
	const [executiveLoading, setExecutiveLoading] = useState(false);
	const [OutageDuration, setOutageDuration] = useState<any>(null);
	const [azureMonthlyCostDetails, setAzureMonthlyCostDetails] = useState<MetricDetails | null>(null);

	const handleNavigateStorage = () => {
		navigate('/dataestate/storagesummary');
	};

	const handleNavigateDataProcessing = () => {
		navigate('/dataestate/data-processing');
	};

	const handleNavigateQueries = () => {
		navigate('/dataestate/queriessummary');
	};

	const handleNavigateBiApps = () => {
		navigate('/dataestate/biApplicationSummary');
	};

	const handleNavigateService = () => {
		navigate('/dataestate/service-request');
	};

	const handleNavigateIncident = () => {
		navigate('/dataestate/majorIncident');
	};

	const handleNavigateCost = () => {
		navigate('/dataestate/costSummary');
	};

	function splitHrsToDays(numberOfHours: any) {
		var Days = Math.floor(numberOfHours / 24);
		var Remainder = numberOfHours % 24;
		var Hours = Math.floor(Remainder);
		var Minutes = Math.floor(60 * (Remainder - Hours));
		return { Days: Days, Hours: Hours, Minutes: Minutes };
	}

	const handleDeStorageData = (data: any) => {
		let teradataSize = data?.dataStoredInTeradata?.[0]?.['VALUE'] ?? 0;
		let teradataPrevSize = data?.dataStoredInTeradataPrev?.[0]?.['VALUE'] ?? 0;
		setTeradataDetails(dispatch, teradataPrevSize, 'TERADATA_PREV_SIZE');
		setTeradataDetails(dispatch, teradataSize, 'TERADATA_SIZE');

		if (teradataPrevSize) {
			setTeradataDetails(dispatch, getPercentageOfTwoNumbers(teradataPrevSize, teradataSize), 'TERADATA_GROWTH_SIZE');
		}
	};

	async function fetchDEStorageSummaryFunc() {
		if (deStorageSummary === null) {
			fetchDEStorageSummary(sector, '0M')
				.then((res) => {
					setDEStorageSummary(dispatch, res.data);
					handleDeStorageData(res.data);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			handleDeStorageData(deStorageSummary);
		}
	}

	async function loadDatabricksClustersCount() {
		if (databricksClusters === null) {
			setLoading(true);
			try {
				const res = await fetchDatabricksClustersCount('', '', true, 'overallDatabrickscluster');
				if (res.success) {
					let count = res.data.databricksClustersCount.length ?
						res.data.databricksClustersCount[0].ClusterCount : 0;
					setDataBricksClusters(dispatch, count);
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		}
	}

	async function dataestateOverallSummaryFunc() {
		if (metricDetails === null) {
			setLoading(true);
			try {
				const res = await dataEstateOverallSummary();
				if (res.success) {
					setMetricDetails(dispatch, res.data);
					// setPowerBiGrowth(res.data.activeReports / 1000);
					// hardcodded 0 as by Pepsico
					setPowerBiGrowth(0);
					fetchAnnualSpendDataSourceIncidents('teradata-jobs', '0M', sector)
						.then((res) => {
							setTeradataDetails(dispatch, res?.data?.result[0]?.count ?? 0, 'TERADATA_JOBS');
						})
						.catch((error) => {
							console.error(error);
						});
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		}
	}

	async function dataestateExecutiveSummaryFunc() {
		if (executiveMetricDetails === null) {
			setExecutiveLoading(true);
			try {
				const res = await dataEstateExecutiveSummary();
				if (res.success) {
					setExecutiveMetricDetails(dispatch, res.data);
					setOutageDuration(splitHrsToDays(res.data.outageDuration));
				}
				setExecutiveLoading(false);
			} catch (error) {
				setExecutiveLoading(false);
			}
		} else {
			setOutageDuration(splitHrsToDays(executiveMetricDetails.outageDuration));
		}
	}

	async function dataestateAzureMonthlyCostFunc() {
		setExecutiveLoading(true);
		try {
			const res = await fetchAzureMonthlyCost();
			if (res.success) {
				setAzureMonthlyCostDetails(res.data);
			}
			setExecutiveLoading(false);
		} catch (error) {
			setExecutiveLoading(false);
		}
	}

	async function getOverviewSummaryMetricsFunc() {
		if (overviewMetrics === null) {
			setLoading(true);
			try {
				const res = await getOverviewSummaryMetrics();
				if (res.success) {
					setOverviewMetrics(dispatch, res.data[0]);
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		}
	}

	async function getTableauUserCount() {
		if (tableauUserCount === null || tableauPrevUserCount === null) {
			setLoading(true);
			try {
				const res = await fetchBIApplicationSummary('tableau-users', '', '0M', moment().format());

				if (res.success) {
					// hardcoding the value for demo
					// let count = res?.data?.result[0]?.TotalRecords;
					let count = 13266 + 11018;
					const previousData = await fetchBIApplicationSummary('tableau-users', '', '1M', moment().format());
					// let previousCount = previousData?.data?.result[0]?.TotalRecords;
					let previousCount = 23955;
					setTableauUserCount(dispatch, count);
					setTableauPrevUserCount(dispatch, previousCount);
					setTableauUsersGrowth(((count - previousCount) / previousCount) * 100);
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		} else {
			setTableauUsersGrowth(((tableauUserCount - tableauPrevUserCount) / tableauPrevUserCount) * 100);
		}
	}

	async function getPowerBIUsageCount() {
		if (powerbiUsageCount === null || powerBiPrevUserCount === null) {
			setLoading(true);
			try {
				const res = await fetchBIApplicationSummary('powerbi-usage', '', '1M', moment().format());

				if (res.success) {
					let count = res?.data?.result[0]?.total_usage;
					const previousData = await fetchBIApplicationSummary('powerbi-usage', '', '2M', moment().format());
					let previousCount = previousData?.data?.result[0]?.total_usage;
					setPowerbiUsageCount(dispatch, count);
					setPowerBIPreviousUsageCount(dispatch, previousCount);
					setPowerbiUsageGrowth(((count - previousCount) / previousCount) * 100);
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		} else {
			setPowerbiUsageGrowth(((powerbiUsageCount - powerBiPrevUserCount) / powerBiPrevUserCount) * 100);
		}
	}
	async function getPowerBIUserCount() {
		if (powerBiUserCount === null) {
			setLoading(true);
			try {
				const res = await fetchBIApplicationSummary('powerbi-users', '', '0M', moment().format());

				if (res.success) {
					let count = res?.data?.result[0]?.PowerBIUsersCount;
					setPowerBiUserCount(dispatch, (count / 1000).toFixed(2));
				}
				setLoading(false);
			} catch (error) {
				setLoading(false);
			}
		}
	}

	// async function getCrossEnvData() {
	// 	setLoading(true);
	// 	try {
	// 		const res = await fetchCrossEnvData('tableau_users', 'Tableau', '0M');
	// 		if (res.success) {
	// 			let count = res?.data[0]?.KPI_VALUE + res?.data[1]?.KPI_VALUE;
	// 			setTableauUsers(count);
	// 			setTableauUsersGrowth(res?.data[0]?.dataGrowth);
	// 		}
	// 		setLoading(false);
	// 	} catch (error) {
	// 		setLoading(false);
	// 	}
	// }
	const dateNew = new Date();
	const getMonthNames = () => {
		const monthNames = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

		// Get the current month and the previous month
		const currentMonthIndex = dateNew.getMonth();
		const completedMonthIndex = (currentMonthIndex - 1 + 12) % 12;
		const completedPreviousMonthIndex = (currentMonthIndex - 2 + 12) % 12;

		const currentMonthName = monthNames[currentMonthIndex];
		const completedMonthName = monthNames[completedMonthIndex];
		const completedPreviousMonthName = monthNames[completedPreviousMonthIndex];

		return { currentMonthName, completedMonthName, completedPreviousMonthName };
	};

	useEffect(() => {
		if (!loading) dataestateOverallSummaryFunc();
		dataestateExecutiveSummaryFunc();
		fetchDEStorageSummaryFunc();
		loadDatabricksClustersCount();

		// // Summary APIs

		if (hasDrillDown) {
			const selectedSector = localStorage.getItem('selectedSector');
			if (selectedSector) {
				const selectedSec = JSON.parse(selectedSector).length ? JSON.parse(selectedSector) : '';
				setCheckedList(selectedSec);
				setCurrSectorFilter(selectedSec);
			}
		} else {
			localStorage.removeItem('selectedSector');
		}

		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		// if (userSector === 'all') fetchSectorOptionsData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/executive-summary') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		//Metrics
		getOverviewSummaryMetricsFunc();
		getTableauUserCount();
		getPowerBIUserCount();
		getPowerBIUsageCount();
		// getCrossEnvData();
	}, []);
	
	const metricDetailsOverSize: number =
		metricDetails === null || metricDetails.overallGrowth === null
			? 0
			: metricDetails.overallGrowth + (teraDataGrowthSize ?? 0);
	
	return (
		<Box mt="120px" p={4}>
			<Grid container spacing={2}>
				<Grid container item xs={12} sm={6} xl={4}>
					<WidgetCardContainer title="Size">
						<Grid item xs={12} display="flex">
							<NumberWidget
								title="Data Stored"
								tooltipLabel="Data Stored Across D&AI Platforms (All environments) and Teradata (Prod)"
								number={
									metricDetails && metricDetails !== null
										? formatNumberWithCommas((metricDetails.dataStoredDA + (teraDataSize ?? 0)).toFixed(2), true)
										: 0
								}
								isLoading={!metricDetails || metricDetails === null}
								footer={{
									footerLabel: 'Click to look at Data Storage breakdown',
									footerLink: '/dataestate/storagesummary'
								}}
								units="PB"
								// handleEvent={handleNavigateStorage}
								handleEvent={() =>
									handleSetComponent('DAI_PLATEFORMS', {
										title: 'Data Stored',
										sector: [],
										selectedDate: '2023-01'
									})
								}
							/>
						</Grid>
						<Grid item xs={12} display="flex">
							<NumberWidget
								title="Data Trend"
								tooltipLabel={
									metricDetails && teraDataSize
										? `Month over month data growth across D&AI platforms and Teradata PROD. (${moment().format("MMM 'YY")} - Azure: ${formatNumberWithCommas(
										metricDetails.dataStoredDA.toFixed(2),
										true
									)} PB Teradata: ${formatNumberWithCommas(
											teraDataSize.toFixed(2),
											true
									)} PB & ${moment().subtract(1, 'months').format("MMM 'YY")} - Azure: ${formatNumberWithCommas(
											metricDetails.storagePrevMonCount.toFixed(2)
									)} PB Teradata: ${teraDataPrevSize?.toFixed(2)} PB)`
									: ''
								}
								number={metricDetails !== null ? formatNumberWithCommas(metricDetailsOverSize.toFixed(2), true) : 0}
								isLoading={!metricDetails || metricDetails === null}
								footer={{
									footerLabel: 'Click to look at Data Storage breakdown',
									footerLink: '/dataestate/storagesummary'
								}}
								units="%"
								handleEvent={() =>
									handleSetComponent('OVERALL_DATA_GROWTH', {
										title: 'Data Trend',
										sector: [],
										selectedDate: '0M'
									})
								}
								// bgColor={
								// 	!!metricDetails &&
								// 	!!metricDetailsOverSize &&
								// 	formatNumberWithCommas(metricDetailsOverSize.toFixed(2), true) !== '0.00'
								// 		? metricDetailsOverSize > 0
								// 			? 'error.main'
								// 			: 'success.main'
								// 		: undefined
								// }
								// unitIcon={
								// 	!!metricDetails &&
								// 	!!metricDetailsOverSize &&
								// 	formatNumberWithCommas(metricDetailsOverSize.toFixed(2), true) !== '0.00'
								// 		? metricDetailsOverSize > 0
								// 			? ArrowUp
								// 			: ArrowDown
								// 		: null
								// }
								color={
									metricDetailsOverSize !== null && metricDetailsOverSize !== 0 && formatNumberWithCommas(metricDetailsOverSize.toFixed(2), true) !== '0.00'
										? metricDetailsOverSize > 0
											? 'error.main'
											: 'success.main'
										: 'text.main'
								}
								unitIcon={
									metricDetailsOverSize !== null && metricDetailsOverSize !== 0 && formatNumberWithCommas(metricDetailsOverSize.toFixed(2), true) !== '0.00'
										? metricDetailsOverSize > 0
											? ArrowIconUpRed
											: ArrowIconDownGreen
										: null
								}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid container item xs={12} sm={6} xl={4}>
					<WidgetCardContainer title={['Processing']}>
						<Grid item xs={12} display="flex">
							<NumberWidget
								title={'ADF Pipelines & Teradata Jobs'}
								tooltipLabel="Total data processing pipelines in D&AI across Azure (All environments) and Teradata Jobs (Prod)"
								number={
									metricDetails !== null && teraDataJobs !== null
										? formatNumberWithCommas((metricDetails.dataPipelineCount + teraDataJobs) / 1000, true)
										: 0
								}
								isLoading={metricDetails === null || teraDataJobs === null}
								footer={{
									footerLabel: 'Click to look at processing breakdown',
									footerLink: '/dataestate/data-processing'
								}}
								units="k"
								// handleEvent={handleNavigateDataProcessing}
								handleEvent={() =>
									handleSetComponent('TOTAL_PIPELINES', {
										title: 'ADF Pipelines & Teradata Jobs',
										sector: [],
										selectedDate: '0M'
									})
								}
								// isInProgress
							/>
						</Grid>
						<Grid item xs={12} display="flex">
							<NumberWidget
								title="Databricks Clusters YTD"
								tooltipLabel={'Total compute clusters in Databricks (All environments)'}
								number={
									databricksClusters !== null 
										? formatNumberWithCommas((databricksClusters) / 1000000, true)
										: 0
								}
								isLoading={databricksClusters === null}
								footer={{
									footerLabel: 'Click to look at processing breakdown',
									footerLink: '/dataestate/data-processing'
								}}
								// number={10.18}
								units="MM"
								//handleEvent={handleNavigateDataProcessing}
								handleEvent={() =>
									handleSetComponent('TOTAL_CLUSTER', {
										title: 'Databricks Clusters',
										sector: [],
										selectedDate: '2023-01'
									})
								}
								// isInProgress
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid container item xs={12} xl={4}>
					<WidgetCardContainer title="BI Usage">
						<Grid item xs={12} sm={6} md={3} xl={6} display="flex">
							<NumberWidget
								title="Power BI"
								tooltipLabel="Total Power BI users in Prod"
								number={(powerBiUserCount && powerBiUserCount !== null ? powerBiUserCount : 0).toLocaleString(
									undefined,
									{
										minimumFractionDigits: 0
									}
								)}
								isLoading={!powerBiUserCount}
								units="k"
								handleEvent={() =>
									handleSetComponent('POWERBI_USERS', {
										title: 'Power BI',
										sector: [],
										selectedDate: '2023-01'
									})
								}
								// isInProgress
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} xl={6} display="flex">
							<NumberWidget
								title="Tableau"
								tooltipLabel={'Total Tableau users in PROD (Licenses are not included)'}
								number={(tableauUserCount ? (tableauUserCount / 1000).toFixed(2) : 0).toLocaleString(undefined, {
									minimumFractionDigits: 0
								})}
								isLoading={!tableauUserCount}
								footer={{
									footerLabel: 'Click to look at processing breakdown',
									footerLink: '/dataestate/biApplicationSummary'
								}}
								units="k"
								// handleEvent={handleNavigateBiApps}
								handleEvent={() =>
									handleSetComponent('TABLEAU_USERS', {
										title: 'Tableau',
										sector: [],
										selectedDate: '2023-01'
									})
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} xl={6} display="flex">
							<NumberWidget
								title="Power BI Trend"
								tooltipLabel={`Month over month usage growth in Power BI - ${currentMonth} Month: ${formatNumberWithCommas(powerbiUsageCount)} & 
								${previousMonth} Month: ${formatNumberWithCommas(powerBiPrevUserCount)}`}
								// Commented as per request by Pepsico
								// number={metricDetails !== null ? (metricDetails.activeReports / 1000).toFixed(2) : 0}
								isLoading={!metricDetails || metricDetails === null}
								number={powerbiUsageGrowth ? formatNumberWithCommas(powerbiUsageGrowth.toFixed(2), true) : 0}
								units="%"
								handleEvent={() =>
									handleSetComponent('POWERBI_USER_GROWTH', {
										title: 'Power BI Trend',
										sector: [],
										selectedDate: '0M'
									})
								}
								// bgColor={
								// 	!!powerbiUsageGrowth && formatNumberWithCommas(powerbiUsageGrowth.toFixed(2), true) !== '0.00'
								// 		? powerbiUsageGrowth > 0
								// 			? 'success.main'
								// 			: 'error.main'
								// 		: undefined
								// }
								// unitIcon={
								// 	!!powerbiUsageGrowth && formatNumberWithCommas(powerbiUsageGrowth.toFixed(2), true) !== '0.00'
								// 		? powerbiUsageGrowth > 0
								// 			? ArrowUp
								// 			: ArrowDown
								// 		: null
								// }
								color={
									powerbiUsageGrowth !== null && powerbiUsageGrowth !== 0 && formatNumberWithCommas(powerbiUsageGrowth.toFixed(2), true) !== '0.00'
										? powerbiUsageGrowth > 0
											? 'success.main'
											: 'error.main'
										: 'text.main'
								}
								unitIcon={
									powerbiUsageGrowth !== null && powerbiUsageGrowth !== 0 && formatNumberWithCommas(powerbiUsageGrowth.toFixed(2), true) !== '0.00'
										? powerbiUsageGrowth > 0
											? ArrowIcon
											: ArrowIconRed
										: null
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} xl={6} display="flex">
							<NumberWidget
								title="Tableau Trend"
								tooltipLabel={`Month over month user growth in Tableau - Current Month: ${formatNumberWithCommas(
									tableauUserCount
								)} & Last Month: ${formatNumberWithCommas(tableauPrevUserCount)}`}
								number={tableauUsersGrowth ? formatNumberWithCommas(tableauUsersGrowth.toFixed(2), true) : 0}
								isLoading={!tableauUsersGrowth || tableauUsersGrowth === null}
								units="%"
								handleEvent={() =>
									handleSetComponent('TABLEAU_USER_GROWTH', {
										title: 'Tableau Trend',
										sector: [],
										selectedDate: '0M'
									})
								}
								// bgColor={
								// 	!!tableauUsersGrowth && formatNumberWithCommas(tableauUsersGrowth.toFixed(2), true) !== '0.00'
								// 		? tableauUsersGrowth < 0
								// 			? 'success.main'
								// 			: 'error.main'
								// 		: undefined
								// }
								// unitIcon={
								// 	!!tableauUsersGrowth && formatNumberWithCommas(tableauUsersGrowth.toFixed(2), true) !== '0.00'
								// 		? tableauUsersGrowth > 0
								// 			? ArrowUp
								// 			: ArrowDown
								// 		: null
								// }
								color={
									tableauUsersGrowth !== null && tableauUsersGrowth !== 0 && formatNumberWithCommas(tableauUsersGrowth.toFixed(2), true) !== '0.00'
										? tableauUsersGrowth > 0
											? 'error.main'
											: 'success.main'
										: 'text.main'
								}
								unitIcon={
									tableauUsersGrowth !== null && tableauUsersGrowth !== 0 && formatNumberWithCommas(tableauUsersGrowth.toFixed(2), true) !== '0.00'
										? tableauUsersGrowth > 0
											? ArrowIconUpRed
											: ArrowIconDownGreen
										: null
								}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid container item xs={12} xl={6}>
					<WidgetCardContainer title="Sustain YTD">
						{/* <Grid item xs={12} sm={4} display="flex">
							<NumberWidget
								title="Major INC Open"
								tooltipLabel="Total D&AI major incidents open"
								number={executiveMetricDetails?.incidentsOpen ?? ''}
								isLoading={!executiveMetricDetails || executiveLoading}
							/>
						</Grid> */}
						<Grid item xs={12} sm={6} display="flex">
							<NumberWidget
								title="Major INC YTD"
								tooltipLabel="Year to date D&AI Major INC"
								number={executiveMetricDetails !== null ? executiveMetricDetails?.incidentOpenedLastMonth : 1}
								isLoading={!executiveMetricDetails || executiveLoading}
								handleEvent={handleNavigateIncident}
								// handleEvent={() => handleSetComponent('MAJOR_INCIDENTS_LAST_MONTH', {
								// 	title: 'Major INC Open',
								// 	sector: [],
								// 	selectedDate: "2023-01",
								// })}
							/>
						</Grid>
						<Grid item xs={12} sm={6} display="flex">
							<NumberWidget
								title="Platform Outages YTD"
								tooltipLabel="Year to date D&AI Outages"
								number={executiveMetricDetails !== null ? executiveMetricDetails?.outage : 1}
								isLoading={!executiveMetricDetails || executiveLoading}
								footer={{
									footerLabel: 'Click to look at processing breakdown',
									footerLink: '/dataestate/service-request'
								}}
								handleEvent={() =>
									handleSetComponent('OUTAGES', {
										title: 'Platform Outages YTD',
										sector: [],
										selectedDate: '2023-01'
									})
								}
							/>
						</Grid>
						<Grid item xs={12} display="flex">
							<NumberWidget
								title="Unplanned Platform Downtime"
								tooltipLabel="Year to date unplanned Downtime"
								number={
									OutageDuration !== null ? [OutageDuration.Days, OutageDuration.Hours, OutageDuration.Minutes] : ''
								}
								isLoading={!OutageDuration || executiveLoading}
								units={OutageDuration !== null ? ['days', 'hours', 'minutes'] : null}
								// handleEvent={handleNavigateService}
								footer={{
									footerLabel: 'Click to look at processing breakdown',
									footerLink: '/dataestate/service-request'
								}}
								handleEvent={() =>
									handleSetComponent('UNPLANNED_DOWNTIME', {
										title: 'Unplanned Platform Downtime YTD',
										sector: [],
										selectedDate: '2023-01'
									})
								}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
				<Grid container item xs={12} xl={6}>
					<WidgetCardContainer title="Cost">
						<Grid item xs={12} display="flex">
							<NumberWidget
								isCost
								title="YTD Spend"
								tooltipLabel="Total D&AI platform cost (Azure, Databricks and Teradata)"
								number={
									overviewMetrics?.annualCostAcrossDA ? (overviewMetrics.annualCostAcrossDA / 1000000).toFixed(2) : '0'
								}
								units="MM"
								isLoading={!overviewMetrics}
								handleEvent={() =>
									handleSetComponent('YTD_SPEND', {
										title: 'YTD Spend',
										sector: [],
										selectedDate: '2023-01'
									})
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6} display="flex">
							<NumberWidget
								isCost
								title="RI Savings"
								tooltipLabel="Year to Date Cost avoidance by using Reserved Instances"
								number={
									overviewMetrics?.reservedInstanceSavingsYTD
										? formatNumberWithCommas(overviewMetrics?.reservedInstanceSavingsYTD.toFixed(2), true)
										: 0
								}
								units="MM"
								isLoading={!overviewMetrics}
								handleEvent={!isEnvTypePROD ? handleNavigateCost : undefined}
							/>
						</Grid>
						<Grid item xs={12} sm={6} display="flex">
							<NumberWidget
								title="Avg Monthly Cost Trend"
								tooltipLabel="Month over month platform cost growth"
								units="%"
								number={
									overviewMetrics?.avg_monthly_cost_growth ? overviewMetrics.avg_monthly_cost_growth.toFixed(2) : '0'
								}
								// unitIcon={
								// 	!!overviewMetrics?.avg_monthly_cost_growth &&
								// 	overviewMetrics.avg_monthly_cost_growth.toFixed(2) != '-0' && overviewMetrics.avg_monthly_cost_growth.toFixed(2) != '0'
								// 		? overviewMetrics?.avg_monthly_cost_growth > 0
								// 			? ArrowUp
								// 			: ArrowDown
								// 		: null
								// }
								isLoading={!overviewMetrics}
								// bgColor={
								// 	!!overviewMetrics?.avg_monthly_cost_growth &&
								// 	overviewMetrics.avg_monthly_cost_growth.toFixed(2) != '-0' && overviewMetrics.avg_monthly_cost_growth.toFixed(2) != '0'
								// 		? overviewMetrics?.avg_monthly_cost_growth < 0
								// 			? 'success.main'
								// 			: 'error.main'
								// 		: undefined
								// }
								//Commented as per request by pepsico
								// unitIcon={
								// 	azureMonthlyCostDetails !== null && metricDetailsOverSize !== 0
								// 		? azureMonthlyCostDetails.avgMonthlyCost >= 0
								// 			? ArrowIcon
								// 			: ArrowIconRed
								// 		: null
								// }
								// number={azureMonthlyCostDetails !== null ? azureMonthlyCostDetails.avgMonthlyCost.toFixed(2) : 0}
								// color={
								// 	azureMonthlyCostDetails !== null ? azureMonthlyCostDetails.avgMonthlyCost <= 0
								// 			? 'error.main'
								// 			: 'success.main'
								// 		: 'text.main'
								// }
								// isLoading={!azureMonthlyCostDetails || executiveLoading}
								// isInProgress
								handleEvent={() =>
									handleSetComponent('AVG_MONTHLY_COST_TREND', {
										title: 'Avg Monthly Cost Trend',
										sector: [],
										selectedDate: '2023-01'
									})
								}
								isCost={false}
								unitIcon={
									overviewMetrics !== null && overviewMetrics.avg_monthly_cost_growth !== 0
										? overviewMetrics?.avg_monthly_cost_growth >= 0
											? ArrowIconUpRed
											: ArrowIconDownGreen
										: null
								}
								color={
									(overviewMetrics?.avg_monthly_cost_growth ?? 0) <= 0
											? 'success.main'
											: 'error.main'
								}
							/>
						</Grid>
					</WidgetCardContainer>
				</Grid>
			</Grid>
		</Box>
	);
};

export default OverallSummary;

import { Button, ButtonGroup, Input, MenuItem, Select, TextareaAutosize, TextField } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import { createKPIConfig } from "service/service"

export const KPIForm = ({onCreateKPI}: any) => {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [update_frequency_unit, setFrequencyUnit] = useState('days')
    const [update_frequency_value, setFrequencyValue] = useState(1)
    const [selectedFile, setSelectedFile] = useState<any>(null)
    const [source_config, setSourceConfig] = useState<any>(null)
    const [target_config, setTargetConfig] = useState<any>(null)

    const parseFile = (file: any) => {
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = () => {
            const text = reader.result as string
            if(text){
                const jsonData : any = JSON.parse(text)
                if(jsonData.source_config){
                    setSourceConfig(jsonData.source_config)
                }
                if(jsonData.target_config){
                    setTargetConfig(jsonData.target_config)
                }
            }
        }
    }

    const handleFile = (e: any) => {
        if(e.target?.files?.length){
            setSelectedFile(e.target.files[0])
            parseFile(e.target?.files?.[0])
        }
    }

    const saveKPIConfig = async () => {
        const kpiConfig = {
            name,
            description,
            update_frequency_unit,
            update_frequency_value,
            source_config,
            target_config,
            isActive: true
        }
        const result = await createKPIConfig(kpiConfig)
        if(result.success){
            onCreateKPI()
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'row', gap: 4}}>
                <TextField variant='filled' required label='KPI Name' style={{flex: 6}} type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                <div style={{display: 'flex', flexDirection: 'row', flex: 6, gap: 2}}>
                    <TextField variant='filled' required label='Frequency' style={{flex: 4}} type='number' value={update_frequency_value} onChange={(e) => setFrequencyValue(parseInt(e.target.value))}/>
                    <Select variant="filled" style={{flex: 8, textAlign: 'left'}} value={update_frequency_unit} onChange={(e) => setFrequencyUnit(e.target.value)}>
                        <MenuItem value='days'>Days</MenuItem>
                        <MenuItem value='weeks'>Weeks</MenuItem>
                        <MenuItem value='months'>Months</MenuItem>
                    </Select>
                </div>
            </div>
            <div style={{marginTop: 16, display: 'flex', flexDirection: 'row',}}>
                <TextField style={{flex: 12}} variant='filled' label="Description" value={description} onChange={(e) => setDescription(e.target.value)}/>
            </div>
            <div style={{marginTop: 32, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <input type='file' style={{display: 'none'}} id='upload-file' onChange={handleFile}/>
                <label htmlFor="upload-file">
                    <Button variant="contained" component='span'>
                        Upload JSON
                    </Button>
                </label>                
                <ButtonGroup>
                    <Button>Cancel</Button>
                    <Button variant='contained' onClick={saveKPIConfig}>Save</Button>
                </ButtonGroup>
            </div>
            {!!selectedFile && <p style={{textAlign: 'left', marginLeft: 16}}>{selectedFile?.name}</p>}
        </div>
    )
}
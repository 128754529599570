import { Dispatch } from 'react';
import { CacheDataActions, CacheDataState } from '../types/CacheDataContext';
import { CommonDataStateType } from './type';

export const commonDataState: CommonDataStateType = {
	conversionUnit: 'PB',
};

export const commonDataReducer = (state: CacheDataState, action: CacheDataActions): CacheDataState => {
	switch (action.type) {
		case 'CONV_UNIT':
			return { ...state, conversionUnit: action.value };
		default:
			return state;
	}
};

// Action creators
export const setCurrentConversionUnit = (
	dispatch: Dispatch<CacheDataActions>,
	value: CacheDataState['conversionUnit']
) => dispatch({ type: 'CONV_UNIT', value });

import { update } from 'react-querybuilder';
import CustomAutocomplete from 'components/Inputs/CustomAutocomplete';
import { autocompleteSxProps } from './utilities';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import LoaderComponent from 'components/Loader';

const AsyncAutoComplete = ({ query, setQuery, path, fields, rule, fieldValueOptionsCallback }: any) => {
	const defaultOptions: any[] =
		fields[rule.field]?.fieldOptions?.options?.map((item: any) => ({
			value: item,
			label: item
		})) ?? [];
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [fetchedOptions, setFetchedOptions] = useState<any[]>(defaultOptions);
  const isFetchOptionEnabled = !!fieldValueOptionsCallback && inputValue.length > 2

	const fetchOptions = async (query: string): Promise<any[]> => {
		const fieldsRes = await fieldValueOptionsCallback({ searchField: rule.field, searchText: query });
		if (fieldsRes.success) {
			const options = fieldsRes.data.result.filter(Boolean).map((item: any) => ({
				value: item,
				label: item
			}));
			return options;
		}
		return [];
	};

	useEffect(() => {
		if (inputValue.length < 3) {
			setFetchedOptions(defaultOptions);
		}
	}, [fields, rule]);

	useEffect(() => {
		if (isFetchOptionEnabled) {
			setLoading(true);
			fetchOptions(inputValue)
				.then((options) => setFetchedOptions(options))
				.finally(() => setLoading(false));
		} else {
			setFetchedOptions(defaultOptions);
		}
	}, [inputValue]);

	return (
		<CustomAutocomplete
			freeSolo
			noOptionsText="No data found"
			{...(isFetchOptionEnabled && { filterOptions: (x) => x })}
			onInputChange={(_e, value) => {
				if (!rule.field) {
					return;
				}
				setInputValue(value);
				setQuery(update(query, 'value', value, path));
			}}
			onChange={(_e, val) => {
				if (!rule.field || !val) {
					return;
				}
				setQuery(update(query, 'value', val.value, path));
			}}
			options={loading ? [''] : fetchedOptions}
			value={rule.value}
			sx={autocompleteSxProps}
			renderOption={(props, option) => {
				const { key, ...optionProps } = props;
				return (
					<Box component="li" key={key} {...optionProps}>
						{loading ? <LoaderComponent size={25} /> : <Typography>{option?.label ?? ''}</Typography>}
					</Box>
				);
			}}
		/>
	);
};

export default AsyncAutoComplete;

import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Chip,
	Grid,
	Menu,
	Stack,
	TextField,
	List,
	ListItem,
	ListItemText,
	FormControlLabel,
	Select,
	InputLabel,
	FormControl,
	Box,
	GridProps
} from '@mui/material';
import NumberWidget from 'components/Widgets/NumberWidget';
import ChartWidget from 'components/Widgets/ChartWidget';
import QueryIcon from '../../assets/query.svg';
import PageHeader from 'components/Header/Page';
import { useCacheDataController } from 'Context/contexts/CacheDataContext';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import '../TableauObs/Summary/Summary.scss';
// import Workbooks from './Workbooks';
// import TableauUsers from '../../TableauObs/Summary/Users';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as DatePickerIcon } from '../../assets/calendar-outline_white.svg';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import { ReactComponent as ServerIcon } from '../../assets/solar_server-outline.svg';
import { getCostSummaryChart, getDecodedToken, getSector, QuerySummaryTable } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import '../DataEstate/data-estate.scss';
import GaugeChart from 'react-gauge-chart';
import ReactECharts from 'echarts-for-react';
import { fetchQuerySummary, fetchDEQueryTrends } from '../../service/service';
import { ReactComponent as CalendarIcon } from '../../assets/calendar-outline_white.svg';

import ArrowUpBlackIcon from '../../assets/up-arrow-black.svg';
import { getPercentageOfTwoNumbers, formatNumberWithCommas } from '../../utils/common-util';
import { ReactComponent as Downarrow } from '../../assets/down-arrow.svg';
import { CustomizedDataGrid } from '../customized_data_grid';
import { Column } from 'react-data-grid';
import { isEnvTypePROD } from '../../service/config';
import NoPrgramFound from '../../assets/no-program-found.svg';
import { getChartOptions } from 'components/Widgets/chartWidgetUtility';
import LoaderComponent from 'components/Loader';

const gridChildProps: GridProps = {
	minWidth: '260px',
	flexGrow: 1,
	flexBasis: 0,
	maxWidth: '100%'
};
const QueriesSummary = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const [controller] = useCacheDataController();
	const { teradataStorageLastSyncDate, conversionUnit } = controller;
	const minJobExecDate = '2023-03-01';
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	const [prestoBubbleValue, SetPrestoBubbleValue] = useState(0);
	const [prestoQueryLastDec, SetPrestoQueryLastDec] = useState(0);
	const [prestoQueryCurrent, SetPrestoQueryCurrent] = useState(0);

	const [teradataBubbleValue, SetTeradataBubbleValue] = useState(0);
	const [teradataQueryLastDec, SetTeradataQueryLastDec] = useState(0);
	const [teradataQueryCurrent, SetTeradataQueryCurrent] = useState(0);

	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [dateFilterMenu, setDateFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);

	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const dataFetchedRef = useRef(false);
	const [TeradataSize, SetTeradataSize] = useState('-');
	const [PrestoSize, SetPrestoSize] = useState('-');
	const [dimension, setDimension] = useState<any>();

	const [grafanaPrestoLoading, setGrafanaPrestoLoading] = useState(true);
	const [grafanaPrestoOptions, setGrafanaPrestoOptions] = useState<any>();
	const [grafanaTeradataLoading, setGrafanaTeradataLoading] = useState(true);
	const [grafanaTeradataOptions, setGrafanaTeradataOptions] = useState<any>();
	const [lastUpdatedDateTeradata, setLastUpdatedDateTeradata] = useState('');
	const [lastUpdatedDatePresto, setLastUpdatedDatePresto] = useState('');	
	const [filterMonth, setFilterMonth] = useState('0M');
	const [filterDefaultMonth, setFilterDefaultMonth] = useState('0M');
	const [filterYear, setFilterYear] = useState('');
	const [customYear, setCustomYear] = useState(moment().format('YYYY'));
	const [isDateOpen, setIsDateOpen] = useState(false);
	const isCustomMonth = useRef(false);
	const [customMonth, setCustomMonth] = useState('');

	const dateFilterMenuClick = (event: any) => {
		setDateFilterMenu(
			dateFilterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 260,
						mouseY: parseInt(event.clientY) - 50
				  }
				: null
		);
	};

	function formatNumber(number: any) {
		if (number != null && !isNaN(number)) {
			return Number(number).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
		} else {
			return '0.00';
		}
	}
	const handleDateFilterMenuClose = (event: any) => {
		setDateFilterMenu(null);
	};

	const sectorFilterClick = (event: any) => {
		event.preventDefault();
		setFilterMenu(
			filterMenu === null
				? {
						mouseX: parseInt(event.clientX) - 20,
						mouseY: parseInt(event.clientY) - 6
				  }
				: null
		);
	};

	const handleClose = (event: any) => {
		setFilterMenu(null);
	};

	const selectAllCheck = (e: any) => {
		const { checked } = e.target;
		const collection: any[] = [];
		if (checked) setCheckedList(sectorOptionsList);
		else setCheckedList([]);
		setItemsChecked(checked);
	};

	const handleChange = (e: any) => {
		const { value, checked } = e.target;
		const newlyChecked = [];
		if (checked && e.target.value) {
			newlyChecked.push(e.target.value);
		}
		if (checked) {
			setCheckedList([...checkedList, ...newlyChecked]);
			if (sectorOptionsList.length === checkedList.length + newlyChecked.length) {
				setItemsChecked(true);
			}
		} else {
			setCheckedList(checkedList.filter((item) => item !== value));
			setItemsChecked(false);
		}
	};
	const handleOkClick = (event: any) => {
		localStorage.setItem('selectedSector', JSON.stringify(checkedList)); // Tableau drilldown filter changes
		if (checkedList.length > 0) {
			setCurrSectorFilter(checkedList);
		} else {
			setCurrSectorFilter([]);
		}
		getQuerySummaryDetails(checkedList, filterMonth);
		setCurrSector(checkedList);
		handleClose(event);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
			setSectorOptionsList(filteredSectorOptions);
		}
	}

	async function getQuerySummaryDetails(sectors: any, month: any) {
		let sector = sectors.length == sectorOptionsList.length ? [] : sectors;
		// Trend APIs
		SetTeradataSize('-');
		SetPrestoSize('-');
		const todaysDate = moment().utc().format('YYYY-MM-DD');
		// setGrafanaTeradataLoading(true);
		// Teradata
		fetchDEQueryTrends('query', 'presto', sector, month)
			.then((res) => {
				setGrafanaPrestoOptions(res?.data.data);
				setLastUpdatedDatePresto(res?.data.lastUpdatedDate ? res.data.lastUpdatedDate : todaysDate);
				setGrafanaPrestoLoading(false);
			})
			.catch((error: any) => {
				console.error(error);
			});

		// Teradata
		fetchDEQueryTrends('query', 'teradata', sector, month)
			.then((res) => {
				setGrafanaTeradataOptions(res?.data.data);
				setLastUpdatedDateTeradata(res?.data.lastUpdatedDate ? res.data.lastUpdatedDate : todaysDate);
				setGrafanaTeradataLoading(false);
			})
			.catch((error: any) => {
				console.error(error);
			});

		// Summary APIs
		fetchQuerySummary('presto', sector, month)
			.then((res) => {
				let prestoSize = 0;
				if (res?.data?.result[0]?.prestoQueries) {
					prestoSize = res?.data?.result[0]?.prestoQueries;
					SetPrestoSize((prestoSize / 1000).toFixed(2).toString());
				} else {
					SetPrestoSize('0');
				}
			})
			.catch((error) => {
				console.error(error);
			});

		fetchQuerySummary('teradata', sector, month)
			.then((res) => {
				let teradataSize = 0;
				if (res?.data?.result[0]?.teradataQueries) {
					teradataSize = res?.data?.result[0]?.teradataQueries;
					SetTeradataSize((teradataSize / 1000).toFixed(2).toString());
				} else {
					SetTeradataSize('0');
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}

	useEffect(() => {
		if (hasDrillDown) {
			const selectedSector = localStorage.getItem('selectedSector');
			if (selectedSector) {
				const selectedSec = JSON.parse(selectedSector).length ? JSON.parse(selectedSector) : '';
				setCheckedList(selectedSec);
				setCurrSectorFilter(selectedSec);
			}
		} else {
			localStorage.removeItem('selectedSector');
		}

		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/queriessummary') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getQuerySummaryDetails(sector, filterMonth);
	}, []);

	// table
	const [tableName, setTableName] = useState('');
	const [typeName, setTypeName] = useState('');
	interface PRESTO {
		batchId_UsedId: string;
		queryDuration: string;
		greaterThanTenMin: string;
		queryStartTime: string;
		queryEndTime: string;
	}
	interface TERADATA {
		batchId_UsedId: string;
		queryDuration: string;
		queryStartTime: string;
		ueryEndTime: string;
	}
	interface Row {}
	function getColumns(): Array<Column<Row>> {
		if (typeName === 'PRESTO') {
			return [
				{
					key: 'batchId_UsedId',
					name: 'Batch Id/Used Id',
					minWidth: 150
				},
				{
					key: 'queryDuration',
					name: 'Query duration',
					minWidth: 170
				},
				{
					key: 'queryDetails',
					name: 'Query details',
					minWidth: 170
				},
				{
					key: 'greaterThanTenMin',
					name: '>10 Mins?',
					minWidth: 170
				},
				{
					key: 'queryStartTime',
					name: 'Query start time',
					minWidth: 170
				},
				{
					key: 'queryEndTime',
					name: 'Query end time',
					minWidth: 170
				}
			];
		} else if (typeName === 'TERADATA') {
			return [
				{
					key: 'batchId_UsedId',
					name: 'Batch Id/Used Id',
					minWidth: 150
				},
				{
					key: 'queryDuration',
					name: 'Query duration',
					minWidth: 170
				},
				{
					key: 'queryStartTime',
					name: 'Query start time',
					minWidth: 170
				},
				{
					key: 'ueryEndTime',
					name: 'Query end time',
					minWidth: 170
				}
			];
		} else {
			return [];
		}
	}
	const PrestoColumns = [
		'Batch Id/Used Id',
		'Query duration',
		'Query details',
		'>10 Mins?',
		'Query start time',
		'Query end time'
	];
	const teradataColumns = ['Batch Id/Used Id', 'Query duration', 'Query start time', 'Query end time'];
	const [defaultColumnNames, setDynamicValue] = useState(['']);
	const [tableData, setTableData] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [isDetailVisible, setIsDetailVisible] = useState(false);
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		summaryListingFunc(value, pageSize, sortingInfo, dimension);
	};
	const handlepageSizeChange = (event: any) => {
		summaryListingFunc(page, event.target.value, sortingInfo, dimension);
	};
	async function summaryListingFunc(pageNumber: any, size: any, sortingInfo: any, dimension: any) {
		const res = await QuerySummaryTable('query', dimension, sector, '1M');
		if (res.success) {
			console.log(res.data, 'resdata');
			setTableData(res.data);
			if (res.data.page) {
				setPaginationData(res.data.page);
			}
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
	}
	const handleSorting = (sortingInfo: any) => {
		summaryListingFunc(page, pageSize, sortingInfo, dimension);
	};
	const handleOnclick = (value: any) => {
		setDynamicValue(['']);
		setTableData([]);
		setIsDetailVisible(true);
		setTypeName(value);
		if (value === 'PRESTO') {
			setDimension('presto');
			setDynamicValue(PrestoColumns);
			type Row = keyof PRESTO;
			setTableName('Presto Queries');
		}
		if (value === 'TERADATA') {
			setDynamicValue(teradataColumns);
			type Row = keyof TERADATA;
			setTableName('Teradata Queries');
			setDimension('teradata');
		}
		summaryListingFunc(page, pageSize, sortingInfo, dimension);
	};

	const clearMetricsSummary = () => {
		setTableData([]);
		setIsDetailVisible(false);
	};

	const handleDateChange = (selectedDate: string) => {
			clearMetricsSummary();
			getQuerySummaryDetails(sector, selectedDate);
	};

	return (
		<Box mt="45px">
			<PageHeader title="Query Summary" icon={QueryIcon} lastSync={teradataStorageLastSyncDate}>
				{/* Code for Date filter */}
				<DateFilterDropdown
						customMonth={customMonth}
						filterMonth={filterMonth}
						setCustomMonth={setCustomMonth}
						setFilterMonth={setFilterMonth}
						handleChange={handleDateChange}
						/>
				{/* Code for sector filter */}
				{/* <div className="service-sector-filter-cost service-sector-filter">
					<Stack className="sector-filter-dataestate">
					{userSector === 'all' ? (
						<FilterIcon onClick={sectorFilterClick} />
						) : (
						<div className="separeat_sector">Sector: {getDecodedToken()?.sector?.name}</div>
					)}
					<><span className='sector-cta' onClick={sectorFilterClick}><span className='sector-label'>Sectors</span> <FilterIcon /></span></>
					<Popover
					open={filterMenu !== null}
						onClose={handleClose}
						className="filterContainer"
						anchorReference="anchorPosition"
						anchorPosition={filterMenu !== null ? { top: filterMenu.mouseY, left: filterMenu.mouseX } : undefined}
						>
						<div className="text-filter-row">
							<div className="selectAll-sec">
							<MenuItem className="selectAll">
									<FormControlLabel
										className={classes.checkbox}
										label="All"
										control={
											<Checkbox
												color="success"
												value="all"
												checked={itemsChecked}
												onChange={(e) => {
													selectAllCheck(e);
													}}
													/>
													}
													/>
													</MenuItem>
													<div className="checkbox-sec">
													{sectorOptionsList &&
													sectorOptionsList.map((filter: any) => (
														<MenuItem key={filter}>
														<FormControlLabel
														className={classes.checkbox}
														label={filter === true ? 'Yes' : filter === false ? 'No' : filter}
														control={
															<Checkbox
															color="success"
															value={filter}
															checked={
																(filter === true && checkedList.includes('true')) ||
																(filter === false && checkedList.includes('false')) ||
																checkedList.includes(filter)
																}
																onChange={(e) => {
																handleChange(e);
																}}
														/>
													}
													/>
											</MenuItem>
										))}
										</div>
										</div>
										<div className="buttonSec">
										<Button variant="contained" className={classes.cancelBtn} onClick={handleClose}>
										cancel
										</Button>
										<Button variant="contained" className={classes.okBtn} onClick={handleOkClick}>
										ok
										</Button>
										</div>
										</div>
										</Popover>
										</Stack>
										</div> */}
				{/* </div> */}
			</PageHeader>
			<Stack p={2} mt={4}>
				<Grid container gap={2} display="flex" flexDirection="row" flexWrap="wrap" >
					<Grid {...gridChildProps}>
						<NumberWidget
							title="Presto Queries"
							tooltipLabel={''}
							//hasTooltip={false}
							number={formatNumber(PrestoSize)}
							units="k"
							isLoading={PrestoSize === '-'}
							handleEvent={!isEnvTypePROD ? () => handleOnclick('PRESTO') : undefined}
						/>
					</Grid>
					<Grid {...gridChildProps}>
						<NumberWidget
							title="Teradata Queries"
							tooltipLabel={''}
							//asTooltip={false}
							number={formatNumber(TeradataSize)}
							units="k"
							isLoading={TeradataSize === '-'}
							handleEvent={!isEnvTypePROD ? () => handleOnclick('TERADATA') : undefined}
						/>
					</Grid>
				</Grid>

				<Grid container mt={2} spacing={2}>
					<Grid item xs={12} md={6}>
						<ChartWidget
							title="Presto Query Trend"
							data={grafanaPrestoOptions}
							isLoading={grafanaPrestoLoading}
							lastUpdatedDate={lastUpdatedDatePresto}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<ChartWidget
							title="Teradata Query Trend"
							data={grafanaTeradataOptions}
							isLoading={grafanaTeradataLoading}
							hasBadge={false}
							lastUpdatedDate={lastUpdatedDateTeradata}
						/>
					</Grid>
				</Grid>
				{isDetailVisible && (
					<>
						<h2 className="panel-legend--title mt-35 ">{tableName}</h2>
						<div className=" dataestate_tabledata">
							<Grid>
								{tableData.length > 0 ? (
									<CustomizedDataGrid
										tableColumns={getColumns()}
										tableData={tableData}
										tableType="sector"
										defaultColumnNames={defaultColumnNames}
										onSorting={handleSorting}
										currSorting={sortingInfo}
										page={page}
										pageSize={pageSize}
										paginationData={paginationData}
										listLength={tableData.length}
										handlepageSizeChange={handlepageSizeChange}
										handlePageChange={handlePageChange}
										isDateRangeFilter={false}
										isDisabled={false}
									/>
								) : (
									<LoaderComponent />
								)}
							</Grid>
						</div>
					</>
				)}

				{/* </div> */}
			</Stack>
		</Box>
	);
};

export default QueriesSummary;

import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton, MenuItem, Select, Switch } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';
import EyeIcon from '../../assets/lucide_info.svg';

import { ReactComponent as CostUp } from '../../assets/ibp_cost_up.svg';
import { ReactComponent as CostDown } from '../../assets/ibp_cost_down.svg';
import { ReactComponent as CostNoChange } from '../../assets/ibp_cost_no_change.svg';
import { ReactComponent as ExecUp } from '../../assets/ibp_exec_up.svg';
import { ReactComponent as ExecDown } from '../../assets/ibp_exec_down.svg';
import DatabricksLogo from '../../assets/databricks.svg';
import AppServiceLogo from '../../assets/service-App-Services.svg';
import SynapseLogo from '../../assets/service-Azure-Synapse-Analytics.svg';
import BandwidthLogo from '../../assets/service-Bandwidth.svg';
import EventGridLogo from '../../assets/service-Event-Grid-Domains.svg';
import EventHubsLogo from '../../assets/service-Event-Hubs.svg';
import KeyValutsLogo from '../../assets/service-Key-Vaults.svg';
import LogAnalyticsLogo from '../../assets/service-Log-Analytics-Workspaces.svg';
import LogicAppsLogo from '../../assets/service-Logic-Apps.svg';
import MonitorLogo from '../../assets/service-Monitor.svg';
import SQLDatabaseLogo from '../../assets/service-SQL-Database.svg';
import StorageAccountsLogo from '../../assets/service-Storage-Accounts.svg';
import VirtualNetworksLogo from '../../assets/service-Virtual-Networks.svg';

import './ibp-obs.scss';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { dashboardUrl, isEnvTypeDEV, isEnvTypeLOCAL, isEnvTypePROD, isEnvTypeUAT } from '../../service/config';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ibpCostByServiceCall, ibpCostByServiceGrafanaCall } from '../../service/service';
import Loader from '../Loader/loader';

import ReactECharts from 'echarts-for-react';

export default function IBPCost() {

	const [loading, setLoading] = useState(false);
	const [grafanaLoading, setGrafanaLoading] = useState(false);

    const [grafanaOptions, setGrafanaOptions] = useState<any>()

	const [costByServiceList, setCostByServiceList] = useState<any[]>([]);
    
    const [checked, setChecked] = useState(false);

    const dataFetchedRef = useRef(false);
    const [selectedServiceName, setSelectedServiceName] = useState<string>('');
    const [expanded, setExpanded] = useState<string | false>(false);
	const [expandedChild, setExpandedChild] = useState<string | false>(false);
    const [marketName, setMarketName] = useState<string>('PBNA')

    const month1Label = moment().subtract(1, 'months').format('MMM')
    const month2Label = moment().subtract(2, 'months').format('MMM')
    const month3Label = moment().subtract(3, 'months').format('MMM')
    const month1 = moment().subtract(1,'months').format('yyyy-MM')
    const month2 = moment().subtract(2,'months').format('yyyy-MM')
    const month3 = moment().subtract(3,'months').format('yyyy-MM')
    const year = moment().subtract(1, 'months').year()
    moment.updateLocale(moment.locale(), { invalidDate: '' });

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
    }, []);


	const handleChange =
    (panel: string, serviceName: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setSelectedServiceName(serviceName);
      setExpanded(isExpanded ? panel : false);
      setChecked(false);
    };

    const handleChangeChild =
    (panel: string) => {
		setExpandedChild(expandedChild === panel ? false : panel );
    };

    const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
            costByServiceGrafanaFunc(selectedServiceName);
        }
    };
    
      async function costByServiceGrafanaFunc(serviceName: string) {
		setGrafanaLoading(true);
		try {
			const res = await ibpCostByServiceGrafanaCall(serviceName, marketName);
			if (res.success) {
                const options = {
                    title: {
                        text: serviceName,
                        textStyle: {
                          fontSize: '16',
                          color: '#E5E5E5',
                          fontFamily: 'sans-serif',
                          fontStyle: 'normal',
                          fontWeight: 600,
                          lineHeight: '20'
                        }
                      },
                    backgroundColor: '#1B1E35',
                    tooltip: {
                        trigger: "axis",
                        formatter: function (data: any) {
                            return `Total Cost - $${data[0].value}`;
                          }
                    },
                    grid: { left: '5%', right: '5%', bottom: '5%', top: '18%', containLabel: true },
                    xAxis: {
                      type: 'category',
                      data: res.data.result.length ? res.data.result[0].costByMonth.map((m: any) => m.month) : [],
                      axisLabel: {
                        textStyle: {
                          fontSize: 12
                        }
                      }
                    },
                    yAxis: [
                        {
                          name: 'Total Cost USD',
                          nameLocation: 'center',
                          nameRotate: 90,
                          nameGap: 55,
                          type: 'value',
                          axisLine: {
                            show: true
                          },
                          splitLine: {
                                lineStyle: {
                                    color: '#333'
                                }
                            }
                        }
                      ],
                    series: [
                      {
                        name: 'Total Cost',
                        data: res.data.result.length ? res.data.result[0].costByMonth.map((tc: any) => tc.totalCost) : [],
                        stack: 'job',
                        itemStyle: { color: '#3698f2' },
                        barWidth: '25%',
                        type: 'bar',
                        smooth: true,
                      },
                    ],
                  };
                setGrafanaOptions(options);
				setGrafanaLoading(false);
			}			
		} catch (error) {
		}
    }
    
    async function costByServiceListingFunc() {
		setLoading(true);
		try {
			const res = await ibpCostByServiceCall(marketName);
			if (res.success) {
                let filteredData: any = [];
                filteredData = res.data.result.filter((service: any) => {
                    return service.serviceName != 'Bandwidth' && service.serviceName != 'Container Registry' && service.serviceName != 'Load Balancer' && service.serviceName != 'Microsoft Defender for Cloud';
                });
                setCostByServiceList(filteredData.sort((a: any, b: any) => b.totalCost - a.totalCost))
				setLoading(false);
			}			
		} catch (error) {
		}
    }
	
	useEffect(() => {
		if (!loading)
		costByServiceListingFunc();
	}, [marketName])

    return (
        <div className="page-table jobs_tabledata page-table-ibp-cost">
            <div className="ibp-filter-wrapper">
                <div className="IBP-filter-text" style={{marginRight: 32}}>
                    <Select color="secondary" size="medium"
                        value={marketName}
                        onChange={(e: any) => {
                            setMarketName(e.target.value)
                        }}
                    >
                        <MenuItem value={'PBNA'}>IBP - PBNA</MenuItem>
                        <MenuItem value={'FRANCE'}>IBP - France</MenuItem>
                        <MenuItem value={'TURKEY'}>IBP - Turkey</MenuItem>
                    </Select>
                </div> 
                <FilterIcon className="sector-filter1" />
            </div>
            {!loading ? (<div className="business-process-accordion">
                <div className="bp-list--header">
                    <span className="bp--name">Service</span>
                    <span className="count-badge total-gdos">Total Cost <span><img src={EyeIcon} className='eye-icon-styles' title={`Cost of resources over ${month3Label}, ${month2Label}, ${month1Label} ${year}`} /></span></span>
                </div>
                {
                    costByServiceList && costByServiceList.map((service: any, idx: number) => (
                        <Accordion className="w-full" expanded={expanded === 'panel-' + idx} onChange={handleChange('panel-' + idx, service.serviceName)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className="according-expand-icon" />}
                                aria-controls={`panel-` + idx + '-content'}
                                id={`panel-` + idx + '-header'}
                            >
                                <div className="business-process--list">
                                    <span className="bp--name">
                                    {
                                            service.serviceName == 'Azure App Service' && <img src={AppServiceLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'Azure Data Factory v2' && <img src={SynapseLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'Azure Databricks' && <img src={DatabricksLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'Azure Monitor' && <img src={MonitorLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'Azure Synapse Analytics' && <img src={SynapseLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'Event Grid' && <img src={EventGridLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'Event Hubs' && <img src={EventHubsLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'Key Vault' && <img src={KeyValutsLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'Log Analytics' && <img src={LogAnalyticsLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'Logic Apps' && <img src={LogicAppsLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'SQL Database' && <img src={SQLDatabaseLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'Storage' && <img src={StorageAccountsLogo} alt="" className='databricks-icon' />
                                        }
                                        {
                                            service.serviceName == 'Virtual Network' && <img src={VirtualNetworksLogo} alt="" className='databricks-icon' />
                                        }

                                        {service.serviceName}</span>
                                    <span className="total-cost-label">${service.totalCost.toFixed(2)}</span>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            { expanded === 'panel-'+idx && <div className='bp-detail-service'>
                                <h2 className="bp-det-header">{ checked ? 'Trends for '+ selectedServiceName : 'Cost By Resource'}</h2>
                                <div className="grafana-switch">
                                    <span>Current</span>
                                <Switch
                                    checked={checked}
                                    onChange={handleChangeSwitch}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <span>Detailed</span>
                                </div>
                                
                                { checked && !grafanaLoading && <div className="react-grafana-wrapper">
                                    <ReactECharts
                                        option={grafanaOptions}
                                        theme={{
                                            backgroundColor: '#1b1e35'
                                        }}
                                        />
                                    
                                </div>
                                }

                                {grafanaLoading && <Loader />}
                                
                                <div className="resource-table">
                                    <div className='resource-table-header'>
                                        <span className="resource-name">Resource Group</span>
                                        <span className="total-cost">{month3Label}</span>
                                        <span className="total-cost">{month2Label}</span>
                                        <span className="total-cost">{month1Label}</span>
                                        <span className="total-cost">Total Cost</span>
                                    </div>

                                    <div className='sub-resource-groups'>
                                        { service.resourceGroups.map((resource: any, index: number) => (
                                            <div className='resource-groups-children'>
                                                <span className="resource-name">
                                                    <IconButton onClick={() => handleChangeChild('panel2-' + index)}><AddIcon sx={{color: "white"}} /></IconButton>{resource.resourceGroup}</span>
                                                <span className="total-cost">{resource && resource[month3] ? '$' + resource[month3].toFixed(2) : 0}</span>
                                                <span className="total-cost">{resource && resource[month2] ? '$' + resource[month2].toFixed(2) : 0}</span>
                                                <span className="total-cost">{resource && resource[month1] ? '$' + resource[month1].toFixed(2) : 0}</span>
                                                <span className="total-cost">{'$' + resource.totalCost.toFixed(2)}</span>
                                                {expandedChild === 'panel2-' + index && resource.resources?.map((resourceChild: any) => {
                                                    return <div className='resource-child-list'>
                                                    <span className="resource-name">
                                                        <IconButton><RemoveIcon sx={{color: "white"}} /></IconButton>{resourceChild.resource}
                                                    </span>
                                                    <span className="total-cost">{resourceChild && resourceChild[month3] ? '$' + resourceChild[month3].toFixed(2) : 0}</span>
                                                    <span className="total-cost">{resourceChild && resourceChild[month2] ? '$' + resourceChild[month2].toFixed(2) : 0}</span>
                                                    <span className="total-cost">{resourceChild && resourceChild[month1] ? '$' + resourceChild[month1].toFixed(2) : 0}</span>
                                                    <span className="total-cost">{'$' + resourceChild.totalCost.toFixed(2)}</span>
                                                </div>
                                                }) }
                                            </div>
                                        ))
                                        }
                                    </div>
                                </div>

                            </div>
                            }
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </div>
            ) : (
				<Loader />
			)}
        </div>
    );
}

type Callback = (message: string) => void;

class EventEmitter {
    private events: { [key: string]: Callback[] } = {};

    on(event: string, callback: Callback) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(callback);
    }

    emit(event: string, message: string) {
        if (this.events[event]) {
            this.events[event].forEach(callback => callback(message));
        }
    }
}

const eventEmitter = new EventEmitter();
export default eventEmitter;

import React, { useEffect, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './ibp-obs.scss';

import { ibpMonitorMetrics } from '../../service/service';
import { ReactComponent as FilterIcon } from '../../assets/ml_summary_filter.svg';

import EyeIcon from '../../assets/lucide_info.svg';

import SyncIcon from '../../assets/uil_process1.svg'
import SyncActiveIcon from '../../assets/uil_process_selected.svg'
import IBPExecutedJobs from './ibp-jobs';

import Loader from '../Loader/loader';

interface MetricDetails {
	totalGDOs: number;
	completedGDOs: number;
	totalJobs: number;
	successJobs: number;
	failedJobs: number;
	atRiskJobs: number;
}

export default function IbpMonitor() {
	
    const [metricDetails, setMetricDetails] = useState<MetricDetails | null>(null);

	const [selectedGDOName, setSelectedGDOName] = useState('');

	const [businessProcessList, setBusinessProcessList] = useState<any[]>([]);

	const [loading, setLoading] = useState(false);

	const [expanded, setExpanded] = React.useState<string | false>(false);
	const [expandedChild, setExpandedChild] = React.useState<string | false>(false);
	const [marketName, setMarketName] = useState<string>('FRANCE')

	const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		console.log(panel)
      setExpanded(isExpanded ? panel : false);
    };

	const handleChangeChild =
    (panel: string) => (event: React.SyntheticEvent, isExpandedChild: boolean) => {
		console.log(panel)
		setExpandedChild(isExpandedChild ? panel : false);
    };

    async function businessProcessListingFunc() {
		setLoading(true);
		try {
			const res = await ibpMonitorMetrics(marketName);
			if (res.success) {
				setLoading(false);
				setMetricDetails(res.data.overall);
				console.log(res.data.overall, 'monitorData');
				const bpLists = res.data.businessProcesses.filter((bpData: any, index: number) => {
					bpData['id'] = index;
					return bpData
				});
				console.log(bpLists)
				setBusinessProcessList(bpLists);
			}			
		} catch (error) {
		}
    }
	
	useEffect(() => {
		if (!loading)
		businessProcessListingFunc();
	}, [marketName])

	return (
		<div className="page-table jobs-tabledata page-table-ibp-monitor">
            <div className="ibp-filter-wrapper">
                <div className="IBP-filter-text" style={{marginRight: 32}}>
                    <Select color="secondary" size="medium"
                        value={marketName}
                        onChange={(e: any) => {
                            setMarketName(e.target.value)
                        }}
                    >
                        <MenuItem value={'PBNA'}>IBP - PBNA</MenuItem>
                        <MenuItem value={'FRANCE'}>IBP - France</MenuItem>
                    </Select>
                </div> 
                <FilterIcon className="sector-filter1" />
            </div>

			<div className="dashboard-panel ibp-dashboard-wrapper">
				<Grid container spacing={0} className="headerIPB">
					<Grid item xs={6} className="headerIPB-title no-ml bgcolor-grey ">
						<div className="monitor-head">Total GDO's <span><img src={EyeIcon} className='eye-icon-styles' title='Count of GDOs being Observed by PepWise' /></span></div>
						{metricDetails && <div className="subheader">{metricDetails.totalGDOs}</div>}
					</Grid>
					<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
						<Grid container spacing={1} className="headerIPB">
							<Grid item xs={6} className="monitor-title bgcolor-green bdr-green">
						<div className="monitor-head">Completed GDO's <span><img src={EyeIcon} className='eye-icon-styles' title='Count of GDOs whose jobs have completed successfully' /></span></div>
						{metricDetails && <div className="subheader">{metricDetails.completedGDOs}</div>}
							</Grid>
							<Grid item xs={6} className="monitor-title bgcolor-blue bdr-blue">
								<div className="monitor-head">In Progress GDO's <span><img src={EyeIcon} className='eye-icon-styles' title='Count of GDOs whose jobs are currently running' /></span></div>
								{metricDetails && <div className="subheader">{metricDetails.totalGDOs - metricDetails.completedGDOs}</div>}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container spacing={0} className="headerIPB">
					<Grid item xs={6} className="headerIPB-title no-ml bgcolor-grey">
						<div className="monitor-head">Total Jobs <span><img src={EyeIcon} className='eye-icon-styles' title='Count of Total jobs being observed by PepWise' /></span></div>
						{metricDetails && <div className="subheader">{metricDetails.totalJobs}</div>}
					</Grid>
					<Grid item xs={6} className="headerIPB-title subheaderIPB-title no-ml">
						<Grid container spacing={1} className="headerIPB">
							<Grid item xs={4} className="monitor-title bgcolor-green">
								<div className="monitor-head">Successful Jobs <span><img src={EyeIcon} className='eye-icon-styles' title='Count of jobs that have successful executions' /></span></div>
								{metricDetails && <div className="subheader">{metricDetails.successJobs}</div>}
							</Grid>
							<Grid item xs={4} className="monitor-title bgcolor-red bdr-red">
								<div className="monitor-head">Failed Jobs <span><img src={EyeIcon} className='eye-icon-styles' title='Count of jobs that have failed executions' /></span></div>
								{metricDetails && <div className="subheader">{metricDetails.failedJobs}</div>}
							</Grid>
							<Grid item xs={4} className="monitor-title bgcolor-maroon bdr-yellow">
								<div className="monitor-head">At Risk Jobs <span><img src={EyeIcon} className='eye-icon-styles' title='Count of jobs that ran for more that 90% of their expected run time' /></span></div>
								{metricDetails && <div className="subheader">{metricDetails.atRiskJobs}</div>}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>

			{!loading ? (<div className="business-process-accordion">
				<div className="bp-list--header" style={{display:'flex', justifyContent: 'space-between', marginRight: 12}}>
					<span className="bp--name">Business Processes</span>
					<span className="count-badge total-gdos">Total GDOs</span>
					<span className="count-badge completed-gdos">Completed GDOs</span>
					<span className="count-badge inprogress-gdos">In Progress GDOs</span>
					<span className="count-badge total-jobs">Total Jobs</span>
					<span className="count-badge success-jobs">Success Jobs</span>
					<span className="count-badge failed-jobs">Failed Jobs</span>
					<span className="count-badge at-risk-jobs">At Risk Jobs</span>
				</div>
				{ businessProcessList && 
				businessProcessList.map((bpList: any, idx: number) => (
				<Accordion className="w-full" expanded={expanded === 'panel-'+idx} onChange={handleChange('panel-'+idx)}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon className="according-expand-icon" />}
						aria-controls={`panel-`+idx+'-content'}
						id={`panel-`+idx+'-header'}
						>
						<div className="business-process--list"  style={{display:'flex', justifyContent: 'space-between', marginRight: 8}}>
							<div className="bp--name"> 
							{ expanded === 'panel-'+idx ? <div className='sync-wrap'><img src={SyncActiveIcon} alt="" /></div> : <div className='sync-wrap'><img src={SyncIcon} alt="" /></div> }
							<div className="business-process-wrap">
							{/* {
								bpList.businessProcess == 'DMR' ? 'DMR - Demand Management Review' : 
								bpList.businessProcess == 'PMR' ? 'PMR - Product Management Review' :
								bpList.businessProcess == 'SMR' ? 'SMR - Supply Management Review' : 
								bpList.businessProcess == 'IR' ? 'IR - Integrated Reconcilliation' :
								bpList.businessProcess == 'MBR' ? 'MBR - Management Business Review' : ''
							} */}
							{bpList.businessProcess}
							</div></div>
							<div className='count-badge-wrap total-gdos'><span className="count-badge total-gdos">{bpList.totalGDOs}</span></div>
							<div className='count-badge-wrap'><span className="count-badge completed-gdos">{bpList.completedGDOs}</span></div>
							<div className='count-badge-wrap'><span className="count-badge inprogress-gdos">{bpList.totalGDOs - bpList.completedGDOs}</span></div>
							<div className='count-badge-wrap'><span className="count-badge total-jobs">{bpList.totalJobs}</span></div>
							<div className='count-badge-wrap'><span className="count-badge success-jobs">{bpList.successJobs}</span></div>
							<div className='count-badge-wrap'><span className="count-badge failed-jobs">{bpList.failedJobs}</span></div>
							<div className='count-badge-wrap'><span className="count-badge at-risk-jobs">{bpList.atRiskJobs}</span></div>
						</div>
					</AccordionSummary>
					<AccordionDetails>
					{bpList.gdoDetails && bpList.gdoDetails.map((gdoList: any, index: number) => (
						<Accordion className="w-full inner-accordion" expanded={expandedChild === 'panel2-'+index} onChange={handleChangeChild('panel2-'+index)}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon className="according-expand-icon" />}
								aria-controls={`panel2-`+index+'-content'}
								id={`panel2-`+index+'-header'}
								>
								<div className="business-process--list" style={{display:'flex', justifyContent: 'space-between'}}>
									<span className="bp--name">{gdoList.gdo} {gdoList.gdoDesc ? ' - '+gdoList.gdoDesc : ''}</span>
									<div className='count-badge-wrap total-gdos'><span className="count-badge total-gdos">-</span></div>
									<div className='count-badge-wrap'><span className="count-badge completed-gdos">{gdoList.completedGDOs}</span></div>
									<div className='count-badge-wrap'><span className="count-badge inprogress-gdos">{gdoList.completedGDOs? 0 : 1}</span></div>
									<div className='count-badge-wrap'><span className="count-badge total-jobs">{gdoList.totalJobs}</span></div>
									<div className='count-badge-wrap'><span className="count-badge success-jobs">{gdoList.successJobs}</span></div>
									<div className='count-badge-wrap'><span className="count-badge failed-jobs">{gdoList.failedJobs}</span></div>
									<div className='count-badge-wrap'><span className="count-badge at-risk-jobs">{gdoList.atRiskJobs}</span></div>
								</div>
							</AccordionSummary>
							<AccordionDetails style={{paddingRight: 0}}>
								{ expandedChild === 'panel2-'+index && <IBPExecutedJobs gdoName={gdoList.gdo} marketName={marketName}></IBPExecutedJobs> }
							</AccordionDetails>
						</Accordion>
					))}
					</AccordionDetails>
				</Accordion>
				))}
			</div>
			) : (
				<Loader />
			)}
		</div>
	);
}

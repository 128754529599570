import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Grid,
	Stack,
	Tooltip
} from '@mui/material';
import { dashboardUrl, isEnvTypePROD } from '../../service/config';
import '../TableauObs/Summary/Summary.scss';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { biApplicationSummary, fetchCrossEnvData, getDecodedToken, getSector } from '../../service/service';
import useStyles from '../jobs/jobs_styles';
import '../DataEstate/data-estate.scss';
import { fetchBIApplicationSummary, fetchTrends, fetchDEQueryTrends } from '../../service/service';
import ReactECharts from 'echarts-for-react';

import ArrowUpBlackIcon from '../../assets/up-arrow-black.svg';
import {getPercentageOfTwoNumbers, formatNumberWithCommas} from '../../utils/common-util';
import { CustomizedDataGrid } from '../customized_data_grid';
import Loader from '../Loader/loader';
import { Column } from 'react-data-grid';
import ToolTipIcon from "../../assets/info-icon.svg";
import PageHeader from 'components/Header/Page';
import DateFilterDropdown from 'components/Dropdown/DateFilter';
import BiProcIcon from '../../assets/biApplication.svg';
// import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import ChartWidget from 'components/Widgets/ChartWidget';
import NumberWidget from 'components/Widgets/NumberWidget';
import WidgetCardContainer from 'components/Widgets/WidgetCardContainer';
import UnderDevelopmentWidget from 'components/Widgets/UnderDevlopmentWidget';

const BIApplicationSummary = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const hasDrillDown = searchParams.has('isDrillDowned');
	const dateFrom: string = searchParams.get('fromDate')?.substring(0, 10) as string;
	const startDt = moment(dateFrom).startOf('day').subtract(1, 'hours').format('YYYY-MM-DD');
	const [fromDateTime, setFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);
	const [PowerBIReports, SetPowerBIReports] = useState('-');
	const [PowerBIDashboard, SetPowerBIDashboard] = useState('-');
	const [PowerBIDataSets, SetPowerBIDataSets] = useState('-');
	const [PowerBIUsers, SetPowerBIUsers] = useState<number | string>('-');
	const [TableauReports, SetTableauReports] = useState('-');
	const [TableauDatasets, SetTableauDatasets] = useState('-');
	const [TableauCockpitUsers, SetTableauCockpitUsers] = useState('-');
	const [TableauNonCockpitUsers, SetTableauNonCockpitUsers] = useState('-');
	const [TableauLicenses, SetTableauLicenses] = useState('-')

	const [grafanaTableauLoading, setGrafanaTableauLoading] = useState(true);
	const [grafanaTableauOptions, setGrafanaTableauOptions] = useState<any>();
	const [grafanaTableauUserLoading, setGrafanaTableauUserLoading] = useState(true);
	const [grafanaTableauUserOptions, setGrafanaTableauUserOptions] = useState<any>();
	const [grafanaPowerBiLoading, setGrafanaPowerBiLoading] = useState(true);
	const [grafanaPowerBiOptions, setGrafanaPowerBiOptions] = useState<any>();
	const [grafanaPowerBiUserLoading, setGrafanaPowerBiUserLoading] = useState(true);
	const [grafanaPowerBiUserOptions, setGrafanaPowerBiUserOptions] = useState<any>();
	const [lastUpdatedDateTableauUser, setLastUpdatedDateTableauUser] = useState('');
	const [lastUpdatedDatePowerBiUser, setLastUpdatedDatePowerBiUser] = useState('');
	const [lastUpdatedDateTableau, setLastUpdatedDateTableau] = useState('');
	const [lastUpdatedDatePowerBi, setLastUpdatedDatePowerBi] = useState('');
	const [lastUpdatedDatePowerBIUsage, setLastUpdatedDatePowerBIUsage] = useState('');

	const [isInSuffBiDataset, setIsInSuffBiDataset] = useState(false);
	const [isInSuffBiUser, setIsInSuffBiUser] = useState(false);
	const [isNoAnnualBiDataset, setIsNoAnnualBiDataset] = useState(false);
	const [isNoAnnualBiUser, setIsNoAnnualBiUser] = useState(false);
	const [biDsStartMonth, setBiDsStartMonth] = useState('');
	const [biUserStartMonth, setBiUserStartMonth] = useState('');
	const [biDatasetLastDec, SetPowerBiDatasetLastDec] = useState(0);
	const [biDatasetCurrent, SetPowerBiDatasetCurrent] = useState(0);
	
	const [biUserLastDec, SetPowerBiUserLastYear] = useState(0);
	const [biUserCurrent, SetPowerBiUserCurrent] = useState(0);

	const [PowerBiBubbleValue, SetPowerBiBubbleValue] = useState(0);
	const [PowerBiUserBubbleValue, SetPowerBiUserBubbleValue] = useState(0);
	const [TableauBubbleValue, SetTableauBubbleValue] = useState(0);
	const [TableauUserBubbleValue, SetTableauUserBubbleValue] = useState(0);
	const [tableauUserLastDec, SetTableauUserLastDec] = useState(0);
	const [tableauUserCurrent, SetTableauUserCurrent] = useState(0);
	const [tableauUserStartMonth, setTableauUserStartMonth] = useState('');
	const [isNoAnnualTableauUser, setIsNoAnnualTableauUser] = useState(false);

	const [tableauDatasetLastDec, SetTableauDatasetLastDec] = useState(0);
	const [tableauDatasetCurrent, SetTableauDatasetCurrent] = useState(0);


	const [propsFromDateTime, setPropsFromDateTime] = useState<any>(
		searchParams.has('fromDate') ? startDt : moment().startOf('day').subtract(1, 'hours')
	);

	// sector filter
	const userSector = getDecodedToken()?.sector?.name?.toLowerCase();
	const [filterMenu, setFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	const [dateFilterMenu, setDateFilterMenu] = useState<{
		mouseX: number;
		mouseY: number;
	} | null>(null);
	
	const classes = useStyles();
	const [itemsChecked, setItemsChecked] = useState(false);
	const [sectorOptionsList, setSectorOptionsList] = useState<any[]>([]);
	const [checkedList, setCheckedList] = useState<any[]>([]);
	const [currSectorFilter, setCurrSectorFilter] = useState<any[]>([]);
	const [sector, setCurrSector] = useState<any[]>([]);
	const [filterMonth, setFilterMonth] = useState('0M');
	const [filterDefaultMonth, setFilterDefaultMonth] = useState("0M");
	const [customYear, setCustomYear] = useState(moment().format('YYYY'));
	const [isDateOpen, setIsDateOpen] = useState(false);
	const isCustomMonth = useRef(false);

	const dataFetchedRef = useRef(false);

	// table
	const [tableName, setTableName] = useState('');
	const [typeName, setTypeName] = useState('');

	interface BIREPORTS {
		sector: string;
		capacityName: string;
		region: string;
		state: string;
		sku: string;
		workspaceName: string;
		reportName: string;
	}
	interface BIDASHBOARDS {
		sector: string;
		capacityName: string;
		region: string;
		state: string;
		sku: string;
		workspaceName: string;
		dashboardName: string;
	}
	interface BIDATSETS {
		sector: string;
		capacityName: string;
		workspaceName: string;
		reportCount: string;
		dataSourceType: string;
		datasetName: string;
	}
	interface BIUSERS {
		sector: string;
		capacityName: string;
		region: string;
		state: string;
		sku: string;
		workspaceName: string;
		userName: string;
	}
	interface TABLEAUREPORTSUSERS {
		userName: string;
		userType: string;
		gpid: string;
		email: string;
		lastLogin: string;
	}
	interface TABLEAUDATASETS {
		id: number;
		extractType: string;
		extractName: string;
		contentName: string;
		projectName: string;
	}
	interface Row {}
	function getColumns(): Array<Column<Row>> {
		if (typeName === 'BIREPORTS') {
			return [
				{
					key: 'sector',
					name: 'Sector',
					minWidth: 150
				},
				{
					key: 'capacityName',
					name: 'Capacity name',
					minWidth: 170
				},
				{
					key: 'region',
					name: 'Region',
					minWidth: 170
				},
				{
					key: 'state',
					name: 'State',
					minWidth: 170
				},
				{
					key: 'sku',
					name: 'SKU',
					minWidth: 170
				},
				{
					key: 'workspaceName',
					name: 'Workspace name',
					minWidth: 170
				},
				{
					key: 'reportName',
					name: 'Report name',
					minWidth: 150
				}
			];
		} else if (typeName === 'BIDASHBOARDS') {
			return [
				{
					key: 'sector',
					name: 'Sector',
					minWidth: 150
				},
				{
					key: 'capacityName',
					name: 'Capacity name',
					minWidth: 170
				},
				{
					key: 'region',
					name: 'Region',
					minWidth: 170
				},
				{
					key: 'state',
					name: 'State',
					minWidth: 170
				},
				{
					key: 'sku',
					name: 'SKU',
					minWidth: 170
				},
				{
					key: 'workspaceName',
					name: 'Workspace name',
					minWidth: 170
				},
				{
					key: 'dashboardName',
					name: 'Dashboard name',
					minWidth: 150
				}
			];
		} else if (typeName === 'BIDATASETS') {
			return [
				{
					key: 'sector',
					name: 'Sector',
					minWidth: 150
				},
				{
					key: 'capacityName',
					name: 'Capacity name',
					minWidth: 170
				},
				{
					key: 'workspaceName',
					name: 'Workspace name',
					minWidth: 170
				},
				{
					key: 'reportCount',
					name: 'Report count',
					minWidth: 170
				},
				{
					key: 'dataSourceType',
					name: 'Data source type',
					minWidth: 170
				},
				{
					key: 'datasetName',
					name: 'Dataset name',
					minWidth: 170
				}
			];
		} else if (typeName === 'BIUSERS') {
			return [
				{
					key: 'sector',
					name: 'Sector',
					minWidth: 150
				},
				{
					key: 'capacityName',
					name: 'Capacity name',
					minWidth: 170
				},
				{
					key: 'region',
					name: 'Region',
					minWidth: 170
				},
				{
					key: 'state',
					name: 'State',
					minWidth: 170
				},
				{
					key: 'sku',
					name: 'SKU',
					minWidth: 170
				},
				{
					key: 'workspaceName',
					name: 'Workspace name',
					minWidth: 170
				},
				{
					key: 'userName',
					name: 'User name',
					minWidth: 150
				}
			];
		} else if (
			typeName === 'TABLEAUREPORTS' ||
			typeName === 'TABLEAUCOCKPITUSERS' ||
			typeName === 'TABLEAUNONCOCKPITUSERS'
		) {
			return [
				{
					key: 'userName',
					name: 'User name',
					minWidth: 150
				},
				{
					key: 'userType',
					name: 'User type',
					minWidth: 170
				},
				{
					key: 'gpid',
					name: 'GPID',
					minWidth: 170
				},
				{
					key: 'email',
					name: 'Email',
					minWidth: 170
				},
				{
					key: 'lastLogin',
					name: 'Last login',
					minWidth: 170
				}
			];
		} else if (typeName === 'TABLEAUDATASETS') {
			return [
				{
					key: 'id',
					name: 'ID',
					minWidth: 150
				},
				{
					key: 'extractType',
					name: 'Extract type',
					minWidth: 170
				},
				{
					key: 'extractName',
					name: 'Extract name',
					minWidth: 170
				},
				{
					key: 'contentName',
					name: 'Content name',
					minWidth: 170
				},
				{
					key: 'projectName',
					name: 'Project name',
					minWidth: 170
				}
			];
		} else {
			return [];
		}
	}
	const BIReportColumnNames = ['Sector', 'Capacity name', 'Region', 'State', 'SKU', 'Workspace name', 'Report name'];
	const BIDashboardColumnNames = [
		'Sector',
		'Capacity name',
		'Region',
		'State',
		'SKU',
		'Workspace name',
		'Dashboard name'
	];
	const BIDatasetsColumnNames = [
		'Sector',
		'Capacity name',
		'Workspace name',
		'Report count',
		'Data source type',
		'Dataset name'
	];
	const BIUsersColumnNames = ['Sector', 'Capacity name', 'Region', 'State', 'SKU', 'Workspace name', 'User name'];
	const TableauUsersColumnNames = ['User name', 'User type', 'GPID', 'Email', 'Last login'];
	const TableauDatasetsColumnNames = ['ID', 'Extract type', 'Extract name', 'Content name', 'Project name'];
	const [defaultColumnNames, setDynamicValue] = useState(['']);
	const [tableData, setTableData] = useState<Row[]>([]);
	const [sortingInfo, setSortingInfo] = useState({ sortBy: '', sortDir: '' });
	const [paginationData, setPaginationData] = useState({ totalElements: 0, totalPages: 0 });
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(100);
	const [isDetailVisible, setIsDetailVisible] = useState(false);
	const dimension = useRef('');
	const tableRef = useRef<HTMLDivElement>(null);
	const [loading, setLoading] = useState(false);
	const [customMonth, setCustomMonth] = useState('');
	const [grafanaPowerBIUsageOptions, setGrafanaPowerBIUsageOption] = useState<any>();
    const [grafanaPowerBiUsageLoading, setGrafanaPowerBIUsageLoading] = useState(true);
	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		summaryListingFunc(value, pageSize, sortingInfo);
	};

	const handlepageSizeChange = (event: any) => {
		summaryListingFunc(page, event.target.value, sortingInfo);
	};

	async function summaryListingFunc(pageNumber: any, size: any, sortingInfo: any) {
		setLoading(true);
		let month = customMonth ? customMonth : filterMonth;
		const res = await biApplicationSummary('powerbi', dimension.current, sector, month);
		if (res.success) {
			setLoading(false);
			setTableData(res.data.summary);
			setPaginationData(res.data.page);
			setPage(pageNumber);
			setPageSize(size);
			setSortingInfo(sortingInfo);
		}
		setTimeout(() => {
			if (tableRef.current) {
				tableRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}, 1000);
	}

	const handleSorting = (sortingInfo: any) => {
		summaryListingFunc(page, pageSize, sortingInfo);
	};

	const handleMetricsOnclick = (value: any) => {
		setDynamicValue(['']);
		setTableData([]);
		setIsDetailVisible(true);
		setTypeName(value);
		if (value === 'BIREPORTS') {
			setDynamicValue(BIReportColumnNames);
			type Row = keyof BIREPORTS;
			setTableName('PowerBI Reports');
			dimension.current = 'powerbi-reports';
		} else if (value === 'BIDASHBOARDS') {
			setDynamicValue(BIDashboardColumnNames);
			type Row = keyof BIDASHBOARDS;
			setTableName('PowerBI Dashboards');
			dimension.current = 'powerbi-dashboards';
		} else if (value === 'BIDATASETS') {
			setDynamicValue(BIDatasetsColumnNames);
			type Row = keyof BIDATSETS;
			setTableName('PowerBI Datasets');
			dimension.current = 'powerbi-datasets';
		} else if (value === 'BIUSERS') {
			setDynamicValue(BIUsersColumnNames);
			type Row = keyof BIUSERS;
			setTableName('PowerBI Users');
			dimension.current = 'powerbi-users';
		} else if (value === 'TABLEAUREPORTS' || value === 'TABLEAUCOCKPITUSERS' || value === 'TABLEAUNONCOCKPITUSERS') {
			setDynamicValue(TableauUsersColumnNames);
			type Row = keyof TABLEAUREPORTSUSERS;
			if (value === 'TABLEAUREPORTS') {
				setTableName('Tableau Reports');
				dimension.current = 'tableau-reports';
			} else if (value === 'TABLEAUCOCKPITUSERS') {
				setTableName('Tableau Cockpit Users');
				dimension.current = 'tableau-cockpit-users';
			} else {
				setTableName('Tableau Non-Cockpit Users');
				dimension.current = 'tableau-noncockpit-users';
			}
		} else if (value === 'TABLEAUDATASETS') {
			setDynamicValue(TableauDatasetsColumnNames);
			type Row = keyof TABLEAUDATASETS;
			setTableName('Tableau Datasets');
			dimension.current = 'tableau-datasets';
		}
		summaryListingFunc(page, pageSize, sortingInfo);
	};

	const clearMetricsSummary = () => {
		// setDynamicValue(['']);
		setTableData([]);
		setIsDetailVisible(false);
	};

	const handleDateFilter = (selectedDate: string) => {
		clearMetricsSummary();
		getBIApplicationSummaryDetails(sector, selectedDate);
	};

	async function fetchSectorOptionsData() {
		const sectorRes = await getSector('1', '100', '');
		if (sectorRes.success) {
			const filteredSectorOptions = sectorRes.data.sector.filter((x: any) => x.name !== 'S&T').map((x: any) => x.name);
			filteredSectorOptions.push('Blank');
			setSectorOptionsList(filteredSectorOptions);
		}
	}

	async function getBIApplicationSummaryDetails(sectors: any, month: any) {
		let sector = sectors.length == sectorOptionsList.length ? [] : sectors;
		SetPowerBIReports('-');
		SetPowerBIDashboard('-');
		SetPowerBIDataSets('-');
		SetPowerBIUsers('-');
		SetTableauReports('-');
		SetTableauDatasets('-');
		SetTableauCockpitUsers('-');
		SetTableauLicenses('-');
		SetTableauNonCockpitUsers('-');
		setGrafanaTableauLoading(true);
		setGrafanaTableauUserLoading(true);
		setGrafanaPowerBiLoading(true);
		setGrafanaPowerBiUserLoading(true);
		const todaysDate = moment().utc().format('YYYY-MM-DD');
		fetchTrends('datasetgrowth', 'tableau', sector, month)
			.then((res) => {
				setGrafanaTableauOptions(res.data.data);
				setLastUpdatedDateTableau(res?.data.lastUpdatedDate ? res.data.lastUpdatedDate : todaysDate);
				setGrafanaTableauLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
			fetchTrends('tabeauusergrowth', 'tableau', sector, month)
			.then((res) => {
				setGrafanaTableauUserOptions(res.data.data)
				setLastUpdatedDateTableauUser(res.data.lastUpdatedDate);
				setGrafanaTableauUserLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
		// setGrafanaPowerBiLoading(true);
		fetchTrends('powerBiDataset', 'powerbi', sector, month).then((res) => {
			if(res.data) {
				setGrafanaPowerBiOptions(res.data.data);
				setLastUpdatedDatePowerBi(res.data.lastUpdatedDate);
				setGrafanaPowerBiLoading(false);
			}
		})
		.catch((error) => {
			console.error(error);
		});
		fetchTrends('usergrowth', 'powerbi', sector, month).then((res) => {
			if(res.data) {
				console.log(res.data)
				setGrafanaPowerBiUserOptions(res.data.data);
				setLastUpdatedDatePowerBiUser(res?.data.lastUpdatedDate ? res.data.lastUpdatedDate : todaysDate);
				setGrafanaPowerBiUserLoading(false);
			}
		})
		.catch((error) => {
			console.error(error);
		});
		fetchBIApplicationSummary('powerbi-reports', sector, month)
			.then((res) => {
				let count = res?.data?.result[0]?.PowerBIReportsCount;
				SetPowerBIReports((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('powerbi-dashboards', sector, month)
			.then((res) => {
				let count = res?.data?.result[0]?.PowerBIDashboardsCount;
				SetPowerBIDashboard((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('powerbi-datasets', sector, month)
			.then((res) => {
				let count = res?.data?.result[0]?.PowerBIDatasetsCount;
				SetPowerBIDataSets((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('powerbi-users', sector, month)
			.then((res) => {
				let count = res?.data?.result[0]?.PowerBIUsersCount;
				SetPowerBIUsers((count/1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});

		fetchDEQueryTrends('powerBiUsage', 'powerbi', sector, month)
			.then((res) => {
			setGrafanaPowerBIUsageOption(res?.data.data);
			setLastUpdatedDatePowerBIUsage(res.data.lastUpdatedDate);
			setGrafanaPowerBIUsageLoading(false);
			})
					.catch((error: any) => {
						console.error(error);
					});
		
		// fetchCrossEnvData('tableau_reports', 'Tableau', month)
		// 	.then((res) => {
		// 		let count = res?.data[0]?.KPI_VALUE + res?.data[1]?.KPI_VALUE;
		// 		SetTableauReports((count / 1000).toFixed(2).toString());
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
		// fetchCrossEnvData('tableau_datasets', 'Tableau', '0M')
		// 	.then((res) => {
		// 		let count = res?.data[0]?.KPI_VALUE + res?.data[1]?.KPI_VALUE;
		// 		SetTableauDatasets((count / 1000).toFixed(2).toString());
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
		// fetchCrossEnvData('tableau_users', 'Tableau', month)
		// 	.then((res) => {
		// 		debugger;
		// 		let cockpitUsers = res?.data.filter((obj: any) => obj['VENDOR'] === 'cockpit');
		// 		let globalUsers = res?.data.filter((obj: any) => obj['VENDOR'] === 'global');
		// 		SetTableauCockpitUsers((cockpitUsers[0].KPI_VALUE / 1000).toFixed(2).toString());
		// 		SetTableauNonCockpitUsers((globalUsers[0].KPI_VALUE / 1000).toFixed(2).toString());
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 	});
		fetchBIApplicationSummary('tableau-reports', sector, month).then(res => {
			let count = res?.data?.result[0]?.tableauReports
			SetTableauReports((count/1000).toFixed(2).toString());
		}).catch(error => {
			console.error(error);
		})
		fetchBIApplicationSummary('tableau-datasets', sector, month)
			.then((res) => {
				let count = res?.data?.result[0]?.tableauDatasets;
				SetTableauDatasets((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('tableau-cockpit-users', sector, month, moment().format())
			.then((res) => {
				let count = res?.data?.result[0]?.TotalRecords;
				SetTableauCockpitUsers((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('tableau-noncockpit-users', sector, month, moment().format())
			.then((res) => {
				let count = res?.data?.result[0]?.TotalRecords;
				SetTableauNonCockpitUsers((count / 1000).toFixed(2).toString());
			})
			.catch((error) => {
				console.error(error);
			});
		fetchBIApplicationSummary('tableau-license', sector, month, moment().format()).then(res => {
			let count = res?.data?.result[0]?.TotalRecords
			SetTableauLicenses((count/1000).toFixed(2).toString());
		}).catch(error => {
			console.error(error);
		})
	}

	useEffect(() => {
		if (hasDrillDown) {
			const selectedSector = localStorage.getItem('selectedSector');
			if (selectedSector) {
				const selectedSec = JSON.parse(selectedSector).length ? JSON.parse(selectedSector) : '';
				setCheckedList(selectedSec);
				setCurrSectorFilter(selectedSec);
			}
		} else {
			localStorage.removeItem('selectedSector');
		}

		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		if (userSector === 'all') fetchSectorOptionsData();
		const url = window.location.pathname;
		const activeId = document.getElementById('root');
		if (window.location.pathname === '/dataestate/biApplicationSummary') {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
				document.body.classList.add('custom-class');
				activeId.classList.add('jobs-list-table');
				activeId.classList.remove('jobs-list');
				activeId.classList.remove('root');
				document.body.classList.add('jobs-exe-datas');
				document.body.classList.remove('jobs-run-table');
				document.body.classList.add('jobs-list-datas');
				document.body.classList.add('datefilter-align-sector');
				document.body.classList.add('sector-label');
			}
		} else {
			if (activeId) {
				document.body.classList.remove('jobs-list-datas');
				activeId.classList.add('root');
				document.body.classList.add('dashboard-panel-dataestate');
				document.body.classList.add('datefilter-align-sector');
			}
		}
		getBIApplicationSummaryDetails(sector, filterMonth);
	}, []);
	let fromDate = moment(propsFromDateTime).format('YYYY-MM-DD');
	return (
		<Box mt="45px">
			<PageHeader title="BI Application Summary" icon={BiProcIcon} lastSync={''}>
				<DateFilterDropdown
					customMonth={customMonth}
					filterMonth={filterMonth}
					setCustomMonth={setCustomMonth}
					setFilterMonth={setFilterMonth}
					handleChange={handleDateFilter}
				/>
			</PageHeader>
			<Grid container spacing={1} p={2} mt={2}>
				<Grid item container xs={12} md={6}>
						<WidgetCardContainer title="Power BI">
							<Grid container spacing={1}>
								<Grid item xs={12} md={6}>
									<NumberWidget
										title="Reports"
										tooltipLabel={''}
										hasTooltip={false}
										number={PowerBIReports && PowerBIReports.toString() !== '-' ? formatNumberWithCommas(PowerBIReports) : '-'}
										units={PowerBIReports && PowerBIReports.toString() !== '-' ? 'k' : ''}
										isLoading={PowerBIReports ? PowerBIReports.toString() === '-' : false}
										handleEvent={!isEnvTypePROD ? () => handleMetricsOnclick('BIREPORTS') : undefined}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<NumberWidget
										title="Datasets"
										tooltipLabel={''}
										hasTooltip={false}
										number={PowerBIDataSets && PowerBIDataSets.toString() !== '-' ? formatNumberWithCommas(PowerBIDataSets) : '-'}
										units={PowerBIDataSets && PowerBIDataSets.toString() !== '-' ? 'k' : ''}
										isLoading={PowerBIDataSets ? PowerBIDataSets.toString() === '-' : false}
										handleEvent={!isEnvTypePROD ? () => handleMetricsOnclick('BIDATASETS') : undefined}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<NumberWidget
										title="Workspaces"
										tooltipLabel={''}
										hasTooltip={false}
										number={PowerBIDashboard && PowerBIDashboard.toString() !== '-' ? PowerBIDashboard : '-'}
										units={PowerBIDashboard && PowerBIDashboard.toString() !== '-' ? 'k' : ''}
										isLoading={PowerBIDashboard ? PowerBIDashboard.toString() === '-' : false}
										handleEvent={!isEnvTypePROD ? () => handleMetricsOnclick('BIDASHBOARDS') : undefined}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<NumberWidget
										title="Users"
										tooltipLabel={''}
										hasTooltip={false}
										number={PowerBIUsers && PowerBIUsers.toString() !== '-' ? PowerBIUsers : '-'}
										units={PowerBIUsers && PowerBIUsers.toString() !== '-' ? 'k' : ''}
										isLoading={PowerBIUsers ? PowerBIUsers.toString() === '-' : false}
										handleEvent={!isEnvTypePROD ? () => handleMetricsOnclick('BIUSERS') : undefined}
									/>
								</Grid>
								<Grid item xs={12}>
									<ChartWidget
										title="Datasets Trend"
										data={grafanaPowerBiOptions}
										isLoading={grafanaPowerBiLoading}
									/>
									{/* <UnderDevelopmentWidget title="Datasets Trend" /> */}
								</Grid>
								<Grid item xs={12}>
								    <ChartWidget
											title="Usage Trend"
											data={grafanaPowerBIUsageOptions}
											isLoading={grafanaPowerBiUsageLoading}
											lastUpdatedDate={lastUpdatedDatePowerBIUsage}
										/>
								</Grid>
							</Grid>
						</WidgetCardContainer>
				</Grid>
				<Grid item container xs={12} md={6}>
					<WidgetCardContainer title="Tableau">
						<Grid container spacing={1}>
							<Grid item xs={12} md={4}>
								<NumberWidget
											title="Reports"
											tooltipLabel={''}
											hasTooltip={false}
											number={TableauReports && TableauReports.toString() !== '-' ? formatNumberWithCommas(TableauReports) : '-'}
											units={TableauReports && TableauReports.toString() !== '-' ? 'k' : ''}
											isLoading={TableauReports ? TableauReports.toString() === '-' : false}
											handleEvent={!isEnvTypePROD ? () => handleMetricsOnclick('TABLEAUREPORTS') : undefined}
										/>
							</Grid>
							<Grid item xs={12} md={4}>
								<NumberWidget
											title="Datasets"
											tooltipLabel={''}
											hasTooltip={false}
											number={TableauDatasets && TableauDatasets.toString() !== '-' ? formatNumberWithCommas(TableauDatasets) : '-'}
											units={TableauDatasets && TableauDatasets.toString() !== '-' ? 'k' : ''}
											isLoading={TableauDatasets ? TableauDatasets.toString() === '-' : false}
											handleEvent={!isEnvTypePROD ? () => handleMetricsOnclick('TABLEAUDATASETS') : undefined}
										/>
							</Grid>
							<Grid item xs={12} md={4}>
								<NumberWidget
									title="Licenses"
									tooltipLabel={''}
									hasTooltip={false}
									number={TableauLicenses && TableauLicenses.toString() !== '-' ? formatNumberWithCommas(TableauLicenses) : '-'}
									units={TableauLicenses && TableauLicenses.toString() !== '-' ? 'k' : ''}
									isLoading={TableauLicenses ? TableauLicenses.toString() === '-' : false}
									handleEvent={undefined}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<NumberWidget
									title="Cockpit Users"
									tooltipLabel={''}
									hasTooltip={false}
									number={TableauCockpitUsers && TableauCockpitUsers.toString() !== '-' ? formatNumberWithCommas(TableauCockpitUsers) : '-'}
									units={TableauCockpitUsers && TableauCockpitUsers.toString() !== '-' ? 'k' : ''}
									isLoading={TableauCockpitUsers ? TableauCockpitUsers.toString() === '-' : false}
									handleEvent={!isEnvTypePROD ? () => handleMetricsOnclick('TABLEAUCOCKPITUSERS') : undefined}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<NumberWidget
									title="Global Users"
									tooltipLabel={''}
									hasTooltip={false}
									number={TableauNonCockpitUsers && TableauNonCockpitUsers.toString() !== '-' ? formatNumberWithCommas(TableauNonCockpitUsers) : '-'}
									units={TableauNonCockpitUsers && TableauNonCockpitUsers.toString() !== '-' ? 'k' : ''}
									isLoading={TableauNonCockpitUsers ? TableauNonCockpitUsers.toString() === '-' : false}
									handleEvent={!isEnvTypePROD ? () => handleMetricsOnclick('TABLEAUNONCOCKPITUSERS') : undefined}
								/>
							</Grid>
							<Grid item xs={12}>
								<ChartWidget
									title="Datasets Trend"
									data={grafanaTableauOptions}
									isLoading={grafanaTableauLoading}
									lastUpdatedDate={lastUpdatedDateTableau}
								/>
							</Grid>
							<Grid item xs={12}>
								<ChartWidget
									title="Users Trend"
									data={grafanaTableauUserOptions}
									isLoading={grafanaTableauUserLoading}
									lastUpdatedDate={lastUpdatedDateTableauUser}
								/>
							</Grid>
						</Grid>
					</WidgetCardContainer>
				</Grid>
			</Grid>		
		</Box>
	);
};

export default BIApplicationSummary;

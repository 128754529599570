import { Stack, Typography } from '@mui/material';

const SplitNumber = ({
	number,
	isCost,
	isOverlayWidget
}: {
	number: number | string;
	isCost?: boolean;
	isOverlayWidget?: boolean;
}) => {
	const [integerPart, decimalPart] = number.toString().split('.');

	return (
		<Stack direction="row" alignItems="baseline">
			<Typography variant={isOverlayWidget ? 'overlayWidgetValue' : 'widgetValue'}>{`${
				isCost ? '$' : ''
			}${integerPart}`}</Typography>
			{decimalPart && (
				<Typography
					variant={isOverlayWidget ? 'overlayWidgetUnit' : 'widgetUnit'}
					m={0}
				>{`.${decimalPart}`}</Typography>
			)}
		</Stack>
	);
};

export default SplitNumber;

import { Box, BoxProps, FormControl, Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

type CustomDropdownProps = {
    options: {
		label: string;
		value: string;
        displayLabel: string;
	}[];
    value: string;
    handleInputChange: (event: any) => void; 
    handleChange: (event: any, value: any) => void;
    boxProps?: BoxProps;
    componentsProps?: any;
	inputSx?: any;
	searchInputLoading?: boolean;
    noOptionsText?: string;
};

const AutoCompleteIndex = ({
    value,
    options,
    handleInputChange,
    handleChange,
    boxProps,
    componentsProps,
	inputSx,
	searchInputLoading,
    noOptionsText,
}: CustomDropdownProps) => {
    const { sx, ...props } = boxProps || {};
    const [lastSelectedValue, setLastSelectedValue] = useState<string | null>(value);
	const selectedOption = options.find(option => option.value === value);
    const selectedValue = selectedOption ? selectedOption : value ?  {
		label: value,
		value: value,
        displayLabel: value,
	} : null;

    const truncatedLabel =
        selectedValue && selectedValue.label.length > 25
            ? selectedValue.label.substring(0, 25) + '...'
            : selectedValue?.label || '';

    useEffect(() => {
        if (selectedValue) {
            setLastSelectedValue(selectedValue.value);
        }
    }, [selectedValue]);
    
    return (
        <Box
            sx={{
                backgroundColor: 'blue.dark',
                borderRadius: '4px',
                fontSize: '15px',
                zIndex: 999,
                minWidth: '90px',
                textAlign: 'left',
                height: 'fit-content',
                width: truncatedLabel ? `${truncatedLabel.length * 8 + 70}px` : '90px',
                ...sx,
            }}
            {...props}
        >
            <FormControl fullWidth>
                <Autocomplete
					value={selectedValue}
                    onChange={(e, val) => handleChange(e, val?.value)}
                    onBlur={(e) => {
                        const input = e.target as HTMLInputElement;
                        if (!input.value && lastSelectedValue) {
                            handleChange(e, lastSelectedValue);
                        }
                    }}
					loading={searchInputLoading}
                    noOptionsText={noOptionsText}
                    options={options}
                    getOptionLabel={(option) => option.displayLabel} 
                    renderOption={(props, option) => (
                        <li {...props}>{option.label}</li>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} onChange={(e) => handleInputChange(e)} />
                    )}
                    componentsProps={componentsProps}
					sx={inputSx}
                />
            </FormControl>
        </Box>
    );
};

export default AutoCompleteIndex;

import React, { useEffect } from 'react';
import './dashboard.scss';
import { powerBIdashboardUrl } from '../../service/config';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AnalyticsVisualization from '../framework/analyticsVisualization';
import { usePepwiseAuthController } from 'Context/contexts/AuthContext';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

const PlatformCostObsDashboard = () => {
	const [controller] = usePepwiseAuthController();
  const { role } = controller;
	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		const activeId = document.getElementById('root');
		console.log(window.location.pathname);
		if (window.location.pathname.includes('/dataestate') && role !== "Program Executive") {
			if (activeId) {
				document.head.classList.add('theme-bg-blue');
				document.body.classList.add('theme-bg-blue');
			}
		}
	}, []);

	return (
		<div className="base-tab">
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs className="PBITabs" value={value} onChange={handleChange} aria-label="basic tabs example">
						<Tab label="PBNA" {...a11yProps(0)} />
						<Tab label="LATAM" {...a11yProps(1)} />
						<Tab label="PFNA" {...a11yProps(2)} />
						<Tab label="CGF" {...a11yProps(3)} />
						<Tab label="AMESA/APAC" {...a11yProps(4)} />
						<Tab label="EUROPE" {...a11yProps(5)} />
					</Tabs>
				</Box>
				<CustomTabPanel value={value} index={0}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: powerBIdashboardUrl.PBNADashboard,
							className: 'powerBiIframeContainer',
							otherParams: {
								name: 'PowerBI'
							},
							seamless: true
						}}
					/>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={1}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: powerBIdashboardUrl.LATAMDashboard,
							className: 'powerBiIframeContainer',
							otherParams: {
								name: 'PowerBI'
							},
							seamless: true
						}}
					/>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={2}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: powerBIdashboardUrl.PFNADashboard,
							className: 'powerBiIframeContainer',
							otherParams: {
								name: 'PowerBI'
							},
							seamless: true
						}}
					/>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={3}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: powerBIdashboardUrl.CGFDashboard,
							className: 'powerBiIframeContainer',
							otherParams: {
								name: 'PowerBI'
							},
							seamless: true
						}}
					/>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={4}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: powerBIdashboardUrl.AMESADashboard,
							className: 'powerBiIframeContainer',
							otherParams: {
								name: 'PowerBI'
							},
							seamless: true
						}}
					/>
				</CustomTabPanel>
				<CustomTabPanel value={value} index={5}>
					<AnalyticsVisualization
						params={{
							dashboardUrl: powerBIdashboardUrl.EUROPEDashboard,
							className: 'powerBiIframeContainer',
							otherParams: {
								name: 'PowerBI'
							},
							seamless: true
						}}
					/>
				</CustomTabPanel>
			</Box>
		</div>
	);
};

export default PlatformCostObsDashboard;

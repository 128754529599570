import { Typography, TypographyProps } from '@mui/material';

const RowTypography: React.FC<
	{ children: React.ReactNode; isSuccessor?: boolean; isEditing?: boolean } & TypographyProps
> = ({ isSuccessor = false, children, isEditing = false, ...props }) => (
	<Typography
		variant="tableRowFont"
		textOverflow="ellipsis"
		overflow="hidden"
		color={isSuccessor ? 'light.diff' : 'white.main'}
		{...(isEditing && {
			sx: {
				display: 'inline-flex',
				width: 'calc(100% - 10px)',
				'&:hover': {
					textOverflow: 'clip',
					overflowX: 'auto !important',
					overflowY: 'hidden !important',
					'&::-webkit-scrollbar': {
						height: '4px !important'
					}
				},
				...props.sx
			}
		})}
		{...props}
	>
		{children}
	</Typography>
);

export default RowTypography;

import React from 'react';
import { Tooltip, Box, Stack, Typography } from '@mui/material';
import { formatIsoDate } from 'utils/common-util';
import { ReactComponent as CalendarGreyIcon } from '../../assets/icon-calendar-grey.svg';

type TooltipItem = {
	label: string;
	value: string;
};

export type DateInfoTooltipProps = {
	titleValues?: TooltipItem[];
	dateValues?: TooltipItem[];
	children: JSX.Element;
};

const DateInfoTooltip: React.FC<DateInfoTooltipProps> = ({ titleValues, dateValues, children }) => {
	return (
		<Tooltip
			arrow
			title={
				<Stack spacing={1}>
					{titleValues?.map((item, index) => (
						<Stack key={index}>
							<Typography variant="subtitle2" color="text.subtle">
								{item.label}
							</Typography>
							<Typography variant="subtitle1">{item.value}</Typography>
						</Stack>
					))}
					<Box display="flex" gap={1}>
						{dateValues?.map((item, index) => (
							<Stack key={index}>
								<Typography variant="subtitle2" color="text.subtle">
									{item.label}
								</Typography>
								<Typography variant="subtitle1">
									{item.value && item.value !== 'NA' ? (
										<Box display="inline-flex" alignItems="center" gap={0.5}>
											{formatIsoDate(item.value)}{' '}
											<Box component="span">
												<CalendarGreyIcon />
											</Box>
										</Box>
									) : (
										'NA'
									)}
								</Typography>
							</Stack>
						))}
					</Box>
				</Stack>
			}
		>
			{children}
		</Tooltip>
	);
};

export default DateInfoTooltip;
